import { useSelector } from 'react-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { RootState } from '..';
import Api from '../api';
import { liveSessionEnded, liveSessionEndFailed, LIVE_SESSION_END_REQUEST } from '../modules/liveSessionEnd';
import { IinitialStateLiveStart, initLiveSessionStarted } from '../modules/liveSessionStart';
import { logout } from '../modules/logout';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction } from './utils/function';

export function* watchliveSessionEnd() {
  yield takeEvery(LIVE_SESSION_END_REQUEST, liveSessionEnd);
}

export function* liveSessionEnd({ payload }: any) {
  try {
    const { status } = yield call(Api.liveSessionRetailer.liveSessionEnd, payload.id);

    if (status === 200) {
      const adaptorAntMedia = payload.adaptorRTC;
      if (adaptorAntMedia) {
        try {
          console.log('- - - - Close publisher - - - -');
          adaptorAntMedia.stop(payload.id);
          adaptorAntMedia.turnOffLocalCamera();
          adaptorAntMedia.closeStream(payload.id);
          adaptorAntMedia.closeWebSocket(payload.id);
        } catch (error) {
          console.log('no adaptor >>:', error);
        }
      }

      yield put(initLiveSessionStarted());
      yield put(liveSessionEnded(status));
      yield put(
        Set_state_snackbar({
          message: 'Votre live a été arrêté avec succès',
          status: 200,
          open: true,
          type: 'success',
        }),
      );
    }
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(liveSessionEndFailed({ status, error: 'bad request' }));
          yield put(Set_state_snackbar({ status: error.status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(liveSessionEndFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(
            Set_state_snackbar({
              status: error.status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(liveSessionEndFailed({ status, error: 'Bad request' }));
          yield put(Set_state_snackbar({ status: error.status, message: 'Error Server', open: true, type: 'error' }));
          break;
        default:
          yield put(liveSessionEndFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(liveSessionEndFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );

            break;
          default:
            yield put(liveSessionEndFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
