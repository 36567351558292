import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './Themes/index';
import { ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';

//*** sentry ***/
Sentry.init({
  environment: (process.env.REACT_APP_SENTRY_ENVIRONNEMENT as string) ?? 'dev',
  dsn: 'https://e22466dba18982f4afd57047f53f0c80@o4507408084631552.ingest.de.sentry.io/4507408110911568',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^https:\/\/app-int.livood\.com\//,
        /^https:\/\/app-qualif.livood\.com\//,
        /^https:\/\/app.livood\.com\//,
      ],
    }),
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
