import { typedAction } from '../../utils';

export const MAILDATA_GUEST_REQUEST = 'MAILDATA/GUEST/REQUEST';
export const MAILDATA_GUEST_SUCCESS = 'MAILDATA/GUEST/SUCCESS';
export const MAILDATA_GUEST_FAILED = 'MAILDATA/GUEST/FAILED';
export const MAILDATA_GUEST_CLEAR = 'MAILDATA/GUEST/CLEAR';

export interface IMailData {
  subject: string;
  body: string;
  isHtmlBody: boolean;
}

export interface IinitialStateMailDataGuest {
  mailDataGuest: IMailData;
  loading: boolean;
  error: string | undefined;
  status: number | undefined;
}

const initialState: IinitialStateMailDataGuest = {
  mailDataGuest: {
    subject: '',
    body: '',
    isHtmlBody: false,
  },
  status: undefined,
  error: undefined,
  loading: false,
}; // GetInvitationMailData

export const get_invitationMailData = (guestId: string) => typedAction(MAILDATA_GUEST_REQUEST, guestId);
export type Get_invitationMailData = typeof get_invitationMailData;

export const clear_invitationMailData = () => typedAction(MAILDATA_GUEST_CLEAR);
export type Clear_invitationMailData = typeof clear_invitationMailData;

export const get_invitationMailData_Success = (data: IMailData[]) => typedAction(MAILDATA_GUEST_SUCCESS, data);
export type Get_invitationMailData_Success = typeof get_invitationMailData_Success;

export const get_invitationMailData_failed = (args: { status: number; error: string }) =>
  typedAction(MAILDATA_GUEST_FAILED, args);
export type Get_invitationMailData_failed = typeof get_invitationMailData_failed;

export type UpdateGuestAction = ReturnType<
  Get_invitationMailData | Get_invitationMailData_Success | Get_invitationMailData_failed | Clear_invitationMailData
>;

export const MailDataGuestReducers = (
  state: IinitialStateMailDataGuest = initialState,
  action: UpdateGuestAction,
): IinitialStateMailDataGuest => {
  switch (action.type) {
    case MAILDATA_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MAILDATA_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        mailDataGuest: action.payload,
      };
    case MAILDATA_GUEST_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    case MAILDATA_GUEST_CLEAR:
      return initialState;
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
