import { IBodyConsumerCount } from '../../../type/account';
import { typedAction } from '../../../utils';

export const CREATE_ACCOUNT_CONSUMER_REQUESTED = 'CREATE_ACCOUNT/CONSUMER/REQUESTED';
export const CREATE_ACCOUNT_CONSUMER_SUCCESS = 'CREATE_ACCOUNT/CONSUMER/SUCCESS';
export const CREATE_ACCOUNT_CONSUMER_FAILED = 'CREATE_ACCOUNT/CONSUMER/FAILED';

export interface IinitialstateCreateAccountConsumer {
  status: number;
  loading: boolean;
}

const initialStateCreateAccountConsumer: IinitialstateCreateAccountConsumer = {
  status: 0,
  loading: false,
};

export const DO_create_account_consumer = (data: IBodyConsumerCount) =>
  typedAction(CREATE_ACCOUNT_CONSUMER_REQUESTED, data);
export type IDO_create_account_consumer = typeof DO_create_account_consumer;

export const CreateAccount_consumer_succefull = (status?: number) =>
  typedAction(CREATE_ACCOUNT_CONSUMER_SUCCESS, status);
export type ICreateAccount_consumer_success = typeof CreateAccount_consumer_succefull;

export const CreateAccount_consumer_failed = () => typedAction(CREATE_ACCOUNT_CONSUMER_FAILED);
export type ICreateAccount_consumer_failed = typeof CreateAccount_consumer_failed;

export type CreateAccountConsumerAction = ReturnType<
  IDO_create_account_consumer | ICreateAccount_consumer_failed | ICreateAccount_consumer_success
>;

export const CreateAccount_consumerReducer = (
  state: IinitialstateCreateAccountConsumer = initialStateCreateAccountConsumer,
  action: CreateAccountConsumerAction,
): IinitialstateCreateAccountConsumer => {
  switch (action.type) {
    case CREATE_ACCOUNT_CONSUMER_REQUESTED:
      return {
        ...state,
        status: 0,
        loading: true,
      };
    case CREATE_ACCOUNT_CONSUMER_SUCCESS:
      return {
        ...state,
        status: action.payload,
        loading: false,
      };
    case CREATE_ACCOUNT_CONSUMER_FAILED:
      return {
        ...state,
        status: 0,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
