import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import authServices from '../services/authSevices';
import { API_URL } from '../../lib/config';
import axios, { AxiosPromise } from 'axios';
import { IListenerHistoryParams } from '../modules/listenerHistory';
import { IMessage, IViewer } from '../type/listenerApi';

export const connexionToListener = async (): Promise<HubConnection> => {
  const token = await authServices.getAccessTokenSignalR();

  return new HubConnectionBuilder()
    .withUrl(API_URL + '/LiveSessionHub', {
      headers: {
        Authorization: `Bearer ${token || ''}`,
      },
      withCredentials: false,
      accessTokenFactory: () => token ?? 'token',
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
};

export const checkHistoryMessagesListener = (historyParams: IListenerHistoryParams): AxiosPromise<IMessage[]> =>
  axios.get(`/${historyParams.userType}/LiveSession/Messages/${historyParams.id.split('?participantId=')[0]}`);

export const checkHistoryViewersListener = (historyParams: IListenerHistoryParams): AxiosPromise<IViewer[]> => {
  const _liveId =
    historyParams.userType === 'Consumer' ? historyParams.id : historyParams.id.split('?participantId=')[0];
  return axios.get(`/${historyParams.userType}/LiveSession/Participants/${_liveId}`);
};
