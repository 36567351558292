import { createLocalAudioTrack, createLocalTracks, createLocalVideoTrack, Participant, Room } from 'livekit-client';
import { useParticipant, VideoRenderer } from '@livekit/react-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WithoutVideoComponent from './WithoutVideoComponent';
import { AspectRatio } from 'react-aspect-ratio';
import { AudioRenderer } from '@livekit/react-components';
import iconPin from '../../Assets/images/icons8-pin-50.png';
import iconPinRed from '../../Assets/images/icons-pin-red.png';
import { useStyles } from './styles';
import { getPinnedOfRoom, updateMetadataOfParticipant, updateMetadataOfRoom } from './utilsRoom/functionRoom';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
LoadingOverlay.propTypes = undefined;
import RingLoader from 'react-spinners/RingLoader';
import { MicOff } from '@material-ui/icons';

interface UserRoomInterface {
  room: Room;
  participant: Participant;
  usernameRoom: string;
  videoEnabled?: boolean;
  audioEnabled?: boolean;
  videoDeviceID?: string;
  audioDeviceID?: string;
  networkQuality?: (quality: string) => void;
  facingMode?: 'user' | 'environment';
  typeUser?: 'retailer' | 'vip' | 'friends' | 'undefined';
}

const UserRoomComponent = (props: UserRoomInterface) => {
  const {
    participant,
    room,
    usernameRoom,
    videoEnabled,
    audioEnabled,
    audioDeviceID,
    videoDeviceID,
    typeUser,
    networkQuality,
  } = props;
  const { isLocal, cameraPublication, microphonePublication, connectionQuality } = useParticipant(participant);
  const style = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  // publish track
  const publishTrack = useCallback(async () => {
    const videoEnable = await localStorage.getItem('videoEnabled');
    const audioEnable = await localStorage.getItem('audioEnabled');
    if (room?.localParticipant.videoTracks.size === 0 && videoEnable === 'true') {
      createLocalVideoTrack().then((track) => {
        room?.localParticipant.publishTrack(track);
      });
    }
    if (room?.localParticipant.audioTracks.size === 0 && audioEnable === 'true') {
      createLocalAudioTrack().then((track) => {
        room?.localParticipant.publishTrack(track);
      });
    }
  }, []);

  // compoent Video
  const videoElement = useMemo(() => {
    if (cameraPublication && cameraPublication.track && participant.isCameraEnabled) {
      return (
        <AspectRatio ratio={16 / 9} style={{ width: '100%', height: '100%' }} id="video-live-container">
          <VideoRenderer track={cameraPublication.track} isLocal={isLocal} isMirrored={true} />
        </AspectRatio>
      );
    }
    if (!participant.isCameraEnabled && participant.metadata) {
      return <WithoutVideoComponent username={usernameRoom} color={JSON.parse(participant.metadata).backgroundColor} />;
    }
  }, [cameraPublication?.track, participant.isCameraEnabled]);

  const handlePinned = async () => {
    setLoading(true);
    const pinnedOfRoom = await getPinnedOfRoom(room);

    if (pinnedOfRoom === undefined) {
      await updateMetadataOfParticipant(room, participant.identity, participant, 'pinned', true);
      await updateMetadataOfParticipant(room, participant.identity, participant, 'type', 'initiator');
      await updateMetadataOfParticipant(room, room.localParticipant.identity, room.localParticipant, 'type', 'vip');
      await updateMetadataOfRoom(room, 'pinnedInLive', participant);
      setLoading(false);
    }
    if (pinnedOfRoom && pinnedOfRoom.identity === participant.identity) {
      await updateMetadataOfParticipant(room, participant.identity, participant, 'pinned', false);
      await updateMetadataOfParticipant(room, room.localParticipant.identity, room.localParticipant, 'pinned', true);
      await updateMetadataOfParticipant(
        room,
        room.localParticipant.identity,
        room.localParticipant,
        'type',
        'initiator',
      );
      await updateMetadataOfParticipant(room, participant.identity, participant, 'type', 'vip');
      await updateMetadataOfRoom(room, 'pinnedInLive', undefined);
      setLoading(false);
    }
    if (pinnedOfRoom && pinnedOfRoom.identity !== participant.identity) {
      await updateMetadataOfParticipant(room, pinnedOfRoom.identity, pinnedOfRoom, 'pinned', false);
      await updateMetadataOfParticipant(room, participant.identity, participant, 'pinned', true);
      await updateMetadataOfParticipant(room, participant.identity, participant, 'type', 'initiator');
      await updateMetadataOfParticipant(room, pinnedOfRoom.identity, pinnedOfRoom, 'type', 'vip');
      await updateMetadataOfRoom(room, 'pinnedInLive', participant);

      setLoading(false);
    }
    setLoading(false);
  };

  // Don't create public tracks if not invited participant to live
  useEffect(() => {
    if (isLocal && JSON.parse(participant.metadata as string).showToLive) {
      publishTrack();
    }
  }, []);

  useEffect(() => {
    if (networkQuality && isLocal) {
      networkQuality(connectionQuality);
    }
  }, [connectionQuality]);

  return (
    <LoadingOverlay active={loading} spinner={<RingLoader color="white" />}>
      <div className={style.topOptionUser}>
        {!participant.isMicrophoneEnabled && <MicOff color="error" style={{ fontSize: 20 }} />}
      </div>
      <div className={style.videoSection} id="videoSection">
        {videoElement}
      </div>
      {microphonePublication && microphonePublication.track && participant.isMicrophoneEnabled && (
        <AudioRenderer track={microphonePublication.track} isLocal={isLocal} />
      )}
      <div className={style.bottomOptionUser}>
        <div className={style.namePart}>{participant.name?.split(' ')[0]}</div>
        {typeUser === 'retailer' && participant.metadata && JSON.parse(participant.metadata).grade === 'vip' && (
          <div className={style.btnPinned} onClick={handlePinned}>
            {participant.metadata && !JSON.parse(participant.metadata).pinned ? (
              <img src={iconPin} width={16} height={16} />
            ) : (
              <img src={iconPinRed} width={16} height={16} />
            )}
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default UserRoomComponent;
