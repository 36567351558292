import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../../api';
import {
  Create_consumer_failed,
  CREATE_CONSUMER_REQUESTED,
  Create_consumer_succefull,
} from '../../../modules/account/consumer/createConsumer';
import { Set_state_snackbar } from '../../../modules/state/stateMessage';
import { logout } from '../../../modules/logout';
import { Console } from 'console';

export function* watchCreateConsumer() {
  yield takeEvery(CREATE_CONSUMER_REQUESTED, createConsumer);
}

export function* createConsumer({ payload }: any) {
  try {
    const { status } = yield call(api.auth.createConsumer, payload);
    yield put(Create_consumer_succefull());
    yield put(
      Set_state_snackbar({ status: status, message: 'Creation du compte reussie', open: true, type: 'success' }),
    );
  } catch (error: any) {
    const { response } = error;
    yield put(Create_consumer_failed());
    if (response) {
      const { status } = response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({ status: status, message: 'Votre session est expiré', open: true, type: 'error' }),
          );
          // yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Wrong connexion', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
