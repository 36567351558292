import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { ButtonImage } from '../../common/buttonWithImage/buttonImage';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { ModalImage } from '../dashboard/CreateLive/ModalImage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import { getlistgallerie, IstateGallerieInitial } from '../../redux-services/modules/gallerieImage';
import { IstateInitialUpload, uploadImage } from '../../redux-services/modules/uploadImage';
import { getInfoRetailer, IResultGetInfoRetailerSAGA } from '../../redux-services/modules/account/getInfoRetailer';
import authSevices from '../../redux-services/services/authSevices';
import { IResultSetLogoRetailerSAGA, setLogoRetailer } from '../../redux-services/modules/account/setLogoRetailer';
import { PASSWORD } from '../../constant/path';
import { useHistory } from 'react-router-dom';

interface IRetailerInfo {
  name: string;
  logoUrl: string;
  idImage?: string;
}

const AccountComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [openModalLogo, setOpenModalLogo] = useState<boolean>(false);
  const [retailerInfo, setRetailerInfo] = useState<IRetailerInfo>({ name: '', logoUrl: '' });
  const [isLocalSource, setIsLocalSource] = useState<boolean>(false);
  const [localImgFile, setLocalImgFile] = useState<File>();

  const Do_uploadImage = useSelector<RootState, IstateInitialUpload>((state) => state.uploadImage);
  const ListGallerie = useSelector<RootState, IstateGallerieInitial>((state) => state.gallerieImage);
  const RetailerInfo = useSelector<RootState, IResultGetInfoRetailerSAGA>((state) => state.accountInfoRetailer);
  const SetedLogoRetailer = useSelector<RootState, IResultSetLogoRetailerSAGA>((state) => state.accountSetLogoRetailer);

  const { loading: loadingUpload, id: idImageUploaded } = Do_uploadImage;
  const { error: errorGallerie, loading: loadingGallerie, gallerie } = ListGallerie;

  const OnChangeLogo = async (e: any) => {
    if (e.target.files[0]) {
      setOpenModalLogo(!openModalLogo);
      setIsLocalSource(true);
      setRetailerInfo({
        name: retailerInfo.name,
        logoUrl: URL.createObjectURL(e.target.files[0]),
      });
      setLocalImgFile(e.target.files[0]);
    }
  };

  const OnValidateLogo = () => {
    if (isLocalSource && localImgFile) {
      dispatch(uploadImage(localImgFile));
    } else {
      if (retailerInfo.idImage && retailerInfo.idImage !== '') {
        dispatch(setLogoRetailer({ galleryImageId: retailerInfo.idImage, logoUrl: retailerInfo.logoUrl }));
      }
    }
    setIsUpdate(false);
  };

  useEffect(() => {
    setRetailerInfo({
      name: RetailerInfo.name !== '' ? RetailerInfo.name : retailerInfo.name,
      logoUrl: RetailerInfo.logoUrl !== '' ? RetailerInfo.logoUrl : retailerInfo.logoUrl,
    });
  }, [RetailerInfo]);

  useEffect(() => {
    if (isLocalSource && idImageUploaded !== '' && gallerie) {
      const findedNewOne = gallerie.items.find((item) => item.id === idImageUploaded);
      if (findedNewOne) {
        setRetailerInfo({
          name: retailerInfo.name,
          logoUrl: findedNewOne.url,
          idImage: findedNewOne.id,
        });
        dispatch(setLogoRetailer({ galleryImageId: idImageUploaded, logoUrl: findedNewOne.url }));
        setIsLocalSource(false);
      }
    }
  }, [idImageUploaded, gallerie]);

  useEffect(() => {
    (async () => {
      const _name = await authSevices.getNameRetailer();
      const _logo = await authSevices.getLogoRetailer();
      setRetailerInfo({ name: _name ?? retailerInfo.name, logoUrl: _logo ?? retailerInfo.logoUrl });
    })();

    dispatch(getlistgallerie({ index: 0, size: 5 }));
    dispatch(getInfoRetailer());
  }, []);

  return RetailerInfo.loading || SetedLogoRetailer.loading ? (
    <CircularProgress />
  ) : retailerInfo.name === '' ? (
    <Box className={classes.container}>
      <Typography>I'm the account component.</Typography>
    </Box>
  ) : (
    <>
      <Grid container spacing={1} className={classes.containerWithData}>
        <Grid item md={9} className={classes.containerData}>
          <Box className={classes.boxImage}>
            <Typography variant="h5">Retailer information</Typography>
            <ButtonImage
              styleRoot={{ alignSelf: 'center' }}
              style={{ border: '2px solid red', width: '150px', overflow: 'hidden' }}
              urlImage={retailerInfo.logoUrl}
              onClick={() => {
                setIsUpdate(true), setOpenModalLogo(!openModalLogo);
              }}
              disabled={!isUpdate}
              isLocalSource={isLocalSource}
            >
              <Box>
                <KeyboardArrowUpIcon />
                <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                  Logo
                </Typography>
              </Box>
            </ButtonImage>
          </Box>
          <Box flex={2} display="flex" flexDirection="column" justifyContent={isUpdate ? 'flex-start' : 'center'}>
            <TextField
              style={{ marginTop: isUpdate ? 'auto' : 0, display: 'flex', flexDirection: 'row' }}
              id="outlined-read-only-input"
              label={
                <Typography variant="button" style={{ color: 'red' }}>
                  {t('details.name')}
                </Typography>
              }
              defaultValue={retailerInfo.name}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            {isUpdate && (
              <Button className={classes.buttonSave} onClick={OnValidateLogo}>
                {t('formulaire.btn.validate')}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item md={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonUpdate}
            onClick={() => {
              setIsUpdate(true), setOpenModalLogo(!openModalLogo);
            }}
          >
            {t('productToSale.modify')} LOGO
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonUpdate}
            onClick={() => {
              history.push(PASSWORD);
            }}
          >
            {t('account.change.password')}
          </Button>
        </Grid>
      </Grid>
      <ModalImage
        {...{
          loadingUpload: loadingUpload,
          gallerie: gallerie,
          openModal: openModalLogo,
          onChangeUpload: OnChangeLogo,
          setOpenModal: setOpenModalLogo,
          setImage: (_url, _id) => {
            setRetailerInfo({
              name: retailerInfo.name,
              logoUrl: _url ?? retailerInfo.logoUrl,
              idImage: _id ?? '',
            });
            setIsLocalSource(false);
            setOpenModalLogo(!openModalLogo);
          },
        }}
      />
    </>
  );
};

export default AccountComponent;
