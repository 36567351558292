import axios, { AxiosPromise } from 'axios';

export interface IparamsUpdate {
  id: string;
  body: any;
}
export interface IparamsGuestConsumer {
  invitationId: string;
}

export const CreateGuest = (data: any): AxiosPromise<any> => {
  return axios.post('/Retailer/Guest/Create', data);
};

export const UpdateGuest = (params: IparamsUpdate): AxiosPromise<any> =>
  axios.post(`/Retailer/Guest/Update/${params.id}`, params.body);

export const GuestList = (idSession: string): AxiosPromise<any> =>
  axios.get(`/Retailer/Guest/List?liveSessionId=${idSession}`);

export const GuestById = (id: string): AxiosPromise<any> => axios.get(`/Retailer/Guest/Get/${id}`);

export const DeleteGuest = (ids: string): AxiosPromise<any> => axios.delete(`/Retailer/Guest/Delete${ids}`);

export const SendInvitationsGuest = (data: any): AxiosPromise<any> =>
  axios.post('/Retailer/Guest/SendInvitations', data);

export const GuestGetInvitationMailData = (guestId: string): AxiosPromise<any> =>
  axios.get(`/Retailer/Guest/GetInvitationMailData?guestId=${guestId}`);

export const GuestConsumer = (invitationId: string): AxiosPromise<any> =>
  axios.post(
    `/Consumer/Guest/BindToConsumer`,
    JSON.stringify({
      invitationId,
    }),
  );

export const GuestParticipantsSummary = (idSession: string): AxiosPromise<any> =>
  axios.get(`/Retailer/Guest/ParticipantsSummaries?liveSessionId=${idSession}`);

export const GuestParticipantsSummaryExcelFile = (idSession: string): AxiosPromise<any> => {
  return axios
    .get(`/Retailer/Guest/ParticipantsSummariesExcelFile?liveSessionId=${idSession}`, {
      responseType: 'arraybuffer',
    })
    .then((result) => {
      if (result && result.data) {
        const outputFilename = `Summary_${Date.now()}.xls`;
        const url = URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      }
      return result;
    });
};

export const SetAdvantageCodes = (data: any): AxiosPromise<any> => {
  return axios.post('/Retailer/Guest/SetAdvantageCodes', data);
};
