import { typedAction } from '../../utils';

export const GET_ONLY_GUEST_REQUEST = 'GET_ONLY/GUEST/REQUEST';
export const GET_ONLY_GUEST_SUCCESS = 'GET_ONLY/GUEST/SUCCESS';
export const GET_ONLY_GUEST_FAILED = 'GET_ONLY/GUEST/FAILED';

export interface IdataGuest {
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  mainImageUrl: string;
}

export interface Ierror {
  message: string;
  status: number | undefined;
}

export interface IinitialStateGuestById {
  ListGuest: IdataGuest;
  loading: boolean;
  message: string;
  status: number | undefined;
}

const initialState: IinitialStateGuestById = {
  ListGuest: {
    description: '',
    furtherInformation: '',
    label: '',
    priceAfterDiscount: 0,
    priceBeforeDiscount: 0,
    url: '',
    mainImageUrl: '',
  },
  status: undefined,
  loading: false,
  message: '',
};

export const get_GuestById = (id: string) => typedAction(GET_ONLY_GUEST_REQUEST, id);
export type Iget_GuestById = typeof get_GuestById;

export const get_GuestByIdSuccess = (data: IdataGuest) => typedAction(GET_ONLY_GUEST_SUCCESS, data);
export type Iget_GuestByIdSuccess = typeof get_GuestByIdSuccess;

export const get_GuestByIdFailed = (args: Ierror) => typedAction(GET_ONLY_GUEST_SUCCESS, args);
export type Iget_GuestByIdFailed = typeof get_GuestByIdFailed;

type GuestAction = ReturnType<Iget_GuestById | Iget_GuestByIdSuccess | Iget_GuestByIdFailed>;

export const GuestReducersById = (
  state: IinitialStateGuestById = initialState,
  action: GuestAction,
): IinitialStateGuestById => {
  switch (action.type) {
    case GET_ONLY_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
      };
    case GET_ONLY_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        ListGuest: action.payload,
        message: '',
      };
    case GET_ONLY_GUEST_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
