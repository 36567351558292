import * as React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Theme, createTheme, WithStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table, TableCellRenderer, TableHeaderProps } from 'react-virtualized';
import { TFunction, useTranslation } from 'react-i18next';
import { Box, Checkbox, CircularProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { IdataGuest } from '../../redux-services/modules/guest/guestList';
import moment from 'moment';
import HelpIcon from '@material-ui/icons/Help';
import ModalSetadvantageCode from '../modal/ModalSetAdvantageCode';

const styles = (theme: Theme) =>
  ({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    table: {
      '& .ReactVirtualized__Table__headerRow': {
        ...(theme.direction === 'rtl' && {
          paddingLeft: '0 !important',
        }),
        ...(theme.direction !== 'rtl' && {
          paddingRight: undefined,
        }),
        'overflow-y': 'hidden !important',
        '& .ReactVirtualized__Grid__innerScrollContainer': {
          'overflow-x': 'scroll !important',
          'overflow-y': 'hidden !important',
        },
        '& .MuiTableCell-head': {
          lineHeight: 1,
        },
      },
    },
    inputBaseCustom: {
      color: 'red',
      '@media(max-width:1180px)': {
        '& > div': {
          color: '#000000 !important',
        },
      },
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    noClick: {
      cursor: 'initial',
    },
  } as const);
const CustumTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  setRows: React.Dispatch<React.SetStateAction<IData[]>>;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  selectAll: boolean;
  columns: readonly ColumnData[];
  headerHeight?: number;
  onRowClick?: (e: any) => void;
  rowCount: number;
  rowGetter: (row: Row) => IData;
  rowHeight?: number;
  t: TFunction<'translation'>;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex, rowData, dataKey, columnData, rowIndex }) => {
    const { columns, classes, rowHeight, onRowClick, t, rowCount } = this.props;

    const advantageCodeValue =
      dataKey === 'advantageCode' && rowData.advantageCode === null && rowData.shoppingUrl != null
        ? rowData.shoppingUrl
        : dataKey === 'advantageCode' && rowData.advantageCode != null && rowData.shoppingUrl === null
        ? rowData.advantageCode
        : '';

    console.log('rowData', rowData, advantageCodeValue, dataKey);

    return (
      <TableCell
        //component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick,
        })}
        variant="body"
        style={{ height: rowHeight, justifyContent: 'center', flexDirection: 'row', display: 'flex' }}
        align="center"
      >
        {columnIndex === 0 ? (
          <Checkbox
            color="primary"
            checked={cellData}
            style={{
              color: '#aaa4a4',
            }}
            inputProps={{
              'aria-label': columnIndex.toString(),
            }}
          />
        ) : columnIndex === 3 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title={
                dataKey === 'advantageCode' && advantageCodeValue
                  ? advantageCodeValue
                  : dataKey != 'advantageCode' && cellData
                  ? cellData
                  : ''
              }
            >
              <TextField
                id="purchase-code"
                disabled
                value={
                  dataKey === 'advantageCode' && advantageCodeValue
                    ? advantageCodeValue
                    : dataKey != 'advantageCode' && cellData
                    ? cellData
                    : ''
                }
                inputProps={{ style: { textAlign: 'center' } }}
                className={classes.inputBaseCustom}
              />
            </Tooltip>

            <ModalSetadvantageCode t={t} data={rowData} />
          </div>
        ) : (
          cellData
        )}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes, selectAll, setSelectAll, setRows, t } = this.props;
    return (
      <TableCell
        //component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}
        align="center"
      >
        {columnIndex === 0 ? (
          <Checkbox
            color="primary"
            checked={selectAll}
            style={{
              color: '#aaa4a4',
            }}
            onChange={(event) => {
              setSelectAll(event.target.checked);
              setRows((r: IData[]) => r.map((_r: IData) => ({ ..._r, check: event.target.checked })));
            }}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        ) : columnIndex === 3 ? (
          <CustumTooltip
            title={
              <>
                <Typography>{t('invitation.purchase.help1')}</Typography>
                <Typography>{t('invitation.purchase.help2')}</Typography>
              </>
            }
            aria-label="help"
            placement="right"
          >
            <Box style={{ display: 'flex' }}>
              <span>{label}</span>
              <HelpIcon />
            </Box>
          </CustumTooltip>
        ) : (
          <span>{label}</span>
        )}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={Math.max(width, 600)}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(MuiVirtualizedTable);

// ---
interface IData extends IdataGuest {
  check: boolean;
}

const createDataRows = (_data: IdataGuest[], t: TFunction<'translation'>): IData[] =>
  _data.map((d: IdataGuest) => {
    const _date = d.invitationSendingDateTime
      ? moment(d.invitationSendingDateTime).utc().format('D/MM/YYYY à HH[h]mm')
      : t('invitation.email.noSend');
    return { ...d, invitationSendingDateTime: _date, check: false };
  });

interface PropsTab {
  data?: IdataGuest[];
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  emailsId: string[];
  setEmailsId: React.Dispatch<React.SetStateAction<string[]>>;
  isSelectedAll: boolean;
}

const TableStickyHead: React.FC<PropsTab> = (props: PropsTab) => {
  const { data, selectAll, setSelectAll, setEmailsId, emailsId, isSelectedAll } = props;
  const { t } = useTranslation();
  const _columns = [
    {
      width: 70,
      label: 'Action',
      dataKey: 'check',
    },
    {
      width: 340,
      label: 'Email',
      dataKey: 'emailAddress',
    },
    {
      width: 200,
      label: t('invitation.date'),
      dataKey: 'invitationSendingDateTime',
    },
    {
      width: 200,
      label: t('invitation.purchase.code'),
      dataKey: 'advantageCode',
    },
  ];

  if (data) {
    const [rows, setRows] = useState<IData[]>([]);

    const onRowClick = (e: any) => {
      const indexRow = e.index;
      setRows(rows.map((r: IData, i) => (i === indexRow ? { ...r, check: !r.check } : r)));
    };

    useEffect(() => {
      setRows((r: IData[]) => r.map((_r: IData) => ({ ..._r, check: selectAll })));
    }, [isSelectedAll]);

    useEffect(() => {
      rows.forEach((r) => {
        if (r.check && !emailsId.includes(r.id)) {
          setEmailsId([...emailsId, r.id]);
        } else if (!r.check && emailsId.includes(r.id)) {
          setEmailsId(emailsId.filter((_r) => r.id !== _r));
        }
      });

      setSelectAll(emailsId.length === rows.length);
    }, [rows, emailsId]);

    useEffect(() => {
      const _rows: IData[] = createDataRows(data, t);
      setRows(_rows);
      setEmailsId([]);
    }, [data]);

    return (
      <Paper style={{ height: 400, width: 'calc(100% - 24px)', overflowX: 'auto', overflowY: 'hidden' }}>
        <VirtualizedTable
          setRows={setRows}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          columns={_columns}
          onRowClick={onRowClick}
          t={t}
        />
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        height: 400,
        maxWidth: 520,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Paper>
  );
};

export default TableStickyHead;
