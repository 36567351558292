import axios, { AxiosPromise } from 'axios';
import { IparamsRetailerPage } from '../modules/liveSessionRetailerPage';
import { IparamsUpdate } from '../modules/liveSessionRetailerUpdate';

export const liveSessionRetaillerPage = (pageParams: IparamsRetailerPage): AxiosPromise<any> =>
  axios.get(
    `/Retailer/LiveSession/Page?sectionId=${pageParams.id}&pageIndex=${pageParams.index}&pageSize=${pageParams.size}`,
  );

export const liveSessionRetaillerCreate = (data: any): AxiosPromise<any> =>
  axios.post(`/Retailer/LiveSession/Create`, data);

export const liveSessionDuplicate = (data: any): AxiosPromise<any> => axios.post(`/Retailer/LiveSession/Copy`, data);

export const liveSessionRetaillerGet = (id: string): AxiosPromise<any> => axios.get(`/Retailer/LiveSession/Get/${id}`);

export const liveSessionRetaillerUpdate = (params: IparamsUpdate): AxiosPromise<any> =>
  axios.post(`/Retailer/LiveSession/Update/${params.id}`, params.body);

export const liveSessionRetaillerSection = (): AxiosPromise<any> => axios.get(`/Retailer/LiveSessionSection/List`);

export const liveSessionRetailerListBySection = (): AxiosPromise<any> =>
  axios.get(`/Retailer/LiveSession/ListBySection`);

export const liveSessionRetailerListEndedSection = (): AxiosPromise<any> =>
  axios.get(`/Retailer/LiveSession/EndedList`);

export const liveSessionDetails = (id: string): AxiosPromise<any> => axios.get(`/Retailer/LiveSession/Details/${id}`);

export const liveSessionStart = (id: string): AxiosPromise<any> => axios.post(`/Retailer/LiveSession/Start/${id}`);

export const liveSessionEnd = (id: string): AxiosPromise<any> => axios.post(`/Retailer/LiveSession/End/${id}`);
export const liveSessionPause = (id: string): AxiosPromise<any> => axios.post(`/Retailer/LiveSession/Pause/${id}`);
export const liveSessionResume = (id: string): AxiosPromise<any> => axios.post(`/Retailer/LiveSession/Resume/${id}`);
