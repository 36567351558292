import { typedAction } from '../../utils';

export const GET_ONLY_PRODUCT_REQUEST = 'GET_ONLY/PRODUCT/REQUEST';
export const GET_ONLY_PRODUCT_SUCCESS = 'GET_ONLY/PRODUCT/SUCCESS';
export const GET_ONLY_PRODUCT_FAILED = 'GET_ONLY/PRODUCT/FAILED';

export interface IdataProduct {
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  mainImageUrl: string;
  mainImageId: string;
}

export interface Ierror {
  message: string;
  status: number | undefined;
}

export interface IinitialStateProductById {
  ListProduct: IdataProduct;
  loading: boolean;
  message: string;
  status: number | undefined;
}

const initialState: IinitialStateProductById = {
  ListProduct: {
    description: '',
    furtherInformation: '',
    label: '',
    priceAfterDiscount: 0,
    priceBeforeDiscount: 0,
    url: '',
    mainImageUrl: '',
    mainImageId: '',
  },
  status: undefined,
  loading: false,
  message: '',
};

export const get_ProductById = (id: string) => typedAction(GET_ONLY_PRODUCT_REQUEST, id);
export type Iget_ProductById = typeof get_ProductById;

export const get_ProductByIdSuccess = (data: IdataProduct) => typedAction(GET_ONLY_PRODUCT_SUCCESS, data);
export type Iget_ProductByIdSuccess = typeof get_ProductByIdSuccess;

export const get_ProductByIdFailed = (args: Ierror) => typedAction(GET_ONLY_PRODUCT_SUCCESS, args);
export type Iget_ProductByIdFailed = typeof get_ProductByIdFailed;

type ProductAction = ReturnType<Iget_ProductById | Iget_ProductByIdSuccess | Iget_ProductByIdFailed>;

export const ProductReducersById = (
  state: IinitialStateProductById = initialState,
  action: ProductAction,
): IinitialStateProductById => {
  switch (action.type) {
    case GET_ONLY_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
      };
    case GET_ONLY_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        ListProduct: Object.keys(action.payload).reduce((acc, current) => {
          return { ...acc, [current]: action.payload[current] ?? '' };
        }, initialState.ListProduct),
        message: '',
      };
    case GET_ONLY_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
