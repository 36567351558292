import * as React from 'react';

function ScheduleLiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6872 17.8125L8.14622 9.35346C8.07011 9.25663 8.03213 9.13526 8.03947 9.01232C8.04681 8.88937 8.09896 8.77338 8.18605 8.68629C8.27314 8.5992 8.38913 8.54705 8.51207 8.53971C8.63502 8.53237 8.75639 8.57035 8.85322 8.64646L17.3122 15.1875C17.702 15.4941 17.954 15.9431 18.0128 16.4355C18.0716 16.928 17.9324 17.4237 17.6257 17.8135C17.3191 18.2033 16.8701 18.4553 16.3776 18.5141C15.8852 18.5729 15.3895 18.4336 14.9997 18.127C14.884 18.0343 14.7791 17.9288 14.6872 17.8125Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39332 5.3935C2.93945 7.84742 1.41235 11.0763 1.07224 14.5299C0.732121 17.9835 1.60002 21.4482 3.52807 24.3337C5.45611 27.2191 8.325 29.3468 11.6459 30.3542C14.9668 31.3615 18.5343 31.1862 21.7404 29.8581C24.9466 28.5301 27.5931 26.1314 29.2289 23.0708C30.8648 20.0102 31.3888 16.4771 30.7118 13.0735C30.0347 9.66982 28.1984 6.60624 25.5158 4.40472C22.8331 2.2032 19.4702 0.999953 15.9998 1V7.5"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ScheduleLiveIcon;
