import { typedAction } from '../utils';

export interface ILiveSession {
  id: number;
  name: string;
}
interface IStateInitial {
  loading: boolean;
  LiveSession: ILiveSession[];
}
const initialSessionState: IStateInitial = {
  loading: false,
  LiveSession: [{ id: 0, name: '' }],
};
export const LIVE_SESSION_REQUEST = 'LIVE_SESSION_REQUEST';
export const LIVE_SESSION_SUCCESS = 'LIVE_SESSION_SUCCESS';
export const LIVE_SESSION_FAILED = 'LIVE_SESSION_FAILED';

export const getLiveSession = () => typedAction(LIVE_SESSION_REQUEST);
export type GetLiveSession = typeof getLiveSession;
export const liveSessionSucces = (data: ILiveSession[]) => {
  return typedAction(LIVE_SESSION_SUCCESS, data);
};
export type LiveSessionSucces = typeof liveSessionSucces;
export const liveSessionFailed = () => typedAction(LIVE_SESSION_FAILED);
export type LiveSessionFailed = typeof liveSessionFailed;

export type SessionListAction = ReturnType<GetLiveSession | LiveSessionSucces | LiveSessionFailed>;

export const LiveSessionReducer = (
  state: IStateInitial = initialSessionState,
  action: SessionListAction,
): IStateInitial => {
  switch (action.type) {
    case LIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        LiveSession: action.payload,
      };
    case LIVE_SESSION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case LIVE_SESSION_REQUEST:
      return { ...state, loading: true };
    default:
      return {
        ...state,
      };
  }
};
