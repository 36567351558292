import { put, call, takeEvery } from 'redux-saga/effects';
import {
  setCounterPartFailed,
  setCounterPartSuccess,
  SET_GUEST_COUNTER_PART_REQUEST,
} from '../../modules/guest/guestCounterPartSet';
import api from '../../api';
import { ErrorFunction } from '../utils/function';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { DO_getCounterPart } from '../../modules/guest/guestCounterPartGet';

export function* watchGuestCounterPartSet() {
  yield takeEvery(SET_GUEST_COUNTER_PART_REQUEST, guestCounterPartSet);
}

export function* guestCounterPartSet(payload: any) {
  try {
    yield call(api.participant.CountAffiliationCreation, payload);
    yield put(setCounterPartSuccess('1245'));
    yield put(DO_getCounterPart(payload.payload.id));
    yield put(
      Set_state_snackbar({
        message: 'Operation effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    if (error) {
      yield put(setCounterPartFailed());
      yield call(ErrorFunction, error);
    }
  }
}
