import { typedAction } from '../utils';

export interface IresultDuration {
  loading: boolean;
  maxDuration: number;
  minutesStep: number;
  error: string;
  status: number | undefined;
}
const initialduration: IresultDuration = {
  loading: false,
  maxDuration: 0,
  minutesStep: 0,
  error: '',
  status: undefined,
};
interface IerrorDuration {
  error: string;
  status: number;
}

export const GET_DURATION_REQUEST = 'GET_DURATION_REQUEST';
export const GET_DURATION_FAILED = 'GET_DURATION_FAILED';
export const GET_DURATION_SUCCESS = 'GET_DURATION_SUCCESS';

export const getduration = () => typedAction(GET_DURATION_REQUEST);
export type Getduration = typeof getduration;

export const getdurationSuccess = (data: IresultDuration) => typedAction(GET_DURATION_SUCCESS, data);
export type GetdurationSuccess = typeof getdurationSuccess;

export const getdurationFailed = (error: IerrorDuration) => typedAction(GET_DURATION_FAILED, error);
export type GetdurationFailed = typeof getdurationFailed;

export type durationAction = ReturnType<Getduration | GetdurationSuccess | GetdurationFailed>;

export const getDurationReducer = (
  state: IresultDuration = initialduration,
  action: durationAction,
): IresultDuration => {
  switch (action.type) {
    case GET_DURATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        maxDuration: action.payload.maxDuration,
        minutesStep: action.payload.minutesStep,
        error: '',
        status: undefined,
      };
    case GET_DURATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
