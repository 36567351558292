import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomBackdrop from '../../../common/backdrop/CustomBackdrop';
import CustomModal from '../../../common/modal/Modal';
import { API_URL } from '../../../lib/config';
import { IGallerieState } from '../../../redux-services/modules/gallerieImage';
import { useIsMobile } from '../../../common/header/useIsMobile';

interface ImodalImage {
  openModal: boolean;
  loadingUpload: boolean;
  onChangeUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  gallerie: IGallerieState;
  setOpenModal: (value: boolean) => void;
  setImage: (value: string | null, id: string | null) => void;
}
export const ModalImage: React.FC<ImodalImage> = ({
  loadingUpload,
  openModal,
  gallerie,
  onChangeUpload,
  setOpenModal,
  setImage,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <CustomModal
      {...{
        open: openModal,
        onClose: () => {
          setOpenModal(!openModal);
        },
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      {loadingUpload && <CustomBackdrop {...{ open: loadingUpload }} />}
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box style={{}}>
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={onChangeUpload}
          />
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
              <Typography> upload image</Typography>
            </IconButton>
          </label>
        </Box>
        {isMobile && (
          <Button onClick={() => setImage(null, null)}>
            <Typography>{t('modal.image.none')}</Typography>
          </Button>
        )}
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {!isMobile && (
            <Button onClick={() => setImage(null, null)}>
              <Typography>{t('modal.image.none')}</Typography>
            </Button>
          )}

          {!isMobile &&
            gallerie.items.map((item, indexKey: number) => {
              return (
                <Box key={indexKey}>
                  <Button onClick={() => setImage(item.url, item.id)}>
                    <img src={item.url} style={{ maxWidth: 250, height: 250, objectFit: 'contain' }} />
                  </Button>
                </Box>
              );
            })}

          {isMobile && (
            <div style={{ width: '100%', display: 'inline-grid', gridTemplateColumns: 'auto auto auto' }}>
              {gallerie.items.map((item, indexKey: number) => {
                return (
                  <Box key={indexKey}>
                    <Button onClick={() => setImage(item.url, item.id)}>
                      <img src={item.url} style={{ maxWidth: 100, height: 100, objectFit: 'contain' }} />
                    </Button>
                  </Box>
                );
              })}
            </div>
          )}
        </Box>
      </Box>
    </CustomModal>
  );
};
