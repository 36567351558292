export default {
  'footer.plateforme': 'Numéro 1 des plateformes de live-commerce en France !',
  'footer.propos': 'A propos',
  'footer.condition': "Conditions d'utilisation",
  'footer.contact': 'Contact',
  'footer.help': "Centre d'aide",
  'footer.preference': 'Préférences cookies',
  'footer.info': 'Corporate informations',
  'footer.client': 'Nos clients',
  'footer.cartes': 'Cartes cadeau',
  'footer.reserved': '© 2021 Livood, Tous les droits sont réservés',
};
