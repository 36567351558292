import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './style';

interface CustomProps {
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  // error: boolean;
  textError: string;
  name: string;
  require: boolean;
  disabled?: boolean;
}
const CustomTextField: FC<CustomProps & TextFieldProps> = (
  { placeholder, value, onChange, textError, name, require, error: errorProps, disabled = false },
  props,
) => {
  const error = errorProps || (value === null && require);
  const classes = useStyles();

  return (
    <Box>
      <TextField
        style={{ width: '100%' }}
        id="outlined-basic"
        variant="outlined"
        name={name}
        label={name}
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={onChange}
        {...{ ...props }}
        className={classes.textfiels}
        disabled={disabled}
      />
      {error && <Typography style={{ marginTop: 0, color: 'red', fontSize: 12 }}>{textError}</Typography>}
    </Box>
  );
};
export default CustomTextField;
