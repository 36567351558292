import { typedAction } from '../../utils';

export const STOP_REQUEST = 'STOP/REQUESTED';
export const ADVANTAGE_REQUESTED = 'ADVANTAGE/REQUESTED';
export const ADVANTAGE_SUCCESS = 'ADVANTAGE/SUCCESS';
export const ADVANTAGE_FAILED = 'ADVANTAGE/FAILED';
export const CLEAR_ADVANTAGE_REQUESTED = 'CLEAR/ADVANTAGE/FAILED';

export interface IResultBinded {
  affiliatedToGuestId: string;
  advantageCode?: string;
  shoppingUrl?: string;
}
export interface IinitialStateAdvantage {
  data: IResultBinded;
  loading: boolean;
  error: string | undefined;
}

const initialState: IinitialStateAdvantage = {
  data: {
    affiliatedToGuestId: '',
    advantageCode: '',
    shoppingUrl: '',
  },
  loading: true,
  error: undefined,
};

export const StopAffiliat = () => typedAction(STOP_REQUEST);
export type IStopAffiliat = typeof StopAffiliat;

export const GetAdvantageCode = (id: string) => typedAction(ADVANTAGE_REQUESTED, id);
export type IAdvantage = typeof GetAdvantageCode;

export const ClearAdvantage = () => typedAction(CLEAR_ADVANTAGE_REQUESTED);
export type IClearAdvantage = typeof ClearAdvantage;

export const AdvantageSuccess = (data: IResultBinded) => typedAction(ADVANTAGE_SUCCESS, data);
export type IAdvantageSuccess = typeof AdvantageSuccess;

export const AdvantageFailed = () => typedAction(ADVANTAGE_FAILED);
export type IAdvantageFailed = typeof AdvantageFailed;

export type AdvantageAction = ReturnType<IAdvantage | IAdvantageFailed | IAdvantageSuccess>;

export const AdvantageReducer = (
  state: IinitialStateAdvantage = initialState,
  action: AdvantageAction,
): IinitialStateAdvantage => {
  switch (action.type) {
    case STOP_REQUEST:
      return {
        ...initialState,
        loading: false,
      };
    case ADVANTAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ADVANTAGE_REQUESTED:
      return initialState;
    case ADVANTAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ADVANTAGE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
