import homepage from './homepage';
import common from './common';
import header from './header';
import formulaire from './retailer/formulaire';
import emptyComponent from './retailer/emptyComponent';
import dashboard from './retailer/dashboard';
import details from './retailer/details';
import action from './retailer/action';
import apercu from './retailer/apercu';
import recognition from './retailer/recognition';
import signUpComponent from './retailer/signUpComponent';
import productsToSale from './retailer/productsToSale';
import productToSale from './retailer/productToSale';
import newProduct from './retailer/newProductToSale';
import questionnaire from './retailer/questionnaire';
import modalImage from './retailer/modalImage';
import consumer from './consumer';
import auth from './auth';
import footer from './footer';
import main from './main';
import account from './retailer/account';
import invitation from './retailer/invitation';
import stat from './retailer/stat';

export default {
  ...account,
  ...modalImage,
  ...homepage,
  ...formulaire,
  ...header,
  ...common,
  ...emptyComponent,
  ...dashboard,
  ...details,
  ...recognition,
  ...signUpComponent,
  ...action,
  ...apercu,
  ...consumer,
  ...auth,
  ...footer,
  ...productsToSale,
  ...productToSale,
  ...newProduct,
  ...questionnaire,
  ...main,
  ...invitation,
  ...stat,
};
