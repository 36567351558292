import { Accordion, AccordionSummary, Avatar, Box } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Participant } from 'livekit-client';
import { FC } from 'react';

interface LiveDetailsViewersProps {
  participants: Participant[];
}

const LiveDetailsViewers: FC<LiveDetailsViewersProps> = ({ participants }) => {
  const realParticipants: Participant[] = participants
    ? participants
        .map(
          (participant) =>
            ({
              ...participant,
              metadata: participant.metadata ? JSON.parse(participant.metadata) : {},
            } as any),
        )
        .filter(({ metadata }) => (metadata as any).grade !== 'retailer')
    : [];

  const vips = realParticipants.filter(({ metadata }) => (metadata as any).grade === 'vip');
  const guests = realParticipants.filter(({ metadata }) => (metadata as any).type === 'friends');

  const renderParticipant = (participant: Participant) => {
    const { sid, name, metadata } = participant;
    return (
      <Box key={sid} display="flex" flexDirection="column" alignItems="center">
        <Avatar
          alt={name}
          src={(metadata as any).profileImage}
          style={{ backgroundColor: (metadata as any).backgroundColor }}
        >
          {name?.charAt(0)}
        </Avatar>
        <Typography variant="body2" style={{ fontWeight: 500, fontSize: '0.9em' }}>
          {name}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography style={{ fontWeight: 600 }}>VIP {vips.length}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ gap: 15, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          {vips.map((item) => renderParticipant(item))}
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography style={{ fontWeight: 600 }}>Invités {guests.length}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ gap: 15, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          {guests.map((item) => renderParticipant(item))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LiveDetailsViewers;
