import { createStyles, makeStyles, Theme } from '@material-ui/core';
import bghome from '../../Assets/images/bg-homepage.png';
import bg from '../../Assets/images/fond.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '16px 2px 16px 2px',
      textAlign: 'center',
      borderRadius: 'unset',
      height: 90,
      background: '#518971',
      color: theme.palette.text.secondary,
      '& .MuiTypography-root': {
        fontWeight: 800,
        fontSize: 14,
      },
    },
    dashboardContainer: {
      marginTop: 70,
      '& .MuiGrid-container': {
        justifyContent: 'center',
      },
      '& a': {
        textDecoration: 'none',
      },
    },
    mobileView: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'block',
        padding: 15,
      },
    },
    desKtopView: {
      display: 'block',
      '@media(max-width:700px)': {
        display: 'none',
      },
    },
    imageModal: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: 300,
      objectFit: 'contain',
      backgroundSize: 'cover',
    },
    home: {
      backgroundImage: `url(${bghome})`,
      height: '60vh',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'center',
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      width: '100%',
      paddingLeft: '3%',
      paddingRight: '3%',
      boxSizing: 'border-box',
      '@media(max-width:750px)': {
        height: 600,
        backgroundPositionX: '-150px',
      },
    },
    bubble: {
      position: 'absolute',
      padding: 40,
      maxWidth: 450,
      backgroundColor: 'rgba(255, 255, 255, 0.8 )',
      borderRadius: 40,
      boxShadow: '0px 0px 10px rgba(34, 34, 34, 0.1)',
      backdropFilter: 'blur(20px)',
      '& h5': {
        fontWeight: 800,
      },
      '@media(max-width:750px)': {
        maxWidth: 'unset',
        position: 'unset',
      },
    },
    live: {
      paddingLeft: '1%',
      paddingRight: '1%',
      backgroundImage: `url(${bg})`,
      '& .title': {
        display: 'flex',
        paddingTop: '3%',
        // paddingBottom: '3%',
        alignItems: 'baseline',
        '& p:first-child': {
          fontWeight: 700,
        },
        '& p:last-child': {
          marginLeft: 15,
        },
      },
    },
    btnAccessLive: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    WrapSlider: {
      overflow: 'hidden',
      '&>div:first-child': {
        position: 'relative',
        '& .scroll-menu-arrow': {
          position: 'absolute',
          zIndex: 20,
          borderRadius: 62,
          backgroundColor: '#ffffffd6',
          height: 60,
          display: 'flex',
          width: '60px',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .scroll-menu-arrow:first-child': {
          left: 20,
        },
        '& .scroll-menu-arrow:last-child': {
          right: 20,
        },
      },
    },
  }),
);
