import { createStyles, makeStyles } from '@material-ui/core';
import theme from '../../Themes';

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'hidden',
      flexDirection: 'column',
      width: 330,
      margin: 10,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '@media(max-width:900px)': {
        width: 220,
        margin: 6,
      },
      '@media(max-width:700px)': {
        width: '100%',
        margin: 0,
        paddingTop: 12,
        paddingRight: 8,
      },
      '& .body': {
        position: 'relative',
        '& .live': {
          position: 'absolute',
          left: 20,
          top: 20,
          display: 'flex',
          backgroundColor: '#ED7861',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          width: 65,
          height: 23,
          fontSize: 14,
          borderRadius: 5,
          fontWeight: 700,
          fontFamily: 'Open Sans',
          '@media(max-width:900px)': {
            width: 60,
            height: 20,
            left: 18,
            top: 18,
          },
          '& .dot': {
            height: 8,
            width: 8,
            marginRight: 5,
            borderRadius: 100,
            backgroundColor: 'white',
          },
        },
        '& .vues': {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          left: 20,
          bottom: 20,
          backgroundColor: '#0000004d',
          fontSize: 14,
          borderRadius: 5,
          fontFamily: 'Open Sans',
          fontWeight: 700,
          width: 70,
          height: 33,
          '& .iconeyes': {
            marginLeft: 5,
          },
          '@media(max-width:900px)': {
            width: 60,
            height: 28,
            left: 18,
            bottom: 18,
          },
          '@media(max-width:700px)': {
            height: 26,
            left: 2,
            bottom: 14,
            fontSize: 12,
          },
        },
        '& .lang': {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          right: 20,
          bottom: 20,
          backgroundColor: '#0000004d',
          fontSize: 14,
          borderRadius: 5,
          fontFamily: 'Open Sans',
          fontWeight: 400,
          paddingLeft: 10,
          paddingRight: 10,
          height: 30,
          '@media(max-width:900px)': {
            paddingLeft: 8,
            paddingRight: 8,
            height: 26,
            right: 18,
            bottom: 18,
          },
          '@media(max-width:700px)': {
            height: 26,
            right: 2,
            bottom: 14,
            fontSize: 12,
          },
        },
        '& .copy': {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          right: 5,
          top: 20,
          fontSize: 14,
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          borderRadius: 5,
          fontFamily: 'Open Sans',
          fontWeight: 400,
          paddingLeft: 10,
          paddingRight: 10,
          height: 30,
          '@media(max-width:900px)': {
            paddingLeft: 8,
            paddingRight: 8,
            height: 26,
            right: 18,
            bottom: 18,
          },
          '@media(max-width:700px)': {
            paddingLeft: 4,
            paddingRight: 4,
            height: 20,
            right: 0,
            bottom: 8,
            top: 8,
          },
        },
        '& img': {
          width: 330,
          height: 200,
          objectFit: 'contain',
          borderRadius: 20,
          maxWidth: 330,
          border: `1px solid ${theme.palette.primary.light}`,
          '@media(max-width:900px)': {
            width: 220,
            height: 150,
          },
          '@media(max-width:700px)': {
            width: '100%',
            height: 100,
            borderRadius: 10,
          },
        },
      },
      '& .footer': {
        overflow: 'hidden',
        paddingLeft: 20,
        paddingTop: 10,
        '@media(max-width:900px)': {
          paddingLeft: 18,
          paddingTop: 8,
        },
        '& p': {
          padding: 0,
          '&.title': {
            fontFamily: 'Open Sans',
            fontWeight: 700,
            '@media(max-width:700px)': {
              fontSize: 13,
            },
          },
          '&.description': {
            fontFamily: 'Open Sans',
            fontSize: 14,
            textTransform: 'uppercase',
            '@media(max-width:900px)': {
              fontSize: 12,
            },
            '@media(max-width:700px)': {
              fontSize: 10,
            },
          },
        },
      },
    },
  }),
);
