import { typedAction } from '../utils';

export interface Iparams {
  title: string | null;
  description: string | null;
  categoryId: string | null;
  scheduledStartDate: string;
  scheduledStartTime: number;
  timeZoneId: number | null;
  scheduledDuration: number;
  privacy: number;
  isPublished: boolean;
  previewImageId: string | null;
  pausedLiveImageId: string | null;
  endedLiveImageId: string | null;
  previewImageUrl?: string | null;
  pausedLiveImageUrl?: string | null;
  endedLiveImageUrl?: string | null;
  primaryColor?: string;
}

export interface IerrorCreate {
  error: string;
  status: number;
}

export interface initialStateCreate {
  loading: boolean;
  status: number | undefined;
  error: string | undefined;
  id: string;
}

export interface IsuccessParams {
  id: string;
  status: number | undefined;
}

const initialState: initialStateCreate = {
  id: '',
  error: '',
  loading: false,
  status: undefined,
};

export const CREATE_LIVE_SESSION_REQUEST = 'CREATE_LIVE_SESSION_REQUEST';
export const CREATE_LIVE_SESSION_SUCCESS = 'CREATE_LIVE_SESSION_SUCCESS';
export const CREATE_LIVE_SESSION_FAILED = 'CREATE_LIVE_SESSION_FAILED';

export const create_live_session = (data: Iparams) => typedAction(CREATE_LIVE_SESSION_REQUEST, data);
export type Create_live_session = typeof create_live_session;

export const create_live_session_success = (result: IsuccessParams) => typedAction(CREATE_LIVE_SESSION_SUCCESS, result);
export type Create_live_session_success = typeof create_live_session_success;

export const create_live_session_failed = (error: IerrorCreate) => typedAction(CREATE_LIVE_SESSION_FAILED, error);
export type Create_live_session_failed = typeof create_live_session_failed;

export type CreateLiveSessionAction = ReturnType<
  Create_live_session | Create_live_session_success | Create_live_session_failed
>;
export const CreateLiveSessionReducer = (
  state: initialStateCreate = initialState,
  action: CreateLiveSessionAction,
): initialStateCreate => {
  switch (action.type) {
    case CREATE_LIVE_SESSION_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case CREATE_LIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        id: action.payload.id,
        status: action.payload.status,
      };
    case CREATE_LIVE_SESSION_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
