import { Box } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { reset_SnackBar } from '../../redux-services/modules/state/stateMessage';

interface snackbar {
  open: boolean;
  message: string | '';
  setOpen?: (value: any) => void;
  type: 'error' | 'warning' | 'info' | 'success';
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CustomSnackBar: FC<snackbar> = ({ setOpen, open, type, message }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen && setOpen(false);
    dispatch(reset_SnackBar());
  };
  return (
    <Box>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        onClose={handleClose}
        key={'Top-Right'}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
