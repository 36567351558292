import {
  GET_PRODUCT_CONSUMER_REQUEST,
  productConsumerFailed,
  productConsumerSuccess,
} from '../modules/liveSessionConsumerProduct';
import api from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { logout } from '../modules/logout';

export function* watchliveSessionConsumerProduct() {
  yield takeEvery(GET_PRODUCT_CONSUMER_REQUEST, liveSessionConsumerProduct);
}

export function* liveSessionConsumerProduct({ payload }: any) {
  try {
    const { data, status } = yield call(api.liveSession.liveSessionConsumerProduct, payload);
    yield put(productConsumerSuccess(data));
  } catch (error: any) {
    yield put(productConsumerFailed(error.message));
    if (error.response) {
      const { status, title } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'Bad Request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Bad request', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'please! check your Connexion', open: true, type: 'error' }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'please! check your Connexion', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
