import {
  Box,
  CircularProgress,
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,
  SelectProps,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { FC } from 'react';

interface selectedProps {
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
  name?: string;
  loading?: boolean;
  onChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
    child?: React.ReactNode,
  ) => void;
  value?: any;
  className?: string | undefined;
  Optionvalue: any[];
  subtextdefault?: string;
  vertical?: number | 'bottom' | 'top' | 'center';
  horizontal?: number | 'center' | 'left' | 'right';
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
  MenuProps?: Partial<MenuProps>;
}

export const CustomSelect: FC<selectedProps & SelectProps> = ({
  name,
  variant,
  label,
  className,
  Optionvalue,
  onChange,
  value,
  vertical,
  horizontal,
  placeholder,
  inputProps,
  MenuProps,
  loading,
  disabled,
  ...props
}) => {
  return (
    <Box>
      {name && <Typography variant="h6">{name}</Typography>}
      <FormControl variant={variant} className={className}>
        <InputLabel id={name} style={{ backgroundColor: 'white' }}>
          {name}
        </InputLabel>
        <Select
          id={name}
          labelId={name}
          label={label}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            ...MenuProps,
            getContentAnchorEl: null,
            classes: { paper: 'MuiPopover-custom' },
            anchorOrigin: {
              vertical: vertical ? vertical : 'bottom',
              horizontal: horizontal ? horizontal : 'left',
            },
          }}
          inputProps={inputProps}
          onChange={onChange}
          {...{ props }}
        >
          {Optionvalue.length && !loading ? (
            Optionvalue.map((element, indexKey: number) => (
              <MenuItem key={indexKey} value={element.id}>
                {element.name}
              </MenuItem>
            ))
          ) : (
            <Box style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CircularProgress
                disableShrink
                style={{
                  color: 'green',
                }}
              />
            </Box>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};
