import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../../api';
import {
  AUTH_GETINFO_RETAILER_REQUEST,
  getInfoRetailerFailed,
  getInfoRetailerSuccess,
} from '../../modules/account/getInfoRetailer';
import { logout } from '../../modules/logout';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import authSevices from '../../services/authSevices';

export function* watchGetInfoRetailer() {
  yield takeEvery(AUTH_GETINFO_RETAILER_REQUEST, getInfoRetailer);
}

function* getInfoRetailer() {
  try {
    const { data, status } = yield call(APi.account.getProfilRetailer);
    const result = { ...data, status };
    if (result) {
      yield authSevices.setNameRetailer(result.name);
      if (result.logoUrl && result.logoUrl !== '') {
        yield authSevices.setLogoRetailer(result.logoUrl);
      }
      yield put(getInfoRetailerSuccess(result));
    }
  } catch (e: any) {
    if (e.response) {
      const { status } = e.response;
      yield put(getInfoRetailerFailed({ status, message: e.message }));
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({ status: status, message: 'Votre session est expiré', open: true, type: 'error' }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Wrong connexion', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = e;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
