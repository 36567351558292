import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import { CREATE_PRODUCT_REQUEST, product_created, product_create_failed } from '../modules/product/productCreate';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction, ErrorFunctionUtils } from './utils/function';

export function* watchcreateProduct() {
  yield takeEvery(CREATE_PRODUCT_REQUEST, createProduct);
}

function* createProduct({ payload }: any) {
  try {
    const { data } = yield call(api.product.CreateProduct, payload);
    yield put(product_created({ status: 200, id: data, message: 'Votre produit a été créé avec succès' }));
    yield put(
      Set_state_snackbar({ message: 'Votre produit a été créé avec succès', status: 200, open: true, type: 'success' }),
    );
  } catch (error: any) {
    const { status } = error.response;
    const message = error.message;
    yield put(product_create_failed({ status: status, message: 'Failed' }));
    yield call(ErrorFunction, error);
  }
}
