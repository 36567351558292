import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      '@media(max-width:700px)': {
        paddingTop: '-10px',
        paddingBottom: '60px',
        paddingRight: '12px',
        paddingLeft: '12px',
        height: 'calc(100vh - 140px)',
        overflowY: 'auto',
      },
    },
    addButtonConterParty: {
      '@media(max-width:700px)': {
        backgroundColor: '#000000 !important',
        color: 'white !important',
        padding: '10px',
      },
    },
    spaceTable: {
      marginTop: 20,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      // justifyContent: 'space-around',
    },
    spaceTable1: {
      marginTop: 20,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
    },
    selectNature: {
      minWidth: 120,
      width: '100%',
      maxWidth: 220,
      '& >div': {
        fontSize: 14,
        fontFamily: 'Open Sans',
        height: 42,
        width: '100%',
        maxWidth: 215,
      },
    },
    inputPropsSelect: {
      padding: 10,
      '& .MuiOutlinedInput-input': {
        padding: 5,
      },
    },
    Adornment: {
      '& .MuiTypography-colorTextSecondary': {
        color: '#000',
      },
    },
    contrePartie: {
      marginLeft: 5,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .MuiOutlinedInput-adornedEnd': {
        height: 42,
        width: '100%',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        border: 0,
        margin: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
      },
    },
    montant: {
      '& .MuiOutlinedInput-adornedEnd': {
        height: 42,
        width: 100,
        '@media(max-width:700px)': {
          width: 80,
        },
      },
    },
    ButtonBox: { display: 'flex', alignItems: 'flex-end', flexDirection: 'row', justifyContent: 'flex-end' },
    TextContrePartie: { width: '100%', textAlign: 'center' },
    TypeContrePartie: { width: '100%', maxWidth: '215px', textAlign: 'center' },
    MontantContrePartie: { maxWidth: 305, width: '100%', textAlign: 'center', minWidth: 304 },
  }),
);
