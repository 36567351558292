import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import { IinitialStateLiveEnd, initLiveSessionEnded } from '../../redux-services/modules/liveSessionEnd';
import { IinitialStateLiveStart, initLiveSessionStarted } from '../../redux-services/modules/liveSessionStart';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';

const TimerUp = () => {
  const dispatch = useDispatch();
  const { loading: loadingStopLive, liveStoped } = useSelector<RootState, IinitialStateLiveEnd>(
    (state) => state.liveSessionEnd,
  );
  const { loading: loadingStartLive, liveStarted } = useSelector<RootState, IinitialStateLiveStart>(
    (state) => state.liveSessionStart,
  );

  const { liveDetails, loading } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);

  const { seconds, minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    if ((liveStarted || liveDetails.actualStart) && !liveDetails.actualEnd) {
      const dateNow = new Date();
      const dateFromServer = new Date(liveDetails.actualStart as string);

      const diffTime = Math.abs(dateNow.getTime() - dateFromServer.getTime());
      dateNow.setTime(dateNow.getTime() + (liveStarted ? 0 : diffTime));

      reset(dateNow);
    } else {
      if (liveStoped || liveDetails.actualEnd) {
        if (liveStoped) {
          pause();
        } else {
          const dateStart = new Date(liveDetails.actualStart as string);
          const dateEnd = new Date(liveDetails.actualEnd as string);
          const diffTime = Math.abs(dateEnd.getTime() - dateStart.getTime());

          const _dateNow = new Date();
          _dateNow.setTime(_dateNow.getTime() + diffTime);

          reset(_dateNow, false);
        }
      }
    }
  }, [liveStarted, liveStoped, liveDetails]);

  useEffect(() => {
    return () => {
      dispatch(initLiveSessionStarted());
      dispatch(initLiveSessionEnded());
    };
  }, []);

  return (
    <>
      <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </>
  );
};

export default TimerUp;
