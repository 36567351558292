import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  get_ClickRecordProductByLiveIdFailed,
  get_ClickRecordProductByLiveIdSuccess,
  GET_CLICK_RECORD_PRODUCT_REQUEST,
} from '../modules/product/participantProductClickRecord';
import { ErrorFunction } from './utils/function';

export function* watchListClickRecordProductByLiveId() {
  yield takeEvery(GET_CLICK_RECORD_PRODUCT_REQUEST, ListClickRecordProductByLiveId);
}

function* ListClickRecordProductByLiveId({ payload }: any) {
  try {
    const { data } = yield call(api.product.ParticipantProductClickRecord, payload);
    yield put(get_ClickRecordProductByLiveIdSuccess(data));
  } catch (error: any) {
    const { status, message } = error.response;
    yield put(get_ClickRecordProductByLiveIdFailed({ status: status, message: message }));
    yield call(ErrorFunction, error);
  }
}
