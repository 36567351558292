import { Box, Button, createMuiTheme, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import { AUTH_INSCRIPTION, RESETPASSWORD } from '../../constant/path';
import { isEmailValid } from '../../lib/auth/authvalidate';
import { RootState } from '../../redux-services';
import { IgetLive } from '../../redux-services/modules/liveSessionConsumerget';
import { IResultLoginSAGA, loginStart } from '../../redux-services/modules/login';
import authSevices from '../../redux-services/services/authSevices';
import { useStyles } from './style';

const MsgError400 = 'Votre email, votre identifiant ou votre mot de passe est incorrect';
const MsgInvalidEmail = 'Mail format invalide ';
const MsgError401 = "Votre email n'a pas encore été confirmer, merci de vérifier";
interface IFieldState {
  value: string;
  error: boolean;
  errorText?: string;
}

export interface LoginProps {
  email: IFieldState;
  password: IFieldState;
}
const initialLoginState: LoginProps = {
  email: { value: '', error: false, errorText: '' },
  password: { value: '', error: false, errorText: '' },
};

interface logProps {
  toggleConnect: any; // React.MouseEventHandler<HTMLButtonElement>;
}

const LoginComponent: React.FC<logProps> = (props) => {
  const history = useHistory();
  const { id, idInvitation, idAffiliation } = useParams<IgetLive>();
  const { t } = useTranslation();
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const { loading, token, status } = login;
  const style = useStyles();
  const dispatch = useDispatch();
  const [input, setInput] = useState<LoginProps>(initialLoginState);
  const [disabled, setdisabled] = useState<boolean>(true);
  const [errorInput, setErrorInput] = useState(false);
  const [msgError, setMsgError] = useState(MsgError400);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#4B7FD5',
      },
    },
  });

  const handleBlur = (key: keyof LoginProps) => {
    if (input[key].value === '') {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: true,
          errorText: 'champ obligatoire',
        },
      });
    } else {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: false,
          errorText: '',
        },
      });
    }
  };

  const handleClick = (e: any) => {
    let isValidate = true;
    if (input.email.value === '') {
      setInput({ ...input, email: { ...input.email, errorText: 'Champ obligatoire', error: true } });
    } else if (input.password.value === '') {
      setInput({ ...input, password: { ...input.password, errorText: 'Champ obligatoire', error: true } });
    }
    if (input.email.value === '' || input.password.value === '') isValidate = false;

    if (isValidate) {
      e.preventDefault();
      dispatch(loginStart({ password: input.password.value, userType: 'Consumer', username: input.email.value }));
      localStorage.removeItem('participantId');

      setTimeout(() => {
        const userPseudo = localStorage.getItem('user');
        const participantLive = localStorage.getItem('participantByLive');
        if (userPseudo && participantLive) {
          localStorage.removeItem('participantByLive');
          window.location.reload();
        }
      }, 1000);
      // props.toggleConnect(e);
    } else {
      handleBlur('password');
      handleBlur('email');
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter' && !disabled) {
      handleClick(e);
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    setErrorInput(false);
    switch (name) {
      case 'email': {
        if (!isEmailValid(value)) {
          setInput({
            ...input,
            email: { value, error: true, errorText: 'Mail format invalide ' },
          });
        } else {
          setInput({
            ...input,
            email: { value, error: false, errorText: '' },
          });
        }
        break;
      }
      case 'password': {
        setInput((prevstate) => ({ ...prevstate, [name]: { ...(prevstate as any)[name], value } }));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const urlBase = idAffiliation ? `/af/${idAffiliation}` : idInvitation ? `/vp/${idInvitation}` : '';
    if (token) {
      if (props && props.toggleConnect) {
        props.toggleConnect();
      }
      if (id) {
        history.push((`/LiveCurrent/${id}` + urlBase).trim());
      }
    }
    authSevices.getAccessToken().then((_token) => {
      if (_token) {
        if (props && props.toggleConnect) {
          props.toggleConnect();
        }
        if (id) {
          history.push((`/LiveCurrent/${id}` + urlBase).trim());
        }
      }
    });
    if (!token && status && status !== 200) {
      if (status === 401) {
        setMsgError(MsgError401);
      } else {
        setMsgError(MsgError400);
      }
      setErrorInput(true);
    }
  }, [token, status]);

  useEffect(() => {
    if (input.email.value && input.password.value) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  }, [input.email.value, input.password.value]);

  return (
    <Box>
      {loading && <CustomBackdrop {...{ open: loading }} />}
      <Box className={style.root}>
        <Box className={style.cadreConsumer}>
          <div className={style.logo}>
            <Typography variant="h6">
              <span> Authentification </span>
            </Typography>
          </div>

          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'email',
                label: 'Email',
                type: 'email',
                variant: 'outlined',
                value: input.email.value,
                error: input.email.error,
                onBlur: () => handleBlur('email'),
                onChange: (e) => handleChange(e),
                onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            {input.email.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.email.errorText}</Typography>
            )}
          </Box>
          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'password',
                label: 'Mot de passe',
                type: 'password',
                onBlur: () => handleBlur('password'),
                value: input.password.value,
                error: input.password.error,
                variant: 'outlined',
                onKeyDown: (e) => onKeyPressed(e),
                onChange: (e) => handleChange(e),
              }}
            />
            {input.password.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.password.errorText}</Typography>
            )}
          </Box>

          <ThemeProvider theme={theme}>
            <Link to={RESETPASSWORD} style={{ textDecoration: 'none', color: theme.palette.primary.light }}>
              Mot de passe oublié
            </Link>
            {errorInput && <Typography style={{ marginTop: 0, color: 'red', fontSize: 12 }}>{msgError}</Typography>}
            <Button
              {...{ color: 'primary', disabled, style: { marginTop: errorInput ? 0 : 20 } }}
              onClick={() => {
                handleClick(event);
              }}
            >
              <Typography>Se connecter</Typography>
            </Button>
            <Typography>Vous n'êtes pas encore membre?</Typography>
            <Link
              to={{
                pathname: AUTH_INSCRIPTION,
                state: {
                  isConsumer: true,
                },
              }}
              style={{ textDecoration: 'none', color: theme.palette.primary.light }}
              target="_blank"
            >
              Inscrivez-vous maintenant
            </Link>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginComponent;
