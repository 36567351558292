const setAccessToken = (token: string) => localStorage.setItem('token', token);
const getAccessToken = async (): Promise<string | null> => await localStorage.getItem('token');

const setAccessTokenSignalR = (tokenSignalR: string) => localStorage.setItem('tokenSignalR', tokenSignalR);
const getAccessTokenSignalR = async (): Promise<string | null> => await localStorage.getItem('tokenSignalR');
const removeAccessTokenSignalR = async () => await localStorage.removeItem('tokenSignalR');

const setRefreshToken = (refreshToken: string) => localStorage.setItem('refreshToken', refreshToken);
const getRefreshToken = async (): Promise<string | null> => await localStorage.getItem('refreshToken');

const setNameRetailer = (user: string) => localStorage.setItem('user', user);
const getNameRetailer = async (): Promise<string | null> => await localStorage.getItem('user');

const setLogoRetailer = (logo: string) => localStorage.setItem('logo', logo);
const getLogoRetailer = async (): Promise<string | null> => await localStorage.getItem('logo');

const setProfilConsumer = (img: string) => localStorage.setItem('profilConsumer', img);
const getProfilConsumer = async (): Promise<string | null> => await localStorage.getItem('profilConsumer');
const removeProfilConsumer = async () => await localStorage.removeItem('profilConsumer');

const setTypeRetailer = (user: string) => localStorage.setItem('userType', user);
const getTypeRetailer = async (): Promise<string | null> => await localStorage.getItem('userType');

const setParticipantId = (id: string) => localStorage.setItem('participantId', id);
const getParticipantId = async (): Promise<string | null> => await localStorage.getItem('participantId');
const removeParticipantId = async () => await localStorage.removeItem('participantId');

const removeRetailerInfo = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenSignalR');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
  localStorage.removeItem('logo');
  localStorage.removeItem('profilConsumer');
};

export default {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  setNameRetailer,
  getNameRetailer,
  setTypeRetailer,
  getTypeRetailer,
  setParticipantId,
  getParticipantId,
  removeParticipantId,
  removeRetailerInfo,
  setLogoRetailer,
  getLogoRetailer,
  setProfilConsumer,
  getProfilConsumer,
  removeProfilConsumer,
  setAccessTokenSignalR,
  getAccessTokenSignalR,
  removeAccessTokenSignalR,
};
