import { typedAction } from '../utils';

export interface IparamsPage {
  id: string;
  index: number;
  size: number;
  type: string;
}
export interface Ilive {
  id: string;
  title: string;
  retailerId: string;
  retailerName: string;
  categoryId: string;
  categoryName: string;
  dateTime: string;
  privacy: number;
  thumbnailImageId: string;
  thumbnailImageUrl: string;
  actualStart: string;
  actualEnd: string;
}
export interface ILiveSessionPage {
  items: [
    {
      id: string;
      title: string;
      retailerId: string;
      retailerName: string;
      categoryId: string;
      categoryName: string;
      dateTime: string;
      privacy: number;
      thumbnailImageId: string;
      thumbnailImageUrl: string;
      actualStart: string;
      actualEnd: string;
    },
  ];
  pageInfo: {
    index: number;
    size: number;
  };
  totalCount: number;
  pageCount: number;
  type: string;
}
export interface ILiveSessionPageWithCategory {
  items: Ilive[];
  pageInfo: {
    index: number;
    size: number;
  };
  totalCount: number;
  pageCount: number;
  type: string;
}
interface IStateInitial {
  loading: boolean;
  error: string;
  liveSessionPageCommingSon: ILiveSessionPage;
  liveSessionPageNow: ILiveSessionPage;
  liveSessionPageAll: ILiveSessionPageWithCategory;
}
const initialSessionState: IStateInitial = {
  error: '',
  loading: false,
  liveSessionPageCommingSon: {
    items: [
      {
        id: '',
        title: '',
        retailerId: '',
        retailerName: '',
        categoryId: '',
        categoryName: '',
        dateTime: new Date().toISOString(),
        privacy: 0,
        thumbnailImageId: '',
        actualEnd: '',
        actualStart: '',
        thumbnailImageUrl: '',
      },
    ],
    pageInfo: {
      index: 0,
      size: 0,
    },
    totalCount: 0,
    pageCount: 0,
    type: '',
  },
  liveSessionPageNow: {
    items: [
      {
        id: '',
        title: '',
        retailerId: '',
        retailerName: '',
        categoryId: '',
        categoryName: '',
        dateTime: new Date().toISOString(),
        privacy: 0,
        thumbnailImageId: '',
        actualEnd: '',
        actualStart: '',
        thumbnailImageUrl: '',
      },
    ],
    pageInfo: {
      index: 0,
      size: 0,
    },
    totalCount: 0,
    type: '',
    pageCount: 0,
  },
  liveSessionPageAll: {
    items: [
      {
        id: '',
        title: '',
        retailerId: '',
        retailerName: '',
        categoryId: '',
        categoryName: '',
        dateTime: new Date().toISOString(),
        privacy: 0,
        thumbnailImageId: '',
        actualEnd: '',
        actualStart: '',
        thumbnailImageUrl: '',
      },
    ],
    pageInfo: {
      index: 0,
      size: 0,
    },
    totalCount: 0,
    type: '',
    pageCount: 0,
  },
};

export const LIVE_SESSION_PAGE_REQUEST = 'LIVE_SESSION_PAGE_REQUEST';
export const LIVE_SESSION_PAGE_SUCCESS = 'LIVE_SESSION_PAGE_SUCCESS';
export const LIVE_SESSION_PAGE_FAILED = 'LIVE_SESSION_PAGE_FAILED';

export const getLiveSessionPage = (pageParams: IparamsPage) => typedAction(LIVE_SESSION_PAGE_REQUEST, pageParams);
export type GetLiveSessionPage = typeof getLiveSessionPage;

export const liveSessionPageSuccess = (result: ILiveSessionPage) => typedAction(LIVE_SESSION_PAGE_SUCCESS, result);
export type LiveSessionPageSuccess = typeof liveSessionPageSuccess;

export const liveSessionPageFailed = () => typedAction(LIVE_SESSION_PAGE_FAILED);
export type LiveSessionPageFailed = typeof liveSessionPageFailed;

export type liveSessionPageAction = ReturnType<GetLiveSessionPage | LiveSessionPageFailed | LiveSessionPageSuccess>;

export const LiveSessionPageReducer = (
  state: IStateInitial = initialSessionState,
  action: liveSessionPageAction,
): IStateInitial => {
  switch (action.type) {
    case LIVE_SESSION_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIVE_SESSION_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        liveSessionPageCommingSon:
          action.payload.type === 'Coming Soon'
            ? { ...action.payload, type: action.payload.type }
            : state.liveSessionPageCommingSon,
        liveSessionPageNow:
          action.payload.type === 'Now' ? { ...action.payload, type: action.payload.type } : state.liveSessionPageNow,
        liveSessionPageAll: { ...action.payload, type: action.payload.type },
      };
    case LIVE_SESSION_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: state.error,
      };
    default:
      return {
        ...state,
      };
  }
};
