import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
import { getTimezone } from '../../../redux-services/modules/timezone';
import { duplicate_live_session } from '../../../redux-services/modules/liveSessionDuplicate';
import { getListBySection } from '../../../redux-services/modules/liveSessionRetailerListBySection';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
// import { CustomSelect } from '../../../common/customSelect/CustomSelect';
// import { RootState } from '../../../redux-services';
// import {
//   getduration,
//   IresultDuration
// } from '../../../redux-services/modules/duration';
interface IPropsModal {
  id: string | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
// interface IDurations {
//   hours: number;
//   min: number;
// }
// interface IOptionValue {
//   id: number | string;
//   name: string;
// }

const DuplicateLiveModal: FC<IPropsModal> = (props: IPropsModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  // const getInfoduration = useSelector<RootState, IresultDuration>((state) => state.duration);

  const [openDate, setOpenDate] = useState<boolean>(false);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  // const { maxDuration, minutesStep, status: statusDuration, loading: loadingDuration } = getInfoduration;
  // const [duration, setduration] = useState<IDurations>({ hours: 0, min: 0 });
  // const [optionhours, setOptionHours] = useState<IOptionValue[]>([{ id: 0, name: '0' }]);
  // const [optionPrev, setOptionPrev] = useState<IOptionValue[]>([{ id: 0, name: '0' }]);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { open, setOpen, id } = props;
  const handleSubmit = () => {
    const scheduledStartDate = moment(selectedDate).utc(true).toISOString();
    const scheduledStartTime = time.getHours() * 60 + time.getMinutes();
    // const totalDuration = duration.hours * 60 + duration.min;
    setLoading(true);
    id &&
      dispatch(
        duplicate_live_session({
          liveSessionId: id,
          scheduledStartDate,
          scheduledStartTime,
          callback: (result) => {
            dispatch(getListBySection());
            setLoading(false);
            history.push(`/dashboard/livesessions/${result.id ?? id}/update`);
          },
        }),
      );
  };

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  // useEffect(() => {
  //   if (maxDuration && minutesStep && !loadingDuration) {
  //     const hours = maxDuration / 60 + 1;
  //     const stepCount = Math.ceil(60 / minutesStep);

  //     const tablePrev = Object.keys(Array.from(Array(stepCount))).map((key) => ({
  //       id: +key * minutesStep,
  //       name: (+key * minutesStep).toString(),
  //     }));
  //     const tablehours = Object.keys(Array.from(Array(hours))).map((key) => ({
  //       id: +key,
  //       name: (+key).toString(),
  //     }));
  //     setOptionHours(tablehours);
  //     setOptionPrev(tablePrev);
  //   }
  // }, [maxDuration, minutesStep, loadingDuration]);

  useEffect(() => {
    dispatch(getTimezone());
    // dispatch(getduration());
  }, []);

  return (
    <div>
      {/* <IconButton
        style={{ marginBottom: '-5px' }}
        onClick={() => {
          console.log('open');
        }}
      >
        <BorderColorIcon />
      </IconButton> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Voulez vous dupliquer le live?</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }}>
            <Box className={classes.boxDate}>
              <Box>
                <Typography variant="subtitle1">{t('formulaire.live.date')}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    open={openDate}
                    onClick={() => setOpenDate(!openDate)}
                    onClose={() => {
                      setOpenDate(false);
                    }}
                    disablePast={true}
                    variant="inline"
                    minDateMessage={() => {
                      return <Typography>dates invalide</Typography>;
                    }}
                    style={{ color: 'red' }}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{
                      position: 'end',
                      style: { padding: 0 },
                    }}
                    onChange={(date: Date | null) => {
                      if (date) {
                        setselectedDate(date);
                      }
                    }}
                    {...{ inputProps: { className: classes.dateStyle } }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ marginLeft: 5 }}>
                <Typography variant="subtitle1">{t('formulaire.live.time')}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    value={time}
                    onChange={(date) => {
                      if (date) {
                        setTime(date);
                      }
                    }}
                    inputProps={{ className: classes.dateStyle }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            {/* <Box style={{ marginTop: 10 }}>
              <Typography variant="subtitle1">{t('formulaire.live.duration')}</Typography>
              <Box className={classes.boxduration}>
                <CustomSelect
                  {...{
                    loading: loadingDuration,
                    Optionvalue: optionhours,
                    className: classes.selectCategorie,
                    inputProps: { className: classes.inputPropsSelect },
                    variant: 'outlined',
                    value: parseInt(`${duration.hours >= 60 ? duration.hours / 60 : duration.hours}`),
                    onChange: (event: any) => {
                      const item = event.target.value;
                      if (item >= maxDuration) {
                        setduration({ ...duration, min: 0, hours: item });
                      } else {
                        setduration({ ...duration, hours: item });
                      }
                    },
                  }}
                />
                <Typography variant="subtitle2" style={{ margin: 5 }}>
                  {t('formulaire.live.heure')}
                </Typography>
                <CustomSelect
                  {...{
                    MenuProps: {},
                    disabled: (duration.hours >= 60 ? duration.hours : duration.hours * 60) === maxDuration,
                    loading: loadingDuration,
                    Optionvalue: optionPrev,
                    className: classes.SelectVideo1,
                    inputProps: { className: classes.inputPropsSelect },
                    variant: 'outlined',
                    value: duration.min > 60 ? duration.min % 60 : duration.min,
                    onChange: (event: any) => {
                      setduration({ ...duration, min: event.target.value });
                    },
                  }}
                />
                {(duration.hours >= 60 ? duration.hours : duration.hours * 60) === maxDuration ? null : (
                  <Typography variant="subtitle2" style={{ margin: 5 }}>
                    {t('formulaire.live.minutes')}{' '}
                  </Typography>
                )}
              </Box>
            </Box> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Dupliquer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(DuplicateLiveModal);
