export default {
  'stat.vip.mail': 'VIP Email',
  'stat.advantageCode': 'Advantage Code',
  'stat.pseudo': 'Pseudo',
  'stat.participantName': 'Participant name',
  'stat.participantMail': 'Paricipant email',
  'stat.invited': 'Guest',
  'stat.invitedNam': 'Name',
  'stat.invitedLastName': 'Last name',
  'stat.invitedMail': 'Email',
};
