import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat: {
      width: '100%',
    },
    content: {
      backgroundColor: '#F9FAF9',
      minHeight: 400,
      // position: 'relative',
      marginTop: 20,
      boxShadow: 'inset 2px -10px 10px 2px rgba(34, 34, 34, 0.05) !important',
      borderRadius: 5,
      paddingTop: '10px',
      // paddingBottom: '10px',
      // paddingLeft: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // '@media(height> 1024px)': {
      //   flexDirection: 'column',
      // },
      '& .titlechat': {
        fontStyle: 'italic',
      },
      '& .messageByMe': {
        margin: '5px 10px 0px auto',
        width: 'fit-content',
        color: '#fdffff',
        padding: '5px',
        borderRadius: 10,
        '& span': {
          fontWeight: 700,
        },
      },
      '& .messageByOther': {
        marginTop: 5,
        '& span': {
          fontWeight: 700,
        },
      },
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        marginTop: 10,
        '& .title': {
          fontSize: 15,
          fontWeight: 700,
        },
        '& .more': {
          fontSize: 14,
          fontWeight: 600,
          fontFamily: 'Open Sans',
        },
      },
      '&>ul>li': {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
        marginTop: 5,
      },
      '& .footer': {
        background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 56%, rgba(255,255,255,1) 82%)',
        borderRadius: 5,
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .dark': {
          height: 30,
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 30,
          boxSizing: 'border-box',
        },
        '& .light': {
          color: '#A0A0A0',
          height: 60,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          paddingTop: 4,
          borderRadius: 5,
          '& input': {
            fontSize: 14,
            display: 'flex',
            fontFamily: 'Open Sans',
            backgroundColor: 'white',
          },
          '& .inputBase': {
            width: '100%',
          },
          '& .iconsend': {
            height: 40,
            width: 40,
            // backgroundColor: theme.palette.primary.light,
            borderRadius: '50%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            '& svg': {
              color: 'white',
              height: 22,
              width: 22,
            },
          },
        },
      },
    },
    chatRetailerInput: {
      color: '#A0A0A0',
      height: 60,
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: 'white',
      boxSizing: 'border-box',
      paddingTop: 4,
      borderRadius: 5,
      '& input': {
        fontSize: 14,
        display: 'flex',
        fontFamily: 'Open Sans',
        backgroundColor: 'white',
      },
      '& .inputBase': {
        width: '100%',
      },
      '& .iconsend': {
        height: 40,
        width: 40,
        // backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
          color: 'white',
          height: 22,
          width: 22,
        },
      },
    },
    discussion: {
      width: '100%',
      height: 375,
      overflow: 'auto',
      marginBottom: 20,
      '& > div': {
        paddingBottom: 50,
        paddingTop: 50,
      },
      '@media(max-width:1180px)': {
        height: '54vh',
      },
    },
    discussionLoadingContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: 0,
    },
    popContent: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginTop: 10,
      },
    },
    nameUser: {
      fontSize: '10px',
    },
    messageOther: {
      fontWeight: 500,
      backgroundColor: '#dddada',
      width: 'fit-content',
      padding: 5,
      borderRadius: 12,
    },
    avatarMess: {
      width: '25px',
      height: '25px',
      fontSize: '15px',
    },
    contemess: {
      display: 'flex',
    },
  }),
);
