import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import CustomTextField from '../../../common/TextInput/CustomTextField';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useStyles } from './style';
import { CustomSelect } from '../../../common/customSelect/CustomSelect';
import { useTranslation } from 'react-i18next';
import { ResetStateQuestion, setStateQuestion } from '../../../redux-services/modules/state/stateSurvey';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import theme from '../../../Themes';
import CustomModal from '../../../common/modal/Modal';

interface IQuestionnaire {
  name: string;
  time: Date;
  question: string;
  type: number;
  responseQuestionnaire: string[];
}
const QuestionnaireComponent = () => {
  const style = useStyles();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [time, setTime] = useState<Date>();
  const [openModalQuitPage, setopenModalQuitPage] = useState<boolean>(false);
  const [responses, setResponses] = useState<string[]>(['', '']);
  const [question, setQuestion] = useState<IQuestionnaire>({
    name: '',
    time: new Date(),
    question: '',
    type: 0,
    responseQuestionnaire: ['', ''],
  });

  const OnCancelProduct = async () => {
    if (question.name) {
      setopenModalQuitPage(!openModalQuitPage);
    } else {
      await dispatch(ResetStateQuestion());
      history.push(`/dashboard/livesessions/${id}/questionnaire`);
    }
  };

  const onConfimQuitPage = async () => {
    await dispatch(ResetStateQuestion());
    history.push(`/dashboard/livesessions/${id}/questionnaire`);
  };

  const OnCloseDialogQuiPage = () => {
    setopenModalQuitPage(!openModalQuitPage);
  };

  const type_de_question = [
    { id: 0, name: t('question.choice.only') },
    { id: 1, name: t('question.choice.multiple') },
  ];

  return (
    <Box style={{ width: '75%' }}>
      <Typography
        style={{
          fontSize: '1.2rem',
          lineHeight: '2.313rem',
          fontWeight: 'bold',
          marginBottom: '17px',
        }}
      >
        {t('question.title')}
      </Typography>
      <Box style={{ display: 'flex', padding: 25 }}>
        <Box
          style={{
            flexDirection: 'column',
            minWidth: '400px',
            width: '780px',
          }}
        >
          <Box className={style.rootBoxChamp}>
            <Box style={{ width: '100%', marginRight: '1%' }}>
              <Typography variant="caption">{t('question.questionnaireform')}</Typography>
              <CustomTextField
                {...{
                  require: true,
                  name: 'title',
                  placeholder: t('question.questionnaire'),
                  onChange: (event) => {
                    setQuestion({ ...question, name: event.target.value });
                    dispatch(setStateQuestion({ name: event.target.value }));
                  },
                  textError: t('common.required'),
                  value: question.name,
                  error: false,
                  style: { width: '100%', backgroundColor: 'red' },
                  inputProps: { style: { backgroundColor: 'red' } },
                }}
              />
            </Box>
            {/* <Box style={{ marginLeft: 5 }}>
              <Typography variant="caption">Time</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  value={time}
                  onChange={(date) => {
                    if (date) {
                      setTime(date);
                    }
                  }}
                  inputProps={{ className: style.dateStyle }}
                />
              </MuiPickersUtilsProvider> 
            </Box> */}
          </Box>
          <Box className={style.rootBoxChamp}>
            <Box style={{ width: '83%', marginRight: '1.5%' }}>
              <Typography variant="caption">{t('question.name')}</Typography>
              <CustomTextField
                {...{
                  require: true,
                  name: 'title',
                  placeholder: 'Question',
                  onChange: (event) => {
                    setQuestion({ ...question, question: event.target.value });
                    dispatch(setStateQuestion({ question: event.target.value }));
                  },
                  textError: t('common.required'),
                  value: question.question,
                  error: false,
                }}
              />
            </Box>
            <Box style={{ width: '41%' }}>
              <Typography variant="caption">{t('question.type.question')}</Typography>
              <CustomSelect
                {...{
                  Optionvalue: type_de_question,
                  className: style.selectType,
                  inputProps: {
                    className: style.selectOption,
                  },
                  variant: 'outlined',
                  value: question.type,
                  onChange: (event: any) => {
                    setQuestion({ ...question, type: event.target.value });
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box style={{ padding: 5, height: 250 }}>
              <Typography variant="caption">{t('question.max.response')}</Typography>
              {responses.map((response, index) => (
                <Box style={{ paddingBottom: 9, paddingTop: 9 }} key={index}>
                  <CustomTextField
                    {...{
                      require: true,
                      name: 'Reponse',
                      placeholder: 'Reponse',
                      onChange: (event) => {
                        setResponses((responsesState) => {
                          const responsesCp = [...responsesState];
                          responsesCp[index] = event.target.value;
                          return responsesCp;
                        });
                      },
                      textError: t('common.required'),
                      value: response,
                      error: false,
                      style: { padding: 50 },
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Button
            style={{
              backgroundColor: '#222222',
              color: 'white',
              padding: 10,
              borderRadius: 10,
              textTransform: 'unset',
              marginTop: 15,
            }}
            onClick={() => {
              if (responses.length < 4) {
                setResponses((responsesState) => [...responsesState, '']);
              }
            }}
          >
            {t('question.add.response')}
          </Button>
          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              style={{
                backgroundColor: 'rgba(81, 137, 113, 0.5)',
                borderRadius: 10,
                color: 'white',
                alignSelf: 'center',
              }}
            >
              {t('question.btn.validate')}
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: 10,
                marginLeft: 5,
                color: theme.palette.common.black,
                alignSelf: 'center',
              }}
              onClick={OnCancelProduct}
            >
              {t('question.btn.cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
      <CustomModal
        {...{
          title: 'Confirmation',
          maxWidth: 'xs',
          open: openModalQuitPage,
          TextCancel: 'Annuler',
          textConfirm: 'Confirmer',
          onClose: OnCloseDialogQuiPage,
          onConfirm: onConfimQuitPage,
        }}
      >
        <Typography>{t('formulaire.confirmation.text')}</Typography>
      </CustomModal>
    </Box>
  );
};

export default QuestionnaireComponent;
