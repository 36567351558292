import { Box, Typography } from '@material-ui/core';
import React, { useState, MouseEvent } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useHistory } from 'react-router-dom';
import CardLives from '../../common/cardLives/CardLives';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useStyles } from './style';
import { IListBySectionRetailer } from '../../redux-services/modules/liveSessionRetailerListBySection';
import { useTranslation } from 'react-i18next';
import { DuplicateLiveModal } from './DuplicateLiveModal';
interface sessionsSectionComponentProps {
  sessions: IListBySectionRetailer;
  isEndLive?: boolean;
  isLiveSessionsPage?: boolean;
  setIdToShow?: (id: string) => void;
}

const SessionsSectionComponent: React.FC<sessionsSectionComponentProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const style = useStyles();
  const { isEndLive, isLiveSessionsPage, setIdToShow } = props;
  const [open, setOpen] = useState(false);
  const [liveId, setLiveId] = useState<string | undefined>(undefined);
  const sessions = props.sessions?.sessions;
  const section = props.sessions?.section;
  const [isSelectedID, setIsSelectedID] = useState('');

  const menu = (Lives: any) =>
    Lives?.map((live: any) => {
      const { thumbnailImageUrl, title, categoryName, id } = live;

      return (
        <CardLives
          {...{
            id,
            img: `${thumbnailImageUrl}`,
            title,
            description: categoryName,
            isSelectedID,
            isLiveSessionsPage,
            onClick: async () => {
              setIsSelectedID(id);
              isEndLive && setIdToShow ? setIdToShow(id) : await history.push(`/dashboard/livesessions/${live.id}`);
            },
            onCopyLive,
          }}
          key={live.id}
        />
      );
    });

  const onCopyLive = (id: string | undefined) => (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    setLiveId(id);
    console.log('duplicate is clicked', id);
  };

  return (
    <>
      {open && <DuplicateLiveModal id={liveId} open={open} setOpen={setOpen} />}
      <Box className={style.live}>
        <Box className="title">
          <Typography component="p" variant="h5">
            {section?.name}
          </Typography>
          <Typography component="p" variant="body2">
            {t('common.explore.all')}
          </Typography>
        </Box>
        <Box className={style.WrapSlider}>
          <ScrollMenu
            {...{ data: menu(sessions), dragging: false, alignCenter: false }}
            arrowLeft={<ArrowBack />}
            arrowRight={<ArrowForward />}
            wrapperStyle={{ width: '100%' }}
            wheel={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default SessionsSectionComponent;
