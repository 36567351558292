import React, { Dispatch, SetStateAction } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import SummaryGuest from './SummaryGuestV2';
import { useDispatch, useSelector } from 'react-redux';
import {
  clear_AllGuestSummaryByLive,
  get_AllGuestSummaryByLive,
  IinitialStateListGuestSummary,
} from '../../redux-services/modules/guest/guestListsParticipantsSummary';
import { RootState } from '../../redux-services';
import CustomBackdrop from '../backdrop/CustomBackdrop';

interface IPopupSummaryGuest {
  id: string;
  primaryColor?: string;
  setOpenAffiliation: Dispatch<SetStateAction<boolean>>;
  openAffiliation: boolean;
  setScroll: Dispatch<SetStateAction<'paper' | 'body' | undefined>>;
  scroll: 'paper' | 'body' | undefined;
  handleClickOpen: (scrollType: DialogProps['scroll']) => () => void;
}

const PopupSummaryGuest: React.FC<IPopupSummaryGuest> = (props: IPopupSummaryGuest) => {
  const { t } = useTranslation();
  const { id, primaryColor, openAffiliation, scroll, setOpenAffiliation, setScroll, handleClickOpen } = props;
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(false);
  // const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const { ListGuestSummary, loading: loadingSummary } = useSelector<RootState, IinitialStateListGuestSummary>(
    (state) => state.guestListGuestSummary,
  );

  // const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  //   dispatch(get_AllGuestSummaryByLive(id));
  // };

  const handleClose = () => {
    dispatch(clear_AllGuestSummaryByLive());
    setOpenAffiliation(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (openAffiliation) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAffiliation]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{
          backgroundColor: primaryColor ?? '#518971',
          width: '100%',
          margin: 5,
          borderRadius: 10,
          padding: 10,
          textTransform: 'unset',
        }}
        onClick={handleClickOpen('paper')}
      >
        {t('action.affiliations')}
      </Button>
      <Dialog
        open={openAffiliation}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={false}
      >
        <DialogTitle id="scroll-dialog-title">{t('action.affiliations')}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            <CustomBackdrop {...{ open: loadingSummary }} />
            <SummaryGuest data={ListGuestSummary} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupSummaryGuest;
