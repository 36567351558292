import { typedAction } from '../../utils';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE/PRODUCT/REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE/PRODUCT/SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE/PRODUCT/FAILED';

export interface IdataProduct {
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  mainImageId: string;
}
export interface IparamsUpdate {
  id: string;
  body: IdataProduct;
}

export interface IproductFailed {
  message: string;
  status: number | undefined;
}

interface Iresult {
  id: string;
  message: string | null;
  status: number | undefined;
}
export interface IinitialStateUpdateProduct {
  id: string;
  message: string | null;
  status: number | undefined;
  loading: boolean;
}
const initialStateProduct: IinitialStateUpdateProduct = {
  id: '',
  message: '',
  status: undefined,
  loading: false,
};

export const do_update_product = (data: IparamsUpdate) => typedAction(UPDATE_PRODUCT_REQUEST, data);
export type Ido_update_product = typeof do_update_product;

export const product_updated = (result: Iresult) => typedAction(UPDATE_PRODUCT_SUCCESS, result);
export type IproduitUpdated = typeof product_updated;

export const product_update_failed = (error: IproductFailed) => typedAction(UPDATE_PRODUCT_FAILED, error);
export type Iproduct_update_failed = typeof product_update_failed;

export type UpdateProductAction = ReturnType<Ido_update_product | IproduitUpdated | Iproduct_update_failed>;

export const UpdateProductReducers = (
  state: IinitialStateUpdateProduct = initialStateProduct,
  action: UpdateProductAction,
): IinitialStateUpdateProduct => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...initialStateProduct,
        loading: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        message: action.payload.message,
        status: action.payload.status,
        loading: false,
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
