import { Box, Button } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import CustomInputWithLabel from '../TextInput/CustomInputWithLabel';

interface PropsEmail {
  subject?: string;
  body?: string;
  isHtmlBody?: boolean;
  addressSender?: string;
}
const Email: FC<PropsEmail> = (props: PropsEmail) => {
  const style = useStyles();
  const { t } = useTranslation();
  const { subject, body, isHtmlBody, addressSender } = props;
  const [data, setData] = useState({ subject: '', body: '' });

  useEffect(() => {
    setData({ subject: subject ? subject : '', body: body ? body : '' });
  }, [subject, body]);

  return (
    <Box className={style.root}>
      <Box className={style.title}>
        <CustomInputWithLabel label={t('invitation.mail.sended')} textDefault="invitation@livood.com" disabled={true} />
      </Box>
      <Box className={style.object}>
        <CustomInputWithLabel
          label={t('invitation.mail.objet')}
          textDefault={data.subject ? data.subject : ''}
          disabled={true}
          overflow="hidden"
        />
      </Box>
      <Box className={style.content} style={{ height: '87%', display: 'flex' }}>
        {data.body !== '' ? (
          <CustomInputWithLabel label="" textDefault={data.body} disabled={true} />
        ) : (
          <CustomInputWithLabel
            label=""
            textDefault=""
            // {`{NOM DE LA MARQUE}vous invite à une réunion Zoom planifiée.\n\n${t(
            //   'invitation.mail.sujet',
            // )} Ma réunion\n${t(
            //   'invitation.mail.time',
            // )} 18 oct. 2021 10:00 AM Paris\n\n\nParticiper à la réunion Zoom\n\n${t(
            //   'invitation.mail.https',
            // )} //zoom.us/j/97382547849?pwd=NVNnS0FOcWNFdHpSOElWQ0dZWG1IQT09`}
            disabled={true}
          />
        )}
      </Box>

      {data.body !== '' && (
        <Button
          style={{ marginTop: 21 }}
          className={style.btnCopyInvitation}
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(data.body);
          }}
        >
          Copier Invitation
        </Button>
      )}
    </Box>
  );
};

export default React.memo(Email);
