import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '-15px',
      display: 'flex',
      flex: 1,
      width: '100%',
      '@media(max-width:700px)': {
        marginTop: '5vh',
        marginBottom: '10px',
        paddingLeft: 12,
      },
    },
    textFiels: {
      '& .MuiFormLabel-root': {
        color: 'black',
        alignItems: 'center',
        '@media(max-width:700px)': {
          // padding: '0 14px !important',
          fontSize: '16px !important',
        },
      },
      '& .MuiOutlinedInput-input': {
        '@media(max-width:700px)': {
          // padding: '8px 14px',
        },
      },
      '& .MuiInputBase-root': {
        '@media(max-width:700px)': {
          // fontSize: 14,
        },
      },
    },
    gridButtonAdd: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    addAdress: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      maxWidth: 800,
      marginBottom: '20px',
      '& button': { textTransform: 'none' },
      '@media(max-width:700px)': {
        maxWidth: '100vh',
        // display: 'block',
        marginBottom: 20,
        justifyContent: 'space-around',
      },
    },
    triButtonRoot: {
      maxWidth: 800,
    },
    info: {
      textAlign: 'center',
      margin: '20px',
      marginTop: '51px',
      '@media(max-width:700px)': {
        marginBottom: '60px',
      },
    },
    email: {
      padding: 20,
      '@media(max-width:700px)': {
        padding: '20px 8px',
      },
    },
    address: { padding: 8 },
  }),
);
