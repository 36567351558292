import { createTheme } from '@material-ui/core';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    htmlFontSize: 16,
    body1: {
      fontFamily: 'Montserrat',
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: 14,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: 30,
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 700,
    },
    overline: {
      fontFamily: 'Open Sans',
      fontSize: 20,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#222222',
      light: '#518971',
      dark: '#518971',
    },
    secondary: {
      main: '#F5F0F6',
      light: '#830B13',
      dark: '#E7F551',
    },
    text: {
      primary: '#222222',
      secondary: '#F5F0F6',
      disabled: '#A0A0A0',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.8em',
          height: '0.5em',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          // border: '1px solid rgb(81, 137, 113)',
          borderRadius: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          // '-webkit-box-shadow': 'inset 0 0 6px rgb(81, 137, 113)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(210,210,210)',
          borderRadius: '0.4em',
        },
      },
    },
  },
});

export default theme;
