import { typedAction } from '../../utils';

export const CREATE_GUEST_REQUEST = 'CREATE/GUEST/REQUEST';
export const CREATE_GUEST_SUCCESS = 'CREATE/GUEST/SUCCESS';
export const CREATE_GUEST_FAILED = 'CREATE/GUEST/FAILED';

export interface IdataGuest {
  liveSessionId: string;
  emailAddress: string;
}

export interface IguestSuccess {
  id: string;
  message: string | null;
  status: number | undefined;
}

export interface IguestFailed {
  message: string;
  status: number | undefined;
}
export interface IinitialStateGuest {
  id: string;
  status: number | undefined;
  message: string | null;
  loading: boolean;
}
const initialStateGuest: IinitialStateGuest = {
  loading: false,
  status: undefined,
  message: '',
  id: '',
};

export const do_create_guest = (data: IdataGuest) => typedAction(CREATE_GUEST_REQUEST, data);
export type Ido_create_guest = typeof do_create_guest;

export const guest_created = (result: IguestSuccess) => typedAction(CREATE_GUEST_SUCCESS, result);
export type IproduitCreated = typeof guest_created;

export const guest_create_failed = (error: IguestFailed) => typedAction(CREATE_GUEST_FAILED, error);
export type Iguest_create_failed = typeof guest_create_failed;

export type CreateGuestAction = ReturnType<Ido_create_guest | IproduitCreated | Iguest_create_failed>;

export const CreateGuestReducers = (
  state: IinitialStateGuest = initialStateGuest,
  action: CreateGuestAction,
): IinitialStateGuest => {
  switch (action.type) {
    case CREATE_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        message: action.payload.message,
      };
    case CREATE_GUEST_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
