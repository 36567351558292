import { useStyles } from './styles';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Track,
  VideoCaptureOptions,
} from 'livekit-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoRenderer } from '@livekit/react-core';
import WithoutVideoComponent from './WithoutVideoComponent';
import { AspectRatio } from 'react-aspect-ratio';
import { Room } from 'livekit-client';
import { FlipCameraIos, MicNone, MicOff, Videocam, VideocamOff } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useIsMobile } from '../../common/header/useIsMobile';
import authSevices from '../../redux-services/services/authSevices';

interface PrejoinInterface {
  getParams: (parms: any) => void;
  liveisEnd: string | null;
  liveIsStarted: string | null;
}

const PrejoinComponent = (props: PrejoinInterface) => {
  const { getParams, liveisEnd, liveIsStarted } = props;
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [audioDevice, setAudioDevice] = useState<MediaDeviceInfo>();
  const [audioDeviceID, setAudioDeviceID] = useState<string>();
  const [videoDeviceID, setVideoDeviceID] = useState<string>();
  const [videoDevice, setVideoDevice] = useState<MediaDeviceInfo>();
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [listVideoDevice, setListVideoDevice] = useState<MediaDeviceInfo[]>();
  const [listAudioDevice, setListAudioDevice] = useState<MediaDeviceInfo[]>();
  const { pathname } = useLocation();
  const style = useStyles();
  const isMobile = useIsMobile();
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const [userNameRetailer, setUserNameRetailer] = useState<string>('');

  const videoElement = useMemo(() => {
    if (videoTrack) {
      return <VideoRenderer track={videoTrack} isLocal={true} isMirrored={facingMode === 'user' ? true : false} />;
    } else {
      return <WithoutVideoComponent username={userNameRetailer} color="#4f9a7c" />;
    }
  }, [videoTrack, facingMode]);

  const toggleVideo = async () => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoEnabled(false);
      localStorage.setItem('videoEnabled', 'false');
      setVideoTrack(undefined);
    } else {
      const track = await createLocalVideoTrack({
        deviceId: videoDevice?.deviceId,
      });
      setVideoEnabled(true);
      localStorage.setItem('videoEnabled', 'true');
      setVideoTrack(track);
    }
  };

  const toggleAudio = () => {
    if (audioEnabled) {
      setAudioEnabled(false);
      localStorage.setItem('audioEnabled', 'false');
    } else {
      setAudioEnabled(true);
      localStorage.setItem('audioEnabled', 'true');
    }
  };

  const selectVideoDevice = (deviceID: string) => {
    setVideoDeviceID(deviceID);
    if (videoTrack) {
      if (videoTrack.mediaStreamTrack.getSettings().deviceId === deviceID) {
        return;
      }
      videoTrack.stop();
    }
  };

  const selectAudioDevice = (deviceID: string) => {
    setAudioDeviceID(deviceID);
    if (audioTrack) {
      if (audioTrack.mediaStreamTrack.getSettings().deviceId === deviceID) {
        return;
      }
      audioTrack.stop();
    }
  };

  const handleFlipCamera = () => {
    let options: VideoCaptureOptions = {
      facingMode: facingMode,
    };
    if (facingMode === 'user') {
      setFacingMode('environment');
      options = {
        facingMode: 'environment',
      };
    } else {
      setFacingMode('user');
      options = {
        facingMode: 'user',
      };
    }
    videoTrack?.restartTrack(options);
  };

  useEffect(() => {
    if (liveIsStarted === null) {
      localStorage.setItem('videoEnabled', 'true');
      localStorage.setItem('audioEnabled', 'true');
    }
  }, []);

  useEffect(() => {
    if (pathname.includes('/showlive') && liveisEnd === null) {
      createLocalVideoTrack({
        deviceId: videoDevice?.deviceId,
      }).then(async (track) => {
        setVideoEnabled(true);
        setVideoTrack(track);
        setVideoDeviceID(await track.getDeviceId());
      });

      createLocalAudioTrack({
        deviceId: audioDevice?.deviceId,
      }).then(async (track) => {
        setAudioEnabled(true);
        setAudioTrack(track);
        setAudioDeviceID(track.constraints.deviceId as string);
      });
    } else {
      videoTrack?.stop();
      audioTrack?.stop();
    }
  }, [videoDevice, audioDevice, pathname]);

  useEffect(() => {
    return () => {
      if (videoTrack && audioTrack) {
        videoTrack?.stop();
        audioTrack?.stop();
      }
    };
  }, [videoTrack, audioTrack]);

  useEffect(() => {
    Room.getLocalDevices('videoinput', true).then((devices) => {
      setListVideoDevice(devices);
    });

    Room.getLocalDevices('audioinput', true).then((devices) => {
      setListAudioDevice(devices);
    });
  }, []);

  useEffect(() => {
    const params = {
      videoPrejoin: videoTrack,
      audioPrejoin: audioTrack,
      audioDeviceId: audioDeviceID,
      videoDeviceId: videoDeviceID,
      videoEnabled: videoEnabled,
      audioEnabled: audioEnabled,
      facingMode: facingMode,
    };

    getParams(params);
  }, [videoTrack, audioTrack, videoEnabled, audioEnabled, audioDeviceID, videoDeviceID, facingMode]);

  useEffect(() => {
    authSevices.getNameRetailer().then((retailer) => {
      setUserNameRetailer(retailer as string);
    });
  }, []);

  return (
    <div className={style.containerRoom}>
      <div className={style.videoSection}>
        <AspectRatio ratio={16 / 9} style={{ height: '100%', width: '100%' }}>
          {videoElement}
        </AspectRatio>
      </div>
      {!isMobile && (
        <div className={style.bottomHandleDevice}>
          <div style={{ left: '15px', position: 'relative', height: 0, top: 12 }}>
            <select name="device" className={style.selectDevice} onChange={(e) => selectVideoDevice(e.target.value)}>
              {listVideoDevice?.map((list) => (
                <option key={list.deviceId} value={list.deviceId}>
                  {list.label}
                </option>
              ))}
            </select>
          </div>
          <div style={{ right: '15px', position: 'relative', height: 0, top: 12 }}>
            <select name="device" className={style.selectDevice} onChange={(e) => selectAudioDevice(e.target.value)}>
              {listAudioDevice?.map((list) => (
                <option key={list.deviceId} value={list.deviceId}>
                  {list.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className={style.bottomHandle}>
        {videoEnabled && videoTrack ? (
          <div className={style.btnActive} onClick={toggleVideo}>
            <Videocam color="secondary" />
          </div>
        ) : (
          <div className={style.btnDisabled} onClick={toggleVideo}>
            <VideocamOff color="secondary" />
          </div>
        )}

        <div style={{ marginLeft: 15 }}>
          {audioEnabled && audioTrack ? (
            <div className={style.btnActive} onClick={toggleAudio}>
              <MicNone color="secondary" />
            </div>
          ) : (
            <div className={style.btnDisabled} onClick={toggleAudio}>
              <MicOff color="secondary" />
            </div>
          )}
        </div>

        {isMobile && (
          <div style={{ marginLeft: 15 }} onClick={handleFlipCamera}>
            <div className={style.btnActive}>
              <FlipCameraIos color="secondary" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrejoinComponent;
