import { IParticipantByLive } from '../type/storageServices';

const getParticipantByLive = async (
  liveId: string,
  userType: string,
  pseudo?: string,
): Promise<IParticipantByLive | undefined> => {
  const data = await localStorage.getItem('participantByLive');

  if (data && data !== 'undefined' && data !== '') {
    const strData: IParticipantByLive[] = JSON.parse(data);
    return strData.find(
      (d) =>
        d.liveId === liveId &&
        ((userType !== 'Retailer' && d.userType === userType) ||
          (userType === 'Retailer' && d.userType === userType && pseudo && d.pseudo === pseudo)),
    );
  } else {
    return undefined;
  }
};

const setParticipantByLive = async (liveId: string, participantId: string, userType: string, pseudo: string) => {
  const history = await localStorage.getItem('participantByLive');
  let strHistory: IParticipantByLive[];
  if (history && history !== '') {
    strHistory = JSON.parse(history);
    if (
      strHistory.findIndex(
        (h) =>
          h.liveId === liveId &&
          ((userType !== 'Retailer' && h.userType === userType) ||
            (userType === 'Retailer' && h.userType === userType && h.pseudo === pseudo)),
      ) === -1
    ) {
      strHistory = [...strHistory, { liveId, participantId, userType, pseudo }];
    }
  } else {
    strHistory = [{ liveId, participantId, userType, pseudo }];
  }
  localStorage.setItem('participantByLive', JSON.stringify(strHistory));
};

const removeParticipantByLive = async (participantId: string) => {
  const history = await localStorage.getItem('participantByLive');
  let strHistory: IParticipantByLive[];
  if (history && history !== '') {
    strHistory = JSON.parse(history);
    if (strHistory.findIndex((h) => h.participantId === participantId) !== -1) {
      const strHistory_ = strHistory.filter((h) => h.participantId !== participantId);
      localStorage.setItem('participantByLive', JSON.stringify(strHistory_));
    } else {
      localStorage.setItem('participantByLive', JSON.stringify(strHistory));
    }
  }
};

const cleanParticipantByLive = async () => await localStorage.removeItem('participantByLive');

export default {
  setParticipantByLive,
  getParticipantByLive,
  removeParticipantByLive,
  cleanParticipantByLive,
};
