import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { DataPacket_Kind, Participant, Room } from 'livekit-client';
import { RoomServiceClient } from 'livekit-server-sdk';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../redux-services';
import { IDom } from '../../redux-services/modules/dom';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import { useStyles } from './styles';
import {
  addOnMetadataParticipantShowToLive,
  deleteOnMetadataParticipantShowToLive,
  updateMetadataOfParticipant,
} from '../RoomComponent/utilsRoom/functionRoom';

interface AlertLivekiProps {
  room: Room;
  vip?: Participant;
  retailer?: Participant;
  handleShow: (rep: boolean) => void;
  type: 'request' | 'add' | 'delete';
}

const AlertLivekitRoom = (props: AlertLivekiProps) => {
  const { vip, type, room, retailer, handleShow } = props;
  const { isPinnedVip } = useSelector<RootState, IDom>((state) => state.dom);
  const styles = useStyles({ isPinnedVip });
  const { id }: any = useParams();

  //***Handle button confurme
  const handleConfirm = async () => {
    handleShow(false);
    const username = await authSevices.getNameRetailer();
    const strData = JSON.stringify({ request: 'participateToLive', retailer: username, openModal: true });
    const encoder = new TextEncoder();
    const data = encoder.encode(strData);
    const client = new RoomServiceClient(
      process.env.REACT_APP_LIVEKIT_SERVER as string,
      process.env.REACT_APP_LIVEKIT_API_KEY as string,
      process.env.REACT_APP_LIVEKIT_API_SECRET as string,
    );

    if (type === 'add') {
      await room.localParticipant.publishData(data, DataPacket_Kind.LOSSY, [vip?.sid as string]);
    }
    if (type === 'request') {
      const initialMetadata = room.localParticipant.metadata;
      if (initialMetadata) {
        const metadataParsed = JSON.parse(initialMetadata);
        metadataParsed.showToLive = true;
        const metadata = JSON.stringify(metadataParsed);
        const _participantByLive = await liveSessionServices.getParticipantByLive(id, 'Consumer');
        const _userId = _participantByLive?.participantId;
        await client.updateParticipant(id, _userId as string, metadata);
        await addOnMetadataParticipantShowToLive(room, _userId as string);
      }
    }
    if (type === 'delete') {
      const initialMetadata = vip?.metadata;
      const metadataParsed = JSON.parse(initialMetadata as string);
      metadataParsed.showToLive = false;
      const metadata = JSON.stringify(metadataParsed);
      await client.updateParticipant(id, vip?.identity as string, metadata);
      await deleteOnMetadataParticipantShowToLive(room, vip?.identity as string);
      if (JSON.parse(vip?.metadata as string).type === 'initiator') {
        await updateMetadataOfParticipant(
          room,
          room.localParticipant.identity,
          room.localParticipant,
          'type',
          'initiator',
        );
        if (vip) {
          await updateMetadataOfParticipant(room, vip.identity, vip, 'type', 'vip');
          await updateMetadataOfParticipant(room, vip.identity, vip, 'pinned', 'false');
        }
      }
      //
    }
  };

  return (
    <div className={styles.alertContener}>
      <Dialog
        open={true}
        onClose={() => handleShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {type === 'add'
            ? `Inviter ${vip?.name} à participer au Live`
            : type === 'request'
            ? `${retailer} vous invite à rejoindre le Live`
            : `Retirer ${vip?.name} du Live`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleShow(false)}>Annuler</Button>
          <Button onClick={handleConfirm}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertLivekitRoom;
