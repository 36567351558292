import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import Chat from '../../common/chat/Chat';
import Header from '../../common/header/Header';
import { RootState } from '../../redux-services';
import { IgetLive, IinitialStateConsumerGet } from '../../redux-services/modules/liveSessionConsumerget';
import LoginComponent from '../LoginComponent/LoginComponent';
import { useStyles } from './style';
import authSevices from '../../redux-services/services/authSevices';
import { HubConnection } from '@microsoft/signalr';
import Call from '../../redux-services/api';
import { doLeaveLiveSession } from '../../redux-services/modules/liveSessionJoin';
import { IResultLoginSAGA } from '../../redux-services/modules/login';
import ProductShareConsumer from '../../common/ProductsToShareView/ProductsToShareView';
import {
  clearCheckHistoryListener,
  IHistoryResult,
  startCheckHistoryListener,
} from '../../redux-services/modules/listenerHistory';
import { IMessage, IViewer } from '../../redux-services/type/listenerApi';
import WebFooter from './liveScreen/WebFooter';
import MobileFooter from './liveScreen/MobileFooter';
import Viewers from './liveScreen/Viewers';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import { Do_Affiliation } from '../../redux-services/modules/participant/affiliation';
import { BindConsumer, ClearBindConsumer } from '../../redux-services/modules/participant/bindConsumer';
import { Do_GuestConsumer } from '../../redux-services/modules/guest/guestConsumer';
import { IinitialStateurlToFrame, setUrlToFrame } from '../../redux-services/modules/state/stateIFrame';
import { IStateProducts } from '../../redux-services/modules/state/stateProducts';
import Bulls from '../../common/bulls/Bulls';
import { resetProductConsumer } from '../../redux-services/modules/liveSessionConsumerProduct';
import {
  ClearAdvantage,
  IinitialStateAdvantage,
  StopAffiliat,
} from '../../redux-services/modules/participant/advantageCode';
import { ILiveStatus } from '../../redux-services/type/live';
import Api from '../../redux-services/api';
import { getTokenLiveKit } from '../../services/tokenLivekitService';
import { useRoom } from '@livekit/react-core';
import { RoomEvent, RoomOptions, VideoPresets } from 'livekit-client';
import { liveSessionConsumerGet } from '../../redux-services/api/liveSessionAPi';
import { InfoOutlined, ModeCommentOutlined, PeopleOutlineOutlined, StorefrontOutlined } from '@material-ui/icons';
import Tabs, { TabsData } from '../Tabs/Tabs';
import clsx from 'clsx';
import { useIsMobile } from '../../common/header/useIsMobile';
import theme from '../../Themes';
import RoomComponent from '../RoomComponent/RoomComponent';
import AlertLivekitRoom from '../LiveRoomComponent/AlertLivekitRoom';
import { roomOptions } from '../RoomComponent/utilsRoom/configRooms';
import { getListOfParticipantsInLive, updateMetadataOfParticipant } from '../RoomComponent/utilsRoom/functionRoom';
// @ts-ignore
import randomColor from 'randomcolor';

interface LiveScreenProps {
  statusLive: ILiveStatus;
  setStatusLive: React.Dispatch<React.SetStateAction<ILiveStatus>>;
}

export interface IMessageBullsEmoji {
  id: string;
  message: string;
  showBulls: boolean;
  toggleClick: boolean;
  participantName?: string;
}

interface IParticipant {
  id: string;
  name: string;
  type?: number;
  identifiant: string;
  profilePictureUrl: string;
}

const LiveScreen: React.FC<LiveScreenProps> = ({ statusLive, setStatusLive, children }) => {
  const { id, idAffiliation, idInvitation } = useParams<IgetLive>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(null);
  const [token, settoken] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [userId, setUserId] = useState('');
  const [, setStatusLivePaused] = useState<boolean>(false);
  const [viewersParticipant, setViewersParticipant] = useState<IParticipant[]>([]);
  const [me, setMe] = useState<IParticipant>();

  const [messageProductShared, setMessageProductShared] = useState<IMessageBullsEmoji>({
    id: '',
    message: '',
    showBulls: false,
    toggleClick: false,
  });
  const [internet, setInternet] = useState<boolean>(false);
  const [productIdsToShow, setProductIdsToShow] = useState<string[]>();
  const [loadingMessViewers, setLoadingMessViewers] = useState({ mess: true, viewers: true });
  const [dataNotificationProductClicked, setDataNotificationProductClicked] = useState({
    productId: '',
    participantName: '',
    participantId: '',
    toggleClick: false,
  });
  const [connectAs, setConnectAs] = useState<'vip' | 'friends' | 'retailer' | 'undefined'>();
  const [hidePipMode, setHidePipMode] = useState<boolean>(false);

  const { search } = useLocation();
  const seeProduct = useRef<any>(undefined);

  const handleOpen = () => {
    setAnchorElTwo(null);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClickTwo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorElTwo(event.currentTarget);
  };

  const handleCloseTwo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorElTwo(null);
  };

  const handleRedirect = (event: React.MouseEvent<HTMLButtonElement>) => {
    //event.preventDefault();
    console.log(event);
  };

  const handleConnect = () => {
    setOpen(false);
  };

  const liveDetails = useSelector<RootState, IinitialStateConsumerGet>((state) => state.liveSessionConsumerById);
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const dataHistory = useSelector<RootState, IHistoryResult>((state) => state.listenerHistory);
  const { url: urlToFrame } = useSelector<RootState, IinitialStateurlToFrame>((state) => state.stateFrame);
  const { productId, checked } = useSelector<RootState, IStateProducts>((state) => state.stateProduct.stateProduct);
  const { loading: loadingCodeAvantage } = useSelector<RootState, IinitialStateAdvantage>(
    (state) => state.guestAdvantageCode,
  );
  const { live, loading } = liveDetails;
  const classes = useStyles();
  const { connect, room, participants, isConnecting, error: errorLiveKit } = useRoom(roomOptions);

  const [livePaused, setLivePaused] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [showBullNotif, setShowBullNotif] = useState<boolean>(false);
  const [dataReceived, setDataReceived] = useState<any>();
  const [openAlertRequest, setOpenAlertRequest] = useState<boolean>(false);

  const liveParams = {
    audioDeviceId: undefined,
    videoDeviceId: undefined,
    audioPrejoin: undefined,
    videoPrejoin: undefined,
    audioEnabled: true,
    videoEnabled: true,
  };

  const [messages, setMessages] = useState<IMessage[]>([
    {
      dateTime: '',
      participantId: userId,
      participantName: 'me',
      text: 'Hi',
      profilePictureUrl: '',
      backgroundAvatar: randomColor(),
    },
  ]);

  const startConnection = async (liveId: string) => {
    try {
      const _participantByLive = await liveSessionServices.getParticipantByLive(liveId, 'Consumer');
      const _userId = _participantByLive?.participantId;
      const _logo = await authSevices.getProfilConsumer();
      if (_userId) {
        await authSevices.setParticipantId(_userId);
        const connex = await Call.listener.connexionToListener();

        connex.on('ParticipantHasJoined', (dataUserJoinned) => {
          if (dataUserJoinned.id === _userId) {
            setMe({
              ...me,
              id: dataUserJoinned.id,
              name: dataUserJoinned.name,
              identifiant: dataUserJoinned.name.slice(0, 2),
              profilePictureUrl: dataUserJoinned?.profilePictureUrl ?? '',
            });
          }

          if (internet && liveId && _userId) {
            dispatch(startCheckHistoryListener({ userType: 'Consumer', id: `${liveId}?participantId=${_userId}` }));
          }
        });

        connex.on('ParticipantHasLeft', (dataUserHasLeft) => {
          setViewersParticipant((prs) => prs && prs.filter((p) => p.id !== dataUserHasLeft.id));
        });

        connex.on('ShownProductsChanged', (dataproductIds) => {
          setProductIdsToShow(dataproductIds);
        });

        connex.on('ParticipantHasClickedOnProduct', (participant, productId) => {
          setDataNotificationProductClicked((_d) => ({
            productId,
            participantId: participant.id,
            participantName: participant.name,
            toggleClick: !_d.toggleClick,
          }));
        });

        connex.on('LiveSessionStarted', () => {
          setStatusLive((stat) => ({ ...stat, isStarted: true }));
        });

        connex.on('LiveSessionEnded', () => {
          setStatusLive((stat) => ({ ...stat, isEnded: true }));
        });

        connex.onclose(() => {
          setConnection(null);
          setInternet(false);
          setViewersParticipant((prs) => prs && prs.filter((p) => p.id !== _userId));
        });

        await connex.start().then(async () => await connex.invoke('Join'));

        setConnection(connex);
        return connex;
      } else {
        console.log('error:: no parcticipant id ');
        const urlBase = idAffiliation
          ? live.privacy === 0
            ? `/private/af/${idAffiliation}`
            : `/af/${idAffiliation}`
          : idInvitation
          ? `/vp/${idInvitation}`
          : '';
        history.push(`/auth/Consumer/${id + urlBase.trim()}`);
      }
    } catch (e: any) {
      console.log('Error start connection >>>>', e);
      throw new Error('Error start connnection consummer', e);
      setInternet(false);
    }
  };

  const messageSend = async (mess: string) => {
    const _logo = await authSevices.getProfilConsumer();
    const name = await localStorage.getItem('user');
    if (connection) {
      try {
        await connection.invoke('SendMessage', mess);
      } catch (e: any) {
        console.log('Error send message :>> ', e);
        throw new Error('Error send message consummer', e);
      }
    }
    setMessages((messages) => [
      ...messages,
      {
        dateTime: '',
        participantId: userId,
        participantName: name as string,
        text: mess,
        profilePictureUrl: _logo as string,
        backgroundAvatar: randomColor(),
      },
    ]);
  };

  const notifyProductClicked = async (productId: string) => {
    if (connection) {
      try {
        await connection.invoke('NotifyProductClicked', productId);
      } catch (e: any) {
        console.log('Error notify product clicked :>> ', e);
        throw new Error('Error notify product clicked', e);
      }
    }
  };

  const leaveSession = async (_connection: Promise<HubConnection | undefined>) => {
    const _userId = await authSevices.getParticipantId();
    const _connex = await _connection;
    if (_connex?.state === 'Connected') {
      await _connex?.invoke('Leave', _userId);
      // for (const p of participants) {
      //   await _connex?.invoke('Leave', p.id);
      // }
      _connex?.stop();
    }
  };

  const leavePage = async () => {
    // await authSevices.removeParticipantId();
    // await liveSessionServices.cleanParticipantByLive();
    dispatch(clearCheckHistoryListener());
    dispatch(doLeaveLiveSession());
    dispatch(setUrlToFrame(''));
    dispatch(resetProductConsumer());
    dispatch(ClearBindConsumer());
    dispatch(ClearAdvantage());
  };

  const handleConnectLive = async () => {
    let metaData = JSON.stringify({});
    if (idInvitation) {
      setConnectAs('vip');
      const _logo = await authSevices.getProfilConsumer();
      metaData = JSON.stringify({
        type: 'vip',
        profileImage: _logo,
        backgroundColor: randomColor(),
        participantRequest: 'none',
        showToLive: false,
        pinned: false,
        grade: 'vip',
      });
    }
    if (idAffiliation) {
      setConnectAs('friends');
      metaData = JSON.stringify({
        type: 'friends',
        profileImage: undefined,
        backgroundColor: randomColor(),
        participantRequest: 'none',
        showToLive: false,
        grade: 'friends',
      });
    }
    const _participantByLive = await liveSessionServices.getParticipantByLive(id, 'Consumer');
    const _userId = _participantByLive?.participantId;
    const _pseudo = _participantByLive?.pseudo;
    if (_userId) {
      getTokenLiveKit({
        participantID: _userId as string,
        participantName: _pseudo as string,
        roomName: id,
        canSubscribe: true,
        canPublish: true,
        metadata: metaData,
      })
        .then(async (dataToken) => {
          await connect(process.env.REACT_APP_LIVEKIT_SERVER as string, dataToken);
        })
        .catch((error) => {
          console.log(error);
          throw new Error('Error during Consummer connect on livekit', error);
        });
    }
  };

  const openPIPScreen = (): void => {
    const videoFirstContainer = document.getElementById('pinned-video-container');
    const videoLastContainer = (
      videoFirstContainer ? videoFirstContainer.querySelector('#video-live-container > video') : undefined
    ) as any;
    if (videoLastContainer) {
      videoLastContainer.requestPictureInPicture();
    }
  };

  useEffect(() => {
    if (live.id === id)
      (async () => {
        if (login.token) {
          settoken(login.token);
        } else {
          const _token = await authSevices.getAccessToken();
          if (_token) {
            settoken(_token);
          } else {
            settoken('');
          }
        }
        const _participantByLive = await liveSessionServices.getParticipantByLive(id, 'Consumer');
        const _userId = _participantByLive?.participantId;
        if (_userId) {
          setUserId(_userId);
        } else {
          console.log('error:: no parcticipant id 0000 =¦x>', live);
          const urlBase = idAffiliation
            ? live.privacy === 0
              ? `/private/af/${idAffiliation}`
              : `/af/${idAffiliation}`
            : idInvitation
            ? `/vp/${idInvitation}`
            : '';
          history.push(`/auth/Consumer/${id + urlBase.trim()}`);
        }
      })();
  }, [id, live.id, login.token]);

  useEffect(() => {
    if (dataHistory.loading === false && userId !== '' && me) {
      setMessages((mess_) =>
        dataHistory.data.messages.length && dataHistory.data.messages[0].text != ''
          ? dataHistory.data.messages.map((m: IMessage) => {
              return m;
            })
          : mess_,
      );
      setLoadingMessViewers({ mess: false, viewers: true });
      // checkExistingParticipant();
      setViewersParticipant((prs_) =>
        dataHistory.data.viewers.length && dataHistory.data.viewers[0].name && dataHistory.data.viewers[0].name != ''
          ? [
              ...dataHistory.data.viewers.map((v: IViewer) => {
                const viewer_ = {
                  ...v,
                  identifiant: v.name.slice(0, 2),
                  profilePictureUrl: v?.profilePictureUrl ?? '',
                };
                return viewer_;
              }),
            ]
          : prs_,
      );
      setLoadingMessViewers({ mess: false, viewers: false });
      if (dataHistory.data.viewers.some((_viewer) => _viewer.id === me.id && _viewer.type !== me.type)) {
        setMe({
          ...me,
          type: dataHistory.data.viewers.find((_d) => _d.id === me.id)!.type,
          profilePictureUrl: dataHistory.data.viewers.find((_d) => _d.id === me.id)?.profilePictureUrl ?? '',
        });
      }
    }
  }, [dataHistory, userId]);

  useEffect(() => {
    if (statusLive.isEnded) {
      room?.localParticipant.setCameraEnabled(false);
      room?.localParticipant.setMicrophoneEnabled(false);
      room?.disconnect(true);
    }
  }, [statusLive.isEnded]);

  useEffect(() => {
    if (livePaused) {
      room?.localParticipant.setCameraEnabled(false);
      room?.localParticipant.setMicrophoneEnabled(false);
    } else {
      const audioEnabled = localStorage.getItem('audioEnabled');
      const videoEnabled = localStorage.getItem('videoEnabled');
      room?.localParticipant.setCameraEnabled(videoEnabled === 'true');
      room?.localParticipant.setMicrophoneEnabled(audioEnabled === 'true');
    }
  }, [livePaused]);

  useEffect(() => {
    if (!internet) {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setInternet(true);
            clearInterval(webPing);
          })
          .catch(() => {
            setInternet(false);
          });
      }, 2000);
    } else {
      if (!loadingCodeAvantage) {
        const _connexStarted = startConnection(id);

        const unloadCallback = (event: any) => {
          event.preventDefault();
          leaveSession(_connexStarted);
          event.returnValue = '';
          return '';
        };
        //window.addEventListener('beforeunload', unloadCallback);
        return () => {
          leaveSession(_connexStarted);
          //window.removeEventListener('beforeunload', unloadCallback);
        };
      }
    }
  }, [internet, loadingCodeAvantage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      leavePage();
    };
  }, []);

  useEffect(() => {
    if (urlToFrame && productId) {
      console.log('Notification sended >>::', productId);

      notifyProductClicked(productId);
    }
  }, [checked]);

  useEffect(() => {
    if (
      me?.type === 2 &&
      viewersParticipant.some(
        (_p: IParticipant) => _p.id === dataNotificationProductClicked.participantId && _p.type === 1,
      )
    ) {
      setMessageProductShared((_mess) => ({
        ..._mess,
        id: dataNotificationProductClicked.productId,
        toggleClick: dataNotificationProductClicked.toggleClick,
        participantName: dataNotificationProductClicked.participantName,
      }));
    }
  }, [dataNotificationProductClicked]);

  useEffect(() => {
    if (!dataNotificationProductClicked.toggleClick) {
      setTimeout(() => {
        setShowBullNotif(false);
      }, 8000);
    } else {
      setShowBullNotif(true);
    }
  }, [dataNotificationProductClicked?.toggleClick]);

  useEffect(() => {
    console.log(showBullNotif);
  }, [showBullNotif]);

  useEffect(() => {
    (async () => {
      if (userId && token) {
        if (!idAffiliation && !idInvitation) {
          await dispatch(BindConsumer(userId));
          await dispatch(StopAffiliat());
        }
        if (idAffiliation) {
          await dispatch(
            Do_Affiliation({ liveId: id, dataAffiliat: { affiliationId: idAffiliation, participantId: userId } }),
          );
        }
        if (idInvitation) {
          await dispatch(Do_GuestConsumer({ liveId: id, idInvitation, participantId: userId }));
        }
      } else {
        if (userId && !token && !idInvitation && live.privacy === 1 && idAffiliation) {
          await Api.participant.Affiliation({
            affiliationId: idAffiliation,
            participantId: userId,
          });
          await dispatch(StopAffiliat());
        }
        if (userId && !token && (idInvitation || (live.privacy === 0 && idAffiliation))) {
          await leavePage();
          const urlBase = idAffiliation
            ? live.privacy === 0
              ? `/private/af/${idAffiliation}`
              : `/af/${idAffiliation}`
            : idInvitation
            ? `/vp/${idInvitation}`
            : '';
          history.push(`/auth/Consumer/${id + urlBase.trim()}`);
        }
        if (userId && !token && !idInvitation && !idAffiliation) {
          await dispatch(StopAffiliat());
        }
      }
    })();
  }, [userId, idAffiliation, idInvitation, token]);

  useEffect(() => {
    setStatusLive((stat) => ({ ...stat, isStarted: !!live.actualStart, isEnded: !!live.actualEnd }));
  }, [live.actualStart, live.actualEnd]);

  useEffect(() => {
    handleConnectLive();
    if (errorLiveKit) {
      handleConnectLive();
    }
  }, [errorLiveKit]);

  useEffect(() => {
    liveSessionConsumerGet(id).then((session) => {
      if (session.data.status === 0) {
        setStatusLivePaused(false);
      } else {
        setStatusLivePaused(true);
      }
    });
  }, []);

  const decoder = new TextDecoder();
  useEffect(() => {
    if (room) {
      room.on(RoomEvent.ParticipantDisconnected, (remoteParticipant) => {
        const remoteMetadata = JSON.parse(remoteParticipant.metadata as string);
        if (remoteMetadata.grade === 'retailer' && !statusLive.isEnded) {
          setLivePaused(true);
        }
      });

      room.on(RoomEvent.ParticipantConnected, (remoteParticipant) => {
        (async () => {
          if (remoteParticipant && remoteParticipant.metadata) {
            const remoteMetadata = JSON.parse(remoteParticipant.metadata as string);
            if (remoteMetadata.grade === 'retailer') {
              setLivePaused(false);
              const localVideoEnable = await localStorage.getItem('videoEnabled');
              const localAudioEnable = await localStorage.getItem('audioEnabled');
              await room.localParticipant.setCameraEnabled(localVideoEnable === 'true');
              await room.localParticipant.setMicrophoneEnabled(localAudioEnable === 'true');
            }
          }
        })();
      });

      room.on(RoomEvent.DataReceived, (payload: Uint8Array) => {
        const strData = decoder.decode(payload);
        const dataJson = JSON.parse(strData);
        setDataReceived(dataJson);
        if (dataJson.request === 'participateToLive') setOpenAlertRequest(true);
      });
    }
  }, [room]);

  useEffect(() => {
    if (room) {
      const retailer = participants.filter((participant) => {
        if (participant.metadata && JSON.parse(participant.metadata).grade === 'retailer') return participant;
      });
      if (retailer.length === 0 && statusLive.isStarted && !statusLive.isEnded) {
        setLivePaused(true);
      } else {
        setLivePaused(false);
      }
    }
  }, [participants]);

  const renderSharedProduct = () => {
    return (
      <ProductShareConsumer
        onPIP={openPIPScreen}
        fullscreen={fullscreen}
        productIdsToShow={productIdsToShow}
        primaryColor={liveDetails.live.primaryColor}
        {...{
          token,
          anchorElTwo,
          handleCloseTwo,
          handleOpen,
          handleClickTwo,
          messageProductShared,
          setMessageProductShared,
        }}
      />
    );
  };

  const renderChat = () => {
    return (
      <Chat
        isLiveStarted={statusLive.isStarted}
        isLiveEnded={statusLive.isEnded}
        messages={messages}
        id={id}
        primaryColor={liveDetails.live.primaryColor}
        loading={loadingMessViewers.mess}
        handleOpen={handleOpen}
        backgroundAvatar={randomColor()}
        connectionHub={connection ? connection : null}
        userId={userId}
      />
    );
  };

  const renderViewers = () => {
    return (
      <Viewers
        userId={userId}
        participant={viewersParticipant}
        participantLive={participants}
        fullscreen={fullscreen}
        {...{
          isLiveStarted: statusLive.isStarted,
          internet,
          id,
          viewersParticipant,
          loading: loadingMessViewers.viewers,
        }}
      />
    );
  };

  const renderWebFooter = () => {
    return (
      <WebFooter
        participant={viewersParticipant}
        livePaused={livePaused}
        fullscreen={fullscreen}
        {...{
          userId,
          live,
          token,
          handleRedirect,
          handleClickTwo,
          anchorElTwo,
          handleCloseTwo,
          handleOpen,
          viewersParticipant,
          id,
          internet,
          loading: loadingMessViewers.viewers,
          statusLive,
        }}
      />
    );
  };

  const tabsData: TabsData[] = [
    {
      label: 'Chat',
      icon: <ModeCommentOutlined />,
      children: (
        <Box
          className={clsx({
            [classes.tabContentContainer]: true,
            [classes.chatContainerMobile]: true,
            [classes.chatContainerMobileFullscreen]: fullscreen,
          })}
        >
          {renderChat()}
        </Box>
      ),
    },
    {
      label: 'Amis',
      icon: <PeopleOutlineOutlined />,
      children: (
        <Box className={clsx(classes.tabContentContainer, classes.viewersContainerMobile)}>{renderViewers()}</Box>
      ),
    },
    {
      label: 'Produits',
      icon: <StorefrontOutlined />,
      children: (
        <Box
          className={clsx({
            [classes.tabContentContainer]: true,
            [classes.productsContainerMobile]: true,
            [classes.tabContentContainerFullscreen]: fullscreen,
          })}
        >
          {renderSharedProduct()}
        </Box>
      ),
    },
    {
      label: 'Infos',
      icon: <InfoOutlined />,
      children: (
        <Box className={clsx(classes.tabContentContainer, classes.infosContainerMobile)}>{renderWebFooter()}</Box>
      ),
    },
  ];

  const isMobile = useIsMobile();

  const openFullscreenNavigator = async () => {
    //@ts-ignore
    if (document.fullscreenElement) {
      //@ts-ignore
      await document.exitFullscreen();
      //@ts-ignore
    } else if (document.webkitFullscreenElement) {
      //@ts-ignore
      await document.webkitExitFullscreen();
      //@ts-ignore
    } else if (document.mozFullScreenElement) {
      //@ts-ignore
      await document.mozCancelFullScreen();
      //@ts-ignore
    } else if (document.msFullscreenElement) {
      //@ts-ignore
      await document.msExitFullscreen();
    } else {
      const puzzleFull = document.getElementById('#containerRoomVIP');
      //@ts-ignore
      if (puzzleFull.requestFullscreen) {
        //@ts-ignore
        await puzzleFull.requestFullscreen();
        //@ts-ignore
      } else if (puzzleFull.webkitRequestFullscreen) {
        //@ts-ignore
        await puzzleFull.webkitRequestFullscreen();
        //@ts-ignore
      } else if (puzzleFull.mozRequestFullScreen) {
        //@ts-ignore
        await puzzleFull.mozRequestFullScreen();
        //@ts-ignore
      } else if (puzzleFull.msRequestFullscreen) {
        //@ts-ignore
        await puzzleFull.msRequestFullscreen();
      }
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      //@ts-ignore
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      //@ts-ignore
      document.webkitCancelFullScreen();
      //@ts-ignore
    } else if (document.msExitFullscreen) {
      /* IE11 */
      //@ts-ignore
      document.msExitFullscreen();
    }
  };

  const handleFullScreen = () => {
    if (isMobile) {
      if (!fullscreen) {
        openFullscreenNavigator();
      } else {
        closeFullscreen();
      }
    }

    setFullscreen(!fullscreen);
  };

  const handleSeeProduct = () => {
    seeProduct.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (statusLive.isEnded && room) {
      room?.localParticipant.tracks.forEach((track) => {
        track.videoTrack?.stop();
      });
      room?.localParticipant.setCameraEnabled(false);
      room?.localParticipant.setMicrophoneEnabled(false);
      room.disconnect();
    }
  }, [statusLive.isEnded]);

  useEffect(() => {
    if (livePaused) {
      room?.localParticipant.tracks.forEach((track) => {
        track.videoTrack?.stop();
        room?.localParticipant.setCameraEnabled(false);
        room?.localParticipant.setMicrophoneEnabled(false);
      });
    }
  }, [livePaused]);

  useEffect(() => {
    if (fullscreen) {
      history.push({
        pathname: location.pathname,
        search: '?fullscreen=true',
      });
    } else {
      const finalSearch = search.replace('?fullscreen=true', '&');
      history.push({
        pathname: location.pathname,
        search: finalSearch,
      });
    }
  }, [fullscreen]);

  useEffect(() => {
    const parsedMetadata = async () => {
      if (room) {
        const _participantByLive = await liveSessionServices.getParticipantByLive(id, 'Consumer');
        const _userId = await _participantByLive?.participantId;
        const listShowToLive: Array<string> = getListOfParticipantsInLive(room);
        if (listShowToLive.indexOf(_userId as string) !== -1) {
          await updateMetadataOfParticipant(room, _userId as string, room.localParticipant, 'showToLive', true);
        }
      }
    };

    parsedMetadata();
  }, [room?.metadata]);

  useEffect(() => {
    if (isMobile) {
      if (fullscreen) {
        history.push({
          pathname: location.pathname,
          search: 'tabsActive=' + 0,
        });
      }
      if (statusLive.isEnded) {
        history.push({
          pathname: location.pathname,
          search: 'tabsActive=' + 2,
        });
      } else {
        history.push({
          pathname: location.pathname,
          search: 'tabsActive=' + 3,
        });
      }
    }
  }, [fullscreen, statusLive.isEnded, isMobile]);

  useEffect(() => {
    const tabActive = search.split('tabsActive=')[1];
    const footerMobile = document.getElementById('footerMobile');
    const tabsContainerFullscreen = document.getElementById('tabsContainerFullscreen');

    if (footerMobile && tabsContainerFullscreen && isMobile) {
      if (tabActive === '0' || tabActive === '1') {
        footerMobile.style.display = 'none';
        tabsContainerFullscreen.style.height = '41vh';
      } else {
        footerMobile.style.display = 'flex';
        tabsContainerFullscreen.style.height = '25vh';
      }
    }
  }, [search, participants]);

  return (
    <Box className={classes.mainContainer}>
      {connectAs !== 'retailer' && !isMobile && !fullscreen && (
        <button
          onClick={handleSeeProduct}
          className={classes.seeProduct}
          style={{ backgroundColor: liveDetails.live.primaryColor ?? theme.palette.primary.light }}
        >
          Voir les produits
        </button>
      )}

      {showBullNotif && <Bulls message={messageProductShared.message} checked={messageProductShared.showBulls} />}
      {!fullscreen && <Header {...{ userId, connection }} />}
      <Box
        className={classes.subHeaderBlockContainer}
        style={{ height: !fullscreen ? 'calc(100vh - 75px)' : '100vh', paddingTop: '0px' }}
      >
        <Box className={clsx({ [classes.root]: true, [classes.rootFullscreen]: fullscreen })}>
          {renderViewers()}
          <Box
            className={clsx({ [classes.video]: true, [classes.videFullscreen]: fullscreen })}
            style={{ height: '100%' }}
          >
            <>
              {statusLive.isEnded || !statusLive.isStarted || livePaused ? (
                <>
                  {children}

                  {livePaused && <Typography style={{ fontWeight: 'bold' }}>Live en Pause</Typography>}
                </>
              ) : (
                <>
                  {statusLive.isStarted && !statusLive.isEnded && room && connectAs && (
                    <RoomComponent
                      paramatersRoom={liveParams}
                      room={room}
                      participantRoom={participants}
                      typeUser={connectAs}
                      setFullscreen={handleFullScreen}
                      fullscreen={fullscreen}
                      liveIsEnded={statusLive.isEnded}
                    />
                  )}
                </>
              )}
            </>

            {renderWebFooter()}

            <MobileFooter
              {...{
                live,
                anchorEl,
                handleClose,
                messages,
                messageSend,
                token,
                handleRedirect,
                handleClickTwo,
                handleClick,
                loading: loadingMessViewers.mess,
                id,
                handleOpen,
                statusLive,
                fullscreen,
                isMobile,
                backgroundAvatar: randomColor(),
                connectionHub: connection,
                userId: userId,
              }}
            />
          </Box>
          <Box
            className={clsx({
              [classes.chatplace]: true,
              [classes.chatplaceFullscreen]: fullscreen,
            })}
          >
            {renderChat()}
          </Box>
          {loading && <CustomBackdrop {...{ open: loading }} />}
          {isConnecting && !errorLiveKit && <CustomBackdrop {...{ open: isConnecting }} />}
        </Box>
        <Box
          className={clsx({
            [classes.sharedProductContainer]: true,
            [classes.sharedProductContainerFullscreen]: fullscreen,
          })}
          // style={{ height: '20%' }}
        >
          {renderSharedProduct()}
        </Box>
        <Box
          className={clsx({ [classes.tabsContainer]: true, [classes.tabsContainerFullscreen]: fullscreen })}
          id="tabsContainerFullscreen"
        >
          <Tabs
            defaultValue={fullscreen ? 0 : statusLive.isEnded ? 2 : 3}
            data={tabsData}
            indicatorPosition="top"
            indicatorColor={live.primaryColor}
          />
        </Box>
      </Box>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <LoginComponent toggleConnect={handleConnect} />
          </div>
        </Fade>
      </Modal>
      <div ref={seeProduct} />
      {/** Alert for User **/}
      {openAlertRequest && room && (
        <AlertLivekitRoom
          type="request"
          retailer={dataReceived.retailer}
          room={room}
          handleShow={setOpenAlertRequest}
          vip={room.localParticipant}
        />
      )}
    </Box>
  );
};
export default LiveScreen;
