import { put, call, takeEvery } from 'redux-saga/effects';
import {
  getCounterPartFailed,
  getCounterPartSuccess,
  GET_GUEST_COUNTER_PART_REQUEST,
} from '../../modules/guest/guestCounterPartGet';
import api from '../../api';
import { ErrorFunction } from '../utils/function';
import { Set_state_snackbar } from '../../modules/state/stateMessage';

export function* watchGuestCounterPartGet() {
  yield takeEvery(GET_GUEST_COUNTER_PART_REQUEST, guestCounterPartGet);
}

export function* guestCounterPartGet(payload: any) {
  try {
    const { data } = yield call(api.participant.CountAffiliationGetInfo, payload.payload);
    yield put(getCounterPartSuccess(data));
  } catch (error: any) {
    if (error) {
      yield put(getCounterPartFailed());
      yield call(ErrorFunction, error);
    }
  }
}
