import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CLOSE_LISTENER_REQUEST,
  endListener,
  listened,
  listenFailed,
  messageReceived,
  START_LISTENER_REQUEST,
} from '../modules/liveListener';
import { logout } from '../modules/logout';

import API from '../api';

export function* watchlistener() {
  yield takeEvery(START_LISTENER_REQUEST, startListening);
  yield takeEvery(CLOSE_LISTENER_REQUEST, closeListening);
}

export function* startListening() {
  try {
    const { connection } = yield call(API.listener.connexionToListener);

    yield put(listened(connection));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(listenFailed({ status, error: 'bad request' }));
          break;
        case 401:
          yield put(listenFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(logout());
          break;
        case 500:
          yield put(listenFailed({ status, error: 'Seule la session publiée peut être démarrée' }));
          break;
        default:
          yield put(listenFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(listenFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
          default:
            yield put(listenFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
        }
      }
    }
  }
}

export function* closeListening({ payload }: any) {
  try {
    yield payload.stop();
    yield put(endListener(5));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(listenFailed({ status, error: 'bad request' }));
          break;
        case 401:
          yield put(listenFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(logout());
          break;
        case 500:
          yield put(listenFailed({ status, error: 'Seule la session publiée peut être démarrée' }));
          break;
        default:
          yield put(listenFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(listenFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
          default:
            yield put(listenFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
        }
      }
    }
  }
}
