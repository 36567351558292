import { call, put, takeEvery } from 'redux-saga/effects';
import { DeleteProductSuccess, DELETE_PRODUCT_REQUEST } from '../modules/product/productDelete';
import api from '../api/index';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { logout } from '../modules/logout';
import { product_create_failed } from '../modules/product/productCreate';
import { ErrorFunction } from './utils/function';

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT_REQUEST, DeleteProductionfunction);
}

export function* DeleteProductionfunction({ payload }: any) {
  try {
    const { data, status } = yield call(api.product.DeleteProduct, payload);
    yield put(DeleteProductSuccess(payload));
    yield put(
      Set_state_snackbar({ status: 200, message: 'Suppression effectuée avec succès', open: true, type: 'success' }),
    );
  } catch (error: any) {
    const { status, message } = error?.response;
    // yield put(Set_state_snackbar({ status: status, message: message, open: true, type: 'error' }));
    yield put(product_create_failed({ status: status, message: message }));
    yield call(ErrorFunction, error);
  }
}
