import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import Smile from '../../Assets/images/smile.svg';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

interface IPropsBull {
  message?: string;
  checked?: boolean;
}

const Bulls: React.FC<IPropsBull> = (props: IPropsBull) => {
  const { message, checked = false } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.bullMess}>{message}</Box>
            <div className={classes.triangle} />
            <img src={Smile} alt="React Logo" />
          </Paper>
        </Slide>
      </div>
    </div>
  );
};

export default Bulls;
