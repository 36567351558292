import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { AFFILIATION_REQUESTED, Affiliation_Sucess, Affiliation_Failed } from '../../modules/participant/affiliation';
import Api from '../../api';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { logout } from '../../modules/logout';
import { GetAdvantageCode } from '../../modules/participant/advantageCode';
import { checkHistoryListenerSuccess } from '../../modules/listenerHistory';
import { BindConsumer } from '../../modules/participant/bindConsumer';

export function* watchAffiliation() {
  yield takeEvery(AFFILIATION_REQUESTED, functionAffiliation);
}

export function* functionAffiliation({ payload }: any) {
  try {
    // bindConsumer participant
    const { status } = yield call(Api.participant.BindConsumer, payload.dataAffiliat.participantId);

    yield delay(100);

    if (status === 200) {
      // Participant/AffiliateToGuest
      const { status: StatusAffiliation } = yield call(Api.participant.Affiliation, payload.dataAffiliat);

      yield delay(100);

      if (StatusAffiliation === 200) {
        // get status and viewers participant
        const { data: viewers, status: statusViewers } = yield call(Api.listener.checkHistoryViewersListener, {
          userType: 'Consumer',
          id: `${payload.liveId}?participantId=${payload.dataAffiliat.participantId}`,
        });
        const { data: messages } = yield call(Api.listener.checkHistoryMessagesListener, {
          userType: 'Consumer',
          id: `${payload.liveId}?participantId=${payload.dataAffiliat.participantId}`,
        });
        const _data = {
          viewers: viewers ? viewers : [{ id: '', name: '' }],
          messages: messages
            ? messages
            : [
                {
                  dateTime: '',
                  participantId: '',
                  participantName: '',
                  text: '',
                },
              ],
        };
        yield put(checkHistoryListenerSuccess(_data));

        yield delay(100);

        if (statusViewers === 200) {
          // get code advantage
          yield put(GetAdvantageCode(payload.dataAffiliat.participantId));
          yield put(Affiliation_Sucess());
        }
      }
    }
  } catch (e: any) {
    if (e.response) {
      yield put(Affiliation_Failed());
      const { status } = e.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = e;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 503,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );

            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 503,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
