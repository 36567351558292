export default {
  'footer.plateforme': 'Number 1 live-commerce platform in France!',
  'footer.propos': 'A propos',
  'footer.condition': 'Terms of use',
  'footer.contact': 'Contact',
  'footer.help': 'Help center',
  'footer.preference': 'Preferences cookies',
  'footer.info': 'Corporate information',
  'footer.client': 'Our clients',
  'footer.cartes': 'Gift cards',
  'footer.reserved': '© 2021 Livood, All Rights Reserved',
};
