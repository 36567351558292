import * as React from 'react';

function ScheduleEventIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9479 14.6979C26 14.2812 26 13.9167 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66116C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66116 4.66116C2.31696 7.00537 1 10.1848 1 13.5C1 13.9167 1 14.3333 1.05208 14.6979V14.8542C2.09375 24.75 13.5 31.7292 13.5 31.7292C13.5 31.7292 24.9062 24.6458 25.8958 14.9062L25.9479 14.6979Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7.25V14.5417H18.7083"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ScheduleEventIcon;
