import { Box, CssBaseline } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomSnackBar } from './common/snackBar/CustomSnackBar';
import { RootState } from './redux-services';
import { IinitialStateLiveEnd } from './redux-services/modules/liveSessionEnd';
import { initialStateCreate } from './redux-services/modules/liveSessionRetailerCreate';
import { initialStateUpdate } from './redux-services/modules/liveSessionRetailerUpdate';
import { IinitialStateLiveStart } from './redux-services/modules/liveSessionStart';
import { IResultLoginSAGA } from './redux-services/modules/login';
import { IinitialStateProduct } from './redux-services/modules/product/productCreate';
import { InitialStateSnackBar } from './redux-services/modules/state/stateMessage';
import Router from './rooter/Router';

interface resultStatusLogin {
  message: string | '';
  type: 'error' | 'warning' | 'info' | 'success';
}

const Main: React.FC = () => {
  const { t } = useTranslation();
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const createLive = useSelector<RootState, initialStateCreate>((state) => state.createLiveSession);
  const endLive = useSelector<RootState, IinitialStateLiveEnd>((state) => state.liveSessionEnd);
  const {
    status: statusCreateProduct,
    loading: loadingCreateProduct,
    message: messageCreatedProduct,
  } = useSelector<RootState, IinitialStateProduct>((state) => state.productCreated);
  const startlive = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);
  const updateLiveSession = useSelector<RootState, initialStateUpdate>((state) => state.updateLiveSession);
  const {
    status: statusSnack,
    message: messageSnack,
    open: openSnack,
    type: typedSnack,
  } = useSelector<RootState, InitialStateSnackBar>((state) => state.stateSnackBar);

  // const { loading: loadingUpdateProduct, status: statusUpdateProduct } = useSelector<
  //   RootState,
  //   IinitialStateUpdateProduct
  // >((state) => state.productUpdate);

  const [open, setOpen] = useState<boolean>(false);
  const [result, setresult] = useState<resultStatusLogin>({
    message: '',
    type: 'info',
  });
  const { status: statusEndLive, loading: loadingEndLive, error: errorEndLive } = endLive;
  const { error: errorStartlive, loading: loadingStartlive, status: statusStartlive } = startlive;
  const { loading, status, error } = login;
  const { error: errorUpdate, status: statusUpdate, loading: loadingUpdate } = updateLiveSession;
  const { status: statusLive, loading: loadingCreateLive, error: errorCreatelive } = createLive;

  const CheckAction = (status: number, messageError: string, messageSuccess: string) => {
    switch (status) {
      case 200 || 204:
        setOpen(!open);
        setresult({ message: messageSuccess, type: 'success' });
        break;
      case 400 || 401:
        setOpen(true);
        setresult({ message: messageError, type: 'error' });
        break;
      case 500 || 503:
        setOpen(!open);
        setresult({ message: messageError, type: 'error' });
        break;
      default:
        setOpen(!open);
        setresult({ message: messageError, type: 'error' });
        break;
    }
  };

  useEffect(() => {
    if (status && !loading) {
      CheckAction(status, error, t('main.auth.succ'));
    }
  }, [status, loading]);

  // useEffect(() => {
  //   if (statusUpdate && !loadingUpdate) {
  //     CheckAction(statusUpdate, errorUpdate, t('main.operation.succ'));
  //   }
  // }, [statusUpdate, loadingUpdate]);

  // useEffect(() => {
  //   if (statusLive && !loadingCreateLive) {
  //     CheckAction(statusLive, errorCreatelive, t('main.operation.succ'));
  //   }
  // }, [statusLive, loadingCreateLive]);

  // useEffect(() => {
  //   if (statusStartlive && !loadingStartlive) {
  //     CheckAction(statusStartlive, errorStartlive, t('main.live.demarre'));
  //   }
  // }, [loadingStartlive, statusStartlive]);

  // useEffect(() => {
  //   if (statusEndLive && !loadingEndLive) {
  //     CheckAction(statusEndLive, errorEndLive, t('main.live.stop'));
  //   }
  // }, [loadingEndLive, statusEndLive]);

  // useEffect(() => {
  //   if (statusCreateProduct && !loadingCreateProduct && messageCreatedProduct) {
  //     CheckAction(statusCreateProduct, 'Bad Request', t('newProductToSale.created.success'));
  //   }
  // }, [statusCreateProduct, loadingCreateProduct]);

  // useEffect(() => {
  //   if (statusUpdateProduct && !loadingUpdateProduct) {
  //     CheckAction(statusUpdateProduct, 'Bad Request', t('newProductToSale.created.success'));
  //   }
  // }, [statusUpdateProduct, loadingUpdateProduct]);

  useEffect(() => {
    document.addEventListener('blur', () => {
      console.log('Blur>>');
    });
    return () => {
      document.removeEventListener('blur', () => {
        console.log('');
      });
    };
  }, []);
  return (
    <Box>
      <CssBaseline />
      {/* <CustomSnackBar {...{ open, setOpen, ...result }} /> */}
      <CustomSnackBar {...{ open: openSnack, message: messageSnack, type: typedSnack }} />
      <Router />
    </Box>
  );
};

export default Main;
