import * as React from 'react';

function AccountIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5498 27.65C7.20779 27.2464 7.93803 26.9747 8.6998 26.85C12.7498 26.1 12.9998 24 12.9998 23.15V23C10.9874 22.8716 8.9936 22.5365 7.0498 22C8.6998 20.35 9.9998 19.4 9.9998 12.4C9.9998 12.4 9.9498 7 14.9998 7C14.9998 7 21.9998 5.8 21.9998 12.6C21.9998 19.4 23.3998 20.35 25.0498 22C23.0721 22.5371 21.0451 22.8721 18.9998 23V23.15C18.9998 23.95 19.2498 26.05 23.2998 26.85C24.0644 26.9925 24.7944 27.2811 25.4498 27.7"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AccountIcon;
