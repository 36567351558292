import { typedAction } from '../../utils';

export const LIST_PRODUCT_REQUEST = 'LIST/PRODUCT/REQUEST';
export const LIST_PRODUCT_SUCCESS = 'LIST/PRODUCT/SUCCESS';
export const LIST_PRODUCT_FAILED = 'LIST/PRODUCT/FAILED';

export interface IdataProduct {
  id: string;
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  mainImageUrl: string;
  isVisible?: boolean;
}

// export interface Ierror {
//   message: string;
//   status: number | undefined;
// }

export interface IinitialStateListProduct {
  ListProduct: IdataProduct[];
  loading: boolean;
  error: string | undefined;
  status: number | undefined;
}

const initialState: IinitialStateListProduct = {
  ListProduct: [],
  status: undefined,
  error: undefined,
  loading: false,
};

export const get_AllProduct = (idSession: string) => typedAction(LIST_PRODUCT_REQUEST, idSession);
export type Get_AllProduct = typeof get_AllProduct;

export const get_allProduct_Success = (data: IdataProduct[]) => typedAction(LIST_PRODUCT_SUCCESS, data);
export type Get_allProduct_Success = typeof get_allProduct_Success;

export const get_allProduct_failed = (args: { status: number; error: string }) =>
  typedAction(LIST_PRODUCT_FAILED, args);
export type Get_allProduct_failed = typeof get_allProduct_failed;

export type UpdateProductAction = ReturnType<Get_AllProduct | Get_allProduct_Success | Get_allProduct_failed>;

export const ListProductReducers = (
  state: IinitialStateListProduct = initialState,
  action: UpdateProductAction,
): IinitialStateListProduct => {
  switch (action.type) {
    case LIST_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        ListProduct: action.payload,
      };
    case LIST_PRODUCT_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
