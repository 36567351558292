import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: '3%',
      paddingRight: '3%',
      backgroundColor: '#222222',
    },
    head: {
      display: 'flex',
      padding: '40px 0px',
      alignItems: 'baseline',
      '& p:last-child': {
        marginLeft: 30,
      },
      '@media(max-width:860px)': {
        flexDirection: 'column',
        '& p:last-child': {
          marginLeft: 0,
        },
      },
    },
    content: {
      display: 'flex',
      '& ul': {
        listStyle: 'none',
        width: '25%',
        padding: 0,
        margin: 0,
      },
      '& >ul>li': {
        color: '#F5F0F6',
        fontFamily: 'Open Sans',
        paddingBottom: 20,
      },
      '@media(max-width:860px)': {
        flexWrap: 'wrap',
        '& ul': {
          maxWidth: '50%',
          width: '50%',
        },
      },
      '@media(max-width:450px)': {
        flexWrap: 'wrap',
        '& ul': {
          maxWidth: '100%',
          width: '100%',
        },
      },
    },
    foot: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 20,
      paddingBottom: 20,
      '& p': {
        fontSize: 12,
        fontWeight: 500,
      },

      '& .iconfooter': {
        display: 'flex',
        '& div': {
          backgroundColor: '#F5F0F6',
          borderRadius: 50,
          marginLeft: 20,
          width: 35,
          height: 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      '@media(max-width:860px)': {
        flexDirection: 'column-reverse',
        alignItems: 'unset',
        '& p': {
          marginTop: 20,
        },
        '& .iconfooter': {
          '& div:first-child': {
            marginLeft: 0,
          },
        },
      },
    },
  }),
);
