import axios, { AxiosPromise } from 'axios';
import { IBodyConsumerCount, IBodyUpdateConsumerCount } from '../../../type/account';

export const createConsumer = (data: IBodyConsumerCount): AxiosPromise<any> =>
  axios.post(`/Admin/Consumer/Create`, data);

export const listConsumerAPi = (): AxiosPromise<any> => axios.get(`/Admin/Consumer/List`);

export const updateConsumer = (payload: { id: string; data: IBodyUpdateConsumerCount }): AxiosPromise<any> =>
  axios.post(`/Admin/Consumer/Update/${payload.id}`, payload.data);

export const uploadProfilImage = (params: File): AxiosPromise<any> => {
  const formData = new FormData();
  formData.append('file', params);
  return axios.post(`/Consumer/Account/SetProfilePicture`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
