import { Box, Button, Menu } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { logout } from '../../redux-services/modules/logout';
import authSevices from '../../redux-services/services/authSevices';
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
import { AUTH_CONSUMER_LOG, CONSUMER_ACCOUNT_SETTING, CONSUMER_PASSWORD } from '../../constant/path';
import { RootState } from '../../redux-services';
import { IgetLive, IinitialStateConsumerGet } from '../../redux-services/modules/liveSessionConsumerget';
import { HubConnection } from '@microsoft/signalr';
import { IinitialProfileImg } from '../../redux-services/modules/state/stateProfileImg';
import theme from '../../Themes';
import CustomDrawer from './Drawer/CustomDrawer';
import { useIsMobile } from './useIsMobile';
interface IProps {
  connection?: HubConnection | null;
  userId?: string;
  securityStamp?: string;
  fullscreen?: boolean;
}

const Header: React.FC<IProps> = (props: IProps) => {
  const { connection, userId, securityStamp, fullscreen } = props;

  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { idInvitation, idAffiliation } = useParams<IgetLive>();
  const userType = localStorage.getItem('userType');
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    live: { id, primaryColor },
  } = useSelector<RootState, IinitialStateConsumerGet>((state) => state.liveSessionConsumerById);
  const { url: stateUrlImg, name: stateName } = useSelector<RootState, IinitialProfileImg>(
    (state) => state.stateProfileImg,
  );
  const initialHeaders = [
    `/`,
    `/LiveCurrent/${id}`,
    `/auth/retailer`,
    `/LiveCurrent/${id}/af/${idAffiliation}`,
    `/LiveCurrent/${id}/vp/${idInvitation}`,
    `/auth/Consumer/${id}`,
    `/auth/inscription`,
    '/auth/resetpassword',
    `/Consumer/account-confirmation/${userId}/${securityStamp}`,
    CONSUMER_ACCOUNT_SETTING,
    CONSUMER_PASSWORD,
    '/home',
  ];
  const [headers, setHeaders] = useState<string[]>(initialHeaders);
  const [regExHeaders, setRegExHeaderss] = useState<RegExp[]>([]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [token, settoken] = React.useState<string>();
  const [info, setInfo] = useState({ name: '', imgUrl: '' });

  const classes = useStyles({ primaryColor });

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickEntreprise = async () => {
    // token ? history.push('/dashboard') : history.push('/auth/Retailer');
    window.location.replace('https://livood.com/');
  };
  authSevices.getAccessToken().then((result) => {
    if (result) {
      settoken(result);
    }
  });

  const handleGotologin = () => {
    history.push(AUTH_CONSUMER_LOG);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handlelogout = async () => {
    if (!securityStamp && userId && connection) {
      await connection.invoke('Leave');
    }
    history.push('/');
    dispatch(logout());
  };

  const handleCGU = () => {
    window.open('https://lamazuna.com/18_nos-conditions-generales-de-vente', '_blank');
  };

  const handleSetting = () => {
    history.push('/Consumer/account');
    handleMenuClose();
    handleMobileMenuClose();
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menu}
    >
      {!token ? (
        <MenuItem onClick={handleGotologin}>{t('header.login')}</MenuItem>
      ) : (
        <div>
          <MenuItem onClick={handleSetting}>Compte</MenuItem>
          <MenuItem onClick={handlelogout}>{t('header.logout')}</MenuItem>
          <MenuItem onClick={handleCGU}>{t('header.cgu')}</MenuItem>
        </div>
      )}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.menumobile}
    >
      {(!userType?.length || (userType === 'Retailer' && pathname === '/')) && (
        <MenuItem onClick={handleProfileMenuOpen}>
          <Button onClick={handleClickEntreprise} className={classes.btnHead}>
            <Typography>{t('header.entreprise')}</Typography>
          </Button>
        </MenuItem>
      )}
      {token ? (
        <MenuItem onClick={handlelogout}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Typography>{t('header.logout')}</Typography>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleGotologin}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Typography>{t('header.login')}</Typography>
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    (async () => {
      const _name = await authSevices.getNameRetailer();
      const _logo =
        userType === 'Consumer' ? await authSevices.getProfilConsumer() : await authSevices.getLogoRetailer();

      setInfo({ name: _name ?? info.name, imgUrl: _logo ?? info.imgUrl });
    })();
  }, []);

  useEffect(() => {
    if (stateUrlImg || stateName) {
      setInfo({ name: stateName ?? info.name, imgUrl: stateUrlImg ?? info.imgUrl });
    }
  }, [stateUrlImg, stateName]);

  useEffect(() => {
    if (isMobile) {
      setHeaders([
        `/dashboard`,
        '/dashboard/account',
        '/dashboard/stats',
        '/dashboard/create',
        '/dashboard/livesessions',
        `/LiveCurrent/${id}`,
        `/auth/retailer`,
        `/dashboard/livesessions/123/products`,
        ...initialHeaders,
      ]);
      setRegExHeaderss([
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/parametre?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/affiliation?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/products?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/showlive?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/invitations?$/gi,
        // eslint-disable-next-line
        /^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]*\/products\/addproduct?$/gi,
      ]);
    } else {
      setHeaders(initialHeaders);
      setRegExHeaderss([]);
    }
  }, [isMobile, id, idAffiliation]);

  const [displayHeader, setDisplayHeader] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile && !pathname.includes('/auth')) {
      setDisplayHeader(true);
    }
    if ((!isMobile && pathname.includes('/dashboard')) || pathname.includes('/auth')) {
      setDisplayHeader(false);
    } else {
      setDisplayHeader(true);
    }
  }, [pathname, isMobile]);

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  return (
    <>
      {displayHeader ? (
        <>
          <AppBar position="sticky" className={classes.appbar} elevation={4}>
            <Toolbar className={classes.navbar}>
              <Box className={classes.navLeft}>
                <Button className={classes.btnLivood}>
                  <Typography className={`${classes.title} _desktopView`} variant="h6">
                    {t('header.title')}
                  </Typography>

                  {pathname.includes('auth/inscription') && (
                    <Typography className={`${classes.title} _mobileView`} variant="h6">
                      Inscription
                    </Typography>
                  )}
                  {pathname === '/dashboard/livesessions' && (
                    <Typography
                      style={{ textTransform: 'initial' }}
                      className={`${classes.title} _mobileView`}
                      variant="h6"
                    >
                      Lives programmés
                    </Typography>
                  )}
                  {regExHeaders.find((p) => pathname.match(p)) !== undefined && (
                    <Typography
                      className={`${classes.title} _mobileView`}
                      style={{ textTransform: 'initial' }}
                      variant="h6"
                    >
                      {pathname.includes('showlive') ? t('details.showlive.mobile.title') : t('details.live')}
                    </Typography>
                  )}
                  {[
                    `/LiveCurrent/${id}`,
                    `/LiveCurrent/${id}/af/${idAffiliation}`,
                    `/LiveCurrent/${id}/vp/${idInvitation}`,
                  ].includes(pathname) && (
                    <Typography
                      className={`${classes.title} _mobileView`}
                      style={{ textTransform: 'initial' }}
                      variant="h6"
                    >
                      {'LIVOOD'}
                    </Typography>
                  )}
                </Button>
              </Box>

              <Box className={classes.navRight}>
                {(!userType?.length || (userType === 'Retailer' && pathname === '/')) && (
                  <Box>
                    <Button onClick={handleClickEntreprise} className={classes.btnHead + ' desktop'}>
                      {t('header.entreprise')}
                    </Button>
                  </Box>
                )}
                <Box>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="primary"
                    className={classes.profile}
                    style={{
                      ...(token && {
                        ...(info.imgUrl !== ''
                          ? {
                              backgroundImage: `url(${info.imgUrl})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }
                          : { backgroundColor: theme.palette.primary.light }),
                      }),
                    }}
                  >
                    {!token ? <AccountCircleIcon fontSize="large" /> : info.imgUrl === '' && info.name.slice(0, 2)}
                  </IconButton>
                </Box>
                <Box>
                  {!token && (
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MenuIcon color="primary" />
                    </IconButton>
                  )}
                  {token && (
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={toggleDrawer}
                      color="inherit"
                      className={classes.burgerMenu}
                    >
                      <MenuIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Toolbar>
            {token && <CustomDrawer open={openDrawer} userType={userType} toggleDrawer={toggleDrawer} />}
          </AppBar>
          {![
            `/LiveCurrent/${id}`,
            `/LiveCurrent/${id}/af/${idAffiliation}`,
            `/LiveCurrent/${id}/vp/${idInvitation}`,
          ].includes(pathname) && <Box className={classes.leaf}></Box>}
          {renderMobileMenu}
          {renderMenu}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
