import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBackdrop from '../../../common/backdrop/CustomBackdrop';
import { isEmailValid } from '../../../lib/auth/authvalidate';
import { RootState } from '../../../redux-services';
import {
  DO_sendEmailResetPassword,
  InitialstateSendEmailResetPassword,
} from '../../../redux-services/modules/account/sendEmailResetPassword';
import theme from '../../../Themes';
import { useStyles } from '../style';

const ForgotPassword: React.FC = () => {
  const style = useStyles();
  const [mail, setMail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [success, setsuccess] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const value = String(event.target.value);

    if (!isEmailValid(value)) {
      setMail(value);
      setErrorEmail(true);
    } else {
      setMail(value);
      setErrorEmail(false);
    }
  };

  // saga
  const dispatch = useDispatch();

  const { loading, status } = useSelector<RootState, InitialstateSendEmailResetPassword>(
    (state) => state.sendEmailResetPassword,
  );

  const handelSendEmail = () => {
    dispatch(DO_sendEmailResetPassword({ userType: 'Consumer', email: mail }));
  };

  useEffect(() => {
    if (!loading && status === 200) {
      setsuccess(true);
    }
  }, [loading, status]);

  return (
    <Box>
      <CustomBackdrop {...{ open: loading }} />
      <Box className={style.root}>
        <Box className={style.cadre}>
          <Typography style={{ fontSize: 22 }}>Mot de passe oublié</Typography>
          {!success && (
            <Box style={{ width: 316, padding: '10px', margin: 25, height: 60 }}>
              <TextField
                {...{
                  style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                  name: 'email',
                  label: 'Email',
                  type: 'email',
                  variant: 'outlined',
                  value: mail,
                  onChange: handleChange,
                  InputLabelProps: { style: { color: 'black' } },
                  // onKeyDown: (e) => onKeyPressed(e),
                }}
              />
              {errorEmail && (
                <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>Mail format invalide</Typography>
              )}
            </Box>
          )}
          {!success && (
            <Button
              {...{
                style: { marginTop: 20, color: theme.palette.primary.light },
                disabled: !isEmailValid(mail),
                onClick: handelSendEmail,
              }}
            >
              <Typography>Réinitialiser</Typography>
            </Button>
          )}
          {success && (
            <Box style={{ marginTop: 25 }}>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Un lien pour le changement votre mot de passe a été envoyé à votre adresse email
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
