export default {
  'stat.vip.mail': 'Email du VIP',
  'stat.advantageCode': 'Code Achat',
  'stat.pseudo': 'Pseudo',
  'stat.participantName': 'Nom du participant',
  'stat.participantMail': 'Email du paricipant',
  'stat.invited': 'Invités',
  'stat.invitedNam': 'Nom',
  'stat.invitedLastName': 'Prenom',
  'stat.invitedMail': 'Email',
};
