import { typedAction } from '../../utils';

export const UPDATE_GUEST_REQUEST = 'UPDATE/GUEST/REQUEST';
export const UPDATE_GUEST_SUCCESS = 'UPDATE/GUEST/SUCCESS';
export const UPDATE_GUEST_FAILED = 'UPDATE/GUEST/FAILED';

export interface IdataGuest {
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  mainImageId: string;
}
export interface IparamsUpdate {
  id: string;
  body: IdataGuest;
}

export interface IguestFailed {
  message: string;
  status: number | undefined;
}

interface Iresult {
  id: string;
  message: string | null;
  status: number | undefined;
}
export interface IinitialStateUpdateGuest {
  id: string;
  message: string | null;
  status: number | undefined;
  loading: boolean;
}
const initialStateGuest: IinitialStateUpdateGuest = {
  id: '',
  message: '',
  status: undefined,
  loading: false,
};

export const do_update_guest = (data: IparamsUpdate) => typedAction(UPDATE_GUEST_REQUEST, data);
export type Ido_update_guest = typeof do_update_guest;

export const guest_updated = (result: Iresult) => typedAction(UPDATE_GUEST_SUCCESS, result);
export type IproduitUpdated = typeof guest_updated;

export const guest_update_failed = (error: IguestFailed) => typedAction(UPDATE_GUEST_FAILED, error);
export type Iguest_update_failed = typeof guest_update_failed;

export type UpdateGuestAction = ReturnType<Ido_update_guest | IproduitUpdated | Iguest_update_failed>;

export const UpdateGuestReducers = (
  state: IinitialStateUpdateGuest = initialStateGuest,
  action: UpdateGuestAction,
): IinitialStateUpdateGuest => {
  switch (action.type) {
    case UPDATE_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GUEST_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        message: action.payload.message,
        status: action.payload.status,
        loading: false,
      };
    case UPDATE_GUEST_FAILED:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
