import React, { FC, JSXElementConstructor, ReactElement, ReactNode, useEffect } from 'react';
import { useStyles } from './style';
import { Tabs as MUITabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

export interface TabPanelProps {
  index: number;
  value: number;
  children?: ReactNode;
}

export interface TabsData {
  label: string;
  children: ReactNode;
  icon?: string | ReactElement<any, string | JSXElementConstructor<any>>;
}

export interface TabsProps {
  data: TabsData[];
  indicatorPosition?: 'top' | 'bottom';
  indicatorColor?: string;
  defaultValue?: number;
}

const TabPanel: FC<TabPanelProps> = ({ index, value, children, ...restProps }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...restProps}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs: FC<TabsProps> = ({ data, indicatorPosition = 'bottom', indicatorColor, defaultValue = 0 }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);
  const history = useHistory();
  const { search } = useLocation();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    history.push({
      pathname: location.pathname,
      search: 'tabsActive=' + newValue,
    });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      {data.map((d, index) => {
        return (
          <TabPanel key={`${index}-tabpanel`} value={value} index={index}>
            {d.children}
          </TabPanel>
        );
      })}
      <MUITabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{
          className: `MuiTabs-indicator ${indicatorPosition}`,
          style: { background: indicatorColor },
        }}
      >
        {data.map((d, index) => {
          return <Tab key={`${index}-tab`} label={d.label} icon={d.icon} {...a11yProps(index)} />;
        })}
      </MUITabs>
    </div>
  );
};

export default Tabs;
