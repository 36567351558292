import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ButtonImage } from '../../common/buttonWithImage/buttonImage';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100%',
    },
    containerWithData: { marginTop: 50, padding: 20 },
    containerData: { flexDirection: 'row', display: 'flex', overflowX: 'auto' },
    boxImage: { display: 'flex', flex: 1, flexDirection: 'column', margin: '26px' },
    buttonImageRoot: { alignSelf: 'center' },
    buttonImage: { border: '2px solid red', width: '150px' },
    buttonSave: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      fontFamily: 'Open Sans',
      textTransform: 'unset',
      // width: 90,
      marginTop: 'auto',
      alignSelf: 'center',
    },
    buttonUpdate: {
      backgroundColor: ' #222222',
      width: '90%',
      margin: 20,
      borderRadius: 10,
      padding: 10,
      textTransform: 'unset',
    },
    titleChangPassword: {
      color: theme.palette.primary.dark,
    },
  }),
);
