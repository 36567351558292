import { typedAction } from '../utils';

export interface section {
  id: number;
  name: string;
}
export interface detailSection {
  id: string;
  title: string;
  categoryId: string;
  categoryName: string;
  scheduledStart: string;
  scheduledDuration: number;
  privacy: number;
  isPublished: boolean;
  thumbnailImageUrl: string;
  actualStart: string;
  actualEnd: string;
}
export interface IListBySectionRetailer {
  section: section;
  sessions: detailSection[];
}

export interface ListBySectionError {
  status: number;
  error: string;
}
export interface IinitialStateBySection {
  ListBySection: IListBySectionRetailer[];
  error: ListBySectionError | undefined;
  loading: boolean;
}
export const initialStateBySection: IinitialStateBySection = {
  ListBySection: [],
  error: undefined,
  loading: false,
};

export const LIST_BY_SECTION_REQUEST = 'LIST_BY_SECTION_REQUEST';
export const LIST_BY_SECTION_SUCCESS = 'LIST_BY_SECTION_SUCCESS';
export const LIST_BY_SECTION_FAILED = 'LIST_BY_SECTION_FAILED';

export const getListBySection = () => typedAction(LIST_BY_SECTION_REQUEST);
export type GetListBySection = typeof getListBySection;

export const listBySectionSuccess = (Result: IListBySectionRetailer[]) => typedAction(LIST_BY_SECTION_SUCCESS, Result);
export type ListBySectionSuccess = typeof listBySectionSuccess;

export const listBySectionFailed = (error: ListBySectionError) => typedAction(LIST_BY_SECTION_FAILED, error);
export type ListBySectionFailed = typeof listBySectionFailed;

export type ActionListBySection = ReturnType<GetListBySection | ListBySectionSuccess | ListBySectionFailed>;

export const listBySectionReducer = (
  state: IinitialStateBySection = initialStateBySection,
  action: ActionListBySection,
): IinitialStateBySection => {
  switch (action.type) {
    case LIST_BY_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_BY_SECTION_SUCCESS:
      return {
        ...state,
        ListBySection: action.payload,
        error: undefined,
        loading: false,
      };
    case LIST_BY_SECTION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
