import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      height: 60,
      '& p': {
        fontSize: 12,
        fontWeight: 'normal',
        margin: 0,
        alignSelf: 'flex-end',
      },
    },
  }),
);
