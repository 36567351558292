import { Box, Button, Grid, Paper, TextareaAutosize, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import { ButtonImage } from '../../common/buttonWithImage/buttonImage';
import CustomModal from '../../common/modal/Modal';
import { CustomSnackBar } from '../../common/snackBar/CustomSnackBar';
import { RootState } from '../../redux-services';
import { do_create_product, IinitialStateProduct } from '../../redux-services/modules/product/productCreate';
import { get_ProductById, IinitialStateProductById } from '../../redux-services/modules/product/productget';
import { do_update_product, IinitialStateUpdateProduct } from '../../redux-services/modules/product/productUpdate';
import { ResetStateProduct, setStateProduct } from '../../redux-services/modules/state/stateProducts';
import MyCustomInput from '../MyCustomInput/MyCustomInput';
import { useStyles } from './styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { getlistgallerie, IstateGallerieInitial } from '../../redux-services/modules/gallerieImage';
import { ModalImage } from '../dashboard/CreateLive/ModalImage';
import { IstateInitialUpload, uploadImage } from '../../redux-services/modules/uploadImage';
import { useIsMobile } from '../../common/header/useIsMobile';
import { ArrowBackIos } from '@material-ui/icons';
import { forceScrollToTop } from '../../utils/common';

export interface IStateProducts {
  productUrl: string | null;
  productName: string | null;
  description: string;
  prixAvant: string | null;
  prixApres: string | null;
  devise: string;
  stocks: string;
  livraisons: string;
  paysDeLivraisons: string;
  autreInfo: string;
  checked: boolean;
  idImage: string | null;
  urlImage?: string | null;
}

interface NewProductToSaleProps {
  toggleValidate?: () => void;
}

const initialstateProduct = {
  productUrl: '',
  productName: '',
  description: '',
  prixAvant: '',
  prixApres: '',
  devise: '',
  stocks: '',
  livraisons: '',
  paysDeLivraisons: '',
  autreInfo: '',
  checked: true,
  idImage: '',
};

const NewProductToSale: React.FC<NewProductToSaleProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, idProduct }: any = useParams();
  const {
    loading: loadingCreateProduct,
    status: statusCreateProduct,
    message: messageCreated,
  } = useSelector<RootState, IinitialStateProduct>((state) => state.productCreated);

  const { loading: loadingUpdateProduct, status: statusUpdateProduct } = useSelector<
    RootState,
    IinitialStateUpdateProduct
  >((state) => state.productUpdate);

  const { loading: loadingProductByID, ListProduct } = useSelector<RootState, IinitialStateProductById>(
    (state) => state.productGetById,
  );
  const Do_uploadImage = useSelector<RootState, IstateInitialUpload>((state) => state.uploadImage);
  const { loading: loadingUpload, id: idImageUploaded } = Do_uploadImage;

  const ListGallerie = useSelector<RootState, IstateGallerieInitial>((state) => state.gallerieImage);
  const { error: errorGallerie, loading: loadingGallerie, gallerie } = ListGallerie;

  const [action, setaction] = useState<boolean>(false);
  const [openModalQuitPage, setopenModalQuitPage] = useState<boolean>(false);
  const [values, setValues] = useState<IStateProducts>(initialstateProduct);
  const [open, setOpen] = useState<boolean>(false);
  const [openModalimagePrev, setopenModalimagePrev] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const handleChange = (prop: keyof IStateProducts) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
    dispatch(setStateProduct({ [prop]: event.target.value }));
  };
  const handleValidate = async () => {
    const {
      productName: name,
      productUrl: url,
      prixApres: afterPrice,
      prixAvant: beforePrice,
      description,
      autreInfo,
      idImage,
    } = values;
    const productName = name || null;
    const productUrl = url || null;
    const prixAvant = beforePrice || null;
    const prixApres = afterPrice || null;
    const image = idImage || null;
    setValues({ ...values, productName, productUrl, prixAvant, prixApres, idImage: image });

    if (!productName || !productUrl || !prixApres || !prixAvant || !image) {
      return;
    }
    if (Number(prixAvant) < Number(prixApres)) {
      setOpen(true);
      return;
    }
    if (idProduct) {
      dispatch(
        do_update_product({
          id: idProduct,
          body: {
            description: description,
            furtherInformation: autreInfo,
            label: productName,
            priceAfterDiscount: Number(prixApres),
            priceBeforeDiscount: Number(prixAvant),
            url: productUrl,
            mainImageId: image,
          },
        }),
      );
    } else {
      await dispatch(
        do_create_product({
          description: description,
          furtherInformation: autreInfo,
          label: productName,
          liveSessionId: id,
          priceAfterDiscount: Number(prixApres),
          priceBeforeDiscount: Number(prixAvant),
          url: productUrl,
          mainImageId: image,
        }),
      );
    }
    setaction(true);
  };
  const OnCancelProduct = async () => {
    if (values.productName || values.description) {
      setopenModalQuitPage(true);
    } else {
      await dispatch(ResetStateProduct());
      history.push(`/dashboard/livesessions/${id}/products`);
    }
  };

  const GoBack = () => {
    history.push(`/dashboard/livesessions/${id}/products`);
  };

  const onConfimQuitPage = async () => {
    await dispatch(ResetStateProduct());
    history.push(`/dashboard/livesessions/${id}/products`);
  };

  const OnCloseDialogQuiPage = () => {
    setopenModalQuitPage(!openModalQuitPage);
  };

  const resetData = async (status: number) => {
    if (status === 200 && action) {
      setValues(initialstateProduct);
      await dispatch(ResetStateProduct());
      history.push(`/dashboard/livesessions/${id}/products`);
      setaction(false);
    }
  };

  const OnChangePrev = async (e: any) => {
    if (e.target.files[0]) {
      dispatch(uploadImage(e.target.files[0]));
      setopenModalimagePrev(!openModalimagePrev);
    }
  };

  useEffect(() => {
    if (statusCreateProduct && !loadingCreateProduct) {
      resetData(statusCreateProduct);
    }
  }, [statusCreateProduct, loadingCreateProduct]);

  useEffect(() => {
    if (statusUpdateProduct && !loadingUpdateProduct) {
      resetData(statusUpdateProduct);
    }
  }, [statusUpdateProduct, loadingUpdateProduct]);

  useEffect(() => {
    if (idProduct) {
      dispatch(get_ProductById(idProduct));
    }
  }, [idProduct]);

  useEffect(() => {
    if (ListProduct && ListProduct.label) {
      setValues({
        ...values,
        productUrl: ListProduct.url,
        description: ListProduct.description,
        prixApres: ListProduct.priceAfterDiscount.toString(),
        prixAvant: ListProduct.priceBeforeDiscount.toString(),
        productName: ListProduct.label,
        autreInfo: ListProduct.furtherInformation,
        urlImage: ListProduct.mainImageUrl || null,
        idImage: ListProduct.mainImageId || null,
      });
    }
  }, [ListProduct]);

  useEffect(() => {
    if (idImageUploaded) {
      setValues({
        ...values,
        idImage: idImageUploaded,
        urlImage: gallerie.items.find((i) => i.id === idImageUploaded)?.url || '',
      });
    }
  }, [idImageUploaded, gallerie]);

  useEffect(() => {
    if (!idProduct) {
      setValues(initialstateProduct);
    }
  }, []);
  return (
    //<div className={classes.container}>
    <Grid
      className={classes.myGrid}
      container
      item
      spacing={1}
      style={isMobile ? { paddingBottom: '1px', width: '100vw', paddingLeft: 8, overflowY: 'hidden' } : {}}
    >
      <CustomBackdrop {...{ open: loadingCreateProduct }} />
      <CustomBackdrop {...{ open: loadingProductByID }} />
      <CustomBackdrop {...{ open: loadingUpdateProduct }} />
      <CustomSnackBar
        {...{ open, setOpen, message: 'Le prix avec remise doit être inférieur au prix normal.', type: 'error' }}
      />
      <ModalImage
        {...{
          loadingUpload: loadingUpload,
          gallerie: gallerie,
          openModal: openModalimagePrev,
          onChangeUpload: OnChangePrev,
          setOpenModal: setopenModalimagePrev,
          setImage: (url, _idImage) => {
            setValues({ ...values, idImage: _idImage || '', urlImage: url });
            setopenModalimagePrev(!openModalimagePrev);
          },
        }}
      />
      {isMobile && (
        <div
          style={{
            width: 'calc(100% - 0px)',
            position: 'absolute',
            zIndex: 999,
            top: 40,
            paddingRight: 8,
            paddingLeft: 8,
            backgroundColor: '#f5f5f5',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, minWidth: 'auto' }} onClick={GoBack}>
              <ArrowBackIos className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" />
            </Button>
            <Typography
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '10px',
                marginTop: '5px',
              }}
            >
              {!idProduct ? t('newProductToSale.title') : t('newProductToSale.title.update')}
            </Typography>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}
            className={`${classes.mobileValidationButton}`}
          >
            <Box>
              <Button onClick={handleValidate} className={classes.btnValid}>
                {t('formulaire.btn.validate')}
              </Button>
              <Button className={classes.btnCancel} onClick={OnCancelProduct}>
                {t('formulaire.btn.cancel')}
              </Button>
            </Box>
          </div>
        </div>
      )}
      {/* //  height: '80vh' */}
      <Grid
        container
        item
        style={
          isMobile
            ? {
                position: 'relative',
                paddingTop: 60,
                height: 'calc(90vh - 120px)',
                overflowY: 'auto',
                width: '100%',
                overflowX: 'hidden',
                paddingBottom: 12,
                scrollbarWidth: 'none',
              }
            : {}
        }
      >
        <Grid item md={5} sm={12} style={isMobile ? { width: '100%' } : {}}>
          {!isMobile && (
            <Typography
              style={{
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '47px',
              }}
            >
              {!idProduct ? t('newProductToSale.title') : t('newProductToSale.title.update')}
            </Typography>
          )}
          <Typography
            style={{
              fontSize: '0.875rem',
              lineHeight: '1.063rem',
              fontWeight: 'bold',
              marginBottom: '0.313rem',
            }}
          >
            {t('newProductToSale.productImages')}
          </Typography>
          <Box className={classes.productsImageContainer}>
            <ButtonImage
              onClick={async () => {
                await dispatch(getlistgallerie({ index: 0, size: 5 }));
                setopenModalimagePrev(!openModalimagePrev);
              }}
              urlImage={values.urlImage}
              style={{ border: values.idImage === null ? '2px solid red' : '' }}
            >
              <Box>
                <KeyboardArrowUpIcon />
                <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                  {t('formulaire.image.preview')}
                </Typography>
              </Box>
            </ButtonImage>
          </Box>
        </Grid>
        <Grid
          item
          md={7}
          sm={12}
          style={
            isMobile
              ? {
                  justifyContent: 'center',
                  display: 'flex',
                }
              : {}
          }
        >
          <Box
            style={{
              paddingTop: isMobile ? 20 : 75,
              justifyContent: isMobile ? 'center' : 'start',
            }}
          >
            <Box>
              <MyCustomInput
                variant="outlined"
                color="secondary"
                placeholder={t('newProductToSale.url')}
                label={t('newProductToSale.url')}
                value={values.productUrl}
                onChange={handleChange('productUrl')}
                error={values.productUrl === null}
                helperText={values.productUrl === null ? 'Champ obligatoire' : ''}
              />
              <MyCustomInput
                variant="outlined"
                color="secondary"
                placeholder={t('newProductToSale.name')}
                label={t('newProductToSale.name')}
                value={values.productName}
                onChange={handleChange('productName')}
                error={values.productName === null}
                helperText={values.productName === null ? 'Champ obligatoire' : ''}
              />
            </Box>
            <Box padding="12px 8px 7px 8px">
              <Typography variant="subtitle1">{t('formulaire.live.descriptions')}</Typography>
              <TextareaAutosize
                aria-label="minimum height"
                placeholder="Descriptions"
                onBlur={forceScrollToTop}
                {...{ maxRows: 6, minRows: 6 }}
                className={classes.descriptions}
                value={values.description}
                onChange={handleChange('description')}
              />
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                  <MyCustomInput
                    type="number"
                    variant="outlined"
                    color="secondary"
                    placeholder={t('newProductToSale.priceBefore')}
                    label={t('newProductToSale.priceBefore')}
                    value={values.prixAvant}
                    onChange={handleChange('prixAvant')}
                    error={values.prixAvant === null}
                    helperText={values.prixAvant === null ? 'Champ obligatoire' : ''}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <MyCustomInput
                    type="number"
                    variant="outlined"
                    color="secondary"
                    placeholder={t('newProductToSale.priceAfter')}
                    label={t('newProductToSale.priceAfter')}
                    value={values.prixApres}
                    onChange={handleChange('prixApres')}
                    error={values.prixApres === null}
                    helperText={values.prixApres === null ? 'Champ obligatoire' : ''}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <MyCustomInput
                variant="outlined"
                color="secondary"
                placeholder={t('newProductToSale.otherInfo')}
                label={t('newProductToSale.otherInfo')}
                value={values.autreInfo}
                onChange={handleChange('autreInfo')}
              />
            </Box>
            {!isMobile && (
              <Box style={{ marginTop: 20 }}>
                <Button onClick={handleValidate} className={classes.btnValid}>
                  {t('formulaire.btn.validate')}
                </Button>
                <Button className={classes.btnCancel} onClick={OnCancelProduct}>
                  {t('formulaire.btn.cancel')}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        {...{
          title: 'Confirmation',
          maxWidth: 'xs',
          open: openModalQuitPage,
          TextCancel: 'Annuler',
          textConfirm: 'Confirmer',
          onClose: OnCloseDialogQuiPage,
          onConfirm: onConfimQuitPage,
        }}
      >
        <Typography>{t('formulaire.confirmation.text')}</Typography>
      </CustomModal>
    </Grid>
    //</div>
  );
};

export default NewProductToSale;
