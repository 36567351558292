import { typedAction } from '../utils';

export interface ILiveSessionRetailerSection {
  id: string;
  name: string;
}

export interface IerrorSection {
  error: string;
  status: number | undefined;
}
export interface IStateInitial {
  loading: boolean;
  LiveSessionSection: ILiveSessionRetailerSection[];
  error: string;
  status: number | undefined;
}
const initialSessionState: IStateInitial = {
  loading: false,
  LiveSessionSection: [],
  error: '',
  status: undefined,
};
export const LIVE_SESSION_RETAILER_SECTION_REQUEST = 'LIVE_SESSION_RETAILER_SECTION_REQUEST';
export const LIVE_SESSION_RETAILER_SECTION_SUCCESS = 'LIVE_SESSION_RETAILER_SECTION_SUCCESS';
export const LIVE_SESSION_RETAILER_SECTION_FAILED = 'LIVE_SESSION_RETAILER_SECTION_FAILED';

export const getLiveSessionSectionRetailer = () => typedAction(LIVE_SESSION_RETAILER_SECTION_REQUEST);
export type GetLiveSessionSectionRetailer = typeof getLiveSessionSectionRetailer;

export const liveSessionSuccesSectionRetailer = (data: ILiveSessionRetailerSection[]) =>
  typedAction(LIVE_SESSION_RETAILER_SECTION_SUCCESS, data);

export type LiveSessionSuccesSectionRetailer = typeof liveSessionSuccesSectionRetailer;
export const liveSessionFailedSectionRetailer = (error: IerrorSection) =>
  typedAction(LIVE_SESSION_RETAILER_SECTION_FAILED, error);
export type LiveSessionFailedSectionRetailer = typeof liveSessionFailedSectionRetailer;

export type SessionListAction = ReturnType<
  GetLiveSessionSectionRetailer | LiveSessionSuccesSectionRetailer | LiveSessionFailedSectionRetailer
>;

export const LiveSessionSectionReducer = (
  state: IStateInitial = initialSessionState,
  action: SessionListAction,
): IStateInitial => {
  switch (action.type) {
    case LIVE_SESSION_RETAILER_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        LiveSessionSection: action.payload,
      };
    case LIVE_SESSION_RETAILER_SECTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case LIVE_SESSION_RETAILER_SECTION_REQUEST:
      return { ...state, loading: true };
    default:
      return {
        ...state,
      };
  }
};
