import { typedAction } from '../utils';

export interface IparamsRetailerPage {
  index: number;
  size: number;
  id: string | undefined;
}

export interface IliveRetailer {
  id: string;
  title: string;
  categoryId: string;
  categoryName: string;
  scheduledStart: string;
  scheduledDuration: number;
  privacy: number;
  isPublished: boolean;
  thumbnailImageId: string;
  thumbnailImageUrl: string;
  actualStart?: string;
  actualEnd?: string;
}
interface IpageInfo {
  index: number;
  size: number;
}

export interface IliveSessionRetailerPage {
  items: IliveRetailer[];
  pageInfo: IpageInfo;
  totalCount: number;
  pageCount: number;
  idSection: number;
}

export interface InitialState {
  loading: boolean;
  error: string;
  status: number | undefined;
  liveSessionRetailerPageNow: IliveSessionRetailerPage | undefined;
  liveSessionRetailerPageComingSon: IliveSessionRetailerPage | undefined;
  liveSessionPageToday: IliveSessionRetailerPage | undefined;
}

export interface IresultErrorRetailerPage {
  error: string;
  status: number;
}
const initialSessionRetailerState: InitialState = {
  error: '',
  loading: false,
  liveSessionPageToday: undefined,
  liveSessionRetailerPageComingSon: undefined,
  liveSessionRetailerPageNow: undefined,
  status: undefined,
};

export const LIVE_SESSION_RETAILLER_PAGE_REQUEST = 'LIVE_SESSION_RETAILLER_PAGE_REQUEST';
export const LIVE_SESSION_RETAILLER_PAGE_SUCCESS = 'LIVE_SESSION_RETAILLER_PAGE_SUCCESS';
export const LIVE_SESSION_RETAILLER_PAGE_FAILED = 'LIVE_SESSION_RETAILLER_PAGE_FAILED';

export const getLiveSessionRetailer = (params: IparamsRetailerPage) =>
  typedAction(LIVE_SESSION_RETAILLER_PAGE_REQUEST, params);
export type GetLiveSessionRetailer = typeof getLiveSessionRetailer;
export const getliveSessionRetailerSuccess = (result: IliveSessionRetailerPage) =>
  typedAction(LIVE_SESSION_RETAILLER_PAGE_SUCCESS, result);
export type GetliveSessionRetailerSuccess = typeof getliveSessionRetailerSuccess;
export const getliveSessionRetailerFailed = (error: IresultErrorRetailerPage) =>
  typedAction(LIVE_SESSION_RETAILLER_PAGE_FAILED, error);
export type GetliveSessionRetailerFailed = typeof getliveSessionRetailerFailed;

export type typeSessionRetaillerAction = ReturnType<
  GetLiveSessionRetailer | GetliveSessionRetailerSuccess | GetliveSessionRetailerFailed
>;

export const LiveSessionRetailerPageReducer = (
  state: InitialState = initialSessionRetailerState,
  action: typeSessionRetaillerAction,
): InitialState => {
  switch (action.type) {
    case LIVE_SESSION_RETAILLER_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIVE_SESSION_RETAILLER_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        liveSessionPageToday: action.payload.idSection === 0 ? action.payload : state.liveSessionPageToday,
        liveSessionRetailerPageComingSon:
          action.payload.idSection === 1 ? action.payload : state.liveSessionRetailerPageComingSon,
        liveSessionRetailerPageNow: action.payload.idSection === 2 ? action.payload : state.liveSessionRetailerPageNow,
      };
    case LIVE_SESSION_RETAILLER_PAGE_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
