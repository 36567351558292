import React from 'react';
import { useStyles } from '../style';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Viewers from './Viewers';

interface IParticipant {
  id: string;
  name: string;
  type?: number;
  identifiant: string;
  profilePictureUrl: string;
}

interface IViewersProps {
  userId: string | undefined;
  internet: boolean;
  id: string;
  participant: IParticipant[];
  loading: boolean;
  isShow: boolean;
  setIsShow: (_isShow: boolean) => void;
  isLiveStarted?: boolean;
}

const MobileViewers: React.FC<IViewersProps> = (props: IViewersProps) => {
  const { internet, id, participant, loading, isShow, setIsShow, isLiveStarted, userId } = props;
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const classes = useStyles();
  React.useEffect(() => {
    if (isShow) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isShow]);

  return (
    <Dialog
      open={isShow}
      onClose={() => setIsShow(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <div className={classes.viewerDialog}>
            <Viewers {...{ userId, internet, id, participant, loading, isLiveStarted }} />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsShow(false)} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileViewers;
