import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../api';
import {
  liveSessionJoined,
  liveSessionJoinFailed,
  liveSessionLeaved,
  LIVE_SESSION_JOIN_REQUEST,
  LIVE_SESSION_LEAVE_REQUEST,
} from '../modules/liveSessionJoin';
import { logout } from '../modules/logout';
import authSevices from '../services/authSevices';
import liveSessionServices from '../services/liveSessionServices';

export function* watchliveSessionJoin() {
  yield takeEvery(LIVE_SESSION_JOIN_REQUEST, liveSessionJoin);
  yield takeEvery(LIVE_SESSION_LEAVE_REQUEST, liveSessionLeave);
}

export function* liveSessionJoin({ payload }: any) {
  try {
    const { data } = yield call(Api.liveSession.liveSessionJoin, payload);
    if (data) {
      yield authSevices.removeParticipantId();
      yield authSevices.removeAccessTokenSignalR();
      yield authSevices.setParticipantId(data.participantId);
      yield authSevices.setAccessTokenSignalR(data.jwt);
      yield liveSessionServices.setParticipantByLive(payload.id, data.participantId, payload.userType, payload.name);
    }
    yield put(liveSessionJoined(data.participantId));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(liveSessionJoinFailed({ status, error: 'bad request' }));
          break;
        case 401:
          yield put(liveSessionJoinFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(logout());
          break;
        case 500:
          yield put(liveSessionJoinFailed({ status, error: 'Seule la session publiée peut être démarrée' }));
          break;
        default:
          yield put(liveSessionJoinFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(liveSessionJoinFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
          default:
            yield put(liveSessionJoinFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
        }
      }
    }
  }
}

export function* liveSessionLeave() {
  try {
    yield put(liveSessionLeaved());
  } catch (error: any) {
    console.log('Error leave sexssion live');
  }
}
