import axios, { AxiosPromise } from 'axios';

export const uploadImage = (params: File): AxiosPromise<any> => {
  const formData = new FormData();
  formData.append('file', params);
  return axios.post(`/Retailer/GalleryImage/Upload`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
