import { Box, Button, Popover, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import { setUrlToFrame } from '../../redux-services/modules/state/stateIFrame';
import { IStateProducts, setStateProduct } from '../../redux-services/modules/state/stateProducts';
import authSevices from '../../redux-services/services/authSevices';
import { useStyles } from './style';
import { useIsMobile } from '../header/useIsMobile';
import clsx from 'clsx';

export interface IcardShooping {
  id?: string;
  token?: string;
  reduction?: number;
  pathProduct: string;
  description: string;
  price: number;
  activePartage?: boolean;
  url?: string;
  onSelectProducts?: (productId: string) => void;
  selectAllProducts?: boolean;
  nbAll?: number;
  nbSelected?: number;
  productsSelected?: string[];
  setProductsSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  handleOpen?: () => void;
  onPip?: () => void;
  fullscreen?: boolean;
}

const MyCardShopping: FC<IcardShooping> = ({
  id,
  description,
  pathProduct,
  price,
  reduction,
  handleOpen,
  activePartage,
  url,
  token,
  productsSelected,
  setProductsSelected,
  fullscreen,
  onPip,
}) => {
  const classes = useStyles();
  const [select, setSelect] = useState<boolean>(false);
  const [userType, setUserType] = useState('Consumer');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = (_select?: boolean) => {
    if (id && setProductsSelected) {
      setProductsSelected((p) => (p.includes(id) ? p.filter((_id) => _id !== id) : [...p, id]));
    }
  };

  const openInNewTab = async (url: string) => {
    if (onPip) await onPip();
    setTimeout(() => {
      window.open(url, '_blank');
    }, 500);
  };

  useEffect(() => {
    authSevices.getTypeRetailer().then((response) => {
      if (response) {
        setUserType(response);
      }
    });
  }, []);

  useEffect(() => {
    if (productsSelected && id) {
      setSelect(productsSelected?.includes(id));
    }
  }, [productsSelected]);

  const reductionfiltered = reduction && reduction < 0 ? reduction : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { productId: _productSelected, checked } = useSelector<RootState, IStateProducts>(
    (state) => state.stateProduct.stateProduct,
  );

  const handleClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    setAnchorEl(event.target as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPop = open ? 'simple-popover' : undefined;

  const isMobile = useIsMobile();

  return (
    <>
      <Box
        className={clsx({ [classes.card]: true, [classes.cardFullscreen]: fullscreen })}
        onClick={() => {
          activePartage && onClick();
        }}
        id="parent"
      >
        <Box className={classes.boxImg}>
          <img
            aria-describedby={idPop}
            src={`${pathProduct}`}
            alt="imgProduct"
            style={{
              width: '100%',
              objectFit: 'contain',
              borderRadius: 12,
              backgroundColor: 'white',
              height: '100%',
              cursor: userType === 'Consumer' && id ? 'pointer' : 'auto',
            }}
            onClick={async (evt) => {
              console.log('token :>>>>>> ', token, url);
              // if (!activePartage && !token) {
              //   handleClick(evt);
              // }

              if (userType === 'Consumer' && id) {
                const keyProd: keyof IStateProducts = 'productId';
                const keyChecked: keyof IStateProducts = 'checked';

                dispatch(setUrlToFrame(url || ''));
                dispatch(setStateProduct({ [keyProd]: id }));
                dispatch(setStateProduct({ [keyChecked]: !checked }));
                openInNewTab && openInNewTab(url || '');
              }
            }}
          />
          {reductionfiltered && <Box className={classes.sales}>{reductionfiltered}%</Box>}
          {activePartage && (
            <Box className={classes.check}>
              <Checkbox
                checked={select}
                color="primary"
                style={{
                  color: 'dark',
                  borderWidth: 1,
                  borderColor: 'white',
                  alignSelf: 'flex-end',
                  fill: 'black',
                  stroke: 'white',
                }}
              />
            </Box>
          )}

          <Popover
            id={idPop}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Box className={classes.popContent}>
              {t('consumer.live.subscribe.request')}
              <Button
                variant="contained"
                onClick={() => {
                  handleOpen && handleOpen();
                  handleClose();
                }}
              >
                {t('consumer.live.login')}
              </Button>
            </Box>
          </Popover>
        </Box>
        <Box>
          <div style={{ width: isMobile ? '28vw' : '7.2rem' }}>
            <Tooltip title={description} placement="top">
              <Typography
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: isMobile ? 14 : 16,
                }}
              >
                {description}
              </Typography>
            </Tooltip>
          </div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: isMobile ? 14 : 16 }}>
            {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price)}
          </Typography>
        </Box>
        {/* {activePartage && (
          <Box className={classes.plus}>
            <button
              style={{ width: '100%', backgroundColor: 'transparent', borderColor: 'transparent' }}
              onClick={() => {
                onClick();
              }}
            >
              {select ? '-' : '+'}
            </button>
          </Box>
        )} */}
        {/* </Button> */}
      </Box>
    </>
  );
};

export default MyCardShopping;
