export default {
  'invitation.title': 'Adresses email des personnes invitées VIP',
  'invitation.asking.adress': 'Entrer adresse email VIP',
  'invitation.button.add': 'Ajouter un VIP',
  'invitation.date': 'Invitation envoyée le',
  'invitation.send': 'Envoyer',
  'invitation.delete': 'Supprimer',
  'invitation.selectAll': 'Tout selectionner',
  'invitation.deselectAll': 'Tout desélectionner',
  'invitation.info':
    'Les VIP recevront automatiquement un email après avoir entré leur email, coché la case action et cliqué sur « envoyer ». Ils pourront bénéficier des contreparties paramétrées dans la section « paramétrage affiliation »',
  'invitation.title.send': 'Mail envoyé aux VIP',
  'invitation.mail.sended': "Mail d'envoi : ",
  'invitation.mail.objet': 'Objet :',
  'invitation.mail.sujet': 'Sujet :',
  'invitation.mail.time': 'Heures :',
  'invitation.mail.https': 'https :',
  'invitation.message.send':
    'Assurez vous que les contreparties VIP ont été remplies dans « fixez les avantages VIP » avant de confirmer l’envoi',
  'invitation.email.noSend': 'mail non envoyé',
  'invitation.purchase.code': 'code avantage invité du VIP',
  'invitation.purchase.help1':
    'Entrez un code avantage différent par VIP émis par votre site ecommerce afin de tracker plus facilement le CA des invités des VIP.',
  'invitation.purchase.help2':
    'Ces codes avantages seront visibles par les invités lors du live et devront être renseignés sur votre site lors de l’achat.',
  'invitation.advantageCode.title': 'Code Achat',
  'invitation.advantageCode.text': 'Code achat pour le VIP ',
  'invitation.shoppingUrl': 'Lien Boutique',
  'invitation.shopingUrlExample': 'https://www.livood.com',
  'invitation.shopingUrlInvalid': 'Invalide URL',
};
