import { HubConnection } from '@microsoft/signalr';
import { typedAction } from '../utils';

export interface IParticipant {
  id: string;
  name: string;
}
export interface IMessageReceived {
  id: string;
  name: string;
  date: string;
  text: string;
}
export interface IerrorListener {
  error: string;
  status: number | undefined;
}

export interface IResultListener {
  loading: boolean;
  error: string;
  status: number | undefined;
  participant: IParticipant;
  message: IMessageReceived;
  connection: HubConnection | null;
}

const initialStateListener: IResultListener = {
  error: '',
  status: undefined,
  loading: false,
  participant: {
    id: '',
    name: '',
  },
  message: {
    id: '',
    name: '',
    date: '',
    text: '',
  },
  connection: null,
};
export const START_LISTENER_REQUEST = 'START_LISTENER_REQUEST';
export const CONNEXION_SUCCESS = 'CONNEXION_SUCCESS';
export const MSG_RECEIVED = 'MSG_RECEIVED';
export const PRS_JOINED = 'PRS_JOINED';
export const PRS_LEFT = 'PRS_LEFT';
export const END_LISTENER = 'END_LISTENER';
export const CONNEXION_FAILED = 'CONNEXION_FAILED';
export const CLOSE_LISTENER_REQUEST = 'CLOSE_LISTENER_REQUEST';

export const startListener = () => typedAction(START_LISTENER_REQUEST);
export type StartListener = typeof startListener;

export const colseListener = (connex: HubConnection) => typedAction(CLOSE_LISTENER_REQUEST, connex);
export type ColseListener = typeof colseListener;

export const listened = (connection: HubConnection) => typedAction(CONNEXION_SUCCESS, connection);
export type Listened = typeof listened;

export const messageReceived = (message: IMessageReceived) => typedAction(MSG_RECEIVED, message);
export type MessageReceived = typeof messageReceived;

export const prsJoined = (prs: IParticipant) => typedAction(PRS_JOINED, prs);
export type PrsJoined = typeof prsJoined;

export const prsLeft = (prs: IParticipant) => typedAction(PRS_LEFT, prs);
export type PrsLeft = typeof prsLeft;

export const endListener = (status: any) => typedAction(END_LISTENER, status);
export type EndListener = typeof endListener;

export const listenFailed = (error: IerrorListener) => typedAction(CONNEXION_FAILED, error);
export type ListenFailed = typeof listenFailed;

export type ListenerAction = ReturnType<
  StartListener | Listened | ListenFailed | EndListener | PrsLeft | PrsJoined | MessageReceived | ColseListener
>;

export const ListenerReducer = (
  state: IResultListener = initialStateListener,
  action: ListenerAction,
): IResultListener => {
  switch (action.type) {
    case START_LISTENER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONNEXION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
        error: '',
        connection: action.payload,
      };
    case MSG_RECEIVED:
      return {
        ...state,
        loading: false,
        status: 2,
        error: '',
        message: action.payload,
      };
    case PRS_JOINED:
      return {
        ...state,
        loading: false,
        status: 3,
        error: '',
        participant: action.payload,
      };
    case PRS_LEFT:
      return {
        ...state,
        loading: false,
        status: 4,
        error: '',
        participant: action.payload,
      };
    case END_LISTENER:
      return {
        ...initialStateListener,
        status: 5,
        connection: null,
      };
    case CLOSE_LISTENER_REQUEST:
      return {
        ...initialStateListener,
        status: 6,
        connection: null,
      };
    case CONNEXION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
