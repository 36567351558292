import { typedAction } from '../../utils';

export interface ISetLogoRetailerParams {
  galleryImageId: string;
  logoUrl?: string;
}

export interface IResultSetLogoRetailerSAGA {
  logoUrl: string;
  loading: boolean;
  error: string;
  status: number | undefined;
}
const initialState: IResultSetLogoRetailerSAGA = {
  logoUrl: '',
  loading: false,
  error: '',
  status: undefined,
};
interface IdataError {
  message: string;
  status: number;
}
export const AUTH_SETLOGO_RETAILER_REQUEST = 'AUTH/SETLOGO_RETAILER/REQUEST';
export const AUTH_SETLOGO_RETAILER_FAILED = 'AUTH/SETLOGO_RETAILER/FAILED';
export const AUTH_SETLOGO_RETAILER_SUCCESS = 'AUTH/SETLOGO_RETAILER/SUCCESS';

export const setLogoRetailer = (idLogo: ISetLogoRetailerParams) => typedAction(AUTH_SETLOGO_RETAILER_REQUEST, idLogo);
export type SetLogoRetailerStart = typeof setLogoRetailer;

export const setLogoRetailerSuccess = (data: IResultSetLogoRetailerSAGA) =>
  typedAction(AUTH_SETLOGO_RETAILER_SUCCESS, data);
export type SetLogoRetailerSuccess = typeof setLogoRetailerSuccess;

export const setLogoRetailerFailed = (error: IdataError) => typedAction(AUTH_SETLOGO_RETAILER_FAILED, error);
export type SetLogoRetailerFailed = typeof setLogoRetailerFailed;

export type AuthAction = ReturnType<SetLogoRetailerStart | SetLogoRetailerSuccess | SetLogoRetailerFailed>;

export const SetLogoRetailer_Reducers = (
  state: IResultSetLogoRetailerSAGA = initialState,
  action: AuthAction,
): IResultSetLogoRetailerSAGA => {
  switch (action.type) {
    case AUTH_SETLOGO_RETAILER_SUCCESS:
      return {
        ...state,
        loading: false,
        logoUrl: action.payload.logoUrl,
        error: '',
        status: action.payload.status,
      };
    case AUTH_SETLOGO_RETAILER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        status: action.payload.status,
      };
    case AUTH_SETLOGO_RETAILER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
