import { Box, Typography } from '@material-ui/core';
import { FC, MouseEvent } from 'react';
import { useStyles } from './styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useIsMobile } from '../header/useIsMobile';

interface ICardLives {
  id?: string;
  img: string;
  title: string;
  description: string;
  altImage?: string;
  isLiveSessionsPage?: boolean;
  onClick: () => void;
  isNow?: number;
  isSelectedID?: string;
  onCopyLive?: (id: string | undefined) => (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
}
const CardLives: FC<ICardLives> = ({
  id,
  img,
  title,
  description,
  isLiveSessionsPage,
  onClick,
  altImage,
  isNow,
  isSelectedID,
  onCopyLive,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <Box
      onClick={onClick}
      className={classes.card}
      style={{ backgroundColor: id && isSelectedID === id ? '#E5E5E5' : '', borderRadius: 20 }}
    >
      <Box className="body">
        {/* {isLiveSessionsPage && ( */}
        <Box component="button" className="copy" onClick={onCopyLive && onCopyLive(id)}>
          <FileCopyIcon />
        </Box>
        {/* )} */}
        {isNow === 0 && (
          <Box className="live">
            <Box className="dot"></Box>LIVE
          </Box>
        )}
        {!isMobile && (
          <>
            <Box className="vues">
              1M
              <VisibilityOutlinedIcon className="iconeyes" />
            </Box>
            <Box className="lang">Français</Box>
          </>
        )}
        <img src={img} alt={altImage} style={{ backgroundColor: 'white' }} />
      </Box>
      <Box className="footer">
        <Typography className="title">{title}</Typography>
        <Typography className="description">{description}</Typography>
      </Box>
    </Box>
  );
};
export default CardLives;
