export default {
  'newProductToSale.title': 'Créer un produit',
  'newProductToSale.title.update': 'Modifier un product',
  'newProductToSale.productImages': 'Images du produit',
  'newProductToSale.url': 'URL vers le produit',
  'newProductToSale.name': 'Nom du produit',
  'newProductToSale.description': 'Description',
  'newProductToSale.priceBefore': 'Prix avant remise',
  'newProductToSale.priceAfter': 'Prix après remise',
  'newProductToSale.otherInfo': 'Autres informations (matières, designer, etc)',
  'newProductToSale.created.success': 'Votre produit a été crée avec succès ',
  'newProductToSale.created.failed': 'La création de produit a été échouée ',
};
