import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      width: 170,
      // background: 'linear-gradient(180deg, rgba(74,125,104,1) 0%, rgba(81,137,113,1) 56%, rgba(63,106,88,1) 82%)',
      // background: theme.palette.primary.light,
      zIndex: 2,
      position: 'fixed',
      paddingTop: 'calc(25px + 3%)',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      '@media(min-width:701px)': {
        minHeight: '100vh',
      },
      '@media(max-width:700px)': {
        display: 'none',
      },
      '& >div>a': {
        textDecoration: 'none',
        paddingBottom: 10,
        paddingTop: 10,
        width: '100%',
        '& p': {
          '@media(max-width:900px)': {
            display: 'none',
          },
        },
        '@media(max-width:900px)': {
          paddingBottom: 20,
          paddingTop: 20,
        },
      },
      '& >div>a.active': {
        backgroundColor: 'white',
        '& p': {
          color: '#416e5b',
        },
        '& >div>svg path': {
          stroke: '#416e5b',
        },
      },
      '@media(max-width:900px)': {
        width: 90,
      },
      '@media(max-width:425px)': {
        width: 50,
      },
    },
    logo: {
      width: 75,
      height: 75,
      borderRadius: 50,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: 20,
    },
    imageSrc: {
      height: '100%',
      objectFit: 'contain',
      '@media(max-width:425px)': {
        width: 45,
      },
    },
    logout: {
      position: 'absolute',
      bottom: '14%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      flexDirection: 'column',
      '& p': {
        paddingTop: 5,
        '@media(max-width:900px)': {
          display: 'none',
        },
      },
    },
    RootSideBar: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    labelStyle: {
      color: 'white',
      width: '100%',
      textAlign: 'center',
    },
    btnSideBar: {
      width: '100%',
    },
  }),
);
