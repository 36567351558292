import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: 250,
      padding: '20px 3%',
      //backgroundColor: theme.palette.primary.light,
      backgroundColor: '#F9FAF9',
      //maxHeight: 300,
      color: 'black',
      boxShadow: 'inset 2px 2px 10px 2px rgba(34, 34, 34, 0.05) ',
      borderRadius: '0.313rem',
      width: '98vw',
      // marginTop: '1.875rem',
      marginBottom: '1rem',
    },
    // slide: {
    //   paddingLeft: 20,
    //   paddingRight: 20,
    // },
    productsContainer: {
      '@media(min-width:700px)': {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        justifyContent: 'space-arround',
        width: '66vw',
        flexBasis: 'auto',
        padding: '10px',
      },
      '@media(max-width:700px)': {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowY: 'scroll',
      },
    },
  }),
);
