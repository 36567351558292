import { useStyles } from './styles';
interface WithoutVideoComponentInterface {
  username: string;
  color: string;
}

const WithoutVideoComponent = (props: WithoutVideoComponentInterface) => {
  const { username, color } = props;
  const style = useStyles();

  const getFirstCharacter = (username: string) => {
    if (username) {
      return username.charAt(0).toUpperCase();
    }
  };

  return (
    <div className={style.containerWithout}>
      <div className={style.labelRound} style={{ backgroundColor: color }}>
        {getFirstCharacter(username)}
      </div>
    </div>
  );
};

export default WithoutVideoComponent;
