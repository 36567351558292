export const AUTH_REATAILER = '/auth/retailer';
export const AUTH_INSCRIPTION = '/auth/inscription';
export const AUTH_INSCRIPTION_RETAILER = '/auth/inscription/Retailer';
export const CONSUMER_ACCOUNT_CONFIRMATION = '/:userType/ConfirmAccount/:consumerId/:securityStamp';
export const AUTH_CONSUMER = '/auth/Consumer/:id';
export const AUTH_CONSUMER_VIP = '/auth/Consumer/:id/vp/:idInvitation';
export const AUTH_CONSUMER_PRIVATE_AF = '/auth/Consumer/:id/private/af/:idAffiliation';
export const AUTH_CONSUMER_AF = '/auth/Consumer/:id/af/:idAffiliation';
export const AUTH_CONSUMER_LOG = '/auth/Consumer';
export const LIVE_SCREEN = '/LiveCurrent/:id';
export const DASHBOARD = '/dashboard';
export const CREATELIVE = '/dashboard/create';
export const LIVEDETAILS = '/dashboard/livesessions/:id';
export const UPDATELIVE = '/dashboard/livesessions/:id/update';
export const ADD_PRODUCTS = '/dashboard/livesessions/:id/products/addproduct';
export const UPDATE_PRODUCTS = '/dashboard/livesessions/:id/products/:idProduct';
export const ACCOUNT_RETAILER = '/dashboard/account';
export const LIVE_STATISTIQUE = '/dashboard/stats';
export const LIVE_SECTION = '/dashboard/livesessions';
export const LIVE_APERCU = '/dashboard/livesessions/:id/showlive';
export const QUESTIONNAIRE = '/dashboard/livesessions/:id/questionnaire';
export const PRODUCTS = '/dashboard/livesessions/:id/products';
export const CREATE_QUESTIONNAIRE = '/dashboard/livesessions/:id/questionnaire/create';
export const MANAGE_INVITATION_VIP = '/dashboard/livesessions/:id/invitations';
export const AFFILIATION = '/dashboard/livesessions/:id/parametre';
export const RESETPASSWORD = '/auth/resetpassword';
export const RESETPASSWORD_RETAILER = '/auth/resetpassword/Retailer';
export const PASSWORD = '/dashboard/account/password';
export const CONSUMER_PASSWORD = '/Consumer/account/password';
export const URL_INVITATION = '/LiveCurrent/:id/vp/:idInvitation';
export const URL_AFFILIATION = '/LiveCurrent/:id/af/:idAffiliation';
export const RESETPASSWORD_REDIRECTED = '/:userType/ResetPassword/:consumerId/:securityStamp';
export const CONSUMER_ACCOUNT_SETTING = '/Consumer/account';
