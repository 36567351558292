import { put, takeEvery, call } from 'redux-saga/effects';
import { logout } from '../../modules/logout';
import api from '../../api';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import {
  updatePasswordFailed,
  updatePasswordSuccefull,
  UPDATE_PASSWORD_REQUESTED,
} from '../../modules/account/updatePassword';
import { ErrorFunction } from '../utils/function';

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD_REQUESTED, updatePassword);
}

export function* updatePassword({ payload }: any) {
  try {
    const { status } = yield call(api.auth.updatePassword, payload.userType, payload.password);
    if (status === 200) {
      yield put(updatePasswordSuccefull());
      yield put(
        Set_state_snackbar({
          status: status,
          message: "La modification du mot de passe s'est déroulée avec succès.",
          open: true,
          type: 'success',
        }),
      );
    }
  } catch (error: any) {
    if (error.response) {
      yield put(updatePasswordFailed());
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({ status: status, message: 'Votre session est expiré', open: true, type: 'error' }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Wrong connexion', open: true, type: 'error' }));
          break;
      }
    } else {
      yield call(ErrorFunction, error);
    }
  }
}
