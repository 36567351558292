import { put, call, takeEvery } from 'redux-saga/effects';
import { logout } from '../../../modules/logout';
import api from '../../../api';
import {
  LIST_CONSUMER_REQUESTED,
  GETlistConsumerSuccefull,
  GETlistConsumerFailed,
} from '../../../modules/account/consumer/listConsumer';
import { Set_state_snackbar } from '../../../modules/state/stateMessage';

export function* watchlistconsumer() {
  yield takeEvery(LIST_CONSUMER_REQUESTED, listConsumer);
}

export function* listConsumer() {
  try {
    const { data } = yield call(api.auth.listConsumerAPi);
    yield put(GETlistConsumerSuccefull(data));
  } catch (error: any) {
    if (error.response) {
      yield put(GETlistConsumerFailed());
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({ status: status, message: 'Votre session est expiré', open: true, type: 'error' }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Wrong connexion', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
