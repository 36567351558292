import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../api';
import { liveSessionFailed, liveSessionSucces, LIVE_SESSION_REQUEST } from '../modules/liveSession';

export function* watchliveSession() {
  yield takeEvery(LIVE_SESSION_REQUEST, liveSession);
}

export function* liveSession() {
  try {
    const { data } = yield call(APi.liveSession.liveSessionList);
    yield put(liveSessionSucces(data));
  } catch (error: any) {
    yield put(liveSessionFailed());
  }
}
