export default {
  'recognition.personalInfo': 'Informations personnelles',
  'recognition.companyInfo': 'Informations entreprise',
  'recognition.email': 'E-mail',
  'recognition.firstname': 'Prénom',
  'recognition.phoneNumber': 'Téléphone',
  'recognition.emailValidation': "Validation de l'e-mail",
  'recognition.lastname': 'Nom',
  'recognition.country': 'Pays',

  'recognition.companyName': "Nom de l'entreprise",
  'recognition.activityType': "Type d'activité",
  'recognition.address': 'Adresse postale',
  'recognition.postalCode': 'Code postale',
  'recognition.city': 'Ville',
  'recognition.companyCountry': 'Pays',
  'recognition.website': 'Site web',
  'recognition.instagram': 'Instagram',
  'recognition.tiktok': 'TikTok',
  'recognition.fb': 'Facebook',
  'recognition.password': 'Mot de passe',
  'recognition.confirmpassword': 'Confirmer le mot de passe',
};
