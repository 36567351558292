import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  duplicate_live_session_failed,
  DUPLICATE_LIVE_SESSION_REQUEST,
  duplicate_live_session_success,
} from '../modules/liveSessionDuplicate';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction } from './utils/function';

export function* watchliveSessionDuplicate() {
  yield takeEvery(DUPLICATE_LIVE_SESSION_REQUEST, liveSessionDuplicate);
}
export function* liveSessionDuplicate({ payload }: any) {
  console.log('payload in saga', payload);

  // const history = useHistory();
  try {
    const { data, status } = yield call(api.liveSessionRetailer.liveSessionDuplicate, payload);
    const result = { id: data, status };

    yield put(duplicate_live_session_success(result));
    yield put(
      Set_state_snackbar({
        message: 'Operation effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
    if (payload.callback) {
      payload.callback(result);
    }
  } catch (error: any) {
    const { status } = error.response;
    yield put(duplicate_live_session_failed({ error: 'Failed', status: status }));
    yield call(ErrorFunction, error);
  }
}
