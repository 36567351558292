import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // container: {
    //   paddingLeft: theme.spacing(3),
    //   paddingRight: theme.spacing(3),
    //   paddingTop: theme.spacing(4),
    // },
    mobileValidationButton: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'flex',
        justifyContent: 'end',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        minHeight: '52px',
        alignItems: 'center',
        background: '#F5F5F5',
      },
    },
    productsImageContainer: {
      flexGrow: 1,
      position: 'relative',
      width: '100%',
      height: '500px',
      '@media(max-width:700px)': {
        height: 'auto',
      },
    },
    miniatureCard: {
      backgroundColor: '#222222',
      height: '220px',
      width: '21rem',
    },
    miniatureCard2: {
      backgroundColor: '#222222',
      height: '100px',
      width: '10rem',
      marginBottom: '10px',
    },
    btnValid: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      fontFamily: 'Open Sans',
      textTransform: 'capitalize',
      width: 90,
      '&:hover': {
        backgroundColor: '#345f4c',
      },
    },
    btnCancel: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: 'Open Sans',
      textTransform: 'capitalize',
      border: '1px solid #222222',
      marginLeft: 10,
      width: 90,
    },
    checkb: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '10px',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      '& >span': {
        padding: 0,
        color: '#222222',
      },
    },
    myGrid: {
      '@media(max-width:1355px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '@media(max-width:700px)': {
        paddingBottom: '60px',
      },
    },
    descriptions: {
      width: '100%',
      borderRadius: 4,
      padding: '10px',
      fontFamily: 'Open Sans',
      fontSize: 14,
      border: '1px solid #bdbdbd',
      boxSizing: 'border-box',
      background: 'none',
    },
  }),
);
