import { call, put, takeEvery } from 'redux-saga/effects';
import {
  checkHistoryListenerFailed,
  checkHistoryListenerSuccess,
  CHECK_HISTORY_REQUEST,
} from '../modules/listenerHistory';
import APi from '../api';
import { logout } from '../modules/logout';

export function* watchListenerHistory() {
  yield takeEvery(CHECK_HISTORY_REQUEST, checkHistory);
}

function* checkHistory({ payload }: any) {
  try {
    const { data: messages } = yield call(APi.listener.checkHistoryMessagesListener, payload);
    const { data: viewers } = yield call(APi.listener.checkHistoryViewersListener, payload);
    const data = {
      viewers: viewers ? viewers : [{ id: '', name: '' }],
      messages: messages
        ? messages
        : [
            {
              dateTime: '',
              participantId: '',
              participantName: '',
              text: '',
            },
          ],
    };
    yield put(checkHistoryListenerSuccess(data));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(checkHistoryListenerFailed({ status, error: 'bad request' }));
          break;
        case 401:
          yield put(
            checkHistoryListenerFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(checkHistoryListenerFailed({ status, error: 'Seule la session publiée peut être démarrée' }));
          break;
        default:
          yield put(
            checkHistoryListenerFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          break;
      }
    } else {
      const { age } = error;
      if (age) {
        switch (age) {
          case 'Network Error':
            yield put(checkHistoryListenerFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
          default:
            yield put(checkHistoryListenerFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
        }
      }
    }
  }
}
