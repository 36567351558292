import React, { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { AzureMP } from 'react-azure-mp';
import { useStyles } from './style';
import ButtonMute from './ButtonMute';
import { API_URL } from '../../lib/config';
import initAdaptorPlayer from '../../WebRTC/adaptorPlayer';
// @ts-nocheck
// @ts-ignore
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor/js/webrtc_adaptor';
import { useIsMobile } from '../header/useIsMobile';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
const userType = localStorage.getItem('userType');

interface Ivideo {
  id?: string;
  titleLiveBeforeStarting?: string;
  streamingUrl?: string;
  urlImage?: string;
  endedLiveImageUrl?: string;
  isEndedLive?: boolean;
  replayVideoFileUrl?: string | null;
  isStartLive?: boolean;
}
const LiveMovie_: React.FC<Ivideo> = ({
  id,
  streamingUrl,
  urlImage,
  isEndedLive,
  isStartLive,
  titleLiveBeforeStarting,
  replayVideoFileUrl,
}) => {
  const [adaptor, setAdaptor] = useState<any>(null);
  const [adaptorReady, setAdaptorReady] = useState<boolean>(false);
  const [fullScreen, setFullscreen] = useState<boolean>(false);
  const location = useLocation();

  const style = useStyles();
  // const ref = useRef<typeof AzureMP | null>(null);
  const ref = useRef<any>(null);
  // const onMutedHandler = (mute: boolean) => {
  //   if (ref.current?.player) {
  //     ref.current.player.muted(mute);
  //   }
  // };

  const createAdaptor = () => {
    const webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: 'wss://media-srv01.livood.com:5443/LiveApp/websocket', // localhost:5080/WebRTCAppEE/websocket',
      isPlayMode: true,
      mediaConstraints: {
        video: false,
        audio: false,
      },
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
      },
      remoteVideoId: 'videoPlayer',
      callback: (info: any, obj: any) => {
        if (info === 'pong') {
          setAdaptorReady(true);
        }
      },
      callbackError: function (error: any, message: any) {
        console.error('error, message :>> ', error, message);
      },
      debug: true,
    });
    setAdaptor(webRTCAdaptor);
  };

  //startSuscribe Streaming
  let doPlayStream: any;

  const startSuscriber = async () => {
    if (adaptor.iceConnectionState(id) && adaptor.iceConnectionState(id) === 'connected') {
      clearInterval(doPlayStream);
    } else {
      adaptor.play(id);
    }
  };

  useEffect(() => {
    if (!adaptor) {
      createAdaptor();
    } else if (!urlImage && id && adaptorReady && adaptor) {
      doPlayStream = setInterval(startSuscriber, 4000);
    }
  }, [id, urlImage, adaptor, adaptorReady]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('fullscreen')) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  }, [location]);

  const isMobile = useIsMobile();
  return (
    <Box className={style.viewBox}>
      {/* {!!liveDetails?.live?.streamingUrl && ( */}
      {urlImage ? (
        <Box className={clsx({ [style.BoxImage]: true, [style.BoxImageFullscreen]: fullScreen })}>
          {titleLiveBeforeStarting && (
            <Typography
              style={{ position: 'absolute', top: 5, left: 5, fontWeight: 'bold', fontSize: isMobile ? 12 : 14 }}
            >
              {titleLiveBeforeStarting}
            </Typography>
          )}
          {replayVideoFileUrl === null ? (
            <img src={`${urlImage}`} className={style.imgPreview} />
          ) : (
            <div style={{ position: 'relative', width: '100%', height: '0px', paddingBottom: '57.500%' }}>
              <iframe
                allow="fullscreen;autoplay"
                allowFullScreen
                height="100%"
                src={
                  replayVideoFileUrl &&
                  replayVideoFileUrl.replace('https://streamable.com/', 'https://streamable.com/e/') +
                    '?autoplay=1&muted=0'
                }
                width="100%"
                style={{
                  border: 'none',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: '0px',
                  top: '0px',
                  overflow: 'hidden',
                }}
              />
            </div>
          )}
          {isEndedLive && (
            <Typography style={{ position: 'absolute', bottom: 0, fontWeight: 'bold' }}>Live Terminé</Typography>
          )}
        </Box>
      ) : (
        <>
          {/* <ButtonMute onMute={onMutedHandler} className={style.muted} />
          <AzureMP
            ref={ref}
            options={{ autoplay: true, controls: false }}
            src={[
              streamingUrl && {
                src: `https:${
                  userType === 'Retailer'
                    ? '//amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest'
                    : streamingUrl
                }`,
                type: 'application/vnd.ms-sstr+xml',
              },
            ]}
          /> */}
        </>
      )}
      <div style={{ width: '100%', backgroundColor: '#333333' }}>
        <video
          id="videoPlayer"
          ref={ref}
          style={{ display: urlImage ? 'none' : 'flex', width: '100%' }}
          autoPlay
          playsInline
          controls
        />
      </div>
    </Box>
  );
};

const LiveMovie = React.memo(LiveMovie_);

export default LiveMovie;
