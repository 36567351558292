import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    rootColumn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      minWidth: '825px',
    },
    content: {
      display: 'flex',
      '@media(max-width: 1024px)': {
        flexDirection: 'column',
        marginTop: 2,
      },
    },
    itemOne: {
      width: '30%',
      position: 'relative',
      '& .title': {
        paddingBottom: 40,
      },
      '& .contentChat>div>h5': {
        display: 'none',
      },
      '& .contentChat>div>div': {
        marginTop: 0,
        boxShadow: 'none',
        borderRadius: 0,
      },
      '@media(max-width: 1280px)': {
        display: 'none',
      },
      '& .loading': {
        marginLeft: '-30px',
      },
    },
    itemTwo: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 2%',
      marginTop: 76,
      '& .title': {
        paddingBottom: 20,
      },
      '@media(max-width: 1280px)': {
        width: '100%',
        marginTop: 20,
        padding: 0,
      },
    },
    itemThree: {
      width: '25%',
      '& .title': {
        paddingBottom: 30,
      },
      '@media(max-width: 1024px)': {
        width: '100%',
      },
    },
    liste: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        padding: '10px 30px',
        width: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
      },
      '& li.action_rapides': {
        backgroundColor: '#222222',
        marginBottom: 14,
        color: 'white',
        borderRadius: 5,
      },
      '& li.action_rapides:hover': {
        backgroundColor: '#000000',
      },
      '& li.action_rapides:last-child': {
        marginBottom: 40,
      },
      '& li.acces_rapides': {
        paddingRight: 0,
      },
      '& li.acces_rapides:last-child': {
        marginBottom: 40,
      },
    },
    btnRight: {
      display: 'flex',
      flexDirection: 'column',
      '& >button': {
        width: '100%',
        padding: '10px 30px',
        borderRadius: 5,
        marginBottom: 14,
      },
    },
    btnPause: {
      border: '1px solid #222222',
    },
    btnStop: {
      backgroundColor: '#416e5b',
      color: 'white',
    },
    details: {
      width: '100%',
      display: 'flex',
      '& >div': {
        width: '25%',
        '& >h6': {
          '@media(max-width: 600px)': {
            fontSize: 12,
          },
        },
        '& >span': {
          '@media(max-width: 600px)': {
            fontSize: 14,
          },
        },
      },
      '& div:last-child': {
        '@media(max-width: 600px)': {
          width: '40%',
        },
      },
    },
    footerMobile: {
      display: 'none',
      '@media(max-width:1280px)': {
        display: 'flex',
        width: '55px!important',
        alignItems: 'center',
        marginTop: 5,
      },
    },
    chatmobileplace: {
      '& .MuiPaper-root.MuiMenu-paper': {
        width: 300,
        position: 'relative',
        '& > ul.MuiList-padding': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        '& h5': {
          display: 'none',
        },
        '& h5 + div': {
          minHeight: 400,
          marginTop: 0,
          '& .footer': {
            height: 'auto',
          },
        },
      },
      '@media(min-width:1280px)': {
        display: 'none',
      },
    },
    conversation: {
      cursor: 'pointer',
    },
    vip: {
      minHeight: 415,
      height: 450,
      backgroundColor: '#F9FAF9',
      boxShadow: 'inset 2px -10px 10px 2px rgba(34, 34, 34, 0.05) !important',
      '& ul': {
        listStyle: 'none',
        paddingLeft: 15,
        paddingRight: 0,
        margin: 0,
        padding: '10px 30px',
      },
      '& ul>li': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
      },
    },
    vipContener: {
      display: 'flex',
    },
    labelVipContener: {
      paddingTop: '5px',
      width: '8vw',
    },
    buttonRight: {
      marginLeft: '10px',
      color: 'rgb(227,202,65)',
      flex: 1,
    },
    head: {
      display: 'flex',
      width: '100%',
      height: 50,
      '& >h5': {
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px 5px 0px 0px',
        cursor: 'pointer',
      },
      '& >h5:first-child': {
        width: '60%',
      },
      '& >h5:last-child': {
        width: '40%',
      },
    },
    headButtonActualites: {
      display: 'flex',
      width: '100%',
      zIndex: 1,
      height: 50,
      '& >h5': {
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px 5px 0px 0px',
        cursor: 'pointer',
      },
      '& >h5:first-child': {
        width: '60%',
      },
      '& >h5:last-child': {
        width: '40%',
      },
      '@media(max-width:460px)': {
        '&>button>span>h5': {
          fontSize: 14,
        },
      },
    },
    avatar: {
      width: 30,
      height: 30,
      marginRight: 8,
      fontSize: 14,
    },
    closeChat: {
      position: 'absolute',
      top: 10,
      right: 15,
      cursor: 'pointer',
      '& svg': {
        height: 20,
        width: 20,
      },
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      backgroundColor: '#0000004d',
      fontSize: 14,
      borderRadius: 5,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      padding: 10,
      marginTop: 10,
      marginBottom: 10,
      height: 30,
      width: 60,
      '@media(max-width:1180px)': {
        height: 20,
      },
    },
    activBton: {
      boxShadow: 'inset 2px 2px 10px 2px rgb(34 34 34 / 5%) ',
      padding: '28px',
      position: 'relative',
      bottom: '-2px',
      textTransform: 'unset',
    },
    activProduct: {
      boxShadow: 'inset 0px 35px 27px 5px rgb(34 34 34 / 5%) ',
      padding: '28px',
      position: 'relative',
      bottom: '-1px',
      width: '45%',
      textTransform: 'unset',
    },
    desactivBtnVIP: {
      boxShadow: '-3px 5px 5px -3px rgb(0 0 0 / 15%)',
      bottom: '2px',
    },
    desactivBtnChat: {
      boxShadow: '2px 5px 5px -3px rgb(0 0 0 / 15%)',
      bottom: '-1px',
      right: '-1px',
    },
    iconfooter: {
      display: 'flex',
      '& div': {
        backgroundColor: '#000000',
        borderRadius: 50,
        color: 'white',
        marginLeft: 20,
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:first-child': {
          marginLeft: 0,
        },
        '@media(max-width:1180px)': {
          marginLeft: 10,
          width: 30,
          height: 30,
          '& svg': {
            width: 20,
            height: 20,
          },
        },
      },
    },
    TextfieldVip: {
      padding: 8,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      width: '100%',
      marginTop: '-10px',
    },
    activProductShadow: {
      backgroundColor: ' #F9FAF9',
      textTransform: 'unset',
      boxShadow: 'inset 0px 6px 11px -6px darkgrey',
      padding: '8px',
      marginBottom: -4,
    },
    boxComponentbtnProduct: {
      // display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      justifyContent: 'space-arround',
      height: 400,
      padding: '10px 10px 22px 10px',
    },
    upperBoxProduxtlist: {
      padding: '20px 3%',
      backgroundColor: '#F9FAF9',
      color: 'black',
      boxShadow: 'inset 2px -6px 10px 2px rgba(34, 34, 34, 0.05) ',
      borderRadius: '0.313rem',
      width: '95%',
      marginBottom: '1rem',
      '@media(max-width:460px)': {
        padding: '20px 0',
        width: '100%',
      },
    },
    RootBtnShareProduct: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media(max-width:460px)': {
        flexDirection: 'column',
        '&>button': {
          width: '100%',
          fontSize: 14,
        },
      },
    },
    btnShare: {
      padding: 10,
      borderRadius: 10,
      textTransform: 'unset',
      marginTop: 15,
      width: '50%',
    },
    cardRootProduct: {
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
      '@media(max-width:1280px)': {
        width: '45%',
      },
    },
    RootProductList: {
      padding: '30px',
      display: 'flex',
      flexDirection: 'row',
      width: '150%',
      '@media(max-width:1180px)': {
        width: '150%',
        display: 'flex',
        flexDirection: 'column-reverse',
      },
      '@media(max-width:900px)': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        padding: '30px 0',
      },
      '@media(max-width:600px)': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
    contentInfo: {
      flex: 1,
      '@media(max-width:460px)': {
        paddingLeft: 0,
      },
    },
    viewBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxHeight: '480px',
      position: 'relative',
    },
    listVip: {
      padding: '5px',
      '&:hover': {
        backgroundColor: '#dddddd',
        cursor: 'pointer',
      },
    },
    liveStatisticBloc: {
      display: 'flex',
      width: '100vw',
      paddingRight: '2rem',
      justifyContent: 'space-evenly',
      '& > div': {
        width: '30%',
      },
      '& > h6': {
        lineHeight: 'unset',
      },
      '& > span': {
        lineHeight: 'unset',
      },
    },
  }),
);
