import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';

interface ICardQuestionnaire {
  nameQuestion: string;
  question: string;
  response: string[];
  hours?: string;
  typeQuestion: string;
}
const CardQuestionnaire: React.FC<ICardQuestionnaire> = ({ nameQuestion, question, response, typeQuestion, hours }) => {
  const style = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={style.BoxRoute}>
      <Box className={style.BoxDisplay}>
        <Box style={{ flex: 6 }}>
          <Typography variant="caption">{t('question.questionnaire') + nameQuestion} </Typography>
          <Typography>{`Question : ${question} ?`} </Typography>
          <Box className={style.Boxresponse}>
            {response.map((item, index) => {
              return <Typography key={index}>{`Choix ${index} : ${item}.`} </Typography>;
            })}
          </Box>
        </Box>
        <Box className={style.boxType}>
          {/* <Typography variant="caption">Heure de la publication : manuelle </Typography> */}
          <Typography variant="caption">{` ${t('question.type') + typeQuestion}.`}</Typography>
          <Box className={style.boxBtn}>
            <Button className={style.btnUpdate}>{t('question.updated')}</Button>
            <Button className={style.btnDelete}>{t('question.delete')}</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardQuestionnaire;
