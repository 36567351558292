import { typedAction } from '../utils';

export const GET_PRODUCT_CONSUMER_REQUEST = 'GET_PRODUCT_CONSUMER_REQUEST';
export const GET_PRODUCT_CONSUMER_SUCCESS = 'GET_PRODUCT_CONSUMER_SUCCESS';
export const GET_PRODUCT_CONSUMER_FAILED = 'GET_PRODUCT_CONSUMER_FAILED';
export const RESET_PRODUCT_CONSUMER_REQUEST = 'RESET_PRODUCT_CONSUMER_REQUEST';
export interface IProductConsumer {
  id: string;
  label: string;
  description: string | null | undefined;
  furtherInformation: string | null | undefined;
  url: string | null | undefined;
  priceBeforeDiscount: number;
  priceAfterDiscount: number;
  isVisible: boolean;
  mainImageUrl: string;
}

export interface InitialStateProductConsumer {
  product: IProductConsumer[];
  loading: boolean;
  error: string | undefined;
}
const initialState: InitialStateProductConsumer = {
  product: [],
  error: undefined,
  loading: false,
};

export const getProductConsumer = (id: string) => typedAction(GET_PRODUCT_CONSUMER_REQUEST, id);
type GetProductConsumer = typeof getProductConsumer;

export const resetProductConsumer = () => typedAction(RESET_PRODUCT_CONSUMER_REQUEST);
type ResetProductConsumer = typeof resetProductConsumer;

export const productConsumerSuccess = (data: IProductConsumer[]) => typedAction(GET_PRODUCT_CONSUMER_SUCCESS, data);
type ProductConsumerSuccess = typeof productConsumerSuccess;

export const productConsumerFailed = (error: string) => typedAction(GET_PRODUCT_CONSUMER_FAILED, error);
type ProductConsumerFailed = typeof productConsumerFailed;

export type ConsumerProductAction = ReturnType<
  GetProductConsumer | ProductConsumerSuccess | ProductConsumerFailed | ResetProductConsumer
>;

export const productConsumerReducer = (
  state: InitialStateProductConsumer = initialState,
  action: ConsumerProductAction,
): InitialStateProductConsumer => {
  switch (action.type) {
    case GET_PRODUCT_CONSUMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESET_PRODUCT_CONSUMER_REQUEST:
      return initialState;
    case GET_PRODUCT_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };
    case GET_PRODUCT_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
