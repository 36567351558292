import { Box, Menu, Button, CircularProgress, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chat from '../../../common/chat/Chat';
import { IMessage } from '../../../redux-services/type/listenerApi';
import { useStyles } from '../style';
import HttpsIcon from '@material-ui/icons/Https';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux-services';
import { IinitialStateAdvantage } from '../../../redux-services/modules/participant/advantageCode';
import { ILiveStatus } from '../../../redux-services/type/live';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import Finger from '../../../Assets/images/finger.png';
import Skeleton from '@material-ui/lab/Skeleton';
import { IliveConsumerById } from '../../../redux-services/modules/liveSessionConsumerget';
import theme from '../../../Themes';
import clsx from 'clsx';
import Line from '@material-ui/icons/Minimize';
import { HubConnection } from '@microsoft/signalr';
import Api from '../../../redux-services/api';

interface IMobileFooterProps {
  anchorEl: HTMLElement | null;
  messages: IMessage[];
  live: IliveConsumerById;
  messageSend?: ((mess: string) => void) | null;
  token: string | undefined;
  handleRedirect: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClickTwo: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  loading: boolean;
  id: string;
  handleOpen?: () => void;
  statusLive?: ILiveStatus;
  fullscreen?: boolean;
  isMobile?: boolean;
  backgroundAvatar: string;
  connectionHub: HubConnection | null;
  userId: string;
}

const MobileFooter: React.FC<IMobileFooterProps> = (props: IMobileFooterProps) => {
  const {
    anchorEl,
    handleClose,
    messages,
    messageSend,
    token,
    handleRedirect,
    handleClickTwo,
    handleClick,
    loading,
    id,
    handleOpen,
    live,
    statusLive,
    fullscreen,
    isMobile,
    backgroundAvatar,
    connectionHub,
    userId,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data: { advantageCode },
    loading: loadingCodeAvantage,
  } = useSelector<RootState, IinitialStateAdvantage>((state) => state.guestAdvantageCode);
  const [codeAchat, setCodeAchat] = useState<string>();
  const [shopUrl, setShopUrl] = useState<string>();

  useEffect(() => {
    if (userId) {
      Api.participant.GetAffiliationInfo(userId).then((data) => {
        setCodeAchat(data.data.advantageCode);
        setShopUrl(data.data.shoppingUrl);
      });
    }
  }, [userId, loading]);

  const VipAdVantage = useCallback(() => {
    if (loading) {
      return <CircularProgress size={20} style={{ marginLeft: 20, marginRight: 20 }} />;
    } else {
      if (statusLive && !statusLive.isStarted && !statusLive.isEnded) {
        return <>{'ATTENTE LIVE'}</>;
      } else {
        if (codeAchat) {
          return <>{codeAchat}</>;
        }
        if (shopUrl && shopUrl !== '') {
          return (
            <Box style={{ width: '100%', display: 'flex' }}>
              <a
                href={shopUrl}
                target="blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  width: 146,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  window.open(shopUrl, '_blank');
                }}
              >
                {t('action.order')}
              </a>
              <ShoppingCartTwoToneIcon />
            </Box>
          );
        }
      }
    }

    return (
      <>
        {fullscreen || isMobile ? (
          <Line style={{ height: 30, fontSize: 50, paddingBottom: 10 }} />
        ) : (
          t('consumer.live.decouverted')
        )}
      </>
    );
  }, [codeAchat, shopUrl, token, loading, statusLive, fullscreen, isMobile]);

  return (
    <Box
      className={clsx({ [classes.footerMobile]: true, [classes.footerMobileFullscreen]: fullscreen })}
      id="footerMobile"
    >
      <Box
        className={clsx({ [classes.conversation]: true, [classes.conversationFullscreen]: fullscreen })}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ChatIcon />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.chatmobileplace}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <div>
          <Chat
            messages={messages}
            id={id}
            loading={loading}
            handleOpen={handleOpen}
            isLiveStarted={statusLive && statusLive.isStarted}
            backgroundAvatar={backgroundAvatar}
            connectionHub={connectionHub ? connectionHub : null}
            userId={userId}
          />
          <Box className={classes.closeChat} onClick={handleClose}>
            <Close />
          </Box>
        </div>
      </Menu>
      <Box>
        {loadingCodeAvantage ? (
          <Skeleton
            className={classes.btnAbonner}
            style={{ backgroundColor: live.primaryColor ?? theme.palette.primary.light, width: 200, height: 42 }}
          />
        ) : (
          <Button
            variant="contained"
            className={classes.btnAbonner}
            aria-describedby="my-pop-over"
            style={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: live.primaryColor }}
            onClick={token ? handleRedirect : handleClickTwo}
          >
            {shopUrl ? t('consumer.live.shopUrl') : t('consumer.live.code')}
            <div className={classes.imgFingerContainerMobile}>
              &nbsp;&nbsp; <img src={Finger} style={{ width: 30, transform: fullscreen ? 'rotate(268deg)' : '' }} />
            </div>
          </Button>
        )}

        {!token?.length && (
          <Popover
            id="my-pop-over"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box className={classes.popContent}>
              {t('consumer.live.subscribe.request')}
              <Button variant="contained" onClick={handleOpen}>
                {t('consumer.live.login')}
              </Button>
            </Box>
          </Popover>
        )}
        <Box
          className={classes.btnInviter}
          style={{ width: '100%', margin: '10px 0 10px 0' }}
          onClick={token ? handleRedirect : handleClickTwo}
        >
          <VipAdVantage />
        </Box>

        {/* <Box className={classes.btnInviter}>
          {loadingCodeAvantage ? (
            <CircularProgress size={20} style={{ marginLeft: 20, marginRight: 20 }} />
          ) : !token?.length ? (
            <>
              {t('consumer.live.decouvert')}{' '}
              <HttpsIcon style={{ color: 'rgb(43 42 42)', fontSize: 22, marginLeft: 6 }} />
            </>
          ) : advantageCode && advantageCode.length ? (
            statusLive && !statusLive.isStarted && !statusLive.isEnded ? (
              'ATTENTE LIVE'
            ) : (
              advantageCode
            )
          ) : (
            t('consumer.live.decouverted')
          )}
        </Box> */}
      </Box>
    </Box>
  );
};

export default MobileFooter;
