import { Participant } from 'livekit-client';
import { typedAction } from '../utils';

export interface IDom {
  isPinnedVip: boolean;
  pinnedVip?: Participant | null;
}

const initialStateDom: IDom = {
  isPinnedVip: false,
  pinnedVip: null,
};

export const PIN_VIP = 'DOM/PIN_VIP';
export const UNPIN_VIP = 'DOM/UN_PIN_VIP';

export const pinVip = (vip: Participant) => typedAction(PIN_VIP, vip);
export type PinVip = typeof pinVip;

export const unPinVip = () => typedAction(UNPIN_VIP);
export type UnPinVip = typeof unPinVip;

export type DomAction = ReturnType<PinVip | UnPinVip>;

export const DomReducer = (state: IDom = initialStateDom, action: DomAction): IDom => {
  switch (action.type) {
    case PIN_VIP:
      return { ...state, isPinnedVip: true, pinnedVip: action.payload };

    case UNPIN_VIP:
      return { ...state, isPinnedVip: false, pinnedVip: null };

    default:
      return state;
  }
};
