import { typedAction } from '../../utils';

export const DO_AFFILIATION_SAVED_SET = 'URL/AFFILIATION/SAVED/SET';
export const DO_AFFILIATION_RESET = 'URL/AFFILIATION/RESET';

export interface IinitialStateAffiliation {
  isSaved: boolean;
}
const initialState: IinitialStateAffiliation = {
  isSaved: true,
};

export const setAffiliationSaved = (value: boolean) => typedAction(DO_AFFILIATION_SAVED_SET, value);
export type IsetAffiliationSaved = typeof setAffiliationSaved;

export const ResetAffiliation = () => typedAction(DO_AFFILIATION_RESET);
export type IresetAffiliation = typeof ResetAffiliation;

export type AffiliationAction = ReturnType<IsetAffiliationSaved | IresetAffiliation>;

export const StateAffiliationReducer = (
  state: IinitialStateAffiliation = initialState,
  action: AffiliationAction,
): IinitialStateAffiliation => {
  switch (action.type) {
    case DO_AFFILIATION_SAVED_SET:
      return {
        ...initialState,
        isSaved: action.payload,
      };
    case DO_AFFILIATION_RESET:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};
