import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '20px 3%',
      backgroundColor: theme.palette.primary.light,
      minHeight: 200,
      color: 'white',
    },
    slider: {
      '@media(max-width)': {
        marginLeft: '20px',
        marginRight: '60px',
        display: 'flex',
        justifyContent: 'center',
        width: '78vw',
      },
    },
    slide: {
      paddingLeft: 20,
      paddingRight: 20,
    },

    card: {
      boxSizing: 'border-box',
      height: '156px',
      width: 'auto',
      backgroundPosition: 'center',
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        borderRadius: 5,
      },
    },
    miniatureCard: {
      backgroundColor: '#222222',
      height: '100%',
      width: '100%',
    },
  }),
);
