import { createStyles, makeStyles } from '@material-ui/core';
import theme from '../../Themes';

export const useStyles = makeStyles(() =>
  createStyles({
    viewBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },
    muted: {
      position: 'absolute',
      alignSelf: 'flex-end',
      zIndex: 10,
    },
    imgPreview: {
      width: '100%',
      padding: 50,
      objectFit: 'contain',
      height: '100%',
    },
    BoxImage: {
      height: '66vh',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderWidth: 1,
      borderRadius: 16,
      backgroundColor: '#FFF',
      '@media(max-width:1180px)': {
        height: '40vh',
      },
    },
    BoxImageFullscreen: {
      height: '80vh',
    },
  }),
);
