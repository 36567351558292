import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import MyCardShopping, { IcardShooping } from '../../../common/MyCardShopping/MyCardShopping';
// import pathProduct from '../../../Assets/images/product.jpg';
import theme from '../../../Themes';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux-services';
import { get_AllProduct, IinitialStateListProduct } from '../../../redux-services/modules/product/productList';
import { IActus } from '../../../redux-services/type/actus';
import { IActusWithActivated } from '../ApercuComponent';
import {
  IinitialStateClickRecordProductByLiveId,
  get_ClickRecordProductByLiveId,
} from '../../../redux-services/modules/product/participantProductClickRecord';

interface IListProductsProps {
  productsShare: (products: string[]) => void;
  actuality?: IActusWithActivated;
}

const ListProductComponent: React.FC<IListProductsProps> = (props: IListProductsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { productsShare, actuality } = props;
  const [goToProductShare, setGoToProductShare] = useState<boolean>(true);
  const [selectAllProducts, setSelectAllProducts] = useState<boolean>(false);
  const [actualitys, setActualitys] = useState<IActus[]>([]);
  const { id }: any = useParams();

  const { loading: loadingListProduct, ListProduct } = useSelector<RootState, IinitialStateListProduct>(
    (state) => state.productList,
  );
  const { ListDataClickRecordProducts, loading: loadingListDataClickRecordProducts } = useSelector<
    RootState,
    IinitialStateClickRecordProductByLiveId
  >((state) => state.listClickRecordProductByLiveId);

  const dispatch = useDispatch();

  const DataProducts: IcardShooping[] = ListProduct.map((item) => {
    return {
      id: item.id,
      description: item.label,
      pathProduct: item.mainImageUrl,
      price: item.priceAfterDiscount,
      activePartage: true,
    };
  });
  const [productsSelected, setProductsSelected] = useState<string[]>(
    DataProducts.reduce<string[]>((acc, product) => {
      if (product?.id) {
        acc.push(product.id);
      }
      return acc;
    }, []),
  );
  const [productsShared, setProductsShared] = useState<string[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(get_AllProduct(id));
      dispatch(get_ClickRecordProductByLiveId({ liveId: id, userType: 'Retailer' }));
    }
  }, [id]);

  useEffect(() => {
    if (!loadingListDataClickRecordProducts && ListDataClickRecordProducts.length > 0) {
      setActualitys(
        ListDataClickRecordProducts.map((_historyActu) => ({
          productId: _historyActu.productLabel,
          participantId: _historyActu.participantId,
          participantName: _historyActu.participantName,
        })),
      );
    }
  }, [loadingListDataClickRecordProducts]);

  useEffect(() => {
    if (DataProducts.length && productsSelected.length === DataProducts.length) {
      setProductsSelected([]);
    } else {
      if (DataProducts.length && productsSelected.length !== DataProducts.length) {
        setProductsSelected(
          DataProducts.reduce<string[]>((acc, product) => {
            if (product?.id) {
              acc.push(product.id);
            }
            return acc;
          }, []),
        );
      }
    }
  }, [selectAllProducts]);

  useEffect(() => {
    if (actuality && actuality?.productId !== '' && actuality?.participantName !== '') {
      const _actuality: IActus = {
        ...actuality,
        productId: DataProducts.find((p) => p.id == actuality.productId)?.description || '',
      };
      setActualitys((_actus) =>
        _actus.length
          ? //  _actus.some(
            //     (_a: IActus) =>
            //       _a.participantId === actuality?.participantId &&
            //       _a.participantName === actuality?.participantName &&
            //       _a.productId === _actuality?.productId,
            //   )
            //   ? _actus
            // :
            [..._actus, _actuality]
          : [_actuality],
      );

      const elementToScroll = document.getElementById('actuality-box');
      elementToScroll?.scrollTo(0, elementToScroll?.scrollHeight);
    }
  }, [actuality]);
  useEffect(() => {
    if (ListProduct.length) {
      const dataSelected = ListProduct.filter((p) => p.isVisible)?.map((_p) => _p.id);
      if (dataSelected.length) {
        setProductsSelected(dataSelected);
        setProductsShared(dataSelected);
      }
    }
  }, [ListProduct]);

  return (
    <Box className={classes.cardRootProduct}>
      <Box className={classes.headButtonActualites}>
        <Button
          style={{
            width: '50%',
            textTransform: 'unset',
          }}
          className={!goToProductShare ? classes.activProductShadow : ''}
          onClick={() => {
            setGoToProductShare(false);
          }}
        >
          <Typography variant="h5" style={{ textTransform: 'unset' }}>
            {t('apercu.actuality.text')}
          </Typography>
        </Button>
        <Button
          style={{
            textTransform: 'unset',
            width: '45%',
          }}
          className={goToProductShare ? classes.activProductShadow : ''}
          onClick={() => {
            setGoToProductShare(true);
          }}
        >
          <Typography variant="h5" style={{ textTransform: 'unset' }}>
            {t('apercu.product.sales')}
            {`(${DataProducts.length})`}
          </Typography>
        </Button>
      </Box>
      <Box className={classes.upperBoxProduxtlist}>
        {goToProductShare && (
          <Box className={classes.boxComponentbtnProduct}>
            <Typography>{t('apercu.text.productShare')}</Typography>
            <Box className={classes.RootBtnShareProduct}>
              <Button
                style={{
                  backgroundColor:
                    JSON.stringify(productsSelected) !== JSON.stringify(productsShared) ? '#222222' : '#D8D8D8',
                  color: 'white',
                }}
                disabled={JSON.stringify(productsSelected) === JSON.stringify(productsShared)}
                className={classes.btnShare}
                onClick={() => {
                  productsShare(productsSelected);
                  setProductsShared(productsSelected);
                }}
              >
                {t('apercu.product.btn.text.share')}
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: theme.palette.common.black,
                }}
                className={classes.btnShare}
                onClick={() => {
                  setSelectAllProducts(!selectAllProducts);
                }}
              >
                {productsSelected.length === DataProducts.length
                  ? t('apercu.product.btn.text.unselect')
                  : t('apercu.product.btn.text.select')}
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" paddingBottom="120px">
              {DataProducts.map((item, indexKey: number) => {
                return (
                  <MyCardShopping
                    key={indexKey}
                    {...{
                      ...item,
                      // onSelectProducts,
                      selectAllProducts,
                      nbAll: DataProducts.length,
                      nbSelected: productsSelected.length,
                      pathProduct: item.pathProduct,
                      productsSelected,
                      setProductsSelected,
                      setSelectAllProducts,
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        )}

        {!goToProductShare && (
          <Box className={classes.boxComponentbtnProduct} id="actuality-box">
            {actualitys.length ? (
              actualitys.map((actu, indexKey: number) => (
                <Typography key={indexKey}>{`${actu.participantName} s’intéresse à ${actu.productId}`}</Typography>
              ))
            ) : (
              <Typography>I am a actualiter</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ListProductComponent;
