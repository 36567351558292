import { createStyles, makeStyles, StepConnector, Theme, withStyles } from '@material-ui/core';
import theme from '../../../Themes';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100%',
      boxSizing: 'border-box',
    },
  }),
);
export const useStylesRecognition = makeStyles(() =>
  createStyles({
    container: {
      minHeight: '100%',
      boxSizing: 'border-box',
      width: '90%',
    },
    subcontainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '48px',
      '@media(max-width:700px)': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      },
    },
    typography: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '47px',
      width: '25%',
      '@media(max-width:700px)': {
        width: '100%',
      },
    },
    divPersonnel: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '75%',
      '@media(max-width:500px)': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      },
    },
    divConsumer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      '@media(max-width:700px)': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      },
    },
    divCompany: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '75%',
      '@media(max-width:500px)': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      },
    },
    boxLeft: {
      width: '48%',
      '@media(max-width:500px)': {
        width: '100%',
      },
    },
  }),
);

export const useColorlibStepIcon = makeStyles({
  root: {
    backgroundColor: 'black',
    zIndex: 1,
    color: theme.palette.common.white,
    width: 40,
    height: 40,
    display: 'flex',
    lineHeight: '17px',
    fontSize: '14px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
  },
});

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: '100px',
      '@media(max-width:700px)': {
        marginTop: 20,
      },
      flexDirection: 'column',
      minHeight: '100%',
      paddingLeft: '24px',
      paddingRight: '24px',
      boxSizing: 'border-box',
      paddingBottom: theme.spacing(6),
    },
    root: {
      color: theme.palette.primary.light,
      width: '60%',
      backgroundColor: 'transparent',
      '@media(max-width:600px)': {
        width: '100%',
      },
    },
    stepperTitle: {
      '@media(max-width:700px)': {
        border: '1px solid rgba(28,110,164,0.19)',
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    iconText: { fontSize: '20px', lineHeight: '24px', fontWeight: 600 },
    containerStep: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  }),
);

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);
