import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import LiveMovie from '../common/cardVideo/CardVideo';
import Header from '../common/header/Header';
import LoginConsumerScreen from '../component/auth/AuthConsumer';
import ForgotPassword from '../component/auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../component/auth/ForgotPassword/ResetPassword';
import LoginConsumer from '../component/auth/LoginConsumer';
import LoginScreen from '../component/auth/LoginRetailer';
import Dashboard from '../component/dashboard/Dashboard';
import HomePage from '../component/homePage/homePage';
import NewHomePage from '../component/NewHomePage/NewHomePage';
import LiveScreen from '../component/ViewLive/LiveScreen';
import SignUpComponent from '../component/auth/SignUpComponent/SignUpComponent';
import AccountConsumerComponent from '../component/AccountComponent/AccountConsumerComponent';
import {
  ACCOUNT_RETAILER,
  ADD_PRODUCTS,
  AFFILIATION,
  AUTH_CONSUMER,
  AUTH_CONSUMER_AF,
  AUTH_CONSUMER_LOG,
  AUTH_CONSUMER_PRIVATE_AF,
  AUTH_CONSUMER_VIP,
  AUTH_INSCRIPTION,
  AUTH_INSCRIPTION_RETAILER,
  AUTH_REATAILER,
  CONSUMER_ACCOUNT_CONFIRMATION,
  CONSUMER_ACCOUNT_SETTING,
  CONSUMER_PASSWORD,
  CREATELIVE,
  CREATE_QUESTIONNAIRE,
  DASHBOARD,
  LIVEDETAILS,
  LIVE_APERCU,
  LIVE_SCREEN,
  LIVE_SECTION,
  LIVE_STATISTIQUE,
  MANAGE_INVITATION_VIP,
  PASSWORD,
  PRODUCTS,
  QUESTIONNAIRE,
  RESETPASSWORD,
  RESETPASSWORD_REDIRECTED,
  RESETPASSWORD_RETAILER,
  UPDATELIVE,
  UPDATE_PRODUCTS,
  URL_AFFILIATION,
  URL_INVITATION,
} from '../constant/path';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { useSelector } from 'react-redux';
import { RootState } from '../redux-services';
import { IinitialStateConsumerGet } from '../redux-services/modules/liveSessionConsumerget';
import moment from 'moment';
import 'moment/locale/fr';
import { ILiveStatus } from '../redux-services/type/live';
import PasswordComponent from '../component/AccountComponent/password/PasswordComponent';
import { useIsMobile } from '../common/header/useIsMobile';

const Router = () => {
  const [statusLive, setStatusLive] = useState<ILiveStatus>({ id: '', isStarted: false, isEnded: false });
  const {
    live: {
      id,
      streamingUrl,
      previewImageUrl,
      actualStart,
      endedLiveImageUrl,
      actualEnd,
      scheduledStart,
      title,
      timeZone: { name: timeZoneName },
      replayVideoFileUrl,
    },
  } = useSelector<RootState, IinitialStateConsumerGet>((state) => state.liveSessionConsumerById);

  const { pathname } = useLocation();

  const isMobile = useIsMobile();

  const displayHeader =
    [
      `/`,
      `/auth/retailer`,
      `/auth/inscription`,
      '/auth/resetpassword',
      '/Consumer/account-confirmation',
      '/Retailer/account-confirmation',
      '/home',
    ].includes(pathname) || pathname.startsWith('/dashboard/livesessions');

  const isStoped = statusLive && statusLive.isStarted && statusLive.isEnded;

  useEffect(() => {
    setStatusLive({
      id,
      isStarted: !!actualStart,
      isEnded: !!actualEnd,
    });
  }, [id, actualStart, actualEnd]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!pathname.includes('LiveCurrent') && <Header />}
      <div style={{ height: displayHeader ? 'calc(100vh - 75px)' : '100vh', ...(isMobile ? {} : {}) }} id="body">
        <Switch>
          <Route path="/" exact component={NewHomePage} />
          <Route path="/home" exact component={HomePage} />
          <Route path={AUTH_REATAILER} exact component={LoginScreen} />
          <Route path={[RESETPASSWORD, RESETPASSWORD_RETAILER]} exact component={ForgotPassword} />
          <Route path={RESETPASSWORD_REDIRECTED} exact component={ResetPassword} />
          <Route path={[AUTH_CONSUMER, AUTH_CONSUMER_AF]} exact>
            <LoginConsumer actualEnd={actualEnd} />
          </Route>
          <Route path={CONSUMER_ACCOUNT_SETTING} exact component={AccountConsumerComponent} />
          <Route path={CONSUMER_PASSWORD} exact component={PasswordComponent} />
          <Route
            path={[AUTH_CONSUMER_LOG, AUTH_CONSUMER_VIP, AUTH_CONSUMER_PRIVATE_AF]}
            exact
            component={LoginConsumerScreen}
          />
          <Route
            path={[AUTH_INSCRIPTION, AUTH_INSCRIPTION_RETAILER, CONSUMER_ACCOUNT_CONFIRMATION]}
            exact
            component={SignUpComponent}
          />
          <Route path={[LIVE_SCREEN, URL_AFFILIATION, URL_INVITATION]} exact>
            <LiveScreen {...{ statusLive, setStatusLive }}>
              <LiveMovie
                {...{
                  id,
                  streamingUrl,
                  // urlImage: !!actualStart || isStartLive ? undefined : previewImageUrl,
                  urlImage: previewImageUrl,
                  endedLiveImageUrl,
                  isEndedLive: isStoped,
                  replayVideoFileUrl,
                  titleLiveBeforeStarting: !statusLive.isStarted
                    ? `${
                        title +
                        ' prévu le ' +
                        moment(scheduledStart).format('DD/MM/YYYY [à] HH:mm ') +
                        '(' +
                        timeZoneName +
                        ')'
                      }`
                    : undefined,
                }}
              />
            </LiveScreen>
          </Route>
          <Route path={DASHBOARD} exact component={Dashboard} />
          <PrivateRoute
            path={[
              DASHBOARD,
              UPDATELIVE,
              CREATELIVE,
              ACCOUNT_RETAILER,
              LIVE_STATISTIQUE,
              LIVE_SECTION,
              LIVE_APERCU,
              LIVEDETAILS,
              ADD_PRODUCTS,
              QUESTIONNAIRE,
              PRODUCTS,
              CREATE_QUESTIONNAIRE,
              UPDATE_PRODUCTS,
              MANAGE_INVITATION_VIP,
              AFFILIATION,
              PASSWORD,
            ]}
            exact
            component={Dashboard}
          />
        </Switch>
      </div>
    </>
  );
};
export default Router;
