import React, { FC } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TFunction, useTranslation } from 'react-i18next';
import { IdataGuestSummary, IParticitantUser } from '../../redux-services/modules/guest/guestListsParticipantsSummary';
import MuiTableCell from '@material-ui/core/TableCell';

interface IPropsSummaryGuest {
  data: IdataGuestSummary[];
}
const TableCellWhitStyles = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  tableHeader: {
    // '& > tr > th': { fontSize: '18px' },
  },
});

const RowChild = (props: { row: IParticitantUser[] }) => {
  const { row } = props;

  return (
    <>
      {row.map((info, index) =>
        index === row.length - 1 ? (
          <TableRow key={index}>
            <TableCellWhitStyles align="center"></TableCellWhitStyles>
            <TableCellWhitStyles align="center" component="th" scope="row">
              {info.participants.map(
                (participant, i, array) => `${participant?.name ?? ''}${i !== array.length - 1 ? ' /' : ''} `,
              )}
            </TableCellWhitStyles>
            <TableCellWhitStyles align="center">{info.consumerFirstName}</TableCellWhitStyles>
            <TableCellWhitStyles align="center">{info.consumerLastName}</TableCellWhitStyles>
            <TableCellWhitStyles align="center">{info.consumerEmail}</TableCellWhitStyles>
          </TableRow>
        ) : (
          <TableRow key={index}>
            <TableCell align="center"></TableCell>
            <TableCell align="center" component="th" scope="row">
              {info.participants.map(
                (participant, i, array) => `${participant?.name ?? ''}${i !== array.length - 1 ? ' /' : ''} `,
              )}
            </TableCell>
            <TableCell align="center">{info.consumerFirstName}</TableCell>
            <TableCell align="center">{info.consumerLastName}</TableCell>
            <TableCell align="center">{info.consumerEmail}</TableCell>
          </TableRow>
        ),
      )}
    </>
  );
};

const Row = (props: { row: IdataGuestSummary; t: TFunction<'translation'> }) => {
  const { row, t } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const rowAffiliated: IParticitantUser[] = [
    ...(row.affiliatedConsumers ?? []),
    ...(row.affiliatedParticipants
      ? row.affiliatedParticipants.map((_r) => ({
          consumerId: '',
          consumerLastName: '',
          consumerFirstName: '',
          consumerEmail: '',
          participants: [_r],
        }))
      : []),
  ];

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          VIP
          {(row.affiliatedConsumers.length || row.affiliatedParticipants.length) && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="center">{row.advantageCode}</TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.guestEmail}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.guestConsumer.participants.map(
            (participant, i, array) => `${participant?.name ?? ''}${i !== array.length - 1 ? ' /' : ''} `,
          )}
        </TableCell>
        <TableCell align="center">{`${row.guestConsumer.consumerFirstName} ${row.guestConsumer.consumerLastName}`}</TableCell>
        <TableCell align="center">{row.guestConsumer.consumerEmail}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" align="left">
                      <Typography variant="h6" gutterBottom component="div">
                        Invités
                      </Typography>
                    </TableCell>
                    <TableCell width="22%" align="center">
                      {t('stat.pseudo')}
                    </TableCell>
                    <TableCell width="18%" align="center">
                      {t('stat.invitedNam')}
                    </TableCell>
                    <TableCell width="18%" align="center">
                      {t('stat.invitedLastName')}
                    </TableCell>
                    <TableCell width="32%" align="center">
                      {t('stat.invitedMail')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {rowAffiliated && (
                  <TableBody>
                    <RowChild row={rowAffiliated} />
                  </TableBody>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const SummaryGuest: FC<IPropsSummaryGuest> = ({ data }) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  return (
    <TableContainer component={Paper} style={{ width: 1100, maxHeight: 500, minHeight: 300 }}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left" width="10%">
              Status
            </TableCell>
            <TableCell align="center" width="10%">
              {t('stat.advantageCode')}
            </TableCell>
            <TableCell align="center" width="25%">
              {t('stat.vip.mail')}
            </TableCell>
            <TableCell align="center" width="15%">
              {t('stat.pseudo')}
            </TableCell>
            <TableCell align="center" width="15%">
              {t('stat.invitedNam')}
            </TableCell>
            <TableCell align="center" width="25%">
              {t('stat.invitedMail')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: IdataGuestSummary) => (
            <Row key={row.guestEmail} row={row} t={t} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryGuest;
