import { Box, Button, Divider, Grid, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import CustomModal from '../../common/modal/Modal';
import { RootState } from '../../redux-services';
import { DoDeleteProduct, IinitialStateDeleteProduct } from '../../redux-services/modules/product/productDelete';
import { get_AllProduct, IinitialStateListProduct } from '../../redux-services/modules/product/productList';
import ProductToSale from '../ProductToSale/ProductToSale';
import { useIsMobile } from '../../common/header/useIsMobile';
import { ArrowBackIos } from '@material-ui/icons';
interface NewProductToSaleProps {
  toggleNewProduct?: () => void;
}

const ProductsToSale: React.FC<NewProductToSaleProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id }: any = useParams();
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const { loading: loadingListProduct, ListProduct: allProduct } = useSelector<RootState, IinitialStateListProduct>(
    (state) => state.productList,
  );
  const { loading: loadingDeleteProduct, data: productDeleted } = useSelector<RootState, IinitialStateDeleteProduct>(
    (state) => state.deleteProduct,
  );

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (id) {
      dispatch(get_AllProduct(id));
    }
  }, [id]);

  const GoBackDetails = () => {
    history.push(`/dashboard/livesessions/${id}`);
  };

  const onCloseDialog = () => {
    setItemToDelete(null);
  };

  const onConfimDelete = () => {
    if (itemToDelete) {
      dispatch(DoDeleteProduct(itemToDelete));
      onCloseDialog();
    }
  };

  useEffect(() => {
    if (productDeleted) {
      dispatch(get_AllProduct(id));
    }
  }, [productDeleted]);
  return (
    <Box style={isMobile ? { marginTop: '-10px', paddingBottom: '40px ', paddingRight: 12, paddingLeft: 12 } : {}}>
      <CustomBackdrop {...{ open: loadingListProduct || loadingDeleteProduct }} />
      <CustomModal
        {...{
          title: 'Confirmation',
          maxWidth: 'xs',
          open: !!itemToDelete,
          TextCancel: 'Annuler',
          textConfirm: 'Confirmer',
          onClose: onCloseDialog,
          onConfirm: onConfimDelete,
        }}
      >
        <Typography>{'Voulez-vous vraiment supprimer ce produit ?'}</Typography>
      </CustomModal>
      <Grid container spacing={2} style={isMobile ? {} : {}}>
        <Grid item xs={8} style={{ paddingTop: 20 }}>
          {isMobile ? (
            <div>
              <Button
                onClick={GoBackDetails}
                style={{ textTransform: 'unset', paddingTop: 0, paddingLeft: 0, minWidth: 'auto' }}
                component={Link}
              >
                <ArrowBackIos className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" />
              </Button>
              <span
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: 'bold',
                  marginTop: '5px',
                }}
              >
                {t('productsToSale.productsList')}
              </span>
            </div>
          ) : (
            <Typography
              style={{
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '47px',
              }}
            >
              {t('productsToSale.productsList')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4} style={{ paddingBottom: 12, paddingRight: 10, paddingTop: -20 }}>
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/dashboard/livesessions/${id}/products/addproduct`);
              }}
              style={{
                backgroundColor: ' #222222',
                width: '100%',
                margin: 5,
                borderRadius: 10,
                fontSize: 13,
                padding: 8,
                textTransform: 'unset',
                fontWeight: 'bold',
              }}
            >
              {t('action.addProduct')}
            </Button>
          )}
        </Grid>
      </Grid>

      {!isMobile && (
        <Button onClick={GoBackDetails} style={{ textTransform: 'unset' }} component={Link}>
          <KeyboardBackspaceIcon /> {'Retour aux details du live'}
        </Button>
      )}
      <Grid item>
        {allProduct.map((item, indexKey) => {
          return (
            <div key={indexKey}>
              <ProductToSale {...{ setItemToDelete, ...item }} />
              <Divider style={{ marginTop: '20px', marginBottom: '20px' }} variant="fullWidth" />
            </div>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProductsToSale;
