export default {
  'action.rapide': 'Quick action',
  'action.update.live': 'Edit Live info',
  'action.apercu.live': 'Live preview',
  'action.manage.product': 'Manage products for sale',
  'action.manage.affiliation': 'Fix the VIP benefits',
  'action.manage.invitations': 'Invite your VIP',
  'action.manage.question': 'Manage survey',
  'action.start.live': 'Start live',
  'action.end.live': 'End the live',
  'action.paused.live': 'paused live',
  'action.product': 'Add product',
  'action.question': 'Add questionnaire',
  'action.access': 'Quick access',
  'action.addProduct': 'Add product',
  'action.affiliations': 'List of affiliations',
  'action.close': 'Close',
  'action.order': 'Order',
};
