import { useStyles } from './styles';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Participant, ParticipantEvent, Room, RoomEvent, Track, VideoCaptureOptions } from 'livekit-client';
import UserRoomComponent from './UserRoomComponent';
import {
  BrandingWatermark,
  FullscreenExit,
  Fullscreen,
  MicNone,
  MicOff,
  Videocam,
  VideocamOff,
  CancelPresentation,
  PanToolOutlined,
  PanTool,
  ArrowBack,
  ArrowRight,
  ArrowLeft,
  FlipCameraIos,
} from '@material-ui/icons';
import React from 'react';
import clsx from 'clsx';
import {
  deleteOnMetadataParticipantShowToLive,
  updateMetadataOfParticipant,
  updateMetadataOfRoom,
} from './utilsRoom/functionRoom';
// @ts-ignore
import WifiIndicator from 'react-wifi-indicator';
import { Typography } from '@material-ui/core';
import CustomModal from '../../common/modal/Modal';
import { useIsMobile } from '../../common/header/useIsMobile';

interface RoomComponentInterface {
  paramatersRoom: any;
  room: Room;
  participantRoom: Participant[];
  typeUser?: 'retailer' | 'vip' | 'friends' | 'undefined';
  setFullscreen?: () => void;
  fullscreen?: boolean;
  connectionQualityInConnected?: string;
  liveIsEnded: boolean;
}

const RoomComponent = (props: RoomComponentInterface) => {
  const {
    paramatersRoom,
    room,
    participantRoom,
    typeUser,
    setFullscreen,
    fullscreen,
    connectionQualityInConnected,
    liveIsEnded,
  } = props;
  const [activeCam, setActiveCam] = useState<boolean>(paramatersRoom.videoEnabled);
  const [activeMic, setActiveMic] = useState<boolean>(paramatersRoom.audioEnabled);
  const [vipParticipant, setVipParticipant] = useState<Participant[]>();
  const [initiator, setInitiator] = useState<Participant[]>();
  const [retailer, setRetailer] = useState<Participant>();
  const [participants, setParticipants] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogExit, setOpenDialogExit] = useState<boolean>(false);
  const [connectionQuality, setconnectionQuality] = useState<string>('UNUSABLE');
  const style = useStyles();
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const params = new URLSearchParams(location.search);
  const [rise, setRise] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const roomVipRef = useRef(null);
  const [showSlide, setShowSlide] = useState<boolean | undefined>(false);
  const [widthRoomVip, setWidthRoomVip] = useState<any>(0);
  const [justifyStateContent, setJustifyStateContent] = useState<string>('center');

  const handleCamera = async () => {
    const enabled = room.localParticipant.isCameraEnabled;
    await room.localParticipant.setCameraEnabled(!enabled);
    setActiveCam(!enabled);
    localStorage.setItem('videoEnabled', (!enabled).toString());
  };

  const handleMicro = async () => {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    await room.localParticipant.setMicrophoneEnabled(!enabled);
    setActiveMic(!enabled);
    localStorage.setItem('audioEnabled', (!enabled).toString());
  };

  const handleFlipCamera = () => {
    const videoPub = room.localParticipant.getTrack(Track.Source.Camera);
    if (!videoPub) {
      return;
    }
    let options: VideoCaptureOptions = {
      facingMode: facingMode,
    };
    if (facingMode === 'user') {
      setFacingMode('environment');
      options = {
        facingMode: 'environment',
      };
    } else {
      setFacingMode('user');
      options = {
        facingMode: 'user',
      };
    }
    videoPub.videoTrack?.restartTrack(options);
  };

  const openPIPScreen = (): void => {
    const videoFirstContainer = document.getElementById('pinned-video-container');
    const videoLastContainer = (
      videoFirstContainer ? videoFirstContainer.querySelector('#video-live-container > video') : undefined
    ) as any;
    if (videoLastContainer) {
      videoLastContainer.requestPictureInPicture();
    }
  };

  const getConnectionQuality = (quality: string) => {
    if (quality === 'excellent') {
      setconnectionQuality('EXCELLENT');
    }
    if (quality === 'good') {
      setconnectionQuality('GREAT');
    }
    if (quality === 'Poor') {
      setconnectionQuality('OKAY');
    }
    if (quality === 'unknow') {
      setconnectionQuality('UNUSABLE');
    }
  };

  const pinnedIsConnected = (obj: Participant, list: Participant[]): boolean => {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].identity === obj.identity) {
        return true;
      }
    }

    return false;
  };

  const exitLive = async () => {
    if (room.localParticipant.metadata && JSON.parse(room.localParticipant.metadata).pinned) {
      if (retailer) await updateMetadataOfParticipant(room, retailer.identity, retailer, 'type', 'initiator');
      await updateMetadataOfParticipant(room, room.localParticipant.identity, room.localParticipant, 'pinned', false);
      await updateMetadataOfParticipant(room, room.localParticipant.identity, room.localParticipant, 'type', 'vip');
      await updateMetadataOfRoom(room, 'pinnedInLive', undefined);
    }
    await updateMetadataOfParticipant(room, room.localParticipant.identity, room.localParticipant, 'showToLive', false);
    await deleteOnMetadataParticipantShowToLive(room, room.localParticipant.identity);
  };

  const handleRise = async () => {
    if (rise) {
      setRise(false);
      await updateMetadataOfParticipant(
        room,
        room.localParticipant.identity,
        room.localParticipant,
        'participantRequest',
        'none',
      );
    } else {
      setRise(true);
      await updateMetadataOfParticipant(
        room,
        room.localParticipant.identity,
        room.localParticipant,
        'participantRequest',
        'manifest',
      );
    }
  };
  const handleHorizantalScroll = (element: any, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const checkOverflow = (elem: any) => {
    if (elem) {
      return elem.clientWidth < elem.scrollWidth;
    }
  };

  useLayoutEffect(() => {
    if (roomVipRef.current) {
      // @ts-ignore
      setWidthRoomVip(roomVipRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (roomVipRef.current) {
        // @ts-ignore
        setWidthRoomVip(roomVipRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const elem = document.getElementById('roomForVip');
    setShowSlide(checkOverflow(elem));
    //handle justify content
    if (checkOverflow(elem)) {
      setJustifyStateContent('flex-start');
    } else {
      setJustifyStateContent('center');
    }
  }, [vipParticipant]);

  useEffect(() => {
    paramatersRoom.videoPrejoin?.stop();
    paramatersRoom.audioPrejoin?.stop();
  }, []);

  // parsed Participant
  useEffect(() => {
    // filter vipParticipant
    const vip = participantRoom?.filter((vip) => {
      if (vip.metadata)
        return JSON.parse(vip?.metadata as string)?.showToLive && JSON.parse(vip?.metadata as string)?.type === 'vip';
    });
    setVipParticipant(vip);

    // filter initiator participantRoom
    const initLive = participantRoom?.filter((init) => {
      if (init.metadata)
        return (
          JSON.parse(init?.metadata as string)?.showToLive && JSON.parse(init?.metadata as string).type === 'initiator'
        );
    });
    setInitiator(initLive);

    //filter retailer participant
    const ret = participantRoom.filter((part) => {
      return part.metadata && JSON.parse(part.metadata).grade === 'retailer';
    });
    setRetailer(ret[0]);
  }, [participantRoom, participants]);

  //*** Live is always plyaing **/
  useEffect(() => {
    (async () => {
      if (room.metadata && JSON.parse(room.metadata as string).pinnedInLive && retailer) {
        const vipPinned = JSON.parse(room.metadata as string).pinnedInLive;
        const checkedIsPinnedConnected = pinnedIsConnected(vipPinned, participantRoom);
        if (checkedIsPinnedConnected) {
          await updateMetadataOfParticipant(room, retailer.identity, retailer, 'type', 'vip');
          await updateMetadataOfParticipant(room, vipPinned.identity, vipPinned, 'type', 'initiator');
          await updateMetadataOfParticipant(room, vipPinned.identity, vipPinned, 'pinned', true);
        } else {
          await updateMetadataOfParticipant(room, retailer.identity, retailer, 'type', 'initiator');
        }

        //
        room.on(RoomEvent.ParticipantDisconnected, async (remote) => {
          setParticipants(remote);
          if (remote.identity === vipPinned.identity) {
            const arrayInit = [retailer];
            setInitiator(arrayInit);
            await updateMetadataOfParticipant(room, retailer.identity, retailer, 'type', 'initiator');
          }
        });
      }
    })();
  }, [retailer]);

  useEffect(() => {
    if (room.metadata && JSON.parse(room.metadata as string).pinnedInLive && retailer) {
      const vipPinned = JSON.parse(room.metadata as string).pinnedInLive;
      //
      room.on(RoomEvent.ParticipantDisconnected, async (remote) => {
        if (remote.identity === vipPinned.identity) {
          const arrayInit = [retailer];
          setInitiator(arrayInit);
          await updateMetadataOfParticipant(room, retailer.identity, retailer, 'type', 'initiator');
        }
      });
    }
  }, []);

  //*** EVENT ON ROOM ***
  useEffect(() => {
    if (room) {
      room.on(ParticipantEvent.DataReceived, (payload) => {
        setParticipants(payload);
      });

      room.on(ParticipantEvent.ParticipantMetadataChanged, (metadata, part) => {
        setParticipants(metadata);
      });

      room.on(RoomEvent.RoomMetadataChanged, (metadata) => {
        setParticipants(metadata);
      });

      room.on(RoomEvent.AudioPlaybackStatusChanged, () => {
        if (!room.canPlaybackAudio) {
          setOpenDialog(true);
        }
      });
    }
  }, []);

  //unpublish track if live is endded
  useEffect(() => {
    if (liveIsEnded) {
      room.localParticipant.setCameraEnabled(false);
      room.localParticipant.setMicrophoneEnabled(false);
    }
  }, [liveIsEnded]);

  return (
    <>
      <div
        id="containerRoomVIP"
        className={clsx({
          [style.containerRoom]: typeUser === 'retailer',
          [style.containerRoomVIP]: typeUser === 'vip' || typeUser === 'friends',
          [style.containerRoomVIPFullscreen]: fullscreen,
        })}
      >
        {vipParticipant?.length !== 0 && (
          <div className={style.roomForVip}>
            {showSlide && (
              <div className={style.contentBtnScrollLeft}>
                <ArrowLeft
                  style={{ color: '#FFF', fontSize: '58px' }}
                  onClick={() => {
                    handleHorizantalScroll(roomVipRef.current, 25, 180, -10);
                  }}
                />
              </div>
            )}
            <div
              className={style.roomForVipContent}
              ref={roomVipRef}
              id="roomForVip"
              style={{ justifyContent: justifyStateContent }}
            >
              {vipParticipant?.map((vipParticip) => (
                <div
                  className={clsx({ [style.sectionVip]: true, [style.vipSp]: vipParticip.isSpeaking })}
                  key={vipParticip.sid}
                >
                  <UserRoomComponent
                    room={room}
                    participant={vipParticip}
                    usernameRoom={vipParticip.name as string}
                    videoEnabled={true}
                    audioEnabled={true}
                    typeUser={typeUser}
                    networkQuality={getConnectionQuality}
                  />
                </div>
              ))}
            </div>

            {showSlide && (
              <div className={style.contentBtnScrollRight}>
                <ArrowRight
                  style={{ color: '#FFF', fontSize: '58px' }}
                  onClick={() => {
                    handleHorizantalScroll(roomVipRef.current, 25, 180, 10);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {initiator && initiator?.length !== 0 && (
          <div
            id="pinned-video-container"
            className={clsx({
              [style.pinnedVideoContainerFullscreen]: fullscreen && vipParticipant?.length !== 0,
              [style.pinnedVideoContainerFullscreenWithoutVIP]: fullscreen && vipParticipant?.length === 0,
            })}
          >
            <UserRoomComponent
              key={initiator[0].sid}
              room={room}
              participant={initiator[0]}
              usernameRoom={initiator[0].name as string}
              videoEnabled={activeCam}
              audioEnabled={activeMic}
              audioDeviceID={paramatersRoom.audioDeviceId}
              videoDeviceID={paramatersRoom.videoDeviceId}
              facingMode={paramatersRoom.facingMode}
              typeUser={typeUser}
              networkQuality={getConnectionQuality}
            />
          </div>
        )}
        {/*** Handlers bottom cam, video ***/}
        <div className={clsx({ [style.bottomHandle]: true, [style.bottomHandleFullscreen]: fullscreen })}>
          {room?.localParticipant.metadata && JSON.parse(room?.localParticipant.metadata as string).showToLive && (
            <>
              {room.localParticipant.isCameraEnabled ? (
                <div className={style.btnActive} onClick={handleCamera}>
                  <Videocam color="secondary" />
                </div>
              ) : (
                <div className={style.btnDisabled} onClick={handleCamera}>
                  <VideocamOff color="secondary" />
                </div>
              )}

              <div style={{ marginLeft: 15 }}>
                {room.localParticipant.isMicrophoneEnabled ? (
                  <div className={style.btnActive} onClick={handleMicro}>
                    <MicNone color="secondary" />
                  </div>
                ) : (
                  <div className={style.btnDisabled} onClick={handleMicro}>
                    <MicOff color="secondary" />
                  </div>
                )}
              </div>

              {isMobile && typeUser === 'retailer' && (
                <div style={{ marginLeft: 15 }} onClick={handleFlipCamera}>
                  <div className={style.btnActive}>
                    <FlipCameraIos color="secondary" />
                  </div>
                </div>
              )}

              {typeUser === 'vip' && (
                <div style={{ marginLeft: 15 }}>
                  <div className={style.btnActive} onClick={() => setOpenDialogExit(true)}>
                    <CancelPresentation color="error" />
                  </div>
                </div>
              )}
            </>
          )}

          {initiator?.length !== 0 && typeUser !== 'retailer' && (
            <>
              {typeUser === 'vip' && (
                <div className={style.btnActive} style={{ marginLeft: 15 }} onClick={handleRise}>
                  {!rise ? <PanToolOutlined color="secondary" /> : <PanTool htmlColor="#e7a525" />}
                </div>
              )}
              <div className={style.btnActive} style={{ marginLeft: 15 }} onClick={openPIPScreen}>
                <BrandingWatermark color="secondary" />
              </div>
              {!params.get('fullscreen') ? (
                <div className={style.btnActive} style={{ marginLeft: 15 }} onClick={setFullscreen}>
                  <Fullscreen color="secondary" />
                </div>
              ) : (
                <div className={style.btnActive} style={{ marginLeft: 15 }} onClick={setFullscreen}>
                  <FullscreenExit color="secondary" />
                </div>
              )}
            </>
          )}
        </div>

        {!isMobile && (
          <div className={style.iconReseau}>
            <WifiIndicator strength={connectionQuality} alt="Excellent Wifi" style={{ height: 25 }} />
          </div>
        )}
      </div>

      <CustomModal
        {...{
          open: openDialog,
          title: 'Confirmation',
          TextCancel: 'Annuler',
          onConfirm: () => {
            if (room) {
              room.startAudio().then(() => {
                setOpenDialog(false);
              });
            }
          },
          textConfirm: 'Confirmer',
          onClose: () => {
            setOpenDialog(false);
          },
        }}
      >
        <Typography>Activer la lecture audio ?</Typography>
      </CustomModal>

      <CustomModal
        {...{
          open: openDialogExit,
          title: 'Exit Live',
          TextCancel: 'Annuler',
          onConfirm: () => {
            exitLive();
            setOpenDialogExit(false);
          },
          textConfirm: 'Confirmer',
          onClose: () => {
            setOpenDialogExit(false);
          },
        }}
      >
        <Typography>Voulez vous vraiment arreter la presentation ?</Typography>
      </CustomModal>
    </>
  );
};

export default RoomComponent;
