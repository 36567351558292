import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './style';
import MyCardShopping, { IcardShooping } from '../MyCardShopping/MyCardShopping';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getProductConsumer,
  InitialStateProductConsumer,
} from '../../redux-services/modules/liveSessionConsumerProduct';
import { RootState } from '../../redux-services';
import { useTranslation } from 'react-i18next';
import { IMessageBullsEmoji } from '../../component/ViewLive/LiveScreen';
import theme from '../../Themes';
import clsx from 'clsx';

interface ProductShareConsumerPropos {
  primaryColor?: string;
  productIdsToShow?: string[];
  token?: string | undefined;
  anchorElTwo?: HTMLElement | null;
  handleCloseTwo?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClickTwo?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOpen?: () => void;
  messageProductShared?: IMessageBullsEmoji;
  setMessageProductShared?: React.Dispatch<React.SetStateAction<IMessageBullsEmoji>>;
  fullscreen?: boolean;
  onPIP?: () => void;
}

const ProductShareConsumer: React.FC<ProductShareConsumerPropos> = (props: ProductShareConsumerPropos) => {
  const { id }: any = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    productIdsToShow,
    primaryColor,
    handleOpen,
    handleClickTwo,
    messageProductShared,
    setMessageProductShared,
    token,
    fullscreen,
    onPIP,
  } = props;
  const { loading, product } = useSelector<RootState, InitialStateProductConsumer>((state) => state.ProductConsumer);
  const [data, setData] = useState<IcardShooping[]>();
  const classes = useStyles();
  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    infinite: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // `${t('consumer.live.prsInterested')}`
  useEffect(() => {
    if (id) {
      dispatch(getProductConsumer(id));
    }
  }, [id]);

  useEffect(() => {
    if (product?.length) {
      setData(
        product
          .filter((item) => (productIdsToShow === undefined && item.isVisible) || productIdsToShow?.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              description: item.label || '',
              pathProduct: item.mainImageUrl,
              url: item.url || '',
              price: item.priceAfterDiscount,
              token,
              handleOpen,
              reduction: parseInt(
                (Number((item.priceAfterDiscount * 100) / item.priceBeforeDiscount) - 100).toString(),
              ),
            };
          }),
      );
    }
  }, [product, productIdsToShow, token]);

  useEffect(() => {
    if (
      messageProductShared &&
      messageProductShared.id !== '' &&
      setMessageProductShared &&
      messageProductShared?.toggleClick
    ) {
      const _productName = product.find((p) => p.id === messageProductShared.id)?.label;
      setMessageProductShared((_mess) => ({
        ..._mess,
        message: _mess.participantName
          ? `${_mess.participantName} s’intéresse à ${_productName}`
          : `${t('consumer.live.prsInterested')} ${_productName}`,
        showBulls: true,
      }));

      setTimeout(() => {
        setMessageProductShared((_mess) => ({
          ..._mess,
          showBulls: false,
          toggleClick: false,
        }));
      }, 8000);
    }
  }, [messageProductShared?.toggleClick]);

  return (
    <Box
      className={clsx({ [classes.root]: true })}
      style={{ backgroundColor: primaryColor ?? theme.palette.primary.light }}
    >
      <Typography variant="h5" className={classes.title}>
        Produits partagés
      </Typography>
      <Box className={classes.slide}>
        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        {!loading && data && (
          <Fragment>
            <Box className={classes.slideContainer}>
              <Slider {...settings}>
                {data.map((item: IcardShooping, indexKey: number) => {
                  return <MyCardShopping key={indexKey} {...{ ...item }} fullscreen={fullscreen} onPip={onPIP} />;
                })}
              </Slider>
            </Box>
            <Box className={classes.productsContainerMobile}>
              {data.map((item: IcardShooping, indexKey: number) => {
                return <MyCardShopping key={indexKey} {...{ ...item }} fullscreen={fullscreen} onPip={onPIP} />;
              })}
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ProductShareConsumer;
