import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState, MouseEvent } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useHistory } from 'react-router-dom';
import CardLives from '../../common/cardLives/CardLives';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useStyles } from './style';
import { IListBySectionRetailer } from '../../redux-services/modules/liveSessionRetailerListBySection';
import { useTranslation } from 'react-i18next';
import { DuplicateLiveModal } from './DuplicateLiveModal';
interface sessionsSectionComponentProps {
  sessions: IListBySectionRetailer;
  isEndLive?: boolean;
  isLiveSessionsPage?: boolean;
  setIdToShow?: (id: string) => void;
}

const SessionsSectionMobileComponent: React.FC<sessionsSectionComponentProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const style = useStyles();
  const { isEndLive, isLiveSessionsPage, setIdToShow } = props;
  const [open, setOpen] = useState(false);
  const [liveId, setLiveId] = useState<string | undefined>(undefined);
  const sessions = props.sessions?.sessions;
  const [isSelectedID, setIsSelectedID] = useState('');

  const onCopyLive = (id: string | undefined) => (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    setLiveId(id);
    console.log('duplicate is clicked', id);
  };

  return (
    <>
      {open && <DuplicateLiveModal id={liveId} open={open} setOpen={setOpen} />}
      <Box className={style.live}>
        <Box className={style.WrapSlider}>
          <Grid container style={{ paddingRight: 20, paddingLeft: 12, paddingTop: 20 }}>
            {sessions.map((live) => {
              const { thumbnailImageUrl, title, categoryName, id } = live;
              return (
                <Grid item xs={6}>
                  <CardLives
                    {...{
                      id,
                      img: `${thumbnailImageUrl}`,
                      title,
                      description: categoryName,
                      isSelectedID,
                      isLiveSessionsPage,
                      onClick: async () => {
                        setIsSelectedID(id);
                        isEndLive && setIdToShow
                          ? setIdToShow(id)
                          : await history.push(`/dashboard/livesessions/${live.id}`);
                      },
                      onCopyLive,
                    }}
                    key={live.id}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SessionsSectionMobileComponent;
