import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../api';
import {
  liveSessionFailedSectionRetailer,
  liveSessionSuccesSectionRetailer,
  LIVE_SESSION_RETAILER_SECTION_REQUEST,
} from '../modules/liveSessionRetailerSection';
import { logout } from '../modules/logout';

export function* watchliveSessionSectionRetailer() {
  yield takeEvery(LIVE_SESSION_RETAILER_SECTION_REQUEST, liveSessionSectionRetailer);
}

export function* liveSessionSectionRetailer() {
  try {
    const { data } = yield call(APi.liveSessionRetailer.liveSessionRetaillerSection);
    yield put(liveSessionSuccesSectionRetailer(data));
  } catch (e: any) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case 400:
          yield put(
            liveSessionFailedSectionRetailer({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          break;
        case 401:
          yield put(
            liveSessionFailedSectionRetailer({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          yield put(logout());
          break;
        default:
          yield put(
            liveSessionFailedSectionRetailer({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          break;
      }
    } else {
      const { message } = e.response;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              liveSessionFailedSectionRetailer({
                status: 503,
                error: 'Network error, please check your internet connection',
              }),
            );
            break;
          default:
            yield put(
              liveSessionFailedSectionRetailer({
                status: 503,
                error: 'Network error, please check your internet connection',
              }),
            );
            break;
        }
      }
    }
  }
}
