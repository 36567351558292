import { FormControl, TextField, Typography, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import { forceScrollToTop } from '../../utils/common';

interface MyCustomInputProps {
  label: string;
  type?: string;
}

const MyCustomInput: React.FC<MyCustomInputProps & TextFieldProps> = ({ type, label, ...props }) => {
  const style = useStyles();
  return (
    <FormControl style={{ padding: '7px' }} fullWidth variant="outlined" className={style.formControl}>
      <Typography variant="subtitle1">{label}</Typography>
      <TextField {...props} {...{ inputProps: { style: { padding: 10 } }, type }} onBlur={forceScrollToTop} />
    </FormControl>
  );
};

export default MyCustomInput;
