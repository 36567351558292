import { takeEvery } from 'redux-saga/effects';
import { ResetProfileImg } from '../modules/state/stateProfileImg';
import authSevices from '../services/authSevices';
import liveSessionServices from '../services/liveSessionServices';

export function* watchLogout() {
  yield takeEvery('AUTH/logout', logout);
}

function* logout() {
  yield resetAuthState();
}

function* resetAuthState() {
  yield ResetProfileImg();
  yield authSevices.removeRetailerInfo();
  yield liveSessionServices.cleanParticipantByLive();
  yield window.location.reload();
}
