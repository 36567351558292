import { typedAction } from '../../utils';

interface IparamsDelete {
  id: string;
}

export interface IinitialStateDeleteProduct {
  loading: boolean;
  data: string;
}

export const initialStateDeleteProduct: IinitialStateDeleteProduct = {
  data: '',
  loading: false,
};

export const DELETE_PRODUCT_REQUEST = 'DELETE/PRODUCT/REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const DoDeleteProduct = (id: string) => typedAction(DELETE_PRODUCT_REQUEST, id);
type IDoDeleteProduct = typeof DoDeleteProduct;

export const DeleteProductSuccess = (data: string) => typedAction(DELETE_PRODUCT_SUCCESS, data);
type IDeleteProductSuccess = typeof DeleteProductSuccess;

export const DeleteProductFailed = () => typedAction(DELETE_PRODUCT_FAILED);
type IDeleteProductFailed = typeof DeleteProductFailed;

export type IDeleteProductAction = ReturnType<IDeleteProductFailed | IDeleteProductSuccess | IDoDeleteProduct>;

export const DeleteProductReducer = (
  state: IinitialStateDeleteProduct = initialStateDeleteProduct,
  action: IDeleteProductAction,
): IinitialStateDeleteProduct => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
