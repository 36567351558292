export default {
  'formulaire.info': 'Live information',
  'formulaire.image.preview': 'Image preview',
  'formulaire.image.pause': 'Live image paused',
  'formulaire.image.fin': 'End of live image',
  'formulaire.live.name': 'Name of live',
  'formulaire.live.color': 'Live color',
  'formulaire.live.categorie': 'Video category',
  'formulaire.live.descriptions': 'Descriptions',
  'formulaire.live.date': 'Date',
  'formulaire.live.time': 'Time',
  'formulaire.live.fuseau.horaire': 'Timezone',
  'formulaire.live.duration': 'Live duration',
  'formulaire.live.heure': 'Hours',
  'formulaire.live.minutes': 'Minutes',
  'formulaire.live.status': 'Live status',
  'formulaire.live.published': 'Published',
  'formulaire.btn.validate': 'Validate',
  'formulaire.btn.cancel': 'Cancel',
  'formulaire.confirmation.text': 'Are you sure you want to leave this page ?',
  'formulaire.status.private': 'Private',
  'formulaire.status.public': 'Public',
};
