import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageModal: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: 300,
      objectFit: 'none',
      backgroundSize: 'cover',
    },
    live: {
      paddingLeft: '3%',
      // paddingRight: '3%',
      '& .title': {
        display: 'flex',
        paddingTop: '1%',
        // paddingBottom: '3%',
        alignItems: 'baseline',
        '& p:first-child': {
          fontWeight: 700,
        },
        '& p:last-child': {
          marginLeft: 15,
        },
      },
    },
    WrapSlider: {
      overflow: 'hidden',
      '&>div:first-child': {
        position: 'relative',
        '& .scroll-menu-arrow': {
          position: 'absolute',
          zIndex: 20,
          borderRadius: 62,
          backgroundColor: '#ffffffd6',
          height: 60,
          display: 'flex',
          width: '60px',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .scroll-menu-arrow:first-child': {
          left: 20,
        },
        '& .scroll-menu-arrow:last-child': {
          right: 20,
        },
      },
    },
  }),
);
