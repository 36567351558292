import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProductShare from '../../common/productShare/ProductShare';
import pathProduct from '../../Assets/images/product.jpg';
import { FormatedTime } from '../../constant/function';
import { RootState } from '../../redux-services';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import ImageSliderComponent from '../ImageSliderComponent/ImageSliderComponent';
import { useStyles } from './styles';
import { IcardShooping } from '../../common/MyCardShopping/MyCardShopping';
import { useGA4React } from 'ga-4-react';
import { get_AllProduct, IinitialStateListProduct } from '../../redux-services/modules/product/productList';
import { useHistory, useParams } from 'react-router-dom';
import { get_AllGuest, IinitialStateListGuest } from '../../redux-services/modules/guest/guestList';
import VerifiedUserSharpIcon from '@material-ui/icons/VerifiedUserSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';

import { DuplicateLiveModal } from '../SessionsSectionComponent/DuplicateLiveModal/index';
import { useIsMobile } from '../../common/header/useIsMobile';
import { IinitialStateCounterPartInfo } from '../../redux-services/modules/guest/guestCounterPartGet';

const DetailsComponent = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const style = useStyles();
  const { liveDetails: live, loading } = useSelector<RootState, IinitialStateDetails>(
    (state) => state.liveSessionDetails,
  );
  const { id }: any = useParams();

  const isMobile = useIsMobile();

  const history = useHistory();

  const { loading: loadingListProduct, ListProduct } = useSelector<RootState, IinitialStateListProduct>(
    (state) => state.productList,
  );

  const { ListGuest, loading: GestOnLoading } = useSelector<RootState, IinitialStateListGuest>(
    (state) => state.guestList,
  );
  const { dataCounterPart, loading: loadingDataCounterPart } = useSelector<RootState, IinitialStateCounterPartInfo>(
    (state) => state.guestCountPartGet,
  );
  const data = useSelector<RootState, IinitialStateCounterPartInfo>((state) => state.guestCountPartGet);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(get_AllProduct(id));
      dispatch(get_AllGuest(id));
    }
  }, [id]);

  // console.log(`ListGuest`, ListGuest.);
  const DataProduct: IcardShooping[] = ListProduct.map((item) => {
    return {
      description: item.label,
      pathProduct: item.mainImageUrl,
      price: item.priceAfterDiscount,
    };
  });

  const onCopyLive = () => (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    console.log('Clciked');
  };

  const goToLiveEdit = () => {
    history.push(`/dashboard/livesessions/${live.id}/update`);
  };
  const goToProduct = () => {
    history.push(`/dashboard/livesessions/${live.id}/products`);
  };
  const goToCounterParty = () => {
    history.push(`/dashboard/livesessions/${live.id}/parametre`);
  };
  const inviteVIP = () => {
    history.push(`/dashboard/livesessions/${live.id}/invitations`);
  };

  // [
  //   { devise: '$', description: 'Correcteur de teint bio 02', pathProduct, price: 15 },
  //   { devise: '$', description: 'Correcteur de pied bio 02', pathProduct, price: 18 },
  //   { devise: '$', description: 'Correcteur de tache bio', pathProduct, price: 19 },
  //   { devise: '$', description: 'Correcteur de acné bio prenium', pathProduct, price: 12 },
  //   { devise: '$', description: 'Correcteur de yeux rouge', pathProduct, price: 11 },
  //   { devise: '$', description: 'Correcteur de gros levre bio 02', pathProduct, price: 18 },
  //   { devise: '$', description: 'Correcteur de gros levre bio 02', pathProduct, price: 18 },
  //   { devise: '$', description: 'Correcteur de gros levre bio 02', pathProduct, price: 18 },
  //   { devise: '$', description: 'Correcteur de gros levre bio 02', pathProduct, price: 18 },

  // ];
  return (
    <>
      <Box
        style={
          isMobile ? { marginTop: '-50px', marginBottom: '150px', paddingLeft: '20px !important', width: '100vw' } : {}
        }
      >
        {!isMobile && (
          <Typography
            style={{
              fontSize: isMobile ? 18 : '1.875rem',
              lineHeight: '2.313rem',
              fontWeight: 'bold',
              marginBottom: '47px',
            }}
          >
            {t('details.live')}{' '}
            <Box
              component="button"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 14,
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
                borderRadius: 5,
                fontFamily: 'Open Sans',
                fontWeight: 400,
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                marginTop: 2,
              }}
              className="copy"
              onClick={onCopyLive()}
            >
              <FileCopyIcon />
            </Box>
          </Typography>
        )}
        <Grid container spacing={1} style={{ width: isMobile ? '100%' : 'auto' }}>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={style.left}>
              {isMobile && (
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                    marginTop: 8,
                    paddingLeft: 15,
                    paddingRight: 10,
                  }}
                >
                  <Grid item style={{ margin: 0 }}>
                    <Typography>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>Infos du live </span>
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingRight: 20 }}>
                    <Button
                      onClick={goToLiveEdit}
                      style={{ backgroundColor: '#010101', color: '#ffffff', fontSize: 14 }}
                    >
                      Modifier
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Box style={{ marginBottom: '30px' }}>
                <Typography className={style.subTitle}>{t('details.name')}</Typography>
                <Typography className={style.normaText}>{live.title}</Typography>
              </Box>
              {isMobile ? (
                <>
                  <Box style={{ marginBottom: '30px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={style.subTitle}>{t('details.date')}</Typography>
                        <Typography className={style.normaText}>
                          {moment(live.scheduledStart).format('DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={style.subTitle}>{t('details.time')}</Typography>
                        <Typography className={style.normaText}>
                          {moment(live.scheduledStart).format('HH:mm')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginBottom: '30px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={style.subTitle}>{t('details.timezone')}</Typography>
                        <Typography className={style.normaText}>{live.timeZoneName}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={style.subTitle}>{t('details.status')}</Typography>
                        <Typography className={style.normaText}>
                          {live.privacy === 'Public' ? t('formulaire.status.public') : t('formulaire.status.private')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ marginBottom: '30px' }}>
                    <Typography className={style.subTitle}>{t('details.date')}</Typography>
                    <Typography className={style.normaText}>
                      {moment(live.scheduledStart).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: '30px' }}>
                    <Grid container spacing={2}>
                      <Grid item md>
                        <Typography className={style.subTitle}>{t('details.time')}</Typography>
                        <Typography className={style.normaText}>
                          {moment(live.scheduledStart).format('HH:mm')}
                        </Typography>
                      </Grid>
                      <Grid item md>
                        <Typography className={style.subTitle}>{t('details.timezone')}</Typography>
                        <Typography className={style.normaText}>{live.timeZoneName}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginBottom: '30px' }}>
                    <Grid container spacing={2}>
                      <Grid item md>
                        <Typography className={style.subTitle}>{t('details.status')}</Typography>
                        <Typography className={style.normaText}>
                          {live.privacy === 'Public' ? t('formulaire.status.public') : t('formulaire.status.private')}
                        </Typography>
                      </Grid>
                      <Grid item md>
                        {/* // <Typography className={style.subTitle}>Type</Typography>
                    // <Typography className={style.normaText}>{'- -'}</Typography> */}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={style.middle}>
              <Box style={{ flexDirection: 'column', display: 'flex' }}>
                <Box>
                  <Typography component="p" variant="h5">
                    {t('details.miniature')}
                  </Typography>
                </Box>
                <Box style={{ maxWidth: isMobile ? '98vw' : 'auto', marginTop: 20 }}>
                  <ImageSliderComponent
                    slidesToShow={2}
                    images={[live.previewImageUrl, live.pausedLiveImageUrl, live.endedLiveImageUrl]}
                  />
                </Box>
              </Box>

              {!isMobile && (
                <Box style={{ marginTop: '20px' }}>
                  <Typography component="p" variant="h5">
                    {t('details.invitation.text')}
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: '#F9FAF9',
                      borderRadius: '5px',
                      marginTop: '20px',
                      padding: '20px',
                      height: '200px',
                      overflow: 'auto',
                    }}
                  >
                    <Box>
                      {ListGuest.map((item, indexKey) => {
                        return (
                          <Box
                            key={indexKey}
                            style={{
                              display: 'flex',
                              // alignItems: 'flex-start',
                              // justifyContent: 'flex-end',
                              flexDirection: 'row',
                            }}
                          >
                            <Typography className={style.normaText}>{item.emailAddress}</Typography>
                            {item.invitationSendingDateTime ? (
                              <VerifiedUserSharpIcon color={'primary'} />
                            ) : (
                              <CancelSharpIcon color={'primary'} />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {isMobile && (
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 8,
              marginTop: 8,
              paddingLeft: 15,
              paddingRight: 10,
            }}
          >
            <Grid item style={{ margin: 0 }}>
              <Typography>
                <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {t('details.product.sales') + `(${DataProduct.length})`}
                </span>
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: 20 }}>
              <Button onClick={goToProduct} style={{ backgroundColor: '#010101', color: '#ffffff', fontSize: 14 }}>
                Modifier
              </Button>
            </Grid>
          </Grid>
        )}
        {!isMobile && <Typography variant="h5">{t('details.product.sales') + `(${DataProduct.length})`}</Typography>}
        <ProductShare {...{ data: DataProduct }} />
        {isMobile && (
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 8,
              marginTop: 8,
              paddingLeft: 15,
              paddingRight: 10,
            }}
          >
            <Grid item style={{ margin: 0 }}>
              <Typography>
                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Countreparties VIP</span>
              </Typography>
              {dataCounterPart && dataCounterPart.length > 0 ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : loadingDataCounterPart ? (
                <CircularProgress color="primary" />
              ) : (
                <span style={{ fontWeight: 'bold', color: 'rgb(1 1 1 / 35%)' }}>Pas de conterparties</span>
              )}
            </Grid>
            <Grid item style={{ paddingRight: 20 }}>
              <Button onClick={goToCounterParty} style={{ backgroundColor: '#010101', color: '#ffffff', fontSize: 14 }}>
                Modifier
              </Button>
            </Grid>
          </Grid>
        )}

        {isMobile && (
          <Box style={{ marginTop: '20px', ...(/apple/i.test(navigator.vendor) ? { marginBottom: 200 } : {}) }}>
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 8,
                marginTop: 8,
                paddingLeft: 15,
                paddingRight: 10,
              }}
            >
              <Grid item style={{ margin: 0 }}>
                <Typography>
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>{t('details.invitation.text')}</span>
                </Typography>
              </Grid>
              <Grid item style={{ paddingRight: 20 }}>
                <Button onClick={inviteVIP} style={{ backgroundColor: '#010101', color: '#ffffff', fontSize: 14 }}>
                  Modifier
                </Button>
              </Grid>
            </Grid>
            <Box
              style={{
                backgroundColor: '#F9FAF9',
                borderRadius: '5px',
                marginTop: '20px',
                marginBottom: '20px',
                padding: '20px',
                height: '200px',
                overflow: 'auto',
              }}
            >
              <Box>
                {ListGuest.map((item, indexKey) => {
                  return (
                    <Box
                      key={indexKey}
                      style={{
                        display: 'flex',
                        // alignItems: 'flex-start',
                        // justifyContent: 'flex-end',
                        flexDirection: 'row',
                      }}
                    >
                      <Typography className={style.normaText}>{item.emailAddress}</Typography>
                      {item.invitationSendingDateTime ? (
                        <VerifiedUserSharpIcon color={'primary'} />
                      ) : (
                        <CancelSharpIcon color={'primary'} />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}

        {open && <DuplicateLiveModal id={id} open={open} setOpen={setOpen} />}
      </Box>
    </>
  );
};

export default DetailsComponent;
