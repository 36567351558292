import { typedAction } from '../../utils';

export const LIST_GUEST_REQUEST = 'LIST/GUEST/REQUEST';
export const LIST_GUEST_SUCCESS = 'LIST/GUEST/SUCCESS';
export const LIST_GUEST_FAILED = 'LIST/GUEST/FAILED';

export interface IdataGuest {
  id: string;
  emailAddress: string;
  invitationSendingDateTime: string;
  liveSessionId: string;
  advantageCode?: string;
  shoppingUrl?: string;
}

// export interface Ierror {
//   message: string;
//   status: number | undefined;
// }

export interface IinitialStateListGuest {
  ListGuest: IdataGuest[];
  loading: boolean;
  error: string | undefined;
  status: number | undefined;
}

const initialState: IinitialStateListGuest = {
  ListGuest: [],
  status: undefined,
  error: undefined,
  loading: false,
};

export const get_AllGuest = (idSession: string) => typedAction(LIST_GUEST_REQUEST, idSession);
export type Get_AllGuest = typeof get_AllGuest;

export const get_allGuest_Success = (data: IdataGuest[]) => typedAction(LIST_GUEST_SUCCESS, data);
export type Get_allGuest_Success = typeof get_allGuest_Success;

export const get_allGuest_failed = (args: { status: number; error: string }) => typedAction(LIST_GUEST_FAILED, args);
export type Get_allGuest_failed = typeof get_allGuest_failed;

export type UpdateGuestAction = ReturnType<Get_AllGuest | Get_allGuest_Success | Get_allGuest_failed>;

export const ListGuestReducers = (
  state: IinitialStateListGuest = initialState,
  action: UpdateGuestAction,
): IinitialStateListGuest => {
  switch (action.type) {
    case LIST_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        ListGuest: action.payload,
      };
    case LIST_GUEST_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
