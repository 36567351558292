import axios, { AxiosPromise } from 'axios';
import { IParamsGetClickRecordProduct } from '../modules/product/participantProductClickRecord';

export interface IparamsUpdate {
  id: string;
  body: any;
}

export const CreateProduct = (data: any): AxiosPromise<any> => {
  return axios.post('/Retailer/Product/Create', data);
};

export const UpdateProduct = (params: IparamsUpdate): AxiosPromise<any> =>
  axios.post(`/Retailer/Product/Update/${params.id}`, params.body);

export const ProductList = (idSession: string): AxiosPromise<any> =>
  axios.get(`/Retailer/Product/List?liveSessionId=${idSession}`);

export const ProductById = (id: string): AxiosPromise<any> => axios.get(`/Retailer/Product/Get/${id}`);

export const DeleteProduct = (id: string): AxiosPromise<any> => axios.delete(`/Retailer/Product/Delete/${id}`);

export const ParticipantProductClickRecord = (param: IParamsGetClickRecordProduct): AxiosPromise<any> =>
  axios.get(`/${param.userType}/ParticipantProductClickRecord/List?liveSessionId=${param.liveId}`);
