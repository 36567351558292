import { typedAction } from '../../../utils';

export interface IParamConfirmationAccountConsumer {
  consumerId: string;
  securityStamp: string;
}

export const CONFIRMATION_ACCOUNT_CONSUMER_REQUESTED = 'CONFIRMATION_ACCOUNT/CONSUMER/REQUESTED';
export const CONFIRMATION_ACCOUNT_CONSUMER_SUCCESS = 'CONFIRMATION_ACCOUNT/CONSUMER/SUCCESS';
export const CONFIRMATION_ACCOUNT_CONSUMER_FAILED = 'CONFIRMATION_ACCOUNT/CONSUMER/FAILED';

export interface IinitialstateConfirmationAccountConsumer {
  token: any;
  loading: boolean;
}

const initialStateConfirmationAccountConsumer: IinitialstateConfirmationAccountConsumer = {
  token: '',
  loading: false,
};

export const DO_confirmation_account_consumer = (data: IParamConfirmationAccountConsumer) =>
  typedAction(CONFIRMATION_ACCOUNT_CONSUMER_REQUESTED, data);
export type IDO_confirmation_account_consumer = typeof DO_confirmation_account_consumer;

export const ConfirmationAccount_consumer_succefull = (data?: any) =>
  typedAction(CONFIRMATION_ACCOUNT_CONSUMER_SUCCESS, data);
export type IConfirmationAccount_consumer_success = typeof ConfirmationAccount_consumer_succefull;

export const ConfirmationAccount_consumer_failed = () => typedAction(CONFIRMATION_ACCOUNT_CONSUMER_FAILED);
export type IConfirmationAccount_consumer_failed = typeof ConfirmationAccount_consumer_failed;

export type ConfirmationAccountConsumerAction = ReturnType<
  IDO_confirmation_account_consumer | IConfirmationAccount_consumer_failed | IConfirmationAccount_consumer_success
>;

export const ConfirmationAccount_consumerReducer = (
  state: IinitialstateConfirmationAccountConsumer = initialStateConfirmationAccountConsumer,
  action: ConfirmationAccountConsumerAction,
): IinitialstateConfirmationAccountConsumer => {
  switch (action.type) {
    case CONFIRMATION_ACCOUNT_CONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CONFIRMATION_ACCOUNT_CONSUMER_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false,
      };
    case CONFIRMATION_ACCOUNT_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
