import { put, call, takeEvery, delay } from 'redux-saga/effects';
import { GuestConsumerFailed, GuestConsumerSuccess, GUEST_CONSUMER_REQUESTED } from '../../modules/guest/guestConsumer';
import Api from '../../api';
import { logout } from '../../modules/logout';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { GetAdvantageCode } from '../../modules/participant/advantageCode';
import { checkHistoryListenerSuccess } from '../../modules/listenerHistory';
import { BindConsumer } from '../../modules/participant/bindConsumer';
import { ErrorFunction } from '../utils/function';

export function* watchGuestConsumer() {
  yield takeEvery(GUEST_CONSUMER_REQUESTED, GuestConsumerFunction);
}
export function* GuestConsumerFunction({ payload }: any) {
  try {
    // bindConsumer participant
    const { status } = yield call(Api.participant.BindConsumer, payload.participantId);

    yield delay(100);

    if (status === 200) {
      // /Consumer/Guest/BindToConsumer
      const { status: StatusBindToConsumer } = yield call(Api.guest.GuestConsumer, payload.idInvitation);

      yield delay(100);

      if (StatusBindToConsumer === 200) {
        // get status and viewers participant
        const { data: viewers, status: statusViewers } = yield call(Api.listener.checkHistoryViewersListener, {
          userType: 'Consumer',
          id: `${payload.liveId}?participantId=${payload.participantId}`,
        });
        const { data: messages } = yield call(Api.listener.checkHistoryMessagesListener, {
          userType: 'Consumer',
          id: `${payload.liveId}?participantId=${payload.participantId}`,
        });
        const _data = {
          viewers: viewers ? viewers : [{ id: '', name: '' }],
          messages: messages
            ? messages
            : [
                {
                  dateTime: '',
                  participantId: '',
                  participantName: '',
                  text: '',
                },
              ],
        };
        yield put(checkHistoryListenerSuccess(_data));

        yield delay(100);

        if (statusViewers === 200) {
          // get code advantage
          yield put(GetAdvantageCode(payload.participantId));
          yield put(GuestConsumerSuccess());
        }
      }
    }
  } catch (e: any) {
    yield put(GuestConsumerFailed());
    yield call(ErrorFunction, e);
  }
}
