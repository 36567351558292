import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  listEndedLiveFailed,
  listEndedLiveSuccess,
  LIST_ENDED_SECTION_REQUEST,
} from '../modules/liveSessionRetailerListEnded';
import { logout } from '../modules/logout';
import { Set_state_snackbar } from '../modules/state/stateMessage';

export function* watchliveSessionEndedSection() {
  yield takeEvery(LIST_ENDED_SECTION_REQUEST, liveSessionEndedSectionSaga);
}

export function* liveSessionEndedSectionSaga() {
  try {
    const { data } = yield call(api.liveSessionRetailer.liveSessionRetailerListEndedSection);
    yield put(listEndedLiveSuccess(data));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: error.status, message: 'Bad request', open: true, type: 'error' }));
          yield put(listEndedLiveFailed({ status, error: 'Bad request' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: error.status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(listEndedLiveFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(logout());
          break;
        default:
          yield put(listEndedLiveFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            yield put(
              listEndedLiveFailed({ error: 'Network error, please check your internet connection', status: 504 }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            yield put(
              listEndedLiveFailed({ error: 'Network error, please check your internet connection', status: 504 }),
            );
            break;
        }
      }
    }
  }
}
