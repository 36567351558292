import { typedAction } from '../../utils';

export const GET_CLICK_RECORD_PRODUCT_REQUEST = 'GET_CLICK_RECORD/PRODUCT/REQUEST';
export const GET_CLICK_RECORD_PRODUCT_SUCCESS = 'GET_CLICK_RECORD/PRODUCT/SUCCESS';
export const GET_CLICK_RECORD_PRODUCT_FAILED = 'GET_CLICK_RECORD/PRODUCT/FAILED';

export interface IParamsGetClickRecordProduct {
  liveId: string;
  userType: string;
}

export interface IdataClickRecordProduct {
  id: string;
  dateTime: Date;
  participantId: string;
  participantName: string;
  consumerId: string;
  consumerLastName: string;
  consumerFirstName: string;
  productId: string;
  productLabel: string;
}
export interface Ierror {
  message: string;
  status: number | undefined;
}

export interface IinitialStateClickRecordProductByLiveId {
  ListDataClickRecordProducts: IdataClickRecordProduct[];
  loading: boolean;
  message: string;
  status: number | undefined;
}

const initialState: IinitialStateClickRecordProductByLiveId = {
  ListDataClickRecordProducts: [],
  status: undefined,
  loading: false,
  message: '',
};

export const get_ClickRecordProductByLiveId = (param: IParamsGetClickRecordProduct) =>
  typedAction(GET_CLICK_RECORD_PRODUCT_REQUEST, param);
export type Iget_ClickRecordProductByLiveId = typeof get_ClickRecordProductByLiveId;

export const get_ClickRecordProductByLiveIdSuccess = (data: IdataClickRecordProduct[]) =>
  typedAction(GET_CLICK_RECORD_PRODUCT_SUCCESS, data);
export type Iget_ClickRecordProductByLiveIdSuccess = typeof get_ClickRecordProductByLiveIdSuccess;

export const get_ClickRecordProductByLiveIdFailed = (args: Ierror) =>
  typedAction(GET_CLICK_RECORD_PRODUCT_SUCCESS, args);
export type Iget_ClickRecordProductByLiveIdFailed = typeof get_ClickRecordProductByLiveIdFailed;

type ProductAction = ReturnType<
  Iget_ClickRecordProductByLiveId | Iget_ClickRecordProductByLiveIdSuccess | Iget_ClickRecordProductByLiveIdFailed
>;

export const ClickRecordProductReducersByLiveId = (
  state: IinitialStateClickRecordProductByLiveId = initialState,
  action: ProductAction,
): IinitialStateClickRecordProductByLiveId => {
  switch (action.type) {
    case GET_CLICK_RECORD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
      };
    case GET_CLICK_RECORD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        ListDataClickRecordProducts: action.payload,
        message: '',
      };
    case GET_CLICK_RECORD_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
