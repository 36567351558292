import { typedAction } from '../utils';

export interface GallerieParams {
  index: number;
  size: number;
}

export interface ErrorDisplayGallerie {
  error: string;
  status: number;
}

export interface IGallerieState {
  items: [
    {
      id: string;
      creationDate: string;
      fileName: string;
      mimeType: string;
      height: number;
      width: number;
      url: string;
    },
  ];
  pageInfo: {
    index: number;
    size: number;
  };
  totalCount: number;
  pageCount: number;
}

export interface IstateGallerieInitial {
  status: number | undefined;
  loading: boolean;
  error: string;
  gallerie: IGallerieState;
}

const stateInitial: IstateGallerieInitial = {
  error: '',
  status: undefined,
  loading: false,
  gallerie: {
    items: [
      {
        id: 'string',
        creationDate: new Date().toISOString(),
        fileName: 'string',
        mimeType: 'string',
        height: 0,
        width: 0,
        url: 'string',
      },
    ],
    pageCount: 0,
    pageInfo: { index: 0, size: 0 },
    totalCount: 0,
  },
};

export const GET_GALLERIE_REQUEST = 'GET_GALLERIE_REQUEST';
export const GET_GALLERIE_SUCCESS = 'GET_GALLERIE_SUCCESS';
export const GET_GALLERIE_FAILED = 'GET_GALLERIE_FAILED';

export const getlistgallerie = (params: GallerieParams) => typedAction(GET_GALLERIE_REQUEST, params);
export type Getlistgallerie = typeof getlistgallerie;

export const getgallerieSuccess = (result: IGallerieState) => typedAction(GET_GALLERIE_SUCCESS, result);
export type GetgallerieSuccess = typeof getgallerieSuccess;

export const getgallerieFailed = (error: ErrorDisplayGallerie) => typedAction(GET_GALLERIE_FAILED, error);
export type GetgallerieFailed = typeof getgallerieFailed;

export type gallerieAction = ReturnType<Getlistgallerie | GetgallerieSuccess | GetgallerieFailed>;

export const GallerieReducer = (
  state: IstateGallerieInitial = stateInitial,
  action: gallerieAction,
): IstateGallerieInitial => {
  switch (action.type) {
    case GET_GALLERIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GALLERIE_SUCCESS:
      return {
        ...state,
        loading: false,
        gallerie: action.payload,
        error: '',
        status: 200,
      };
    case GET_GALLERIE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
