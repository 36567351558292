export default {
  'apercu.apercu.text': 'Aperçu du live',
  'apercu.visible.info': 'Informations visibles ',
  'apercu.chat': 'Chat du live',
  'apercu.product.sales': 'Produit à la vente',
  'apercu.actuality.text': 'Actualités',
  'apercu.product.btn.text.select': 'Tout sélectionner',
  'apercu.product.btn.text.unselect': 'Tout désélectionner',
  'apercu.product.btn.text.share': 'Partager sélectionnés',
  'apercu.text.productShare':
    'Sélectionnez les produits au fur et à mesure de leur apparition sur le live. S’ils sont tous présents dès le début, alors les pré-sélectionner tous avant le lancement du live.',
};
