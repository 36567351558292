import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import MyCardShopping, { IcardShooping } from '../../../common/MyCardShopping/MyCardShopping';
import { RootState } from '../../../redux-services';
import { get_AllProduct, IinitialStateListProduct } from '../../../redux-services/modules/product/productList';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../../Themes';
import {
  IinitialStateClickRecordProductByLiveId,
  get_ClickRecordProductByLiveId,
} from '../../../redux-services/modules/product/participantProductClickRecord';
import { IActus } from '../../../redux-services/type/actus';

interface IListProductsProps {
  productsShare: (products: string[]) => void;
  id?: string;
}

const ProductRetailerMobile: React.FC<IListProductsProps> = (props: IListProductsProps) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { productsShare, id } = props;
  const dispatch = useDispatch();

  const [selectAllProducts, setSelectAllProducts] = useState<boolean>(false);
  const [productsShared, setProductsShared] = useState<string[]>([]);
  const { ListProduct } = useSelector<RootState, IinitialStateListProduct>((state) => state.productList);
  const [actualitys, setActualitys] = useState<IActus[]>([]);

  const { ListDataClickRecordProducts, loading: loadingListDataClickRecordProducts } = useSelector<
    RootState,
    IinitialStateClickRecordProductByLiveId
  >((state) => state.listClickRecordProductByLiveId);

  const DataProducts: IcardShooping[] = useMemo(
    () =>
      ListProduct.map((item) => {
        return {
          id: item.id,
          description: item.label,
          pathProduct: item.mainImageUrl,
          price: item.priceAfterDiscount,
          activePartage: true,
        };
      }),
    [ListProduct],
  );

  const [productsSelected, setProductsSelected] = useState<string[]>(
    DataProducts.reduce<string[]>((acc, product) => {
      if (product?.id) {
        acc.push(product.id);
      }
      return acc;
    }, []),
  );

  console.log('ListProduct', ListProduct);

  useEffect(() => {
    if (!loadingListDataClickRecordProducts && ListDataClickRecordProducts.length > 0) {
      setActualitys(
        ListDataClickRecordProducts.map((_historyActu) => ({
          productId: _historyActu.productLabel,
          participantId: _historyActu.participantId,
          participantName: _historyActu.participantName,
        })),
      );
    }
  }, [loadingListDataClickRecordProducts]);

  useEffect(() => {
    if (DataProducts.length && productsSelected.length === DataProducts.length) {
      setProductsSelected([]);
    } else {
      if (DataProducts.length && productsSelected.length !== DataProducts.length) {
        setProductsSelected(
          DataProducts.reduce<string[]>((acc, product) => {
            if (product?.id) {
              acc.push(product.id);
            }
            return acc;
          }, []),
        );
      }
    }
  }, [selectAllProducts]);

  useEffect(() => {
    if (ListProduct.length) {
      const dataSelected = ListProduct.filter((p) => p.isVisible)?.map((_p) => _p.id);
      if (dataSelected.length) {
        setProductsSelected(dataSelected);
        setProductsShared(dataSelected);
      }
    }
  }, [ListProduct]);

  useEffect(() => {
    if (id) {
      dispatch(get_AllProduct(id));
      dispatch(get_ClickRecordProductByLiveId({ liveId: id, userType: 'Retailer' }));
    }
  }, [id]);

  return (
    <Box className={classes.boxComponentbtnProduct}>
      <Box className={classes.RootBtnShareProduct}>
        <Button
          style={{
            backgroundColor:
              JSON.stringify(productsSelected) !== JSON.stringify(productsShared) ? '#222222' : '#D8D8D8',
            color: 'white',
          }}
          disabled={JSON.stringify(productsSelected) === JSON.stringify(productsShared)}
          className={classes.btnShare}
          onClick={() => {
            productsShare(productsSelected);
            setProductsShared(productsSelected);
          }}
        >
          {t('apercu.product.btn.text.share')}
        </Button>
        <Button
          style={{
            backgroundColor: 'transparent',
            color: theme.palette.common.black,
          }}
          className={classes.btnShare}
          onClick={() => {
            setSelectAllProducts(!selectAllProducts);
          }}
        >
          {productsSelected.length === DataProducts.length
            ? t('apercu.product.btn.text.unselect')
            : t('apercu.product.btn.text.select')}
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom="120px">
        {DataProducts.map((item, indexKey: number) => {
          return (
            <MyCardShopping
              key={indexKey}
              {...{
                ...item,
                // onSelectProducts,
                selectAllProducts,
                nbAll: DataProducts.length,
                nbSelected: productsSelected.length,
                pathProduct: item.pathProduct,
                productsSelected,
                setProductsSelected,
                setSelectAllProducts,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ProductRetailerMobile;
