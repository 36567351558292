import { call, delay, put, takeEvery } from 'redux-saga/effects';
import initAdaptor from '../../WebRTC/adaptorPublisher';
import Api from '../api';
import { liveSessionStarted, liveSessionStartFailed, LIVE_SESSION_START_REQUEST } from '../modules/liveSessionStart';
import { logout } from '../modules/logout';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction } from './utils/function';

export function* watchliveSessionStart() {
  yield takeEvery(LIVE_SESSION_START_REQUEST, liveSessionStart);
}

export function* liveSessionStart({ payload }: any) {
  try {
    const { status } = yield call(Api.liveSessionRetailer.liveSessionStart, payload.idLive);
    yield put(liveSessionStarted({ status, adaptor: payload.webRTCAdaptor }));
    yield put(
      Set_state_snackbar({
        message: 'Votre live a été demarré avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(liveSessionStartFailed({ status, error: 'bad request' }));
          yield put(Set_state_snackbar({ status: error.status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: error.status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(liveSessionStartFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: error.status,
              message:
                'Pour démarrer le live, veuillez le valider en allant dans « Modifiez les infos du live » et en cochant « Publié »',
              open: true,
              type: 'error',
            }),
          );
          yield put(liveSessionStartFailed({ status, error: 'Seule la session publiée peut être démarrée' }));
          break;
        default:
          yield put(
            Set_state_snackbar({
              status: error.status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(liveSessionStartFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(liveSessionStartFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(liveSessionStartFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
