import { typedAction } from '../utils';

export interface IparamsId {
  id: string;
}
export interface IerrorLiveStart {
  error: string;
  status: number | undefined;
}

export interface IinitialStateLiveStart {
  adaptorAntMedia: any;
  liveStarted: boolean;
  loading: boolean;
  error: string;
  status: number | undefined;
}

const initialStateLiveStart: IinitialStateLiveStart = {
  adaptorAntMedia: undefined,
  liveStarted: false,
  error: '',
  status: undefined,
  loading: false,
};
export const LIVE_SESSION_START_REQUEST = 'LIVE_SESSION_START_REQUEST';
export const LIVE_SESSION_START_SUCCESS = 'LIVE_SESSION_START_SUCCESS';
export const INIT_LIVE_SESSION_START = 'LIVE_SESSION_START_INIT';
export const LIVE_SESSION_START_FAILED = 'LIVE_SESSION_START_FAILED';

export const doStartLiveSession = (id: { idLive: string; webRTCAdaptor: any }) =>
  typedAction(LIVE_SESSION_START_REQUEST, id);
export type DoStartLiveSession = typeof doStartLiveSession;

export const liveSessionStarted = (result: { status: number; adaptor: any }) =>
  typedAction(LIVE_SESSION_START_SUCCESS, result);
export type LiveSessionStarted = typeof liveSessionStarted;

export const initLiveSessionStarted = () => typedAction(INIT_LIVE_SESSION_START);
export type InitLiveSessionStarted = typeof initLiveSessionStarted;

export const liveSessionStartFailed = (error: IerrorLiveStart) => typedAction(LIVE_SESSION_START_FAILED, error);
export type LiveSessionStartFailed = typeof liveSessionStartFailed;

export type LiveSessionStartAction = ReturnType<
  DoStartLiveSession | LiveSessionStarted | LiveSessionStartFailed | InitLiveSessionStarted
>;

export const DoLiveSessionStartReducer = (
  state: IinitialStateLiveStart = initialStateLiveStart,
  action: LiveSessionStartAction,
): IinitialStateLiveStart => {
  switch (action.type) {
    case LIVE_SESSION_START_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIVE_SESSION_START_SUCCESS:
      return {
        ...state,
        liveStarted: true,
        loading: false,
        status: action.payload.status,
        adaptorAntMedia: action.payload.adaptor,
        error: '',
      };
    case INIT_LIVE_SESSION_START:
      return initialStateLiveStart;
    case LIVE_SESSION_START_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return {
        ...state,
      };
  }
};
