import { put } from 'redux-saga/effects';
import { logout } from '../../modules/logout';
import { Set_state_snackbar } from '../../modules/state/stateMessage';

export function* ErrorFunction(error: any) {
  const errorText = error.response.data.errors;
  if (error.response) {
    const { status, title, statusText } = error.response;
    const resultError = error.response.data.errors
      ? (Object.values(error.response.data.errors)[0] as any)[0]
      : error.response.data.title;
    switch (status) {
      case 400:
        yield put(
          Set_state_snackbar({
            status: status,
            message: resultError ?? title ?? statusText,
            open: true,
            type: 'error',
          }),
        );
        break;
      case 401:
        yield put(
          Set_state_snackbar({
            status: status,
            message: resultError ?? title ?? statusText,
            open: true,
            type: 'error',
          }),
        );
        yield put(logout());
        break;
      default:
        yield put(
          Set_state_snackbar({
            status: status,
            message: resultError ?? title ?? statusText,
            open: true,
            type: 'error',
          }),
        );
        break;
    }
  } else {
    const { message } = error;
    const resultError = (Object.values(errorText)[0] as any)[0] ? (Object.values(errorText)[0] as any)[0] : message;
    if (message) {
      switch (message) {
        case 'Network Error':
          yield put(
            Set_state_snackbar({
              status: 503,
              message: resultError,
              open: true,
              type: 'error',
            }),
          );
          break;
        default:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: resultError,
              open: true,
              type: 'error',
            }),
          );
          break;
      }
    }
  }
}

export function* ErrorFunctionUtils(error: { message: string; status: number }) {
  if (error) {
    const { status, message } = error;
    switch (status) {
      case 400:
        yield put(
          Set_state_snackbar({
            status: status,
            message,
            open: true,
            type: 'error',
          }),
        );
        break;
      case 401:
        yield put(
          Set_state_snackbar({
            status: status,
            message,
            open: true,
            type: 'error',
          }),
        );
        yield put(logout());
        break;
      case 500:
        yield put(
          Set_state_snackbar({
            status: 503,
            message: message,
            open: true,
            type: 'error',
          }),
        );
        break;
      default:
        yield put(
          Set_state_snackbar({
            status: status,
            message,
            open: true,
            type: 'error',
          }),
        );
        break;
    }
  } else {
    const { message } = error;

    if (message) {
      switch (message) {
        case 'Network Error':
          yield put(
            Set_state_snackbar({
              status: 503,
              message: message,
              open: true,
              type: 'error',
            }),
          );
          break;
        default:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: message,
              open: true,
              type: 'error',
            }),
          );
          break;
      }
    }
  }
}
