import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfiels: {
      '& input': {
        // padding: '10px',
        fontSize: 14,
        // fontFamily: 'Open Sans',
      },
      '& > *': {
        margin: theme.spacing(1),
        // width: '25ch',
      },
    },
  }),
);
