import { typedAction } from '../../utils';

export interface InitialStateSnackBar {
  message: string;
  status?: number | undefined;
  open: boolean;
  type: 'error' | 'warning' | 'info' | 'success';
}

const initialStateSnackbar: InitialStateSnackBar = {
  message: '',
  status: undefined,
  open: false,
  type: 'warning',
};
export const STATE_SET_SNACK = 'STATE_SET_SNACK';
export const RESET_STATE_SNACK = 'RESET_STATE_SNACK';

export const Set_state_snackbar = (value: InitialStateSnackBar) => typedAction(STATE_SET_SNACK, value);
export type ISET_SNACKBAR = typeof Set_state_snackbar;
export const reset_SnackBar = () => typedAction(RESET_STATE_SNACK);
export type Ireset_SnackBar = typeof reset_SnackBar;

type SnackBarAction = ReturnType<ISET_SNACKBAR | Ireset_SnackBar>;

export const SnackBarReducers = (
  state: InitialStateSnackBar = initialStateSnackbar,
  action: SnackBarAction,
): InitialStateSnackBar => {
  switch (action.type) {
    case STATE_SET_SNACK:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        open: true,
        type: action.payload.type,
      };
    case RESET_STATE_SNACK:
      return {
        ...state,
        message: '',
        status: undefined,
        open: false,
      };
    default:
      return {
        ...state,
      };
  }
};
