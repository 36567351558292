export default {
  'question.title': 'Gestion des questions',
  'question.created': 'Question déjà créée',
  'question.updated': 'Modifier',
  'question.delete': 'Supprimer',
  'question.type': 'Type de choix :',
  'question.questionnaire': 'Nom de la Question : ',
  'question.questionnaireform': 'Nom de la Question',
  'question.name': 'Question',
  'question.type.question': 'Type de question',
  'question.add.response': 'Ajouter une reponse',
  'question.btn.validate': 'Valider',
  'question.btn.cancel': 'Annuler',
  'question.max.response': 'Reponses (2 minimum , 4 maximum)',
  'question.choice.only': 'Choix unique',
  'question.choice.multiple': 'choice Multiple',
};
