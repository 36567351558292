import axios, { AxiosPromise } from 'axios';
import { IJoinParams } from '../modules/liveSessionJoin';
import { IparamsPage } from '../modules/liveSessionPage';

export const liveSessionPage = (pageParams: IparamsPage): AxiosPromise<any> =>
  axios.get(
    `/Consumer/LiveSession/Page?sectionId=${pageParams.id}&pageIndex=${pageParams.index}&pageSize=${pageParams.size}`,
  );
export const liveSessionList = (): AxiosPromise<any> => axios.get(`/Consumer/LiveSessionSection/List`);

export const liveSessionConsumerGet = (id: string): AxiosPromise<any> => axios.get(`/Consumer/LiveSession/Get/${id}`);

export const liveSessionJoin = (joinPram: IJoinParams): AxiosPromise<any> =>
  axios.post(`/${joinPram.userType}/LiveSession/Join/${joinPram.id}`, { name: joinPram.name });

export const liveSessionConsumerProduct = (id: string): AxiosPromise<any> => {
  return axios.get(`/Consumer/LiveSession/Products/${id}`);
};
