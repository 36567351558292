import * as React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useStyles } from './style';

interface IButton {
  label: string;
  style?: string;
  onClick?: () => void;
  isDisabled?: boolean;
}
interface PropsTriButton {
  styleContainer?: string;
  button1: IButton;
  button2: IButton;
  button3: IButton;
}

const TriButton: React.FC<PropsTriButton> = (props: PropsTriButton) => {
  const { styleContainer, button1, button2, button3 } = props;
  const style = useStyles();
  return (
    <Grid className={`${style.root} ${styleContainer}`} spacing={2}>
      <Button
        variant="outlined"
        className={`${style.button1} ${button1.style}`}
        disabled={button1.isDisabled}
        onClick={button1.onClick}
      >
        {button1.label}
      </Button>
      <Button
        variant="outlined"
        className={`${style.button2} ${button2.style}`}
        disabled={button2.isDisabled}
        onClick={button2.onClick}
      >
        {button2.label}
      </Button>
      <Button
        variant="outlined"
        className={`${style.button3} ${button3.style}`}
        disabled={button3.isDisabled}
        onClick={button3.onClick}
      >
        {button3.label}
      </Button>
    </Grid>
  );
};

export default TriButton;
