import { IparamsAffiliation } from '../../api/participant';
import { typedAction } from '../../utils';

export const AFFILIATION_REQUESTED = 'AFFILIATION/REQUESTED';
export const AFFILIATION_SUCCESS = 'AFFILIATION/SUCCESS';
export const AFFILIATION_FAILED = 'AFFILIATION/FAILED';

interface IdoAffiliation {
  liveId: string;
  dataAffiliat: IparamsAffiliation;
}
export interface IinitialStateAffiliation {
  loading: boolean;
  data: any | undefined;
}

const initialStateAfiliation: IinitialStateAffiliation = {
  loading: false,
  data: undefined,
};
export const Do_Affiliation = (params: IdoAffiliation) => typedAction(AFFILIATION_REQUESTED, params);
export type IDo_Affilication = typeof Do_Affiliation;

export const Affiliation_Sucess = () => typedAction(AFFILIATION_SUCCESS);
export type IAffiliation_Success = typeof Affiliation_Sucess;

export const Affiliation_Failed = () => typedAction(AFFILIATION_FAILED);
export type IAffiliation_Failed = typeof Affiliation_Failed;

export type IAffiliationAction = ReturnType<IDo_Affilication | IAffiliation_Success | IAffiliation_Failed>;

export const AffiliationReducer = (
  state: IinitialStateAffiliation = initialStateAfiliation,
  action: IAffiliationAction,
): IinitialStateAffiliation => {
  switch (action.type) {
    case AFFILIATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case AFFILIATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AFFILIATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
