import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../../api';
import {
  AUTH_SETLOGO_RETAILER_REQUEST,
  setLogoRetailerFailed,
  setLogoRetailerSuccess,
} from '../../modules/account/setLogoRetailer';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import authSevices from '../../services/authSevices';

export function* watchSetLogoRetailer() {
  yield takeEvery(AUTH_SETLOGO_RETAILER_REQUEST, SetLogoRetailer);
}

function* SetLogoRetailer({ payload }: any) {
  try {
    const { data, status } = yield call(APi.account.setLogoRetailer, payload);
    const result = { ...data, status };

    if (result.status === 200) {
      yield authSevices.setLogoRetailer(payload.logoUrl ?? payload.galleryImageId);
      yield put(setLogoRetailerSuccess({ ...result, logoUrl: payload.logoUrl ?? payload.galleryImageId }));
    }
  } catch (e: any) {
    if (e.response) {
      const { status } = e.response;
      yield put(setLogoRetailerFailed({ status, message: e.message }));
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({ status: status, message: 'Votre session est expiré', open: true, type: 'error' }),
          );
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Wrong connexion', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = e;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
