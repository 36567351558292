import { typedAction } from '../../utils';

export const UPDATE_PASSWORD_REQUESTED = 'UPDATE/PASSWORD/REQUESTED';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE/PASSWORD/SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE/PASSWORD/FAILED';

export interface IparamsUpdatePassword {
  userType: string;
  password: string;
}

export interface InitialstateUpdatePassword {
  loading: boolean;
  status: number;
}

const initialState: InitialstateUpdatePassword = {
  loading: false,
  status: 0,
};

export const DO_updatePassword = (params: IparamsUpdatePassword) => typedAction(UPDATE_PASSWORD_REQUESTED, params);
export type IDO_updatePassword = typeof DO_updatePassword;

export const updatePasswordSuccefull = () => typedAction(UPDATE_PASSWORD_SUCCESS);
export type IupdatePasswordSuccefull = typeof updatePasswordSuccefull;

export const updatePasswordFailed = () => typedAction(UPDATE_PASSWORD_FAILED);
export type IupdatePasswordFailed = typeof updatePasswordFailed;

export type updatePasswordAction = ReturnType<IDO_updatePassword | IupdatePasswordSuccefull | IupdatePasswordFailed>;

export const updatePasswordReducer = (
  state: InitialstateUpdatePassword = initialState,
  action: updatePasswordAction,
): InitialstateUpdatePassword => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUESTED:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        status: 400,
      };
    default:
      return {
        ...state,
      };
  }
};
