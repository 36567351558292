import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: 70,
      paddingTop: 0,
      // height: '100%',
      '@media(max-width:460px)': {
        paddingLeft: 12,
        paddingRight: 8,
        marginTop: 20,
      },
      '@media(max-width: 1190px)': {
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
    contentPL: {
      '@media(max-width: 1280px)': {
        width: '100vw',
      },
    },
    left: {
      fontFamily: 'Poppins, sans-serif',
      '@media(max-width: 1190px)': {
        paddingLeft: 15,
        paddingTop: 30,
      },
    },
    middle: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Poppins, sans-serif',
      '& div .slick-slider': {
        marginLeft: '20px',
        marginRight: '60px',
      },
      '@media(max-width: 1190px)': {
        paddingLeft: 15,
        paddingTop: 30,
      },
    },
    miniatureCard: {
      backgroundColor: '#222222',
      height: '105px',
      width: '100%',
    },
    right: {
      fontFamily: 'Poppins, sans-serif',
    },
    timeContainer: {
      display: 'flex',
      fontFamily: 'Poppins, sans-serif',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
    },
    subTitle: {
      fontSize: '0.875rem',
      lineHeight: '1.063rem',
      fontWeight: 'bold',
      marginBottom: '0.313rem',
    },
    normaText: {
      fontSize: '1.25rem',
      lineHeight: '1.688rem',
      '@media(max-width:700px)': {
        fontSize: 14,
      },
    },
    content: {
      display: 'flex',
      width: '100%',
      '@media(max-width: 1190px)': {
        flexDirection: 'column',
      },
    },
    item: {
      width: '49%',
      marginRight: 8,
      '@media(max-width: 1190px)': {
        width: '100%',
        marginRight: 0,
        marginTop: 8,
      },
    },
    imageModal: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: 300,
      objectFit: 'none',
      backgroundSize: 'cover',
    },
    liste: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        padding: '10px 30px',
        width: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
      },
      '& li.action_rapides': {
        backgroundColor: '#222222',
        marginBottom: 14,
        color: 'white',
        borderRadius: 5,
      },
      '& li.action_rapides:hover': {
        backgroundColor: '#000000',
      },
      '& li.action_rapides:last-child': {
        marginBottom: 40,
      },
      '& li.acces_rapides': {
        paddingRight: 0,
      },
      '& li.acces_rapides:last-child': {
        marginBottom: 40,
      },
    },
    viewBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxHeight: 450,
      position: 'relative',
    },
    vipContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '66vh',
      width: '20vw',
      float: 'right',
      '@media(max-width: 960px)': {
        height: 'unset',
      },
    },

    bottomBtnVideoRender: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      bottom: 60,
    },
    btnConfCall: {
      width: '40px',
      height: '40px',
      background: 'rgb(106 106 106 / 85%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50px',
      cursor: 'pointer',
      marginRight: '20px',
    },
    btnConfCallDisabled: {
      width: '40px',
      height: '40px',
      background: 'rgba(231,3,3,0.82)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50px',
      cursor: 'pointer',
      marginRight: '20px',
    },
    iconBtnConfCall: {
      color: '#f8f7f7',
      fontSize: '30px',
    },
    wihoutVideoContainer: {
      width: '42vw',
      height: '60vh',
      backgroundColor: '#2a2121',
      '@media(max-width: 1280px)': {
        width: '61vw',
        height: '35vw',
      },
      '@media(max-width: 958px)': {
        width: '76vw',
        height: '42vw',
      },
    },
    viewersContainer: {
      width: '100%',
      height: '100%',
      marginTop: 20,
      overflowY: 'auto',
    },
    tabContentContainer: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    chatContainerMobile: {
      height: '100%',
      overflow: 'hidden',
      '& > div': {
        height: '100%',
        '& > h5': {
          display: 'none',
        },
        '& > div': {
          marginTop: 0,
          borderRadius: 0,
          minHeight: '100%',
          maxHeight: '100%',
          '& > div:nth-of-type(1)': {
            height: '15vh',
            marginBottom: 0,
            paddingTop: 15,
            paddingBottom: 90,
            overflowY: 'auto',
            display: 'flex!important',
            flexDirection: 'column-reverse',
          },
          '& > div:nth-of-type(2)': {
            '& > div:nth-of-type(1)': {
              display: 'none',
            },
            '& > div:nth-of-type(2)': {
              borderRadius: 0,
              position: 'fixed',
              width: '100%',
              bottom: '53px',
            },
          },
        },
      },
    },
    viewersContainerMobile: {
      '& > div': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > h5': {
          display: 'none',
        },
        '& > div': {
          marginTop: 0,
        },
      },
    },
    productsContainerMobile: {
      '& > div': {
        height: '100%',
      },
    },
    infosContainerMobile: {
      padding: '5px 10px 10px',
      '& > div': {
        width: '100%',
        '& > div:nth-of-type(1)': {
          '& > div:nth-of-type(2)': {
            '& *': {
              fontWeight: 'normal',
            },
          },
          '& > div:nth-of-type(3)': {
            display: 'none',
          },
        },
        '& > div:not(:nth-of-type(1))': {
          display: 'none',
        },
      },
    },
    tabsContainer: {
      width: '100%',
      display: 'none',
      flexGrow: 1,
      '@media(max-width:1180px)': {
        height: '100%',
        display: 'block',

        // Tabs root
        '& > div': {
          height: 'calc(100% - 70px)',
          // overflow: 'scroll',
          position: 'relative',
          // Tabpanels
          '& > div:not(:nth-last-of-type(1))': {
            width: '100%',
            // height: 'calc(100% - 51px)',
            // overflow: 'hidden',
            // position: 'absolute',
            // top: 0,
          },
        },
      },
      '& .MuiTabs-root': {
        width: '100%',
        minHeight: '51px',
        position: 'fixed',
        bottom: 0,
      },
    },
    mobileLiveStartLiveBlocButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    mobileLiveStartLiveBlocButtonsStart: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    goToAffiliation: {
      marginRight: 25,
      paddingTop: 20,
      cursor: 'pointer',
      '& > img ': {
        border: '1px solid #000000',
      },
    },
    goToAffiliationEnded: {
      marginLeft: 16,
      marginRight: '0px !important',
      paddingTop: 20,
      cursor: 'pointer',
      '& > img ': {
        border: '1px solid #000000',
      },
    },
  }),
);
