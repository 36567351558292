import { BooleanLiteral } from 'typescript';
import { typedAction } from '../utils';

export interface IResult {
  title: string;
  description: string;
  categoryId: string;
  scheduledStartDate: string;
  scheduledStartTime: number;
  timeZoneId: number;
  scheduledDuration: number;
  privacy: number;
  isPublished: boolean;
  previewImageId: string;
  pausedLiveImageId: string | null;
  endedLiveImageId: string | null;
}

export interface IparamsUpdate {
  id: string;
  body: IResult;
}

export interface IrrorUpdate {
  status: number;
  error: string;
}
export interface initialStateUpdate {
  loading: boolean;
  status: number | undefined;
  error: boolean | undefined;
  data: IResult;
}

const initialState: initialStateUpdate = {
  data: {
    title: '',
    description: '',
    categoryId: '',
    scheduledStartDate: '',
    scheduledStartTime: 0,
    timeZoneId: 0,
    scheduledDuration: 0,
    privacy: 0,
    isPublished: false,
    previewImageId: '',
    pausedLiveImageId: '',
    endedLiveImageId: '',
  },
  error: undefined,
  loading: false,
  status: undefined,
};

export const UPDATE_LIVE_SESSION_REQUEST = 'UPDATE_LIVE_SESSION_REQUEST';
export const UPDATE_LIVE_SESSION_SUCCESS = 'UPDATE_LIVE_SESSION_SUCCESS';
export const UPDATE_LIVE_SESSION_FAILED = 'UPDATE_LIVE_SESSION_FAILED';

export const update_live_session = (params: IparamsUpdate) => typedAction(UPDATE_LIVE_SESSION_REQUEST, params);
export type Update_live_session = typeof update_live_session;

export const update_live_session_success = (result: IResult) => typedAction(UPDATE_LIVE_SESSION_SUCCESS, result);
export type Update_live_session_success = typeof update_live_session_success;

export const update_live_session_failed = (error: IrrorUpdate) => typedAction(UPDATE_LIVE_SESSION_FAILED, error);
export type Update_live_session_failed = typeof update_live_session_failed;

export type UpdateLiveSessionAction = ReturnType<
  Update_live_session | Update_live_session_success | Update_live_session_failed
>;
export const UpdateLiveSessionReducer = (
  state: initialStateUpdate = initialState,
  action: UpdateLiveSessionAction,
): initialStateUpdate => {
  switch (action.type) {
    case UPDATE_LIVE_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        status: 200,
      };
    case UPDATE_LIVE_SESSION_FAILED:
      return {
        ...state,
        error: true,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
