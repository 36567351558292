import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './style';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import ScheduleEventIcon from '../ScheduleEventIcon/ScheduleEventIcon';
import ScheduleLiveIcon from '../ScheduleLiveIcon/ScheduleLiveIcon';
import StatisticsIcon from '../StatisticsIcon/StatisticsIcon';
import AccountIcon from '../AccountIcon/AccountIcon';
import { NavLink } from 'react-router-dom';

const MobileHomePage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.dashboardContainer}>
      <Box className={classes.mobileView}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <NavLink to={'/dashboard/create'}>
              <Paper className={classes.paper}>
                <Box>
                  <ScheduleEventIcon />
                </Box>
                <Typography>Programmer un live</Typography>
              </Paper>
            </NavLink>
          </Grid>
          <Grid item xs={6}>
            <NavLink to={'/dashboard/livesessions'}>
              <Paper className={classes.paper}>
                <Box>
                  <ScheduleLiveIcon />
                </Box>
                <Typography>Lives programmés</Typography>
              </Paper>
            </NavLink>
          </Grid>
          <Grid item xs={6}>
            <NavLink to={'/dashboard/stats'}>
              <Paper className={classes.paper}>
                <Box>
                  <StatisticsIcon />
                </Box>
                <Typography>Statistique</Typography>
              </Paper>
            </NavLink>
          </Grid>
          <Grid item xs={6}>
            <NavLink to={'/dashboard/account'}>
              <Paper className={classes.paper}>
                <Box>
                  <AccountIcon />
                </Box>
                <Typography>Mon compte</Typography>
              </Paper>
            </NavLink>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.desKtopView}>
        <EmptyComponent />
      </Box>
    </Box>
  );
};

export default MobileHomePage;
