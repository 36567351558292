export default {
  'formulaire.info': 'Live information',
  'formulaire.image.preview': 'Image preview',
  'formulaire.image.pause': 'Image du live en pause',
  'formulaire.image.fin': 'Image de fin du live',
  'formulaire.live.name': 'Nom du live',
  'formulaire.live.color': 'Couleur dominante du live',
  'formulaire.live.categorie': 'Catégorie de la vidéo',
  'formulaire.live.descriptions': 'Descriptions',
  'formulaire.live.date': 'Date',
  'formulaire.live.time': 'Heure',
  'formulaire.live.fuseau.horaire': 'Fuseau horaire',
  'formulaire.live.duration': 'Durée du live',
  'formulaire.live.heure': 'heure',
  'formulaire.live.minutes': 'Minutes',
  'formulaire.live.status': 'Statut du live',
  'formulaire.live.published': 'Publié',
  'formulaire.btn.validate': 'Valider',
  'formulaire.btn.cancel': 'Annuler',
  'formulaire.confirmation.text': 'Etes-vous sûr de vouloir quitter cette page ?',
  'formulaire.status.private': 'Privé',
  'formulaire.status.public': 'Public',
};
