import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      '& .MuiOutlinedInput-input': {
        padding: ' 8.5px 14px',
      },
    },
    dateStyle: {
      padding: '10px',
      fontSize: 14,
      fontFamily: 'Open Sans',
      color: 'black',
    },
    selectCategorie: {
      width: '100%',
    },
    SelectVideo1: {
      width: '100%',
    },
    selectOption: {
      width: '100%',
      padding: 0,
      '& .MuiInputBase-root': {
        fontSize: 14,
        fontFamily: 'Open Sans',
      },
    },
    inputPropsSelect: {
      padding: 10,
      '& .MuiOutlinedInput-input': {
        padding: 5,
      },
    },
    boxduration: {
      display: 'flex',
      alignItems: 'center',
    },
    TimezoneError: {
      ' & .MuiFormControl-root': {
        width: '100%',
        border: '1px solid red',
        borderRadius: 5,
      },
    },
    TimezoneSuccess: {
      ' & .MuiFormControl-root': {
        width: '100%',
        borderRadius: 5,
      },
    },
    boxDate: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& >div': {
        width: '100%',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& >div>div>div>div': {
        margin: 0,
      },
      '& >div>div>div>div>button': {
        padding: '0px 3px',
      },
      '@media(max-width:700px)': {
        flexDirection: 'column',
        alignItems: 'unset',
        '& >div': {
          width: '100%',
          marginLeft: '0px!important',
        },
        '& >div>div': {
          width: '100%',
          marginLeft: '0px!important',
        },
      },
    },
    textRequired: { marginTop: 0, color: 'red', fontSize: 12 },
  }),
);
