export default {
  'apercu.apercu.text': 'Live preview',
  'apercu.visible.info': 'Visible information',
  'apercu.chat': 'Chat',
  'apercu.actuality.text': 'Actuality',
  'apercu.product.sales': 'Product for sale',
  'apercu.product.btn.text.select': 'Select all',
  'apercu.product.btn.text.unselect': 'Unselect all',
  'apercu.product.btn.text.share': 'Share selected',
  'apercu.text.productShare':
    'Select the products as they appear on the live. If they are all present from the start, then pre-select them all before the live starts.',
};
