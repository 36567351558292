import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 411,
      border: 'solid 1px',
      '@media(mai-width:700px': {
        minWidth: 450,
        height: 'auto',
      },
    },
    title: {
      display: 'flex',
      borderBottom: 'solid 1px',
      height: '100%',
      maxHeight: '64px',
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: 10,
    },
    object: {
      display: 'flex',
      borderBottom: 'solid 1px',
      height: '100%',
      maxHeight: '64px',
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: 10,
    },
    content: { padding: 10, minHeight: '346px !important' },

    btnCopyInvitation: {
      marginTop: 21,
    },
  }),
);
