import { typedAction } from '../utils';

export interface IparamsId {
  id: string;
  adaptorRTC: any;
}
export interface IerrorLiveEnd {
  error: string;
  status: number | undefined;
}

export interface IinitialStateLiveEnd {
  liveStoped: boolean;
  loading: boolean;
  error: string;
  status: number | undefined;
}

const initialStateLiveEnd: IinitialStateLiveEnd = {
  liveStoped: false,
  error: '',
  loading: false,
  status: undefined,
};
export const LIVE_SESSION_END_REQUEST = 'LIVE_SESSION_END_REQUEST';
export const LIVE_SESSION_END_SUCCESS = 'LIVE_SESSION_END_SUCCESS';
export const LIVE_SESSION_END_FAILED = 'LIVE_SESSION_END_FAILED';
export const INIT_LIVE_SESSION_END = 'LIVE_SESSION_END_INIT';

export const doEndLiveSession = (params: IparamsId) => typedAction(LIVE_SESSION_END_REQUEST, params);
export type DoEndLiveSession = typeof doEndLiveSession;

export const liveSessionEnded = (status: number) => typedAction(LIVE_SESSION_END_SUCCESS, status);
export type LiveSessionEnded = typeof liveSessionEnded;

export const initLiveSessionEnded = () => typedAction(INIT_LIVE_SESSION_END);
export type InitLiveSessionEnded = typeof initLiveSessionEnded;

export const liveSessionEndFailed = (error: IerrorLiveEnd) => typedAction(LIVE_SESSION_END_FAILED, error);
export type LiveSessionEndFailed = typeof liveSessionEndFailed;

export type LiveSessionEndAction = ReturnType<
  DoEndLiveSession | LiveSessionEnded | LiveSessionEndFailed | InitLiveSessionEnded
>;

export const DoLiveSessionEndReducer = (
  state: IinitialStateLiveEnd = initialStateLiveEnd,
  action: LiveSessionEndAction,
): IinitialStateLiveEnd => {
  switch (action.type) {
    case LIVE_SESSION_END_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIVE_SESSION_END_SUCCESS:
      return {
        ...state,
        liveStoped: true,
        loading: false,
        error: '',
        status: action.payload,
      };
    case INIT_LIVE_SESSION_END:
      return initialStateLiveEnd;
    case LIVE_SESSION_END_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
