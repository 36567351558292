import { Box, Button, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import SummaryGuest from '../../common/Table/SummaryGuestV2';
import { RootState } from '../../redux-services';
import {
  clear_AllGuestSummaryByLive,
  get_AllGuestSummaryByLive,
  get_GuestSummaryExcelFile,
  IinitialStateListGuestSummary,
} from '../../redux-services/modules/guest/guestListsParticipantsSummary';
import { getListEndedLive, IinitialStateEndedSection } from '../../redux-services/modules/liveSessionRetailerListEnded';
import SessionsSectionComponent from '../SessionsSectionComponent/SessionsSectionComponent';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useStyles } from '../ApercuLiveComponent/styles';
import { useTranslation } from 'react-i18next';
import { IActus } from '../../redux-services/type/actus';
import {
  get_ClickRecordProductByLiveId,
  IinitialStateClickRecordProductByLiveId,
} from '../../redux-services/modules/product/participantProductClickRecord';

const useStylesRoot = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: 50,
      marginTop: '25px',
      overflow: 'auto',
      marginBottom: 80,
    },
    listBySection: {
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: 70,
      paddingTop: 0,
    },
    button: {
      margin: theme.spacing(1),
      alignSelf: 'end',
    },
  }),
);

const StatisticsComponent = () => {
  const { t } = useTranslation();
  const style = useStylesRoot();
  const styleButton = useStyles();
  const dispatch = useDispatch();
  const [idSelected, setIdSelected] = useState<string>('');
  const [showActuality, setShowActuality] = useState(false);
  const [actualitys, setActualitys] = useState<IActus[]>([]);
  const { ListEndedLive, loading } = useSelector<RootState, IinitialStateEndedSection>(
    (state) => state.liveSessionRetailerEndedSection,
  );
  const { ListGuestSummary, loading: loadingSummary } = useSelector<RootState, IinitialStateListGuestSummary>(
    (state) => state.guestListGuestSummary,
  );
  const { ListDataClickRecordProducts, loading: loadingListDataClickRecordProducts } = useSelector<
    RootState,
    IinitialStateClickRecordProductByLiveId
  >((state) => state.listClickRecordProductByLiveId);

  const setIdToShow = (id: string) => {
    // console.log('id show stat :>> ', id);
    setIdSelected(id);
    dispatch(get_AllGuestSummaryByLive(id));
    dispatch(get_ClickRecordProductByLiveId({ liveId: id, userType: 'Retailer' }));
    setActualitys([]);
  };
  const downloadExcelFileSummary = () => {
    dispatch(get_GuestSummaryExcelFile(idSelected));
  };

  useEffect(() => {
    dispatch(getListEndedLive());
    return () => {
      dispatch(clear_AllGuestSummaryByLive());
    };
  }, []);

  useEffect(() => {
    if (!loadingListDataClickRecordProducts && ListDataClickRecordProducts.length > 0) {
      setActualitys(
        ListDataClickRecordProducts.map((_historyActu: any) => ({
          productId: _historyActu.productLabel,
          participantId: _historyActu.participantId,
          participantName: _historyActu.participantName,
        })),
      );
    }
  }, [loadingListDataClickRecordProducts]);

  return (
    <>
      <CustomBackdrop {...{ open: loading || loadingSummary }} />
      <Box className={style.listBySection}>
        {(loading && <CustomBackdrop {...{ open: loading }} />) || (
          <SessionsSectionComponent sessions={ListEndedLive} setIdToShow={setIdToShow} isEndLive={true} />
        )}
      </Box>

      {!loading && idSelected !== '' && (
        <Box className={style.container}>
          <Box className={styleButton.headButtonActualites}>
            <Button
              style={{
                width: '50%',
                textTransform: 'unset',
              }}
              className={!showActuality ? styleButton.activProductShadow : ''}
              onClick={() => {
                setShowActuality(false);
              }}
            >
              <Typography variant="h5" style={{ textTransform: 'unset' }}>
                Affiliations
              </Typography>
            </Button>
            <Button
              style={{
                textTransform: 'unset',
                width: '45%',
              }}
              className={showActuality ? styleButton.activProductShadow : ''}
              onClick={() => {
                setShowActuality(true);
              }}
            >
              <Typography variant="h5" style={{ textTransform: 'unset' }}>
                {t('apercu.actuality.text')}
              </Typography>
            </Button>
            <IconButton
              aria-label="download"
              className={style.button}
              onClick={downloadExcelFileSummary}
              disabled={idSelected === ''}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Box>
          <Box className={styleButton.upperBoxProduxtlist}>
            {!showActuality && (
              <Box className={style.container}>
                <SummaryGuest data={ListGuestSummary} />
              </Box>
            )}

            {showActuality && (
              <Box className={styleButton.boxComponentbtnProduct} id="actuality-box">
                {actualitys.length ? (
                  actualitys.map((actu, indexKey) => (
                    <Typography key={indexKey}>{`${actu.participantName} s’intéresse à ${actu.productId}`}</Typography>
                  ))
                ) : (
                  <Typography>I am a actualiter</Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default StatisticsComponent;
