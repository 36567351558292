import { Box, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useEffect, useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import CardLives from '../../common/cardLives/CardLives';
import Footer from '../../common/footer/Footer';
import CustomModal from '../../common/modal/Modal';
import { API_URL } from '../../lib/config';
import { RootState } from '../../redux-services';
import { getCategorie } from '../../redux-services/modules/categorie';
import { getLiveSession, ILiveSession } from '../../redux-services/modules/liveSession';
import {
  getliveSessionConsumerById,
  IinitialStateConsumerGet,
} from '../../redux-services/modules/liveSessionConsumerget';
import { getLiveSessionPage, Ilive, ILiveSessionPageWithCategory } from '../../redux-services/modules/liveSessionPage';
import { getLiveSessionSectionRetailer } from '../../redux-services/modules/liveSessionRetailerSection';
import { useStyles } from './style';
import liveSessionServices from '../../redux-services/services/liveSessionServices';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [accessLive, setAccessLive] = useState<boolean>(true);
  const { t } = useTranslation();
  const {
    liveSession: { LiveSession },
    liveSessionPage: { loading, liveSessionPageAll },
  }: any = useSelector<RootState>((state) => state);
  const LiveSessionConsumerById = useSelector<RootState, IinitialStateConsumerGet>(
    (state) => state.liveSessionConsumerById,
  );
  const { loading: loadingLiveById, live: liveById } = LiveSessionConsumerById;
  const [open, setOpen] = useState<boolean>(false);

  const [dataLive, setDataLive] = useState<Ilive>();

  const [Lives, setLives] = useState<ILiveSessionPageWithCategory[]>([
    {
      items: [
        {
          id: '',
          title: '',
          retailerId: '',
          retailerName: '',
          categoryId: '',
          categoryName: '',
          dateTime: '2021-07-09T12:54:11.242Z',
          privacy: 0,
          thumbnailImageId: '',
          actualEnd: '',
          actualStart: '',
          thumbnailImageUrl: '',
        },
      ],
      pageInfo: {
        index: 0,
        size: 0,
      },
      totalCount: 0,
      type: '',
      pageCount: 0,
    },
  ]);

  useEffect(() => {
    dispatch(getLiveSession());
    dispatch(getCategorie());
    // dispatch(getLiveSessionSectionRetailer());
  }, []);

  useEffect(() => {
    LiveSession.map((item: ILiveSession) => {
      dispatch(getLiveSessionPage({ id: item.id.toString(), index: 0, size: 20, type: item.name }));
    });
  }, [LiveSession]);

  useEffect(() => {
    if (Lives[0].type == '' && liveSessionPageAll && liveSessionPageAll.type != '') {
      setLives([liveSessionPageAll]);
    } else {
      let check = false;
      for (const i of Lives) {
        if (i.type === liveSessionPageAll.type) check = true;
      }
      if (check) {
        for (const [index, i] of Lives.entries()) {
          if (i.type != '' && i.type === liveSessionPageAll.type && i.pageCount != liveSessionPageAll.pageCount) {
            if (i.pageCount < liveSessionPageAll.pageCount) {
              setLives((old) => {
                old[index] = {
                  ...i,
                  items: [...i.items, ...liveSessionPageAll.items],
                  pageInfo: {
                    index: i.pageInfo.index,
                    size: liveSessionPageAll.pageInfo.size,
                  },
                  totalCount: i.totalCount + liveSessionPageAll.totalCount,
                };
                return old;
              });
            } else {
              if (i.pageCount > liveSessionPageAll.pageCount) {
                setLives((old) => {
                  old[index] = liveSessionPageAll;
                  return old;
                });
              }
            }
          }
        }
      } else {
        setLives([...Lives, liveSessionPageAll]);
      }
    }
  }, [liveSessionPageAll, Lives]);

  const handleClose = () => {
    setOpen(false);
  };

  const menu = (Lives: any, _item: number) =>
    Lives?.map((data: any, index: number) => {
      const { thumbnailImageUrl, title, categoryName, id } = data;
      return (
        <CardLives
          {...{
            isNow: _item,
            img: `${thumbnailImageUrl}`,
            title,
            description: categoryName,
            onClick: async () => {
              await setDataLive(data);
              await dispatch(getliveSessionConsumerById(id));
              setOpen(!open);
            },
          }}
          key={index}
        />
      );
    });

  useEffect(() => {
    if (!loadingLiveById && liveById) {
      if (liveById.viewingMode) {
        setAccessLive(false);
      }
    } else {
      setAccessLive(true);
    }
  }, [loadingLiveById, liveById]);

  const ArrowLeft = <ArrowBack />;
  const ArrowRight = <ArrowForward />;
  return (
    <>
      <Box className={classes.home}>
        <Box className={classes.bubble}>
          <Typography component="h5" variant="h5" color="primary">
            {t('homepage.textfavorite')}
          </Typography>
        </Box>
      </Box>
      {(loading && (
        <>
          <Box style={{ minHeight: '500px' }}>
            <CustomBackdrop {...{ open: loading }} />
          </Box>
        </>
      )) ||
        (Lives &&
          Lives.length &&
          LiveSession &&
          LiveSession.length &&
          Lives[0].type != '' &&
          LiveSession.map((item: ILiveSession, _item: number) =>
            Lives.map((live, indexKey) => {
              if (item.name === live.type)
                return (
                  <Box className={classes.live} key={_item + indexKey}>
                    <Box className="title">
                      <Typography component="p" variant="h5">
                        {live.type}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {t('common.explore.all')}
                      </Typography>
                    </Box>
                    <Box className={classes.WrapSlider}>
                      <ScrollMenu
                        {...{ data: menu(live.items, _item), dragging: false, alignCenter: false }}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                        wrapperStyle={{ width: '100%' }}
                        wheel={false}
                      />
                    </Box>
                  </Box>
                );
            }),
          ))}

      <CustomModal
        {...{
          open: open && Boolean(dataLive),
          onClose: handleClose,
          loading: loadingLiveById,
          maxWidth: 'xs',
          fullWidth: true,
          textConfirm: t('homepage.join.live'),
          onConfirm: async () => {
            if (dataLive) {
              await dispatch(getliveSessionConsumerById(dataLive.id));
              const participantByLive = await liveSessionServices.getParticipantByLive(dataLive.id, 'Consumer');
              if (participantByLive && participantByLive !== undefined) {
                history.push(`/LiveCurrent/${dataLive.id}`);
              } else {
                history.push(`/auth/Consumer/${dataLive.id}`);
              }
            }
          },
        }}
        disabledAction={!liveById.viewingMode}
      >
        <Box className={classes.btnAccessLive}>
          <img alt={dataLive?.categoryName} src={`${dataLive?.thumbnailImageUrl}`} className={classes.imageModal} />
          <Typography>{dataLive?.categoryName}</Typography>
          <Typography>{dataLive?.title}</Typography>
        </Box>
      </CustomModal>
      <Footer />
    </>
  );
};

export default HomePage;
