import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootDashboard: {
      // overflow: 'auto',
      display: 'flex',
      '@media(min-width:700px)': {
        height: '100vh',
      },
    },
    sidebarBody: {
      width: '100%',
      marginLeft: 170,
      // marginTop: 75,
      overflow: 'hidden auto',
      '@media(max-width:900px)': {
        overflow: 'unset',
        marginLeft: 90,
      },
      '@media(max-width:425px)': {
        marginLeft: 45,
      },
      '@media(max-width:700px)': {
        marginLeft: '0 !important',
      },
    },
  }),
);
