import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      margin: 15,
      '@media(max-width:700px)': {
        maxWidth: '28vw',
        margin: 4,
      },
      width: 170,
      '& >div': {
        margin: '7px 0px',
      },
      '& >div:first-child': {
        position: 'relative',
      },
      '& img': {
        // width: '100%',
        borderRadius: 5,
      },
    },
    cardFullscreen: {
      width: '8vw',
    },
    popContent: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginTop: 10,
      },
    },
    plus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #000',
      borderRadius: 20,
    },
    sales: {
      position: 'absolute',
      height: 56,
      width: 56,
      backgroundColor: 'black',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: -20,
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Open Sans',
    },
    boxImg: {
      display: 'flex',
      justifyContent: 'center',
      height: 170,
      '@media(max-width:700px)': {
        height: 80,
      },
      border: '1px solid gray',
      borderRadius: 12,
    },
    check: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      right: 2,
      '& svg': {
        fill: 'black',
      },
    },
  }),
);
