export default {
  'action.rapide': 'Action rapide',
  'action.update.live': 'Modifiez les infos du Live',
  'action.apercu.live': 'Aperçu du live',
  'action.manage.product': 'Gérez vos produits à la vente',
  'action.manage.affiliation': 'Fixez les avantages VIP',
  'action.manage.invitations': 'Invitez vos VIP',
  'action.manage.question': 'Gestion des questions',
  'action.start.live': 'Démarrer le live',
  'action.end.live': 'Arrêter le live',
  'action.paused.live': 'Mettre en pause le live',
  'action.access': 'Accès rapide',
  'action.question': 'Ajouter une question',
  'action.addProduct': 'Ajouter un produit',
  'action.affiliations': 'Liste affiliations',
  'action.close': 'Fermer',
  'action.order': 'Commander',
};
