import * as React from 'react';

function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3077 8.65385V1H1V25.875H16.3077V18.2212"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0047 18.6996L29.2234 13.4376L23.0047 8.17554M6.74023 13.4376H29.2234H6.74023Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon;
