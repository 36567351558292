import { Box, Button, createMuiTheme, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import { RESETPASSWORD } from '../../constant/path';
import { isEmailValid } from '../../lib/auth/authvalidate';
import { RootState } from '../../redux-services';
import { IResultLoginSAGA, loginStart } from '../../redux-services/modules/login';
import authSevices from '../../redux-services/services/authSevices';
import { useStyles } from './style';

const MsgError400 = 'Votre email, votre identifiant ou votre mot de passe est incorrect';
const MsgError401 = "Votre email n'a pas encore été confirmer, merci de vérifier";
interface IFieldState {
  value: string;
  error: boolean;
  errorText?: string;
}

interface loginProps {
  email: IFieldState;
  password: IFieldState;
}
const initialLoginState: loginProps = {
  email: { value: '', error: false, errorText: '' },
  password: { value: '', error: false, errorText: '' },
};

const LoginScreen = () => {
  const history = useHistory();
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const { loading, token, status } = login;
  const style = useStyles();
  const dispatch = useDispatch();
  const [input, setInput] = useState<loginProps>(initialLoginState);
  const [disabled, setdisabled] = useState<boolean>(true);
  const [errorInput, setErrorInput] = useState(false);
  const [msgError, setMsgError] = useState(MsgError400);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#4B7FD5',
      },
    },
  });

  const handleBlur = (key: keyof loginProps) => {
    if (input[key].value === '') {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: true,
          errorText: 'champ obligatoire',
        },
      });
    } else {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: false,
          errorText: '',
        },
      });
    }
  };

  const handleClick = () => {
    let isValidate = true;
    if (input.email.value === '') {
      setInput({ ...input, email: { ...input.email, errorText: 'Champ obligatoire', error: true } });
    } else if (input.password.value === '') {
      setInput({ ...input, password: { ...input.password, errorText: 'Champ obligatoire', error: true } });
    }
    if (input.email.value === '' || input.password.value === '') isValidate = false;

    if (isValidate) {
      dispatch(loginStart({ password: input.password.value, userType: 'Retailer', username: input.email.value }));
    } else {
      handleBlur('password');
      handleBlur('email');
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter' && !disabled) {
      handleClick();
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    setErrorInput(false);
    switch (name) {
      case 'email': {
        if (!isEmailValid(value)) {
          setInput({
            ...input,
            email: { value, error: true, errorText: 'Mail format invalide ' },
          });
        } else {
          setInput({
            ...input,
            email: { value, error: false, errorText: '' },
          });
        }
        break;
      }
      case 'password': {
        setInput((prevstate) => ({ ...prevstate, [name]: { ...(prevstate as any)[name], value } }));
        break;
      }
      default:
        break;
    }
  };
  useEffect(() => {
    if (token) {
      history.push('/dashboard');
    }
    authSevices.getAccessToken().then((token) => {
      if (token) {
        history.push('/dashboard');
      }
    });

    if (!token && status && status !== 200) {
      if (status === 401) {
        setMsgError(MsgError401);
      } else {
        setMsgError(MsgError400);
      }
      setErrorInput(true);
    }
  }, [token, status]);

  useEffect(() => {
    if (input.email.value && input.password.value) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  }, [input.email.value, input.password.value]);

  return (
    <Box>
      {loading && <CustomBackdrop {...{ open: loading }} />}
      <Box className={style.root}>
        <Box className={style.retailerInstruction}>
          <Typography>
            Vous êtes <u>l'animateur du live</u>
            <br className="_mobileView" />
            <b> (Marque, conseiller vente...)</b>
          </Typography>
          <hr className="_mobileView" />
        </Box>
        <Box className={style.cadre}>
          <div className={style.logo}>
            <Typography variant="h6">
              <span> Authentification </span>
            </Typography>
          </div>
          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'email',
                label: 'Email',
                type: 'email',
                variant: 'outlined',
                value: input.email.value,
                error: input.email.error,
                onBlur: () => handleBlur('email'),
                onChange: (e) => handleChange(e),
                onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            {input.email.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.email.errorText}</Typography>
            )}
          </Box>
          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'password',
                label: 'Mot de passe',
                type: 'password',
                onBlur: () => handleBlur('password'),
                value: input.password.value,
                error: input.password.error,
                variant: 'outlined',
                onKeyDown: (e) => onKeyPressed(e),
                onChange: (e) => handleChange(e),
              }}
            />
            {input.password.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.password.errorText}</Typography>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <Link to={RESETPASSWORD} style={{ textDecoration: 'none', color: theme.palette.primary.light }}>
              Mot de passe oublié
            </Link>
            {errorInput && <Typography style={{ marginTop: 0, color: 'red', fontSize: 12 }}>{msgError}</Typography>}
            <Button
              {...{ disabled, style: { marginTop: 20, color: theme.palette.primary.light } }}
              onClick={() => {
                handleClick();
              }}
            >
              <Typography>Se connecter</Typography>
            </Button>
            <Typography>Vous n'êtes pas encore membre?</Typography>
            <Link
              to="https://livood.com/sinscrire/"
              style={{ textDecoration: 'none', color: theme.palette.primary.light }}
              target="_blank"
            >
              Inscrivez-vous maintenant
            </Link>
          </ThemeProvider>
        </Box>
        <Box className={style.retailerInstructionFooter}>
          <hr className="_mobileView" />
          <Box>
            <Typography>
              Si vous n'êtes pas l'animateur du live mais un participant <a href="/auth/Consumer"> cliquez ici</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginScreen;
