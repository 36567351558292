import { typedAction } from '../utils';

interface Icategorie {
  id: string;
  name: string;
}

export interface IinitialStateCategorie {
  loading: boolean;
  error: any;
  categorie: Icategorie[];
}
const initialState: IinitialStateCategorie = {
  categorie: [],
  error: null,
  loading: false,
};
export const GET_CATEGORIE_REQUEST = 'GET_CATEGORIE_REQUEST';
export const GET_CATEGORIE_FAILED = 'GET_CATEGORIE_FAILED';
export const GET_CATEGORIE_SUCCESS = 'GET_CATEGORIE_SUCCESS';

export const getCategorie = () => typedAction(GET_CATEGORIE_REQUEST);
export type GetCategorie = typeof getCategorie;

export const getCategorieSuccess = (data: Icategorie[]) => typedAction(GET_CATEGORIE_SUCCESS, data);
export type GetCategorieSuccess = typeof getCategorieSuccess;

export const getCategorieFailed = (error: any) => typedAction(GET_CATEGORIE_FAILED, error);
export type GetCategorieFailed = typeof getCategorieFailed;

export type CategorieAction = ReturnType<GetCategorie | GetCategorieSuccess | GetCategorieFailed>;

export const CategorieReducer = (
  state: IinitialStateCategorie = initialState,
  action: CategorieAction,
): IinitialStateCategorie => {
  switch (action.type) {
    case GET_CATEGORIE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIE_SUCCESS:
      return {
        ...state,
        loading: false,
        categorie: action.payload,
      };
    case GET_CATEGORIE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
