import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';

interface ICustomModal {
  open: boolean;
  disabledAction?: boolean;
  onClose?: () => void;
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading?: boolean;
  textConfirm?: string;
  TextCancel?: string;
  title?: string;
}
const CustomModal: FC<ICustomModal & DialogProps> = ({
  open,
  children,
  onClose,
  TextCancel,
  onConfirm,
  textConfirm,
  title,
  disabledAction,
  loading,
  ...props
}) => {
  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" {...props}>
        {title && (
          <DialogTitle
            id="form-dialog-title"
            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        {/* {onConfirm ||
          (textConfirm && ( */}
        <DialogActions
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#00000010',
            width: '-webkit-fill-available',
          }}
        >
          {TextCancel && (
            <Button onClick={onClose} color="default" style={{ width: '50%' }}>
              {TextCancel}
            </Button>
          )}
          {textConfirm && (
            <Button
              onClick={onConfirm}
              color="primary"
              variant="outlined"
              disabled={disabledAction}
              style={{ width: '50%' }}
            >
              {loading ? <CircularProgress size="1.75rem" /> : textConfirm}
            </Button>
          )}
        </DialogActions>
        {/* ))} */}
      </Dialog>
    </Box>
  );
};

export default CustomModal;
