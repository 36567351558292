import { typedAction } from '../../utils';

export const SET_ADVANTAGECODES_GUEST_REQUEST = 'SET/ADVANTAGECODES/GUEST/REQUEST';
export const SET_ADVANTAGECODES_GUEST_SUCCESS = 'SET/ADVANTAGECODES/GUEST/SUCCESS';
export const SET_ADVANTAGECODES_GUEST_FAILED = 'SET/ADVANTAGECODES/GUEST/FAILED';

export interface IdataSetAdvantageCode {
  guestId: string;
  advantageCode?: string;
  shoppingUrl?: string;
}
export interface IguestResponse {
  message: string | null;
  status: number | undefined;
}
export interface IinitialStateGuestAdvantageCode {
  status: number | undefined;
  message: string | null;
  loading: boolean;
}
const initialStateGuestAdvantageCode: IinitialStateGuestAdvantageCode = {
  loading: false,
  status: undefined,
  message: '',
};

export const do_SetAdvantageCodes_guest = (data: IdataSetAdvantageCode[]) =>
  typedAction(SET_ADVANTAGECODES_GUEST_REQUEST, data);
export type Ido_SetAdvantageCodes_guest = typeof do_SetAdvantageCodes_guest;

export const guest_SetedAdvantageCode = (result: IguestResponse) =>
  typedAction(SET_ADVANTAGECODES_GUEST_SUCCESS, result);
export type Iguest_SetedAdvantageCode = typeof guest_SetedAdvantageCode;

export const guest_SetAdvantageCode_failed = (error: IguestResponse) =>
  typedAction(SET_ADVANTAGECODES_GUEST_FAILED, error);
export type Iguest_SetAdvantageCode_failed = typeof guest_SetAdvantageCode_failed;

export type SetAdvangeCodesGuestAction = ReturnType<
  Ido_SetAdvantageCodes_guest | Iguest_SetedAdvantageCode | Iguest_SetAdvantageCode_failed
>;

export const SetAdvantageCodeGuestReducers = (
  state: IinitialStateGuestAdvantageCode = initialStateGuestAdvantageCode,
  action: SetAdvangeCodesGuestAction,
): IinitialStateGuestAdvantageCode => {
  switch (action.type) {
    case SET_ADVANTAGECODES_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_ADVANTAGECODES_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        message: action.payload.message,
      };
    case SET_ADVANTAGECODES_GUEST_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
