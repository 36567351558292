export default {
  'recognition.personalInfo': 'Personal information',
  'recognition.companyInfo': 'Company Information',
  'recognition.email': 'Email',
  'recognition.firstname': 'First name',
  'recognition.phoneNumber': 'Phone number',
  'recognition.emailValidation': 'Email validation',
  'recognition.lastname': 'Last name',
  'recognition.country': 'Country',

  'recognition.companyName': 'Company name',
  'recognition.activityType': 'Activity type',
  'recognition.address': 'Address',
  'recognition.postalCode': 'Pastal code',
  'recognition.city': 'City',
  'recognition.companyCountry': 'Country',
  'recognition.website': 'Website',
  'recognition.instagram': 'Instagram',
  'recognition.tiktok': 'TikTok',
  'recognition.fb': 'Facebook',
  'recognition.snapchat': 'Snapchat',
};
