import { createStyles, makeStyles, Theme } from '@material-ui/core';
import imgleaf from '../../Assets/images/leaf.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appbar: {
      position: 'initial',
      backgroundColor: '#F9FAF9',
      '@media(max-width:700px)': {
        backgroundColor: '#518971',
      },
      '@media(min-width:700px)': {
        boxShadow: '4px 4px 40px inset rgba(0, 0, 0, 0.1)',
      },
    },
    title: {
      color: theme.palette.primary.light,
    },
    navbar: {
      backgroundColor: '#F9FAF9',
      height: 75,
      paddingLeft: '3%',
      paddingRight: '3%',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      '@media(max-width:700px)': {
        backgroundColor: '#518971',
      },
      '@media(max-width:1180px)': {
        position: 'relative',
        justifyContent: 'center',
        minHeight: 'unset',
        height: 40,
        backgroundColor: (props: any) => props.primaryColor,
        '& *': {
          color: '#FFFFFF',
        },
        '& > div:nth-of-type(1)': {
          '& > button': {
            padding: 0,
            '& h6': {
              fontSize: 18,
              fontWeight: 'bold',
            },
          },
        },
        '& > div:nth-of-type(2)': {
          position: 'absolute',
          left: 0,
          '& > div:nth-of-type(1)': {
            display: 'none',
          },
          '& > div:nth-of-type(2)': {
            '& > button': {
              paddingTop: 5,
              paddingBottom: 5,
            },
          },
        },
      },
    },
    navRight: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        marginLeft: 15,
      },
      '& >div:last-child': {
        display: 'none',
        '@media(max-width:700px)': {
          display: 'block',
        },
      },
      '@media(max-width:700px)': {
        '& > div:nth-child(2)': {
          marginLeft: 0,
        },
      },
    },
    navLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    btnLivood: {
      paddingLeft: 0,
    },
    search: {
      backgroundColor: 'white',
      borderRadius: 20,
      marginLeft: '3%',
      display: 'flex',
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
      },
      '& .icon svg': {
        color: '#222222',
      },
      '@media(max-width:580px)': {
        display: 'none',
      },
    },
    inputSearch: {
      width: 234,
      height: 43,
      '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#222222',
        paddingLeft: 10,
      },
    },
    btnHead: {
      backgroundColor: '#222222',
      color: '#ffffff',
      textTransform: 'unset',
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      width: '100%',
      '&:hover': {
        backgroundColor: '#000000',
        color: '#ffffff',
      },
      '&.desktop': {
        '@media(max-width:700px)': {
          display: 'none',
        },
      },
    },
    profile: {
      // backgroundImage: `url(${imgprofile})`,
      height: 40,
      width: 40,
      backgroundSize: '100%',
    },
    burgerMenu: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'block',
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        top: '60px!important',
        '& >ul >li': {
          fontSize: 16,
        },
      },
    },
    menumobile: {
      '& .MuiMenu-paper': {
        top: '60px!important',
        fontSize: 16,
        '@media(max-width:1180px)': {
          top: '40px!important',
        },

        '& ul': {
          paddingBottom: 0,
        },
        '& ul>li>button': {
          height: 36,
        },
        '& ul>li>button>span>p': {
          fontSize: 16,
        },
        '& ul>li>p': {
          fontSize: 16,
        },
      },
    },
    navtext: {
      fontSize: 16,
    },
    leaf: {
      // backgroundImage: `url(${imgleaf})`,
      '@media(max-width:700px)': {
        display: 'none',
      },
      height: 134,
      //position: 'fixed',
      top: '-12px',
      width: '100%',
      zIndex: 1000,
      // background-repeat-y: no-repeat;
      backgroundRepeatY: 'no-repeat',
    },
  }),
);
