import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import SessionsSectionComponent from '../SessionsSectionComponent/SessionsSectionComponent';
import SessionsSectionMobileComponent from '../SessionsSectionComponent/SessionSectionMobileComponent';
import {
  getListBySection,
  IinitialStateBySection,
} from '../../redux-services/modules/liveSessionRetailerListBySection';
import { useIsMobile } from '../../common/header/useIsMobile';
import Tabs from './TabPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: 70,
      paddingTop: 0,
      '@media(max-width:700px)': {
        marginTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),
);

const ScheduleLiveComponent = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const style = useStyles();
  const { ListBySection, loading } = useSelector<RootState, IinitialStateBySection>(
    (state) => state.liveSessionRetailerBySection,
  );

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getListBySection());
  }, []);

  console.log('ListBySection', ListBySection);

  return (
    <Box className={style.container}>
      {!isMobile && (
        <>
          {(loading && <CustomBackdrop {...{ open: loading }} />) ||
            ListBySection.map((section, indexKey) => (
              <SessionsSectionComponent isLiveSessionsPage key={indexKey} sessions={section} />
            ))}
        </>
      )}
      {isMobile && ListBySection.length > 0 && (
        <Box>
          <Tabs
            data={ListBySection.map((section, indexKey) => ({
              label: section.section.name,
              children: <SessionsSectionMobileComponent isLiveSessionsPage key={indexKey} sessions={section} />,
            }))}
            indicatorPosition="bottom"
            indicatorColor={'#518971'}
          />
        </Box>
      )}
    </Box>
  );
};

export default ScheduleLiveComponent;
