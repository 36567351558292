import { StepIconProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBackdrop from '../../../common/backdrop/CustomBackdrop';
import { AUTH_INSCRIPTION } from '../../../constant/path';
import { RootState } from '../../../redux-services';
import {
  DO_confirmation_account_consumer,
  IinitialstateConfirmationAccountConsumer,
} from '../../../redux-services/modules/account/consumer/confirmationAccountConsumer';
import {
  DO_create_account_consumer,
  IinitialstateCreateAccountConsumer,
} from '../../../redux-services/modules/account/consumer/createAccountConsumer';
import { IinitialstateCreateConsumer } from '../../../redux-services/modules/account/consumer/createConsumer';
import { Set_state_snackbar } from '../../../redux-services/modules/state/stateMessage';
import theme from '../../../Themes';
import RecognitionComponent, { CompanyInformationState, PersonalInfoState } from './RecognitionComponent';
import { ColorlibConnector, useColorlibStepIcon, useStyle as useStyles } from './styles';

const ColorlibStepIcon = (props: StepIconProps) => {
  const classes = useColorlibStepIcon();
  const styles = useStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <span className={styles.iconText}>1.</span>,
    2: <span className={styles.iconText}>2.</span>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

const getSteps = () => {
  return ['identify', 'confirm'];
};

interface RouteParams {
  isConsumer?: boolean;
}
interface IParamAccountConfirmation {
  userType?: 'Consumer' | 'Retailer';
  consumerId?: string;
  securityStamp?: string;
}

const SignUpComponent = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const routeParams = useLocation<RouteParams>();
  const { userType, consumerId, securityStamp } = useParams<IParamAccountConfirmation>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConsumer, setIsConsumer] = useState<boolean>(false);
  const [loadingReSendEmail, setLoadingReSendEmail] = useState<boolean>(false);
  const [isConfirmationAccount, setIsConfirmationAccount] = useState<boolean>(false);

  const { loading } = useSelector<RootState, IinitialstateCreateConsumer>((state) => state.createConsumer);
  const { loading: loadingCreateAccountConsumer, status: statusCreateAccountConsumer } = useSelector<
    RootState,
    IinitialstateCreateAccountConsumer
  >((state) => state.createAccountConsumer);

  const { loading: loadingConfirmationAccountConsumer } = useSelector<
    RootState,
    IinitialstateConfirmationAccountConsumer
  >((state) => state.confirmationAccountConsumer);

  const initialStatePersonnal: PersonalInfoState = {
    email: '',
    emailValidation: '',
    lastname: '',
    firstname: '',
    phoneNumber: '',
    country: '',
    password: '',
    confirmpassword: '',
  };

  const initialStateCompany: CompanyInformationState = {
    name: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    webSite: '',
    instagram: '',
    tiktok: '',
    facebook: '',
    snapchat: '',
    activity: '',
    cgu: 'false',
  };
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoState>(initialStatePersonnal);

  const [companyInformation, setCompanyInformation] = useState<CompanyInformationState>(initialStateCompany);

  const handlePersonalInfoChange = (prop: keyof PersonalInfoState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalInfo({ ...personalInfo, [prop]: event.target.value });
  };

  const handleCompanyInfoInfoChange =
    (prop: keyof CompanyInformationState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCompanyInformation({
        ...companyInformation,
        [prop]: event.target.value,
      });
    };

  const handleNext = () => {
    const { email, firstname: firstName, lastname: lastName, password, confirmpassword } = personalInfo;
    const { name: RetailerName, cgu } = companyInformation;
    if (isConsumer && cgu === 'false') {
      return dispatch(
        Set_state_snackbar({
          message: 'Veuillez accepter les CGU en cochant la case CGU',
          open: true,
          type: 'error',
        }),
      );
    }
    if (email && firstName && lastName && password && confirmpassword) {
      if (password !== confirmpassword) {
        return dispatch(
          Set_state_snackbar({ message: 'Votre mot de passe ne correspond pas', open: true, type: 'error' }),
        );
      }

      if (isConsumer) {
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (!isConsumer && RetailerName) {
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    return dispatch(Set_state_snackbar({ message: 'Veuillez complétez les champs', open: true, type: 'error' }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    history.push('/');
  };

  const onConfirm = () => {
    const { email, firstname: firstName, lastname: lastName, password } = personalInfo;
    const { name: RetailerName } = companyInformation;
    if (isConsumer) {
      dispatch(DO_create_account_consumer({ email, password, firstName, lastName }));
    }
    if (!isConsumer && RetailerName) {
      return dispatch(
        Set_state_snackbar({
          message: "Cette action n'est pas encore pret! veuillez contacter votre administrateur",
          open: true,
          type: 'warning',
        }),
      );
    }
  };

  const resendEmail = () => {
    setLoadingReSendEmail(true);
    axios.post('/Consumer/Account/SendConfirmationMail', { email: personalInfo.email }).then((respons) => {
      setLoadingReSendEmail(false);
    });
  };

  const getStepContent = (stepIndex: number, isConsumer: boolean) => {
    switch (stepIndex) {
      case 0:
        return (
          <RecognitionComponent
            {...{ isConsumer, handlePersonalInfoChange, companyInformation, handleCompanyInfoInfoChange, personalInfo }}
          />
        );
      case 1:
        return 'Veuilliez confirmer pour valider toutes les informations entrées.';
      default:
        return 'Unknown stepIndex';
    }
  };

  useEffect(() => {
    if (
      (routeParams.state && routeParams.state?.isConsumer && routeParams.state.isConsumer === true) ||
      routeParams.pathname === AUTH_INSCRIPTION
    ) {
      setIsConsumer(true);
    }
    if (
      routeParams.pathname.includes('ConfirmAccount') &&
      userType &&
      userType === 'Consumer' &&
      securityStamp &&
      securityStamp !== '' &&
      consumerId &&
      consumerId !== ''
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsConfirmationAccount(true);
      dispatch(DO_confirmation_account_consumer({ consumerId, securityStamp }));
    }
  }, [routeParams]);

  return (
    <div className={classes.container}>
      <CustomBackdrop
        {...{
          open: loading || loadingCreateAccountConsumer || loadingReSendEmail || loadingConfirmationAccountConsumer,
        }}
      />
      <Typography className="_desktopView" style={{ alignSelf: 'center', fontSize: '20px' }} variant="caption">
        Inscription
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.stepperTitle}>
        <Stepper activeStep={activeStep} className={classes.root} alternativeLabel connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  variant="caption"
                  style={{ color: activeStep === index || index <= activeStep ? theme.palette.primary.light : 'black' }}
                >
                  {t(`signIn.${label}`)}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div style={{ marginTop: '40px' }}>
        {isConfirmationAccount ? (
          <div>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '32px',
                fontWeight: 'bold',
                marginBottom: '48px',
              }}
            >
              Félicitations, votre compte sur Livood est créé
            </Typography>
            <Typography style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>
              Retournez sur l’URL du live afin de vous connecter directement à votre live shopping
            </Typography>
          </div>
        ) : isConsumer && statusCreateAccountConsumer === 200 ? (
          <div>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '32px',
                fontWeight: 'bold',
                marginBottom: '48px',
              }}
            >
              Plus qu’une étape pour valider le compte
            </Typography>
            <Typography style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>
              Nous vous avons envoyé un email avec un lien
            </Typography>
            <Typography style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>
              Sur lequel vous devrez cliquer pour valider votre compte
            </Typography>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
                fontStyle: 'italic',
                marginBottom: '36px',
              }}
            >
              {'(si vous ne voyez pas le mail, vérifiez dans vos spams)'}
            </Typography>
            <Typography style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
              {`Pour renvoyer le mai, cliquez `}
              <Button style={{ fontSize: '20px', color: '#1055CC', display: 'contents' }} onClick={resendEmail}>
                _ici
              </Button>
            </Typography>
          </div>
        ) : activeStep === steps.length ? (
          <div className={classes.containerStep}>
            <Typography className={classes.instructions}>{t('signIn.allYourInfo')}</Typography>
            <Button
              onClick={handleReset}
              variant="contained"
              color="primary"
              style={{
                backgroundImage:
                  'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
              }}
            >
              Ok
            </Button>
          </div>
        ) : (
          <div className={classes.containerStep}>
            <div className={classes.instructions}>{getStepContent(activeStep, isConsumer)}</div>
            <div style={{ marginTop: '10px' }}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                {t('signIn.previous')}
              </Button>

              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundImage:
                      'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
                  }}
                  onClick={onConfirm}
                >
                  {t('signIn.btnConfirm')}
                </Button>
              )}
              {!(activeStep === steps.length - 1) && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!companyInformation.cgu}
                  style={{
                    backgroundImage:
                      'linear-gradient(90deg, rgba(37,108,78,1) 0%, rgba(32,129,96,1) 44%, rgba(0,138,79,1) 97%)',
                  }}
                  onClick={handleNext}
                >
                  {t('signIn.next')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpComponent;
