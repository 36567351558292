import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';

const EmptyComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Typography>{t('empty.first.text')} </Typography>
      <Typography>{t('empty.sub.text')}</Typography>
    </Box>
  );
};

import { useTranslation } from 'react-i18next';

export default EmptyComponent;
