import { typedAction } from '../../utils';

export const SET_GUEST_COUNTER_PART_REQUEST = 'SET/GUEST/COUNTER/PART/REQUEST';
export const SET_GUEST_COUNTER_PART_SUCCESS = 'SET/GUEST/COUNTER/PART/SUCCESS';
export const SET_GUEST_COUNTER_PART_FAILED = 'SET/GUEST/COUNTER/PART/FAILED';

export interface IdataCounterPart {
  revenuesLowerBound: number;
  revenuesUpperBound: number;
  type: number;
  description: string;
}

export interface IparamsCounterPart {
  id: string;
  data: IdataCounterPart[];
}

export interface IinitialStateCounterPart {
  loading: boolean;
  id: string;
  error: string | undefined;
}

const initialState: IinitialStateCounterPart = {
  loading: false,
  id: '',
  error: undefined,
};

export const DO_setCounterPart = (params: IparamsCounterPart) => typedAction(SET_GUEST_COUNTER_PART_REQUEST, params);
export type IDO_setCounterPart = typeof DO_setCounterPart;

export const setCounterPartSuccess = (result: any) => typedAction(SET_GUEST_COUNTER_PART_SUCCESS, result);
export type IsetCounterPartSuccess = typeof setCounterPartSuccess;

export const setCounterPartFailed = () => typedAction(SET_GUEST_COUNTER_PART_FAILED);
export type IsetCounterPartFailed = typeof setCounterPartFailed;

export type counterPartAction = ReturnType<IDO_setCounterPart | IsetCounterPartFailed | IsetCounterPartSuccess>;

export const CounterPartReducer = (
  state: IinitialStateCounterPart = initialState,
  action: counterPartAction,
): IinitialStateCounterPart => {
  switch (action.type) {
    case SET_GUEST_COUNTER_PART_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case SET_GUEST_COUNTER_PART_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload,
      };
    case SET_GUEST_COUNTER_PART_FAILED:
      return {
        ...state,
        error: 'error',
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
