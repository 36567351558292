import { typedAction } from '../utils';

export interface Iparams {
  liveSessionId: string;
  scheduledStartDate: string;
  scheduledStartTime: number;
  callback: (res: any) => void;
}

export interface IerrorCreate {
  error: string;
  status: number;
}

export interface initialStateCreate {
  loading: boolean;
  status: number | undefined;
  error: string | undefined;
  id: string;
}

export interface IsuccessParams {
  id: string;
  status: number | undefined;
}

const initialState: initialStateCreate = {
  id: '',
  error: '',
  loading: false,
  status: undefined,
};

export const DUPLICATE_LIVE_SESSION_REQUEST = 'DUPLICATE_LIVE_SESSION_REQUEST';
export const DUPLICATE_LIVE_SESSION_SUCCESS = 'DUPLICATE_LIVE_SESSION_SUCCESS';
export const DUPLICATE_LIVE_SESSION_FAILED = 'DUPLICATE_LIVE_SESSION_FAILED';

export const duplicate_live_session = (data: Iparams) => typedAction(DUPLICATE_LIVE_SESSION_REQUEST, data);
export type Duplicate_live_session = typeof duplicate_live_session;

export const duplicate_live_session_success = (result: IsuccessParams) =>
  typedAction(DUPLICATE_LIVE_SESSION_SUCCESS, result);
export type Duplicate_live_session_success = typeof duplicate_live_session_success;

export const duplicate_live_session_failed = (error: IerrorCreate) => typedAction(DUPLICATE_LIVE_SESSION_FAILED, error);
export type Duplicate_live_session_failed = typeof duplicate_live_session_failed;

export type DuplicateLiveSessionAction = ReturnType<
  Duplicate_live_session | Duplicate_live_session_success | Duplicate_live_session_failed
>;
export const DuplicateLiveSessionReducer = (
  state: initialStateCreate = initialState,
  action: DuplicateLiveSessionAction,
): initialStateCreate => {
  switch (action.type) {
    case DUPLICATE_LIVE_SESSION_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case DUPLICATE_LIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        id: action.payload.id,
        status: action.payload.status,
      };
    case DUPLICATE_LIVE_SESSION_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
