import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, ClickAwayListener, Fade, Popper, TextareaAutosize, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBackdrop from '../../../common/backdrop/CustomBackdrop';
import { ButtonImage } from '../../../common/buttonWithImage/buttonImage';
import { CustomSelect } from '../../../common/customSelect/CustomSelect';
import CustomModal from '../../../common/modal/Modal';
import { CustomSnackBar } from '../../../common/snackBar/CustomSnackBar';
import CustomTextField from '../../../common/TextInput/CustomTextField';
import { GetTimeFromMins } from '../../../constant/function';
import { RootState } from '../../../redux-services';
import { getCategorie, IinitialStateCategorie } from '../../../redux-services/modules/categorie';
import { getduration, IresultDuration } from '../../../redux-services/modules/duration';
import { getlistgallerie, IstateGallerieInitial } from '../../../redux-services/modules/gallerieImage';
import {
  create_live_session,
  initialStateCreate,
  Iparams,
} from '../../../redux-services/modules/liveSessionRetailerCreate';
import {
  getliveSessionById as findLiveById,
  IinitialStateGet,
} from '../../../redux-services/modules/liveSessionRetailerget';
import { initialStateUpdate, update_live_session } from '../../../redux-services/modules/liveSessionRetailerUpdate';
import { getTimezone, IinitialTimezone } from '../../../redux-services/modules/timezone';
import { IstateInitialUpload, uploadImage } from '../../../redux-services/modules/uploadImage';
import { ModalImage } from './ModalImage';
import { useStyles } from './style';
import { forceScrollToTop } from '../../../utils/common';

interface durations {
  hours: number;
  min: number;
}

interface IOptionValue {
  id: number | string;
  name: string;
}

const InfoLive: React.FC = () => {
  // const history = useHistory();
  const initialState = {
    title: '',
    description: '',
    categoryId: '',
    scheduledStartDate: new Date().toISOString(),
    scheduledStartTime: new Date().getMinutes() + new Date().getHours() * 60,
    timeZoneId: 0,
    scheduledDuration: 0,
    privacy: 1,
    isPublished: true,
    previewImageId: '',
    pausedLiveImageId: null,
    endedLiveImageId: null,
    previewImageUrl: '',
    pausedLiveImageUrl: null,
    endedLiveImageUrl: null,
    primaryColor: '#518971',
  };
  const { id }: any = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const timezone = useSelector<RootState, IinitialTimezone>((state) => state.timezone);
  //initialStateUpdate
  const updateLiveSession = useSelector<RootState, initialStateUpdate>((state) => state.updateLiveSession);
  const createLiveSession = useSelector<RootState, initialStateCreate>((state) => state.createLiveSession);
  const getInfoduration = useSelector<RootState, IresultDuration>((state) => state.duration);
  const getLiveSession = useSelector<RootState, IinitialStateGet>((state) => state.getLiveSessionById);
  const categorieData = useSelector<RootState, IinitialStateCategorie>((state) => state.categorie);
  const ListGallerie = useSelector<RootState, IstateGallerieInitial>((state) => state.gallerieImage);
  const Do_uploadImage = useSelector<RootState, IstateInitialUpload>((state) => state.uploadImage);
  const { maxDuration, minutesStep, status: statusDuration, loading: loadingDuration } = getInfoduration;
  const { loading: loadingUpload, id: idImageUploaded } = Do_uploadImage;
  const { loading: loadingUpdate, status: statusUpdate, error: errorUpdate } = updateLiveSession;
  const { categorie: ListCategorie, error: errorCategorie } = categorieData;
  const { error: errorGallerie, loading: loadingGallerie, gallerie } = ListGallerie;
  const { error, loading: loadingTimezone, timezone: listTimezone } = timezone;
  const { loading: loadingcreate, error: errorCreate, status: statusCreate, id: newLive } = createLiveSession;
  const { error: erroGetLive, loading: loadingGetLive, live: liveGetted } = getLiveSession;
  const [selectedDate, setselectedDate] = useState(new Date());
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [duration, setduration] = useState<durations>({ hours: 0, min: 0 });
  const [openModalimagePrev, setopenModalimagePrev] = useState<boolean>(false);
  const [openModalimagePause, setopenModalimagePause] = useState<boolean>(false);
  const [openModalimageFin, setopenModalimageFin] = useState<boolean>(false);
  const [isImage, setisImage] = useState<string>('');
  const [liveCreated, setLiveCreated] = useState<Iparams>(initialState);
  const [optionhours, setOptionHours] = useState<IOptionValue[]>([{ id: 0, name: '0' }]);
  const [optionPrev, setOptionPrev] = useState<IOptionValue[]>([{ id: 0, name: '0' }]);
  const [openDuration, setOpenerrorDuration] = useState<boolean>(false);
  const [messageDuration, setmessageDuration] = useState<string>('');
  const [openModalQuitPage, setopenModalQuitPage] = useState<boolean>(false);
  const [showPalette, setShowPalette] = useState<boolean>(false);
  const [time, setTime] = useState(new Date());
  const [create, setcreate] = useState<boolean>(false);
  const [errorRequiredImg, setErrorRequiredImg] = useState<boolean>(false);
  const classes = useStyles();
  const myRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const idPopper = open ? 'transitions-popper' : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const options_status = [
    { id: 0, name: t('formulaire.status.private') },
    { id: 1, name: t('formulaire.status.public') },
  ];
  useEffect(() => {
    dispatch(getTimezone());
    dispatch(getlistgallerie({ index: 0, size: 5 }));
    dispatch(getCategorie());
    dispatch(getduration());
  }, []);

  const handleValidate = async () => {
    const totalDuration = duration.hours * 60 + duration.min;
    if (!liveCreated.title?.trim()) {
      setLiveCreated({ ...liveCreated, title: null });
      return;
    }
    if (!liveCreated.categoryId?.trim()) {
      setLiveCreated({ ...liveCreated, categoryId: null });
      return;
    }
    if (!liveCreated.description?.trim()) {
      setLiveCreated({ ...liveCreated, description: null });
      return;
    }
    if (!liveCreated.timeZoneId) {
      setLiveCreated({ ...liveCreated, timeZoneId: null });
      return;
    }
    if (totalDuration === 0 || totalDuration > maxDuration) {
      setmessageDuration(`${t('common.error.duration')} ${maxDuration / 60}h : ${maxDuration % 60}min`);
      setOpenerrorDuration(!openDuration);
      return;
    }

    if (!liveCreated.endedLiveImageId) {
      setLiveCreated({ ...liveCreated, endedLiveImageId: null });
      setErrorRequiredImg(true);
      return;
    }
    if (!liveCreated.pausedLiveImageId) {
      setLiveCreated({ ...liveCreated, pausedLiveImageId: null });
      setErrorRequiredImg(true);
      return;
    }
    if (!liveCreated.previewImageId) {
      setLiveCreated({ ...liveCreated, previewImageId: null });
      setErrorRequiredImg(true);
      return;
    }

    if (moment(liveCreated.scheduledStartDate).isBefore(new Date(), 'day')) {
      return;
    }
    if (id) {
      await dispatch(
        update_live_session({
          id: id,
          body: {
            ...liveCreated,
            title: liveCreated.title,
            description: liveCreated.description,
            categoryId: liveCreated.categoryId,
            timeZoneId: liveCreated.timeZoneId,
            endedLiveImageId: liveCreated.endedLiveImageId,
            pausedLiveImageId: liveCreated.pausedLiveImageId,
            previewImageId: liveCreated.previewImageId,
            scheduledDuration: totalDuration > 180 ? 180 : totalDuration,
          },
        }),
      );

      // history.push(`/dashboard/livesessions/${id}`);
    } else {
      await dispatch(
        create_live_session({
          ...liveCreated,
          scheduledDuration: totalDuration > 180 ? 180 : totalDuration,
        }),
      );
    }
    setErrorRequiredImg(false);
    setcreate(true);
  };

  const onConfimQuitPage = async () => {
    await setLiveCreated(initialState);
    if (id) {
      history.push(`/dashboard/livesessions/${id}`);
    } else {
      history.push(`/dashboard`);
    }
  };
  const OnCloseDialogQuiPage = () => {
    setopenModalQuitPage(!openModalQuitPage);
  };

  const OnChangePause = async (e: any) => {
    if (e.target.files[0]) {
      await setisImage('Pause');
      dispatch(uploadImage(e.target.files[0]));
      setopenModalimagePause(!openModalimagePause);
    }
  };
  const OnChangeFin = async (e: any) => {
    if (e.target.files[0]) {
      await setisImage('end');
      dispatch(uploadImage(e.target.files[0]));
      setopenModalimageFin(!openModalimageFin);
    }
  };
  const OnChangePrev = async (e: any) => {
    if (e.target.files[0]) {
      await setisImage('Prev');
      dispatch(uploadImage(e.target.files[0]));
      setopenModalimagePrev(!openModalimagePrev);
    }
  };

  useEffect(() => {
    if (idImageUploaded) {
      switch (isImage) {
        case 'Prev':
          setLiveCreated({
            ...liveCreated,
            previewImageId: idImageUploaded,
            previewImageUrl: gallerie.items.find((i) => i.id === idImageUploaded)?.url || '',
          });
          break;
        case 'Pause':
          setLiveCreated({
            ...liveCreated,
            pausedLiveImageId: idImageUploaded,
            pausedLiveImageUrl: gallerie.items.find((i) => i.id === idImageUploaded)?.url || '',
          });
          break;
        case 'end':
          setLiveCreated({
            ...liveCreated,
            endedLiveImageId: idImageUploaded,
            endedLiveImageUrl: gallerie.items.find((i) => i.id === idImageUploaded)?.url || '',
          });
          break;
        default:
          break;
      }
    }
  }, [idImageUploaded, gallerie]);

  useEffect(() => {
    if (id) {
      dispatch(findLiveById(id));
    }
  }, [id]);

  useEffect(() => {
    if (liveGetted && !loadingGetLive && id) {
      setLiveCreated({
        categoryId: liveGetted.categoryId,
        description: liveGetted.description,
        endedLiveImageId: liveGetted.endedLiveImageId,
        isPublished: liveGetted.isPublished,
        pausedLiveImageId: liveGetted.pausedLiveImageId,
        previewImageId: liveGetted.previewImageId,
        privacy: liveGetted.privacy,
        timeZoneId: liveGetted.timeZoneId,
        title: liveGetted.title,
        scheduledStartDate: liveGetted.scheduledStartDate,
        scheduledDuration: liveGetted.scheduledDuration,
        primaryColor: liveGetted.primaryColor,
        scheduledStartTime: liveGetted.scheduledStartTime,
        previewImageUrl: liveGetted.previewImageUrl,
        pausedLiveImageUrl: liveGetted.pausedLiveImageUrl,
        endedLiveImageUrl: liveGetted.endedLiveImageUrl,
      });
      setduration({ hours: Math.trunc(liveGetted.scheduledDuration / 60), min: liveGetted.scheduledDuration % 60 });
      setErrorRequiredImg(true);
    }
  }, [liveGetted, loadingGetLive, id]);

  useEffect(() => {
    if (
      (newLive && newLive !== '' && !loadingcreate && create && !errorCreate && !id) ||
      (!loadingUpdate && !errorUpdate && statusUpdate === 200 && create && id && id !== '')
    ) {
      setLiveCreated(initialState);
      history.push(`/dashboard/livesessions/${id ? id : newLive}`);
      setcreate(false);
    }
  }, [loadingcreate, newLive, create, errorUpdate, loadingUpdate, statusUpdate, errorCreate]);

  useEffect(() => {
    if (maxDuration && minutesStep && !loadingDuration) {
      const hours = maxDuration / 60 + 1;
      const stepCount = Math.ceil(60 / minutesStep);

      const tablePrev = Object.keys(Array.from(Array(stepCount))).map((key) => ({
        id: +key * minutesStep,
        name: (+key * minutesStep).toString(),
      }));
      const tablehours = Object.keys(Array.from(Array(hours))).map((key) => ({
        id: +key,
        name: (+key).toString(),
      }));
      setOptionHours(tablehours);
      setOptionPrev(tablePrev);
    }
  }, [maxDuration, minutesStep, loadingDuration]);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (pathname === `/dashboard/livesessions/${id}/update`) {
        return 'show warning';
      }
      return;
    }
  }, []);

  /*useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ your logic here
        handleClickAway();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);*/

  return (
    <>
      <Box className={classes.sectionOne}>
        {loadingUpdate && <CustomBackdrop {...{ open: loadingUpdate }} />}
        {loadingcreate && <CustomBackdrop {...{ open: loadingcreate }} />}
        {loadingGetLive && <CustomBackdrop {...{ open: loadingGetLive }} />}
        <CustomSnackBar
          {...{ open: openDuration, setOpen: setOpenerrorDuration, message: messageDuration, type: 'error' }}
        />
        <Box className={`${classes.rootBox}`}>
          <Box className={`${classes.mobileView} ${classes.mobileValidationButton}`}>
            <Button onClick={handleValidate} className={classes.btnValid}>
              {t('formulaire.btn.validate')}
            </Button>
            <Button className={classes.btnCancel} onClick={OnCloseDialogQuiPage}>
              {t('formulaire.btn.cancel')}
            </Button>
          </Box>
          <Box className={classes.col1}>
            <Typography className={classes.desktopView} variant="h5">
              {t('formulaire.info')}
            </Typography>
            <Box className={classes.boxContent}>
              <Box className={classes.boxImageButton}>
                <ButtonImage
                  styleRoot={{ width: '100%' }}
                  onClick={async () => {
                    await dispatch(getlistgallerie({ index: 0, size: 5 }));
                    setopenModalimagePrev(!openModalimagePrev);
                  }}
                  urlImage={liveCreated.previewImageUrl}
                  style={{ border: liveCreated.previewImageId === null ? '2px solid red' : '' }}
                >
                  <Box>
                    <KeyboardArrowUpIcon />
                    <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                      {t('formulaire.image.preview')}
                    </Typography>
                  </Box>
                </ButtonImage>
                {!liveCreated.previewImageUrl && errorRequiredImg && (
                  <Typography className={classes.textRequired} style={{ marginLeft: 10 }}>
                    {'Champ Obligatoire'}
                  </Typography>
                )}
              </Box>
              <Box className={classes.boxImageButton}>
                <ButtonImage
                  styleRoot={{ width: '100%' }}
                  onClick={async () => {
                    await dispatch(getlistgallerie({ index: 0, size: 5 }));
                    setopenModalimagePause(!openModalimagePause);
                  }}
                  urlImage={liveCreated.pausedLiveImageUrl}
                >
                  <Box>
                    <KeyboardArrowUpIcon />
                    <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                      {t('formulaire.image.pause')}
                    </Typography>
                  </Box>
                </ButtonImage>
                {!liveCreated.pausedLiveImageUrl && errorRequiredImg && (
                  <Typography className={classes.textRequired} style={{ marginLeft: 10 }}>
                    {'Champ Obligatoire'}
                  </Typography>
                )}
              </Box>

              <Box className={classes.boxImageButton}>
                <ButtonImage
                  styleRoot={{ width: '100%' }}
                  onClick={async () => {
                    await dispatch(getlistgallerie({ index: 0, size: 5 }));
                    setopenModalimageFin(!openModalimageFin);
                  }}
                  urlImage={liveCreated.endedLiveImageUrl}
                >
                  <Box>
                    <KeyboardArrowUpIcon />
                    <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                      {t('formulaire.image.fin')}
                    </Typography>
                  </Box>
                </ButtonImage>
                {!liveCreated.endedLiveImageUrl && errorRequiredImg && (
                  <Typography className={classes.textRequired} style={{ marginLeft: 10 }}>
                    {'Champ Obligatoire'}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.col2}>
            <Box>
              <Typography variant="subtitle1">{t('formulaire.live.name')}</Typography>
              <CustomTextField
                {...{
                  require: true,
                  name: 'title',
                  placeholder: t('formulaire.live.name'),
                  onChange: (event) => setLiveCreated({ ...liveCreated, title: event.target.value || null }),
                  textError: 'Champ obligatoire',
                  value: liveCreated.title || '',
                  error: liveCreated.title === null,
                }}
              />
            </Box>
            <Box
              style={{ width: '50%' }}
              className={liveCreated.categoryId === null ? classes.categorieError : classes.categorieSuccess}
            >
              <Typography variant="subtitle1">{t('formulaire.live.categorie')}</Typography>
              <CustomSelect
                {...{
                  Optionvalue: ListCategorie,
                  className: classes.selectCategorie,
                  inputProps: { className: classes.inputPropsSelect },
                  variant: 'outlined',
                  value: liveCreated.categoryId,
                  onChange: (event: any) => {
                    setLiveCreated({ ...liveCreated, categoryId: event.target.value });
                  },
                }}
              />
              {liveCreated.categoryId === null && (
                <Typography className={classes.textRequired}>{'Champ Obligatoire'}</Typography>
              )}
            </Box>
            {/* <Box style={{ width: '50%' }}>
              <Typography>Langue </Typography>
              <CustomSelect
                {...{
                  Optionvalue: Option_langue,
                  className: classes.SelectVideo1,
                  inputProps: { className: classes.inputPropsSelect },
                  variant: 'outlined',
                }}
              />
            </Box> */}
            <Box>
              <Typography variant="subtitle1">{t('formulaire.live.descriptions')}</Typography>
              <TextareaAutosize
                aria-label="minimum height"
                placeholder="Descriptions"
                {...{ maxRows: 6, minRows: 6 }}
                className={classes.descriptions}
                style={{ borderColor: liveCreated.description === null ? 'red' : '' }}
                value={liveCreated.description || ''}
                onChange={(event) => {
                  setLiveCreated({ ...liveCreated, description: event.target.value });
                }}
              />
              {liveCreated.description === null && (
                <Typography className={classes.textRequired}>{'Champ Obligatoire'}</Typography>
              )}
            </Box>
            <Box className={classes.boxDate}>
              <Box>
                <Typography variant="subtitle1">{t('formulaire.live.date')}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    open={openDate}
                    onClick={() => setOpenDate(!openDate)}
                    onClose={() => {
                      setOpenDate(false);
                    }}
                    disablePast={true}
                    variant="inline"
                    minDateMessage={() => {
                      return <Typography>dates invalide</Typography>;
                    }}
                    style={{ color: 'red' }}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={liveCreated.scheduledStartDate}
                    InputAdornmentProps={{
                      position: 'end',
                      style: { padding: 0 },
                    }}
                    onChange={(date) => {
                      if (date) {
                        setselectedDate(date);
                        setLiveCreated({ ...liveCreated, scheduledStartDate: moment(date).utc(true).toISOString() });
                      }
                    }}
                    onBlur={forceScrollToTop}
                    // minDate={liveCreated.scheduledStartDate}
                    {...{ inputProps: { className: classes.dateStyle } }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ marginLeft: 5 }}>
                <Typography variant="subtitle1">{t('formulaire.live.time')}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    ampm={false}
                    variant="inline"
                    inputVariant="outlined"
                    value={liveCreated.scheduledStartTime ? GetTimeFromMins(liveCreated.scheduledStartTime) : time}
                    onChange={(date) => {
                      if (date) {
                        setTime(date);
                        setLiveCreated({
                          ...liveCreated,
                          scheduledStartTime: date.getHours() * 60 + date.getMinutes(),
                        });
                      }
                    }}
                    onBlur={forceScrollToTop}
                    inputProps={{ className: classes.dateStyle }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box
                className={liveCreated.timeZoneId === null ? classes.TimezoneError : classes.TimezoneSuccess}
                style={{ marginLeft: 5 }}
              >
                <Typography variant="subtitle1">{t('formulaire.live.fuseau.horaire')}</Typography>
                <CustomSelect
                  {...{
                    loading: loadingTimezone,
                    Optionvalue: listTimezone.map((item) => {
                      const sign = item.utcOffset >= 0 ? '+' : '';
                      return {
                        id: item.id,
                        name: item.name + ` (UTC${sign}${item.utcOffset / 60})`,
                        offset: item.utcOffset,
                      };
                    }),
                    // .sort((a, b) => {
                    //   if (a.offset > b.offset) {
                    //     return -1;
                    //   } else {
                    //     return 1;
                    //   }
                    // })
                    className: classes.selectOption,
                    inputProps: { className: classes.inputPropsSelect },
                    variant: 'outlined',
                    value: liveCreated.timeZoneId,
                    MenuProps: {
                      onClick: () => {
                        dispatch(getTimezone());
                      },
                    },
                    onChange: (event: any) => {
                      setLiveCreated({
                        ...liveCreated,
                        timeZoneId: event.target.value,
                      });
                    },
                  }}
                />
                {liveCreated.timeZoneId === null && (
                  <Typography className={classes.textRequired}>{'Champ Obligatoire'}</Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">{t('formulaire.live.duration')}</Typography>
              <Box className={classes.boxduration}>
                <CustomSelect
                  {...{
                    loading: loadingDuration,
                    Optionvalue: optionhours,
                    className: classes.selectCategorie,
                    inputProps: { className: classes.inputPropsSelect },
                    variant: 'outlined',
                    value: parseInt(`${duration.hours >= 60 ? duration.hours / 60 : duration.hours}`),
                    onChange: (event: any) => {
                      const item = event.target.value;
                      if (item >= maxDuration) {
                        setduration({ ...duration, min: 0, hours: item });
                      } else {
                        setduration({ ...duration, hours: item });
                      }
                    },
                  }}
                />
                <Typography variant="subtitle2" style={{ margin: 5 }}>
                  {t('formulaire.live.heure')}
                </Typography>
                <CustomSelect
                  {...{
                    MenuProps: {},
                    disabled: (duration.hours >= 60 ? duration.hours : duration.hours * 60) === maxDuration,
                    loading: loadingDuration,
                    Optionvalue: optionPrev,
                    className: classes.SelectVideo1,
                    inputProps: { className: classes.inputPropsSelect },
                    variant: 'outlined',
                    value: duration.min > 60 ? duration.min % 60 : duration.min,
                    onChange: (event: any) => {
                      setduration({ ...duration, min: event.target.value });
                    },
                  }}
                />
                {(duration.hours >= 60 ? duration.hours : duration.hours * 60) === maxDuration ? null : (
                  <Typography variant="subtitle2" style={{ margin: 5 }}>
                    {t('formulaire.live.minutes')}{' '}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Typography variant="subtitle1">{t('formulaire.live.status')}</Typography>
              <CustomSelect
                {...{
                  Optionvalue: options_status,
                  className: classes.customSelectStatus,
                  inputProps: { className: classes.inputPropsSelect },
                  variant: 'outlined',
                  value: liveCreated.privacy,
                  onChange: (event: any) => {
                    setLiveCreated({
                      ...liveCreated,
                      privacy: event.target.value,
                    });
                  },
                }}
              />
            </Box>
            <Box>
              <Box style={{ marginBottom: 75 }}>
                <Typography variant="subtitle1">{t('formulaire.live.color')}</Typography>

                <Box
                  style={{
                    width: 270,
                    border: '1px solid grey',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    borderRadius: 4,
                  }}
                >
                  <Box
                    style={{
                      background: `${liveCreated.primaryColor || '#518971'}`,
                      width: 25,
                      height: '50%',
                      marginLeft: 10,
                    }}
                  />
                  <Typography>{liveCreated.primaryColor || '#518971'}</Typography>
                  <Box style={{ cursor: 'pointer' }} onClick={handleClick}>
                    {!open ? (
                      <KeyboardArrowDownIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    ) : (
                      <KeyboardArrowUpIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    )}
                  </Box>
                </Box>
              </Box>

              <Popper id={idPopper} open={open} placement="top" anchorEl={anchorEl} transition style={{ zIndex: 100 }}>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <div className={classes.paper}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <ChromePicker
                          ref={myRef as any}
                          disableAlpha
                          color={liveCreated.primaryColor || '#518971'}
                          key="ColorPicker"
                          onChange={(e) => {
                            setLiveCreated({
                              ...liveCreated,
                              primaryColor: e.hex,
                            });
                          }}
                          className={classes.customPicker}
                          onChangeComplete={(color, event) => {
                            event.stopPropagation();
                          }}
                        />
                      </ClickAwayListener>
                    </div>
                  </Fade>
                )}
              </Popper>
            </Box>
            <Box className={classes.desktopView}>
              <Button onClick={handleValidate} className={classes.btnValid}>
                {t('formulaire.btn.validate')}
              </Button>
              <Button className={classes.btnCancel} onClick={OnCloseDialogQuiPage}>
                {t('formulaire.btn.cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
        <ModalImage
          {...{
            loadingUpload: loadingUpload,
            gallerie: gallerie,
            openModal: openModalimagePause,
            onChangeUpload: OnChangePause,
            setOpenModal: setopenModalimagePause,
            setImage: (url, idImage) => {
              setLiveCreated({ ...liveCreated, pausedLiveImageId: idImage, pausedLiveImageUrl: url });
              setopenModalimagePause(!openModalimagePause);
            },
          }}
        />
        <ModalImage
          {...{
            loadingUpload: loadingUpload,
            gallerie: gallerie,
            openModal: openModalimageFin,
            onChangeUpload: OnChangeFin,
            setOpenModal: setopenModalimageFin,
            setImage: (url, idImage) => {
              setLiveCreated({ ...liveCreated, endedLiveImageId: idImage, endedLiveImageUrl: url });
              setopenModalimageFin(!openModalimageFin);
            },
          }}
        />
        <ModalImage
          {...{
            loadingUpload: loadingUpload,
            gallerie: gallerie,
            openModal: openModalimagePrev,
            onChangeUpload: OnChangePrev,
            setOpenModal: setopenModalimagePrev,
            setImage: (url, idImage) => {
              setLiveCreated({ ...liveCreated, previewImageId: idImage, previewImageUrl: url });
              setopenModalimagePrev(!openModalimagePrev);
            },
          }}
        />
        <CustomModal
          {...{
            title: 'Confirmation',
            maxWidth: 'xs',
            open: openModalQuitPage,
            TextCancel: 'Annuler',
            textConfirm: 'Confirmer',
            onClose: OnCloseDialogQuiPage,
            onConfirm: onConfimQuitPage,
          }}
        >
          <Typography>{t('formulaire.confirmation.text')}</Typography>
        </CustomModal>
      </Box>
      {/* <Box className={classes.sectionTwo}></Box> */}
    </>
  );
};
export default InfoLive;
