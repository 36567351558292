import theme from '../../Themes';
import { typedAction } from '../utils';

export interface IgetLive {
  id: string;
}

export interface IliveRetailerById {
  id: string;
  title: string;
  description: string;
  categoryId: string;
  scheduledStartDate: string;
  scheduledStartTime: number;
  timeZoneId: number;
  scheduledDuration: number;
  privacy: number;
  primaryColor: string;
  isPublished: boolean;
  previewImageId: string;
  previewImageUrl: string;
  pausedLiveImageId: string;
  pausedLiveImageUrl: string;
  endedLiveImageId: string;
  endedLiveImageUrl: string;
  replayVideoFileUrl: string;
}

export interface IinitialStateGet {
  loading: boolean;
  error: string;
  live: IliveRetailerById;
}
const initialState: IinitialStateGet = {
  loading: false,
  error: '',
  live: {
    id: '',
    title: '',
    description: '',
    categoryId: '',
    scheduledStartDate: new Date().toISOString(),
    scheduledStartTime: 0,
    timeZoneId: 0,
    scheduledDuration: 0,
    privacy: 0,
    isPublished: false,
    previewImageId: '',
    previewImageUrl: '',
    pausedLiveImageId: '',
    pausedLiveImageUrl: '',
    endedLiveImageId: '',
    endedLiveImageUrl: '',
    primaryColor: theme.palette.primary.main,
    replayVideoFileUrl: '',
  },
};

export const GET_LIVE_SESSION_BY_ID_REQUEST = 'GET_LIVE_SESSION_BY_ID_REQUEST';
export const GET_LIVE_SESSION_BY_ID_SUCCESS = 'GET_LIVE_SESSION_BY_ID_SUCCESS';
export const GET_LIVE_SESSION_BY_ID_FAILED = 'GET_LIVE_SESSION_BY_ID_FAILED';

export const getliveSessionById = (id: string) => typedAction(GET_LIVE_SESSION_BY_ID_REQUEST, id);
export type GetliveSessionById = typeof getliveSessionById;

export const getliveSessionByIdSuccess = (result: IliveRetailerById) =>
  typedAction(GET_LIVE_SESSION_BY_ID_SUCCESS, result);
export type GetliveSessionByIdSuccess = typeof getliveSessionByIdSuccess;

export const getliveSessionByIdFailed = (error: string) => typedAction(GET_LIVE_SESSION_BY_ID_FAILED, error);
export type GetliveSessionByIdFailed = typeof getliveSessionByIdFailed;

export type liveSessionById = ReturnType<GetliveSessionById | GetliveSessionByIdSuccess | GetliveSessionByIdFailed>;

export const getliveSessionByIdReducer = (
  state: IinitialStateGet = initialState,
  action: liveSessionById,
): IinitialStateGet => {
  switch (action.type) {
    case GET_LIVE_SESSION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIVE_SESSION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        live: action.payload,
      };
    case GET_LIVE_SESSION_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
