import React, { useEffect, useMemo, useState } from 'react';
import { useStyles } from '../style';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import { startCheckHistoryListener } from '../../../redux-services/modules/listenerHistory';
import { getliveSessionConsumerById } from '../../../redux-services/modules/liveSessionConsumerget';
import { useTranslation } from 'react-i18next';
import liveSessionServices from '../../../redux-services/services/liveSessionServices';
import { Participant } from 'livekit-client';
import clsx from 'clsx';
// @ts-ignore
import randomColor from 'randomcolor';

interface IParticipant {
  id: string;
  name: string;
  type?: number;
  identifiant: string;
  profilePictureUrl: string;
}

interface IViewersProps {
  userId: string | undefined;
  internet: boolean;
  id: string;
  participant: IParticipant[];
  participantLive?: Participant[];
  loading: boolean;
  isLiveStarted?: boolean;
  fullscreen?: boolean;
}

const Viewers: React.FC<IViewersProps> = (props: IViewersProps) => {
  const classes = useStyles();
  const { internet, id, participant, participantLive, loading, isLiveStarted, userId, fullscreen } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [friends, setFriends] = useState<Participant[]>([]);
  const [friendsP, setFriendsP] = useState<IParticipant[]>([]);
  const [vip, setVip] = useState<Participant[]>([]);
  const [other, setOther] = useState<Participant[]>();
  const participantOthers = participant?.filter((p0) => p0.type === 0);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const filterOther = participantLive?.filter((p) => {
      return participantOthers.find((pO) => pO.name === p.name);
    });
    setOther(filterOther);
  }, [participant]);

  useEffect(() => {
    if (id) {
      dispatch(getliveSessionConsumerById(id));
    }
  }, [id]);

  useEffect(() => {
    if (internet) {
      (async () => {
        const _participantByLive = await liveSessionServices.getParticipantByLive(id, 'Consumer');
        const _userId = _participantByLive?.participantId;
        if (id && _userId) {
          dispatch(startCheckHistoryListener({ userType: 'Consumer', id: `${id}?participantId=${_userId}` }));
        }
      })();
    }
  }, [internet]);

  useEffect(() => {
    const fri = participant?.filter((p1) => p1.type === 1 && p1.id !== userId);
    setFriendsP(fri);
  }, [participant, id]);

  useEffect(() => {
    if (participantLive) {
      const fri = participantLive?.filter((p1) => {
        return friendsP.find((p2) => p1.identity === p2.id);
      });
      setFriends(fri);
      const vip = participantLive?.filter((p1) => {
        if (p1.metadata) {
          return JSON.parse(p1.metadata).grade === 'vip';
        }
      });
      setVip(vip);
    }
  }, [participantLive, friendsP]);

  return (
    <Box className={clsx({ [classes.viewers]: true, [classes.viewersFullscreen]: fullscreen })}>
      <Typography variant="h5">
        {`${t('consumer.live.viewer.title')} `}
        {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : participant?.length}
      </Typography>
      <Box className={classes.content}>
        <Accordion
          elevation={0}
          expanded={expanded === false || expanded === t('consumer.live.viewer.friends')}
          onChange={handleChange(t('consumer.live.viewer.friends'))}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="title" component="div">
              {`${t('consumer.live.viewer.friends')} `}
              {loading ? <CircularProgress size={15} style={{ marginLeft: '10px' }} /> : friends?.length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionDetails}
            style={{
              maxHeight: expanded === false ? '140px' : '300px',
              // backgroundColor: isLiveStarted ? 'white' : '#00000010',
            }}
          >
            {isLiveStarted ? (
              friends &&
              friends?.length > 0 &&
              friends.map((item, indexKey) => (
                <Tooltip key={indexKey} title={item.name as string}>
                  <Box className={classes.boxAvatar}>
                    <Avatar
                      alt={item.identity}
                      className={classes.avatar}
                      src={item.metadata ? JSON.parse(item.metadata as string).profileImage : ''}
                      style={{
                        backgroundColor: item.metadata ? JSON.parse(item.metadata as string).backgroundColor : '',
                      }}
                    >
                      {item.name?.slice(0, 1)}
                    </Avatar>
                    <Box
                      component="div"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      width="95%"
                      whiteSpace="nowrap"
                      textAlign="center"
                      fontSize="12px"
                    >
                      {item.name?.slice(0, 8).trim()}
                    </Box>
                  </Box>
                </Tooltip>
              ))
            ) : (
              <Box />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded === false || expanded === 'VIP'} onChange={handleChange('VIP')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="title" component="div">
              {'VIP '}
              {loading ? <CircularProgress size={15} style={{ marginLeft: '10px' }} /> : vip?.length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionDetails}
            style={{
              maxHeight: expanded === false ? '140px' : '300px',
              // backgroundColor: isLiveStarted ? 'white' : '#00000010',
            }}
          >
            {isLiveStarted ? (
              vip.map((item, indexKey) => (
                <Tooltip key={indexKey} title={item.name as string}>
                  <Box className={classes.boxAvatar}>
                    <Avatar
                      alt={item.sid}
                      className={classes.avatar}
                      src={JSON.parse(item.metadata as string).profileImage}
                      style={{ backgroundColor: `${JSON.parse(item.metadata as string).backgroundColor}` }}
                    >
                      {item.name?.slice(0, 1)}
                    </Avatar>
                    <Box
                      component="div"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      width="95%"
                      whiteSpace="nowrap"
                      textAlign="center"
                      fontSize="12px"
                    >
                      {item.name?.slice(0, 8).trim()}
                    </Box>
                  </Box>
                </Tooltip>
              ))
            ) : (
              <Box style={{ height: 100 }} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={expanded === t('consumer.live.viewer.others')}
          onChange={handleChange(t('consumer.live.viewer.others'))}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="title" component="div">
              {`${t('consumer.live.viewer.others')} `}
              {loading ? <CircularProgress size={15} style={{ marginLeft: '10px' }} /> : participantOthers?.length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionDetails}
            // style={{ backgroundColor: isLiveStarted ? 'white' : '#00000010' }}
          >
            {isLiveStarted ? (
              other &&
              other.map((item, indexKey) => (
                <Tooltip key={indexKey} title={item.name as string}>
                  <Box className={classes.boxAvatar}>
                    <Avatar
                      alt={item.identity}
                      className={classes.avatar}
                      src={'http://'}
                      style={{ backgroundColor: JSON.parse(item.metadata as string).backgroundColor }}
                    >
                      {item.name && item.name.slice(0, 1).trim()}
                    </Avatar>
                    <Box
                      component="div"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      width="95%"
                      whiteSpace="nowrap"
                      textAlign="center"
                      fontSize="12px"
                    >
                      {item.name && item.name.split(' ')[0]}
                    </Box>
                  </Box>
                </Tooltip>
              ))
            ) : (
              <Box />
            )}
            {/* <Box className={classes.boxAvatar}>
              <Avatar alt="Jessica Snow" src={avatarimg} className={classes.avatar}></Avatar> Stéphanie Cli.
            </Box> */}
          </AccordionDetails>
        </Accordion>
        {/* <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="title">{t('consumer.live.viewer.more')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}></AccordionDetails>
        </Accordion> */}
      </Box>
    </Box>
  );
};

export default Viewers;
