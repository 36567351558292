import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerRoomVIP: {
      width: '55vw',
      backgroundColor: '#202124',
      '@media(max-width:1180px)': {
        width: '100vw',
        position: 'relative',
        zIndex: 11,
      },
    },
    containerRoomVIPFullscreen: {
      width: '68vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '@media(max-width:768px)': {
        width: '100vh',
        height: '100vw',
        display: 'block',
        transform: 'rotate(90deg) translate(27%, 0px)',
      },

      '@media screen (max-width:720px), screen and (max-height:480px)': {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        transform: 'none',
      },
    },
    containerRoom: {
      backgroundColor: '#202124',
      width: '45vw',
      '@media(max-width:1432px)': {
        width: '40vw',
      },
      '@media(max-width:1280px)': {
        width: '58vw',
      },
      '@media(max-width:962px)': {
        width: '80vw',
      },
      '@media(max-width:700px)': {
        width: '100vw',
      },
    },
    containerWithout: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#414040',
    },
    labelRound: {
      width: '15vw',
      height: '15vw',
      borderRadius: '50%',
      backgroundColor: 'rgba(50,136,170,0.74)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '2em',
      color: '#FFFFFF',
      fontWeight: 600,
      '@media(max-width:1180px)': {
        width: '35vw',
        height: '35vw',
      },
    },
    videoSection: {
      width: '100%',
      aspectRatio: '16/9',
      display: 'flex',
      justifyContent: 'center',
      '@media(max-width:1180px)': {
        aspectRatio: '16/6',
      },
      '@media(max-width:700px)': {
        aspectRatio: '4/3',
      },
    },
    bottomHandle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '66px',
    },
    bottomHandleFullscreen: {
      '@media(max-width:700px)': {
        position: 'relative',
      },
      '@media screen (max-width:720px), screen and (max-height:480px)': {
        position: 'relative',
      },
      '@media(max-height:670px)': {
        position: 'relative',
      },
    },
    bottomHandleDevice: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    topOptionUser: {
      position: 'relative',
      height: 0,
      top: 2,
      zIndex: 11,
    },
    btnActive: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '45px',
      height: '45px',
      borderRadius: '45px',
      backgroundColor: '#76797b',
      cursor: 'pointer',
    },
    btnDisabled: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '45px',
      height: '45px',
      borderRadius: '45px',
      backgroundColor: 'rgb(255,0,0)',
      cursor: 'pointer',
    },
    selectDevice: {
      width: 186,
      height: 40,
      borderRadius: 12,
    },
    roomForVip: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100px',
      overflow: 'hidden',
      position: 'relative',
    },
    roomForVipContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      overflow: 'hidden',
      width: '100%',
    },
    sectionVip: {
      minWidth: '140px',
      width: '140px',
      border: '1px solid #656565',
      backgroundColor: '#454545',
      '& > div > div > video': {
        objectFit: 'cover',
      },
      '& > div > div > div > div': {
        width: '52px',
        height: '52px',
      },

      '@media(max-width:700px)': {
        width: '110px',
        minWidth: '110px',
      },
    },
    vipSp: {
      border: '3px solid #1ba963',
      borderRadius: '8px',
    },
    bottomOptionUser: {
      position: 'relative',
      height: 0,
      display: 'flex',
      justifyContent: 'space-between',
      bottom: '26px',
      zIndex: 11,
      backgroundColor: '#454545',
    },
    namePart: {
      color: '#FFFFFF',
      paddingTop: 9,
      paddingLeft: 5,
      fontSize: '11px',
    },
    btnPinned: {
      width: '25px',
      height: '25px',
      borderRadius: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: 'rgb(0 0 0 / 62%)',
      marginRight: '3px',
      cursor: 'pointer',
    },
    pinnedVideoContainerFullscreen: {
      '@media(max-height:670px)': {
        height: '75vh',
      },
      '@media(max-width:700px)': {
        height: '59vw',
        '& > div > #videoSection > div > video': {
          height: '74vw',
        },
      },
      '@media screen (max-width:720px), screen and (max-height:480px)': {
        height: '54vh',
        '& > div > #videoSection > div > video': {
          height: '72vh',
        },
      },
    },
    pinnedVideoContainerFullscreenWithoutVIP: {
      '@media(max-width:700px)': {
        height: '84vw',
        '& > div': {
          height: '100vw',
        },
      },
      '@media screen (max-width:720px), screen and (max-height:480px)': {
        height: '84vh',
        '& > div': {
          height: '100vh',
        },
      },
    },
    iconReseau: {
      position: 'relative',
      top: '-39px',
      height: 0,
      left: 8,
    },
    blHandleScroll: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      zIndex: 80,
    },
    btnScroll: {
      background: '#FFF',
    },
    contentBtnScrollLeft: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgb(0 0 0 / 75%)',
      height: '100px',
      width: '20px',
      cursor: 'pointer',
      zIndex: 11,
      position: 'sticky',
      left: 0,
    },
    contentBtnScrollRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgb(0 0 0 / 75%)',
      height: '100px',
      width: '20px',
      cursor: 'pointer',
      zIndex: 11,
      position: 'sticky',
      right: 0,
    },
  }),
);
