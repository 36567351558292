import React, { FC, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, InputBase, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import Chat from '../../../common/chat/Chat';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import theme from '../../../Themes';
import { IMessage } from '../../../redux-services/type/listenerApi';
import { Participant, Room, RoomEvent } from 'livekit-client';
import { HighlightOff, PanToolTwoTone, VideoCallRounded } from '@material-ui/icons';
import AlertLivekitRoom from '../../LiveRoomComponent/AlertLivekitRoom';
import notificationSound from '../Notification.mp3';
import rings from '../../../Assets/sounds/notif.mp3';
import { HubConnection } from '@microsoft/signalr';
import Call from '../../../redux-services/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux-services';
import { IHistoryResult } from '../../../redux-services/modules/listenerHistory';

interface IItemChatProps {
  id: string;
  userType?: string;
  messages: IMessage[];
  loading: boolean;
  primaryColor?: string;
  backgroundAvatar: string;
  participantsVIP?: Participant[];
  room?: Room;
  connectionHub: HubConnection | null;
  userId: string;
}
interface IVipCompProps {
  participantsVIP: Participant[];
  room: Room;
  primaryColor?: string;
}
const ItemChatComponent: React.FC<IItemChatProps> = (props: IItemChatProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { messages, userType, id, loading, participantsVIP, room, primaryColor } = props;
  const [VIP, setVIP] = useState<boolean>(false);
  const [meta, setMeta] = useState<string>();
  const [newMessage, setNewMessage] = useState<boolean>(false);
  const [vipConnected, setVipConnected] = useState<Participant[] | undefined>([]);
  const [listMessage, setListMessage] = useState<IMessage[]>(messages);
  const soundPlayer = useRef(null);
  const audio = new Audio(rings);
  const dataHistory = useSelector<RootState, IHistoryResult>((state) => state.listenerHistory);

  useEffect(() => {
    if (room) {
      room.on(RoomEvent.ParticipantMetadataChanged, (payload, participant) => {
        setMeta(payload);
        const metadata = participant.metadata;
        const participantRequest = JSON.parse(metadata as string).participantRequest;
        if (participantRequest === 'manifest') {
          audio.play();
        }
      });
    }
  }, [room]);

  useEffect(() => {
    setVipConnected(participantsVIP);
  }, [participantsVIP]);

  useEffect(() => {
    (async () => {
      const idUser = localStorage.getItem('participantId');
      if (props.connectionHub) {
        props.connectionHub.on('MessageSent', (dataMessageSent) => {
          if (VIP) {
            setNewMessage(true);
          } else {
            setNewMessage(false);
          }
        });

        /*await props.connectionHub.start().then(async () => {
          if (props.connectionHub) await props.connectionHub.invoke('Join');
        });*/
      }
    })();
  }, [VIP, props.connectionHub]);

  useEffect(() => {
    if (!dataHistory.loading) {
      setListMessage((mess_) =>
        dataHistory.data.messages.length && dataHistory.data.messages[0].text != ''
          ? dataHistory.data.messages.map((m: IMessage) => {
              return m;
            })
          : mess_,
      );
    }
  }, [dataHistory]);

  return (
    <Box className={classes.itemOne}>
      <audio ref={soundPlayer} src={notificationSound} />
      <Box className={classes.head}>
        <Button
          style={{
            width: '60%',
            zIndex: !VIP ? -1 : 0,
            textTransform: 'unset',
          }}
          className={
            !VIP ? classes.activBton : newMessage ? classes.desactivBtnChatNewMessage : classes.desactivBtnChat
          }
          onClick={() => {
            setVIP(false);
            setNewMessage(false);
          }}
        >
          <Typography variant="h5" style={{ textTransform: 'unset', fontSize: 18 }}>
            {t('apercu.chat')}
          </Typography>
        </Button>
        <Button
          style={{
            backgroundColor: VIP ? '#F9FAF9' : '',
            zIndex: VIP ? -1 : 0,
            width: '40%',
          }}
          className={VIP ? classes.activBton : classes.desactivBtnVIP}
          onClick={() => {
            setVIP(true);
          }}
        >
          <Typography variant="h5">VIP</Typography>
        </Button>
      </Box>
      {!VIP && (
        <Box className="contentChat">
          <Chat
            primaryColor={primaryColor}
            messages={listMessage}
            userType={userType}
            id={id}
            loading={loading}
            backgroundAvatar={props.backgroundAvatar}
            connectionHub={props.connectionHub ? props.connectionHub : null}
            userId={props.userId}
          />
        </Box>
      )}

      {VIP && (
        <div className={classes.cvp}>
          {vipConnected && room && (
            <VipComponent participantsVIP={vipConnected} room={room} primaryColor={primaryColor} />
          )}
        </div>
      )}
    </Box>
  );
};

const VipComponent: FC<IVipCompProps> = ({ participantsVIP, primaryColor, room }) => {
  const classes = useStyles();
  const [vipClicked, setVipClicked] = useState<Participant>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openAlertDelete, setOpenAlertDelete] = useState<boolean>(false);

  const handleClickVip = (vip: Participant) => {
    setVipClicked(vip);
    const meta = JSON.parse(vip.metadata as string);
    if (!meta.showToLive) {
      setOpenAlert(true);
    } else {
      setOpenAlertDelete(true);
    }
  };

  return (
    <Box>
      <Box className={classes.vip}>
        <ul>
          {participantsVIP.length ? (
            participantsVIP.map((_vip, indexKey) => (
              <li className={classes.listVip} key={indexKey} onClick={() => handleClickVip(_vip)}>
                <div className={classes.vipContener}>
                  <Avatar
                    alt={_vip.name}
                    className={classes.avatar}
                    style={{ backgroundColor: `${JSON.parse(_vip.metadata as string).backgroundColor}` }}
                    src={JSON.parse(_vip.metadata as string).profileImage}
                  >
                    {_vip.name?.slice(0, 1)}
                  </Avatar>
                  <label className={classes.labelVipContener}>{_vip.name}</label>
                  <div className={classes.buttonRight}>
                    {JSON.parse(_vip.metadata as string).participantRequest === 'manifest' && <PanToolTwoTone />}
                    {JSON.parse(_vip.metadata as string).showToLive === false ? (
                      <VideoCallRounded color={'action'} style={{ marginLeft: '10px' }} />
                    ) : (
                      <HighlightOff color={'error'} style={{ marginLeft: '10px' }} />
                    )}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <Box />
          )}
          {/* <li>
            <Avatar alt="Jessica Snow" src={avatarimg} className={classes.avatar}></Avatar> Fabrice Hughes
          </li> */}
        </ul>
      </Box>
      <Box className={classes.TextfieldVip}>
        <Box
          className={'light'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            borderRadius: 25,
            borderWidth: 1,
          }}
        >
          <InputBase placeholder="Envoyer un message aux VIP" style={{ width: '100%' }} disabled />
          <Box
            className="iconsend"
            style={{
              backgroundColor: primaryColor ? primaryColor : theme.palette.primary.light,
              padding: 7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 25,
            }}
          >
            <SendOutlinedIcon color="secondary" />
          </Box>
        </Box>
      </Box>
      {openAlert && vipClicked && (
        <AlertLivekitRoom type="add" vip={vipClicked} room={room} handleShow={setOpenAlert} />
      )}
      {openAlertDelete && vipClicked && (
        <AlertLivekitRoom type="delete" vip={vipClicked} room={room} handleShow={setOpenAlertDelete} />
      )}
    </Box>
  );
};
export default ItemChatComponent;
