import React, { FC, useEffect, useState } from 'react';
import { Participant, ParticipantEvent, Room } from 'livekit-client';
import { useStyles } from './styles';
import { Avatar, Box, InputBase } from '@material-ui/core';
import { HighlightOff, PanToolTwoTone, VideoCallRounded } from '@material-ui/icons';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import AlertLivekitRoom from '../../LiveRoomComponent/AlertLivekitRoom';
import rings from '../../../Assets/sounds/notif.mp3';

interface IVipCompProps {
  participantsVIP: Participant[];
  room: Room;
  primaryColor?: string;
}

const VipComponent: FC<IVipCompProps> = ({ participantsVIP, primaryColor, room }) => {
  const classes = useStyles();
  const [vipClicked, setVipClicked] = useState<Participant>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openAlertDelete, setOpenAlertDelete] = useState<boolean>(false);

  const handleClickVip = (vip: Participant) => {
    setVipClicked(vip);
    const meta = JSON.parse(vip.metadata as string);
    if (!meta.showToLive) {
      setOpenAlert(true);
    } else {
      setOpenAlertDelete(true);
    }
  };

  return (
    <Box style={{ minHeight: 500 }}>
      <Box className={classes.vip}>
        <ul>
          {participantsVIP.length ? (
            participantsVIP.map((_vip, indexKey) => (
              <li className={classes.listVip} key={indexKey} onClick={() => handleClickVip(_vip)}>
                <div className={classes.vipContener}>
                  <Avatar
                    alt={_vip.name}
                    className={classes.avatar}
                    style={{ backgroundColor: `${JSON.parse(_vip.metadata as string).backgroundColor}` }}
                    src={JSON.parse(_vip.metadata as string).profileImage}
                  >
                    {_vip.name?.slice(0, 1)}
                  </Avatar>
                  <label className={classes.labelVipContener}>{_vip.name}</label>
                  <div className={classes.buttonRight}>
                    {JSON.parse(_vip.metadata as string).participantRequest === 'manifest' && <PanToolTwoTone />}
                    {JSON.parse(_vip.metadata as string).showToLive === false ? (
                      <VideoCallRounded color={'action'} style={{ marginLeft: '10px' }} />
                    ) : (
                      <HighlightOff color={'error'} style={{ marginLeft: '10px' }} />
                    )}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <Box />
          )}
          {/* <li>
              <Avatar alt="Jessica Snow" src={avatarimg} className={classes.avatar}></Avatar> Fabrice Hughes
            </li> */}
        </ul>
      </Box>

      {openAlert && vipClicked && (
        <AlertLivekitRoom type="add" vip={vipClicked} room={room} handleShow={setOpenAlert} />
      )}
      {openAlertDelete && vipClicked && (
        <AlertLivekitRoom type="delete" vip={vipClicked} room={room} handleShow={setOpenAlertDelete} />
      )}
    </Box>
  );
};

export default VipComponent;
