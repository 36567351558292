export default {
  'newProductToSale.title': 'Create product',
  'newProductToSale.title.update': 'Update product',
  'newProductToSale.productImages': 'Images',
  'newProductToSale.url': 'Link to product',
  'newProductToSale.name': 'Product name',
  'newProductToSale.description': 'Description',
  'newProductToSale.priceBefore': 'Price before discount',
  'newProductToSale.priceAfter': 'Price after discount',
  'newProductToSale.otherInfo': 'Other informations',
  'newProductToSale.created.success': 'Your product has been successfully created',
  'newProductToSale.created.failed': 'Product creation was unsuccessful',
};
