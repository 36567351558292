import { typedAction } from '../utils';

export interface ILoginParams {
  username: string;
  password: string;
  userType: string;
}

export interface IResultLoginSAGA {
  token: string;
  username: string;
  loading: boolean;
  error: string;
  status: number | undefined;
}
const initialState: IResultLoginSAGA = {
  loading: false,
  token: '',
  error: '',
  username: '',
  status: undefined,
};
interface IdataError {
  message: string;
  status: number;
}
export const AUTH_LOGIN_REQUEST = 'AUTH/LOGIN/REQUEST';
export const AUTH_LOGIN_FAILED = 'AUTH/LOGIN/FAILED';
export const AUTH_LOGIN_SUCCESS = 'AUTH/LOGIN/SUCCESS';

export const loginStart = (loginParams: ILoginParams) => typedAction(AUTH_LOGIN_REQUEST, loginParams);
export type LoginStart = typeof loginStart;

// export const loginIsLoading = () => typedAction(AUTH_LOGIN_REQUEST);
// export type LoginIsLoading = typeof loginIsLoading;

export const loginSuccess = (data: IResultLoginSAGA) => typedAction(AUTH_LOGIN_SUCCESS, data);
export type LoginSuccess = typeof loginSuccess;

export const loginFailed = (error: IdataError) => typedAction(AUTH_LOGIN_FAILED, error);
export type LoginFailed = typeof loginFailed;

export type AuthAction = ReturnType<LoginStart | LoginSuccess | LoginFailed>;

export const LOGIN_Reducers = (state: IResultLoginSAGA = initialState, action: AuthAction): IResultLoginSAGA => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.jwt,
        error: '',
        username: action.payload.displayName,
        status: action.payload.status,
      };
    case AUTH_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        status: action.payload.status,
      };
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        status: undefined,
      };
    default:
      return state;
  }
};
