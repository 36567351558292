import { Button, CardActions, Drawer } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import imgProduct from '../../Assets/images/product.jpg';
import { API_URL } from '../../lib/config';
import { RootState } from '../../redux-services';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { IdataProduct } from '../../redux-services/modules/product/productList';
import { useIsMobile } from '../../common/header/useIsMobile';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: '100%',
      justifyContent: 'space-between',
      height: 200,
      borderRadius: 0,
      padding: 5,
      '@media(max-width:700px)': {
        height: 'auto',
        width: 'auto',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 7,
      //width: '70%',
    },
    content: {
      flex: '1 0 auto',
      '@media(max-width:700px)': {
        flex: '1 0 50%',
      },
    },
    contentAction: {
      '@media(min-width:700px)': {
        display: 'none',
        paddingTop: '18px',
      },
      '@media(max-width:700px)': {
        flex: '1 0 0%',
        justifyContent: 'end',
        display: 'flex',
        paddingTop: '2px',
        flexDirection: '',
      },
    },
    cover: {
      objectFit: 'cover',
      backgroundSize: 'contain',
      width: '225px',
      flex: 'none',
      '@media(max-width:700px)': {
        flex: '1 0 10%',
      },
    },
    bottomActionMobile: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 8,
    },
    bottomActionMobileItem: {
      backgroundColor: 'black',
      color: 'white !important',
      padding: '8px 16px',
      textTransform: 'capitalize',
      width: 120,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    imgProduct: {
      display: 'flex',
      //flexWrap: 'wrap',
      width: '78%',
      '@media(max-width:700px)': {
        width: '100%',
      },
    },
    styleDescription: {
      display: ' -webkit-box',
      wordBreak: 'break-word',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  }),
);
interface IProductTOSaleProps {
  setItemToDelete?: (value: string | null) => void;
}
const ProductToSale: React.FC<IdataProduct & IProductTOSaleProps> = (props) => {
  const { id }: any = useParams();
  const { liveDetails: live } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const {
    url: urlImg,
    priceBeforeDiscount: priceBefore,
    priceAfterDiscount: priceAfter,
    id: MyId,
    furtherInformation: OtherInformation,
    description,
    label,
    mainImageUrl,
    setItemToDelete,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const openDialogFunction = () => {
    setItemToDelete && setItemToDelete(MyId);
  };
  return (
    <>
      <Drawer anchor={'bottom'} open={isOpenDrawer} onClose={(e, r) => setIsOpenDrawer(false)}>
        <div style={{ paddingBottom: 20, paddingTop: 20, justifyContent: 'center' }}>
          <div className={classes.bottomActionMobile}>
            <Button
              disabled={live.actualStart !== null}
              size="small"
              color="primary"
              className={classes.bottomActionMobileItem}
              onClick={() => {
                history.push(`/dashboard/livesessions/${id}/products/${MyId}`);
              }}
            >
              {t('productToSale.modify')}
            </Button>
          </div>
          <div className={classes.bottomActionMobile}>
            <Button
              size="small"
              className={classes.bottomActionMobileItem}
              style={{ color: ' #ED7861' }}
              onClick={openDialogFunction}
            >
              {t('productToSale.delete')}
            </Button>
          </div>
        </div>
      </Drawer>
      <Card className={classes.root}>
        <div className={classes.imgProduct}>
          <CardMedia className={classes.cover} image={`${mainImageUrl}`} title="Live from space album cover" />
          {isMobile ? (
            <CardContent className={classes.content}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography>{label}</Typography>
                  <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceAfter)}
                  </Typography>
                </div>
                <div className={classes.contentAction}>
                  <Button
                    size="small"
                    style={{ color: '#010101', height: 40, textAlign: 'end', justifyContent: 'flex-end' }}
                    onClick={() => setIsOpenDrawer(true)}
                  >
                    <MoreVert className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" />
                  </Button>
                </div>
              </div>

              <Typography className={classes.styleDescription}>{description}</Typography>
            </CardContent>
          ) : (
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography>{label}</Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                  {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceBefore)}
                </Typography>
                <Typography className={classes.styleDescription}>{description}</Typography>
              </CardContent>
            </div>
          )}
        </div>
        {!isMobile && (
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                {`${t('productToSale.price.after')}` +
                  ' : ' +
                  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceAfter)}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                {/* {descriptionStock ? `Stocks : ${descriptionStock}` : ''} */}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                {/* {livraison ? ` ${t('productToSale.livraison')} : ${livraison}` : ''} */}
              </Typography>
              <CardActions>
                <Button
                  disabled={live.actualStart !== null}
                  size="small"
                  color="primary"
                  onClick={() => {
                    history.push(`/dashboard/livesessions/${id}/products/${MyId}`);
                  }}
                >
                  {t('productToSale.modify')}
                </Button>
                <Button size="small" style={{ color: ' #ED7861' }} onClick={openDialogFunction}>
                  {t('productToSale.delete')}
                </Button>
              </CardActions>
            </CardContent>
          </div>
        )}
      </Card>
    </>
  );
};

export default ProductToSale;
