import { Grid } from '@material-ui/core';
import React from 'react';
import MyCardShopping, { IcardShooping } from '../MyCardShopping/MyCardShopping';
import { useStyles } from './style';
import { useIsMobile } from '../header/useIsMobile';

interface IProductShare {
  data: IcardShooping[];
}
const ProductShare: React.FC<IProductShare> = ({ data }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.productsContainer}>
        {data.map((item, indexKey: number) => {
          return <MyCardShopping key={indexKey} {...{ ...item }} />;
        })}
      </Grid>
    </Grid>
  );
};

export default ProductShare;
