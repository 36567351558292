import { stat } from 'fs';
import { typedAction } from '../../../utils';

export const LIST_CONSUMER_REQUESTED = 'LIST/CONSUMER/REQUESTED';
export const LIST_CONSUMER_SUCCESS = 'LIST/CONSUMER/SUCCESS';
export const LIST_CONSUMER_FAILED = 'LIST/CONSUMER/FAILED';

interface listConsumer {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
}

export interface IinitialStateListConsumer {
  data: listConsumer[];
  loading: boolean;
}

const initialState: IinitialStateListConsumer = {
  data: [],
  loading: false,
};

export const GET_listConsumer = () => typedAction(LIST_CONSUMER_REQUESTED);
export type IGET_listConsumer = typeof GET_listConsumer;

export const GETlistConsumerSuccefull = (data: IinitialStateListConsumer[]) => typedAction(LIST_CONSUMER_SUCCESS, data);
export type IGETlistConsumerSuccefull = typeof GETlistConsumerSuccefull;

export const GETlistConsumerFailed = () => typedAction(LIST_CONSUMER_FAILED);
export type IGETlistConsumerFailed = typeof GETlistConsumerFailed;

export type listConsumerAction = ReturnType<IGET_listConsumer | IGETlistConsumerFailed | IGETlistConsumerSuccefull>;

export const listConsumerReducer = (
  state: IinitialStateListConsumer = initialState,
  action: listConsumerAction,
): IinitialStateListConsumer => {
  switch (action.type) {
    case LIST_CONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case LIST_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case LIST_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
