import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
      padding: 30,
      boxSizing: 'border-box',
      marginTop: 70,
      paddingTop: 0,
      '@media(max-width:700px)': {
        padding: '0px 10px 30px 10px ',
        marginTop: 0,
      },
    },
  }),
);
