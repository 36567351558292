import axios, { AxiosPromise } from 'axios';

export interface IparamsAffiliation {
  affiliationId: string;
  participantId: string;
}

export const Affiliation = (params: IparamsAffiliation): AxiosPromise<any> =>
  axios.post(`/Consumer/Participant/AffiliateToGuest`, params);

export const BindConsumer = (participantId: string): AxiosPromise<any> =>
  axios.get(`/Consumer/Participant/BindConsumer/${participantId}`);

export const GetAffiliationInfo = (participantId: string): AxiosPromise<any> =>
  axios.get(`/Consumer/Participant/GetAffiliationInfo/${participantId}`);

export const CountAffiliationCreation = (data: any): AxiosPromise<any> =>
  axios.post(`Retailer/LiveSessionGuestCounterPart/Set?liveSessionId=${data.payload.id}`, data.payload.data);

export const CountAffiliationGetInfo = (idSession: string): AxiosPromise<any> =>
  axios.get(`/Retailer/LiveSessionGuestCounterPart/List?liveSessionId=${idSession}`);
