import { typedAction } from '../../utils';

export const SEND_GUEST_REQUEST = 'SEND/GUEST/REQUEST';
export const SEND_GUEST_SUCCESS = 'SEND/GUEST/SUCCESS';
export const SEND_GUEST_FAILED = 'SEND/GUEST/FAILED';

export interface IparamsSend {
  liveSessionId: string;
  guestIds: string[];
}

export interface IguestFailed {
  message: string;
  status: number | undefined;
}

interface Iresult {
  id: string;
  message: string | null;
  status: number | undefined;
}
export interface IinitialStateSendGuest {
  id: string;
  message: string | null;
  status: number | undefined;
  loading: boolean;
}
const initialStateGuest: IinitialStateSendGuest = {
  id: '',
  message: '',
  status: undefined,
  loading: false,
};

export const do_send_guest = (data: IparamsSend) => typedAction(SEND_GUEST_REQUEST, data);
export type Ido_send_guest = typeof do_send_guest;

export const guest_sendd = (result: Iresult) => typedAction(SEND_GUEST_SUCCESS, result);
export type IproduitSendd = typeof guest_sendd;

export const guest_send_failed = (error: IguestFailed) => typedAction(SEND_GUEST_FAILED, error);
export type Iguest_send_failed = typeof guest_send_failed;

export type SendGuestAction = ReturnType<Ido_send_guest | IproduitSendd | Iguest_send_failed>;

export const SendGuestReducers = (
  state: IinitialStateSendGuest = initialStateGuest,
  action: SendGuestAction,
): IinitialStateSendGuest => {
  switch (action.type) {
    case SEND_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_GUEST_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        message: action.payload.message,
        status: action.payload.status,
        loading: false,
      };
    case SEND_GUEST_FAILED:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
