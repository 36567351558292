import { typedAction } from '../../utils';

export const BINDCONSUMER_REQUESTED = 'BINDCONSUMER/REQUESTED';
export const BINDCONSUMER_SUCCESS = 'BINDCONSUMER/SUCCESS';
export const BINDCONSUMER_FAILED = 'BINDCONSUMER/FAILED';
export const CLEAR_BINDCONSUMER_REQUESTED = 'CLEAR/BINDCONSUMER/FAILED';

export interface IinitialStateBindConsumer {
  loading: boolean;
  error: string | undefined;
}

const initialState: IinitialStateBindConsumer = {
  loading: false,
  error: undefined,
};

export const BindConsumer = (id: string) => typedAction(BINDCONSUMER_REQUESTED, id);
export type IBindConsumer = typeof BindConsumer;

export const ClearBindConsumer = () => typedAction(CLEAR_BINDCONSUMER_REQUESTED);
export type IClearBindConsumer = typeof ClearBindConsumer;

export const BindConsumerSuccess = () => typedAction(BINDCONSUMER_SUCCESS);
export type IBindConsumerSuccess = typeof BindConsumerSuccess;

export const BindConsumerFailed = () => typedAction(BINDCONSUMER_FAILED);
export type IBindConsumerFailed = typeof BindConsumerFailed;

export type BindConsumerAction = ReturnType<IBindConsumer | IBindConsumerFailed | IBindConsumerSuccess>;

export const BindConsumerReducer = (
  state: IinitialStateBindConsumer = initialState,
  action: BindConsumerAction,
): IinitialStateBindConsumer => {
  switch (action.type) {
    case BINDCONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_BINDCONSUMER_REQUESTED:
      return initialState;
    case BINDCONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BINDCONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
