import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '20px 3%',
      // minHeight: 200,
      height: '100%',
      color: 'white',
      '@media(max-width:1180px)': {
        height: 'fit-content !important',
      },
    },
    tabRoot: {
      height: 'auto!important',
    },
    title: {
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    slide: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 80,
      '@media(max-width:1180px)': {
        padding: 0,
      },
    },
    slideContainer: {
      display: 'block',
      width: '100%',
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    productsContainerMobile: {
      display: 'none',
      width: '100%',
      '@media(max-width:1180px)': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 15,
        // Each Product container
        '& > div': {
          margin: 0,
          width: 100,
          // Product image container
          '& > div:nth-of-type(1)': {
            height: 100,
            // Reduction
            '& > div': {
              fontSize: 12,
              width: 35,
              height: 35,
              right: -15,
            },
          },
        },
      },

      '@media(max-width:823px)': {
        flexWrap: 'nowrap',
        overflowX: 'auto',
        justifyContent: 'flex-start',
      },
    },
  }),
);
