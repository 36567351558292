import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import CustomInputWithLabel from '../TextInput/CustomInputWithLabel';

interface PropsEmail {
  subject?: string;
  body?: string;
  isHtmlBody?: boolean;
  addressSender?: string;
}
const EmailMobile: FC<PropsEmail> = (props: PropsEmail) => {
  const style = useStyles();
  const { t } = useTranslation();
  const { subject, body, isHtmlBody, addressSender } = props;
  const [data, setData] = useState({ subject: '', body: '' });

  useEffect(() => {
    setData({ subject: subject ? subject : '', body: body ? body : '' });
  }, [subject, body]);

  return (
    <>
      <Box className={style.root}>
        <Grid style={{ padding: '8px 8px', borderBottom: '1px solid #010101' }} container>
          <Grid item>
            <Typography style={{ fontSize: 13, fontWeight: 'bold', paddingRight: 8 }}>
              {t('invitation.mail.sended')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: 14 }}>{addressSender}</Typography>
          </Grid>
        </Grid>
        <Grid style={{ padding: '8px 8px', display: 'flex', borderBottom: '1px solid #010101' }}>
          <Grid item style={{ padding: '1px 1px' }}>
            <Typography style={{ fontSize: 14, fontWeight: 'bold', paddingRight: 8, whiteSpace: 'nowrap' }}>
              {t('invitation.mail.objet')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: 14, textAlign: 'start' }}>{subject}</Typography>
          </Grid>
        </Grid>
        <Box className={style.content} style={{ height: 300, display: 'flex', paddingTop: 8 }}>
          {data.body !== '' ? (
            <CustomInputWithLabel label="" textDefault={data.body} disabled={true} />
          ) : (
            <CustomInputWithLabel
              label=""
              textDefault=""
              // {`{NOM DE LA MARQUE}vous invite à une réunion Zoom planifiée.\n\n${t(
              //   'invitation.mail.sujet',
              // )} Ma réunion\n${t(
              //   'invitation.mail.time',
              // )} 18 oct. 2021 10:00 AM Paris\n\n\nParticiper à la réunion Zoom\n\n${t(
              //   'invitation.mail.https',
              // )} //zoom.us/j/97382547849?pwd=NVNnS0FOcWNFdHpSOElWQ0dZWG1IQT09`}
              disabled={true}
            />
          )}
        </Box>
      </Box>
      {data.body !== '' && (
        <Button
          className={style.btnCopyInvitation}
          style={{ marginTop: 12, fontSize: 13 }}
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(data.body);
          }}
        >
          Copier Invitation
        </Button>
      )}
    </>
  );
};

export default React.memo(EmailMobile);
