import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBackdrop from '../../../common/backdrop/CustomBackdrop';
import { RootState } from '../../../redux-services';
import { DO_resetPassword, InitialstateResetPassword } from '../../../redux-services/modules/account/resetPassword';
import theme from '../../../Themes';
import { useStyles } from '../style';

interface IParamRestPassword {
  userType: 'Consumer' | 'Retailer';
  consumerId: string;
  securityStamp: string;
}

const ResetPassword: React.FC = () => {
  const style = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userType, consumerId, securityStamp } = useParams<IParamRestPassword>();
  const [passe0, setPasse0] = useState<string>('');
  const [passe1, setPasse1] = useState<string>('');

  const { loading, status } = useSelector<RootState, InitialstateResetPassword>((state) => state.resetPassword);

  const handelChangePasse = () => {
    if (
      userType &&
      userType === 'Consumer' &&
      securityStamp &&
      securityStamp !== '' &&
      consumerId &&
      consumerId !== ''
    ) {
      dispatch(DO_resetPassword({ userType, data: { consumerId, securityStamp, password: passe1 } }));
    }
  };

  useEffect(() => {
    if (!loading && status === 200) {
      history.push('/auth/Consumer');
    }
  }, [loading, status]);

  return (
    <Box>
      <CustomBackdrop {...{ open: loading }} />
      <Box className={style.root}>
        <Box className={style.cadre} style={{ width: 420 }}>
          <Typography style={{ fontSize: 22 }}>Reset mot de passe</Typography>
          <Typography style={{ textAlign: 'center' }}>Merci d'ajouter un nouveau mot de passe</Typography>

          <Box style={{ width: 316, padding: '10px', margin: 25, minHeight: 186 }}>
            <Typography style={{ fontSize: 10 }}>Nouveau mot de passe</Typography>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'Password',
                label: 'Password',
                variant: 'outlined',
                value: passe0,
                onChange: (e) => setPasse0(e.target.value),
                // onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            <Typography style={{ fontSize: 10, marginTop: 18 }}>Confirmation du nouveau mot de passe</Typography>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'Password',
                label: 'Confirmation password',
                variant: 'outlined',
                value: passe1,
                onChange: (e) => setPasse1(e.target.value),
                // onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            {passe0 !== passe1 && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>
                La confirmation du mot de passe est invalide
              </Typography>
            )}
          </Box>

          <Button
            {...{
              style: { color: theme.palette.primary.light },
              onClick: handelChangePasse,
              disabled: passe0 !== passe1,
            }}
          >
            <Typography>Changer mot de passe</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
