import { Box, Typography, Button, Popover, Avatar, CircularProgress, TextField } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import Finger from '../../../Assets/images/finger.png';
import HttpsIcon from '@material-ui/icons/Https';
import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from '../style';
import { IliveConsumerById } from '../../../redux-services/modules/liveSessionConsumerget';
import { useTranslation } from 'react-i18next';
import ShowMoreText from 'react-show-more-text';
import MobileViewers from './MobileViewers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux-services';
import { IinitialStateAdvantage } from '../../../redux-services/modules/participant/advantageCode';
import { IinitialStateGuestConsumer } from '../../../redux-services/modules/guest/guestConsumer';
import { IinitialStateAffiliation } from '../../../redux-services/modules/participant/affiliation';
import { IinitialStateBindConsumer } from '../../../redux-services/modules/participant/bindConsumer';
import { ILiveStatus } from '../../../redux-services/type/live';
import theme from '../../../Themes';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import Line from '@material-ui/icons/Minimize';
import clsx from 'clsx';
import { useIsMobile } from '../../../common/header/useIsMobile';
import Api from '../../../redux-services/api';
import { GetAffiliationInfo } from '../../../redux-services/api/participant';

interface IParticipant {
  id: string;
  name: string;
  type?: number;
  identifiant: string;
  profilePictureUrl: string;
}
interface IWebFooterProps {
  live: IliveConsumerById;
  token: string | undefined;
  userId: string | undefined;
  handleRedirect: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClickTwo: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorElTwo: HTMLElement | null;
  handleCloseTwo: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOpen: () => void;
  participant: IParticipant[];
  internet: boolean;
  id: string;
  loading: boolean;
  statusLive?: ILiveStatus;
  livePaused: boolean;
  fullscreen?: boolean;
}

const WebFooter: React.FC<IWebFooterProps> = (props: IWebFooterProps) => {
  const {
    live,
    token,
    userId,
    handleRedirect,
    handleClickTwo,
    anchorElTwo,
    handleCloseTwo,
    handleOpen,
    participant,
    id,
    internet,
    loading,
    statusLive,
    livePaused,
    fullscreen,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowViewers, setIsShowViewers] = useState<boolean>(false);
  const {
    data: { advantageCode },
    loading: loadingCodeAvantage,
  } = useSelector<RootState, IinitialStateAdvantage>((state) => state.guestAdvantageCode);
  const { loading: loadingGuest } = useSelector<RootState, IinitialStateGuestConsumer>((state) => state.guestConsumer);
  const { loading: loadingAffiliation, data } = useSelector<RootState, IinitialStateAffiliation>(
    (state) => state.affiliation,
  );
  const { loading: loadingBind } = useSelector<RootState, IinitialStateBindConsumer>((state) => state.bindConsumer);
  const executeOnClick = (isExpanded: boolean) => {
    console.log(isExpanded);
  };

  //loading
  const [load, setLoad] = useState(true);
  const [codeAchat, setCodeAchat] = useState<string>();
  const [shopUrl, setShopUrl] = useState<string>();
  const width = document.getElementById('centerFooter')?.scrollWidth;

  const isMobile = useIsMobile();

  const shoppinUrlValue = (url: string) => {
    const isUrlValid = url.split('//')[0] === 'https:';
    if (!isUrlValid) {
      return 'https://' + url;
    }
    return url;
  };

  const VipAdVantage = useCallback(() => {
    if (load) {
      return <CircularProgress size={20} style={{ marginLeft: 20, marginRight: 20 }} />;
    } else {
      if (statusLive && !statusLive.isStarted && !statusLive.isEnded) {
        return <>{'ATTENTE LIVE'}</>;
      } else {
        if (codeAchat) {
          return <>{codeAchat}</>;
        }
        if (shopUrl && shopUrl !== '') {
          return (
            <Box style={{ width: '100%', display: 'flex' }}>
              <a
                href={shoppinUrlValue(shopUrl)}
                target="blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  width: 146,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  window.open(shoppinUrlValue(shopUrl), '_blank');
                }}
              >
                {t('action.order')}
              </a>
              <ShoppingCartTwoToneIcon />
            </Box>
          );
        }
        if (!codeAchat && !shopUrl) {
          return (
            <Box
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                width: 146,
                display: 'flex',
                height: 28,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Line style={{ position: 'relative', fontSize: 50, top: '-60%' }} />
            </Box>
          );
        }
      }
    }

    return <Line />;
  }, [load, statusLive, livePaused, fullscreen, isMobile, codeAchat, shopUrl]);

  useEffect(() => {
    if (userId) {
      Api.participant.GetAffiliationInfo(userId).then((data) => {
        setLoad(loadingCodeAvantage);
        setCodeAchat(data.data.advantageCode);
        setShopUrl(data.data.shoppingUrl);
      });
    }
  }, [userId, loadingCodeAvantage, statusLive]);

  return (
    <Box className={clsx({ ['footer']: true, [classes.footerFullscreen]: fullscreen })}>
      <Box className={clsx({ ['center']: true })} id="centerFooter">
        {statusLive && statusLive.isStarted === statusLive.isEnded ? (
          <>
            <Typography style={{ fontWeight: 600 }}>{live.title}</Typography>
            {screen.width > 500 ? (
              <Typography style={{ fontWeight: 600 }}>{live.description}</Typography>
            ) : (
              <ShowMoreText
                /* Default options */
                lines={isMobile ? 10 : 2}
                more="Voir plus"
                less="Voir moins"
                className={!isMobile ? 'content-css' : 'content-mobile-css'}
                anchorClass="my-anchor-css-class"
                onClick={executeOnClick}
                expanded={false}
                width={width && width - 30}
                truncatedEndingComponent={'... '}
              >
                {live.description}
              </ShowMoreText>
            )}
          </>
        ) : (
          <>
            <ShowMoreText
              lines={1}
              more="Voir plus"
              less="Voir moins"
              className="content-css"
              anchorClass="my-anchor-css-class"
              onClick={executeOnClick}
              expanded={false}
              width={width && width - 150}
              truncatedEndingComponent={'... '}
            >
              {live.title}
            </ShowMoreText>
            <ShowMoreText
              /* Default options */
              lines={isMobile ? 10 : 1}
              more="Voir plus"
              less="Voir moins"
              className={!isMobile ? 'content-css' : 'content-mobile-css'}
              anchorClass="my-anchor-css-class"
              onClick={executeOnClick}
              expanded={statusLive ? statusLive.isStarted === statusLive.isEnded : false}
              width={width && width - 150}
              truncatedEndingComponent={'... '}
            >
              {live.description}
            </ShowMoreText>
          </>
        )}

        <Box className={classes.lang}>{t('consumer.live.langage')}</Box>
        {/* <Box className={classes.iconfooter}>
          <Box>
            <FacebookIcon />
          </Box>
          <Box>
            <InstagramIcon />
          </Box>
          <Box>
            <TwitterIcon />
          </Box>
        </Box> */}
      </Box>
      <Box className={clsx({ ['right']: true, [classes.footerLabelFullscreen]: fullscreen })} style={{ width: '25%' }}>
        {loadingCodeAvantage ? (
          <Skeleton
            className={classes.btnAbonner}
            style={{ backgroundColor: live.primaryColor ?? theme.palette.primary.light, width: 200, height: 42 }}
          />
        ) : (
          <Button
            aria-describedby="my-pop-over"
            onClick={token ? handleRedirect : handleClickTwo}
            variant="contained"
            className={clsx({ [classes.btnAbonner]: true, [classes.btnCursor]: !token?.length })}
            style={{ backgroundColor: live.primaryColor ?? theme.palette.primary.light, width: 200 }}
          >
            {shopUrl ? t('consumer.live.shopUrl') : t('consumer.live.code')}
            <img src={Finger} style={{ width: 30, marginLeft: 10 }} />
          </Button>
        )}

        <Box style={{ display: 'flex' }}>
          <Box
            className={clsx({
              [classes.btnInviter]: true,
              [classes.btnText]: live.actualStart,
              [classes.btnCursor]: !token?.length,
            })}
            onClick={token ? handleRedirect : handleClickTwo}
          >
            <VipAdVantage />
          </Box>
        </Box>
      </Box>
      <Box className="rightMobile">
        <AvatarGroup max={4} onClick={() => setIsShowViewers(true)}>
          {participant &&
            participant.map((item, indexKey) => (
              <Avatar key={indexKey} alt={item.id} src={item.profilePictureUrl}>
                {item.profilePictureUrl === '' && item.identifiant}
              </Avatar>
            ))}
        </AvatarGroup>
      </Box>
      <MobileViewers
        {...{
          userId,
          internet,
          id,
          participant,
          loading,
          isShow: isShowViewers,
          setIsShow: setIsShowViewers,
          isLiveStarted: statusLive?.isStarted,
        }}
      />
    </Box>
  );
};

export default WebFooter;
