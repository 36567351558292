import { combineReducers } from 'redux';
import { CategorieReducer } from './modules/categorie';
import { getDurationReducer } from './modules/duration';
//import { getDurationReducer } from './modules/duration';
import { GallerieReducer } from './modules/gallerieImage';
import { LiveSessionReducer } from './modules/liveSession';
import { getliveSessionConsumerByIdReducer } from './modules/liveSessionConsumerget';
import { DoLiveSessionEndReducer } from './modules/liveSessionEnd';
import { DoLiveSessionJoinReducer } from './modules/liveSessionJoin';
import { LiveSessionPageReducer } from './modules/liveSessionPage';
import { CreateLiveSessionReducer } from './modules/liveSessionRetailerCreate';
import { liveSessionDetailsReducer } from './modules/liveSessionRetailerDetails';
import { getliveSessionByIdReducer } from './modules/liveSessionRetailerget';
import { listBySectionReducer } from './modules/liveSessionRetailerListBySection';
import { LiveSessionRetailerPageReducer } from './modules/liveSessionRetailerPage';
import { LiveSessionSectionReducer } from './modules/liveSessionRetailerSection';
import { UpdateLiveSessionReducer } from './modules/liveSessionRetailerUpdate';
import { DoLiveSessionStartReducer } from './modules/liveSessionStart';
import { LOGIN_Reducers } from './modules/login';
import { TimeZoneReducer } from './modules/timezone';
import { UploadImageReducer } from './modules/uploadImage';
import { ListenerReducer } from './modules/liveListener';
import { ListenerHistory_Reduceur } from './modules/listenerHistory';
import { ProductStateReducers } from './modules/state/stateProducts';
import { QuestionStateReducers } from './modules/state/stateSurvey';
import { ListProductReducers } from './modules/product/productList';
import { UpdateProductReducers } from './modules/product/productUpdate';
import { CreateProductReducers } from './modules/product/productCreate';
import { ProductReducersById } from './modules/product/productget';
import { SnackBarReducers } from './modules/state/stateMessage';
import { productConsumerReducer } from './modules/liveSessionConsumerProduct';
import { DeleteProductReducer } from './modules/product/productDelete';
import { GetInfoRetailer_Reducers } from './modules/account/getInfoRetailer';
import { SetLogoRetailer_Reducers } from './modules/account/setLogoRetailer';
import { CreateGuestReducers } from './modules/guest/guestCreate';
import { GuestReducersById } from './modules/guest/guestGet';
import { ListGuestReducers } from './modules/guest/guestList';
import { UpdateGuestReducers } from './modules/guest/guestUpdate';
import { SendGuestReducers } from './modules/guest/guestSend';
import { DeleteGuestReducer } from './modules/guest/guestDelete';
import { AffiliationReducer } from './modules/participant/affiliation';
import { BindConsumerReducer } from './modules/participant/bindConsumer';
import { GuestConsumerReducer } from './modules/guest/guestConsumer';
import { urlToFrameReducer } from './modules/state/stateIFrame';
import { profileImgReducer } from './modules/state/stateProfileImg';
import { MailDataGuestReducers } from './modules/guest/guestGetInvitationMailData';
import { listEndedLiveReducer } from './modules/liveSessionRetailerListEnded';
import { ListGuestSummaryReducers } from './modules/guest/guestListsParticipantsSummary';
import { Create_consumerReducer } from './modules/account/consumer/createConsumer';
import { listConsumerReducer } from './modules/account/consumer/listConsumer';
import { updateConsumerReducer } from './modules/account/consumer/updateConsumer';
import { SetAdvantageCodeGuestReducers } from './modules/guest/guestAdvantageCodes';
import { AdvantageReducer } from './modules/participant/advantageCode';
import { CounterPartReducer } from './modules/guest/guestCounterPartSet';
import { CounterPartInfoReducer } from './modules/guest/guestCounterPartGet';
import { StateAffiliationReducer } from './modules/state/stateAffiliation';
import { ClickRecordProductReducersByLiveId } from './modules/product/participantProductClickRecord';
import { CreateAccount_consumerReducer } from './modules/account/consumer/createAccountConsumer';
import { ConfirmationAccount_consumerReducer } from './modules/account/consumer/confirmationAccountConsumer';
import { sendEmailResetPasswordReducer } from './modules/account/sendEmailResetPassword';
import { resetPasswordReducer } from './modules/account/resetPassword';
import { updatePasswordReducer } from './modules/account/updatePassword';
import { UploadProfileImageReducer } from './modules/account/consumer/uploadProfileImage';
import { DomReducer } from './modules/dom';
import { DuplicateLiveSessionReducer } from './modules/liveSessionDuplicate';
export const rootReducer = combineReducers({
  //add functionReducers
  login: LOGIN_Reducers,
  liveSession: LiveSessionReducer,
  liveSessionPage: LiveSessionPageReducer,
  liveSessionConsumerById: getliveSessionConsumerByIdReducer,
  liveSessionRetailerPage: LiveSessionRetailerPageReducer,
  createLiveSession: CreateLiveSessionReducer,
  duplicateLiveSession: DuplicateLiveSessionReducer,
  getLiveSessionById: getliveSessionByIdReducer,
  timezone: TimeZoneReducer,
  categorie: CategorieReducer,
  uploadImage: UploadImageReducer,
  gallerieImage: GallerieReducer,
  updateLiveSession: UpdateLiveSessionReducer,
  liveSessionRetailerSection: LiveSessionSectionReducer,
  duration: getDurationReducer,
  liveSessionRetailerBySection: listBySectionReducer,
  liveSessionDetails: liveSessionDetailsReducer,
  liveSessionStart: DoLiveSessionStartReducer,
  liveSessionEnd: DoLiveSessionEndReducer,
  liveSessionJoinned: DoLiveSessionJoinReducer,
  listener: ListenerReducer,
  listenerHistory: ListenerHistory_Reduceur,
  stateProduct: ProductStateReducers,
  stateQuestion: QuestionStateReducers,
  stateSnackBar: SnackBarReducers,
  stateFrame: urlToFrameReducer,
  stateProfileImg: profileImgReducer,
  productList: ListProductReducers,
  productUpdate: UpdateProductReducers,
  productCreated: CreateProductReducers,
  productGetById: ProductReducersById,
  ProductConsumer: productConsumerReducer,
  deleteProduct: DeleteProductReducer,
  accountInfoRetailer: GetInfoRetailer_Reducers,
  accountSetLogoRetailer: SetLogoRetailer_Reducers,
  guestList: ListGuestReducers,
  guestUpdate: UpdateGuestReducers,
  guestSend: SendGuestReducers,
  guestCreated: CreateGuestReducers,
  guestGetById: GuestReducersById,
  guestDeleted: DeleteGuestReducer,
  guestConsumer: GuestConsumerReducer,
  affiliation: AffiliationReducer,
  bindConsumer: BindConsumerReducer,
  guestInvitationMailData: MailDataGuestReducers,
  liveSessionRetailerEndedSection: listEndedLiveReducer,
  guestListGuestSummary: ListGuestSummaryReducers,
  createConsumer: Create_consumerReducer,
  listConsumer: listConsumerReducer,
  updateConsumer: updateConsumerReducer,
  guestSetAdvantageCodes: SetAdvantageCodeGuestReducers,
  guestAdvantageCode: AdvantageReducer,
  guestCountPartSet: CounterPartReducer,
  guestCountPartGet: CounterPartInfoReducer,
  stateAffiliation: StateAffiliationReducer,
  listClickRecordProductByLiveId: ClickRecordProductReducersByLiveId,
  createAccountConsumer: CreateAccount_consumerReducer,
  confirmationAccountConsumer: ConfirmationAccount_consumerReducer,
  resetPassword: resetPasswordReducer,
  updatePassword: updatePasswordReducer,
  sendEmailResetPassword: sendEmailResetPasswordReducer,
  uploadConsumerProfileImage: UploadProfileImageReducer,
  dom: DomReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
