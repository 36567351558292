export default {
  'consumer.live.viewer.title': 'Viewers',
  'consumer.live.viewer.friends': 'Friends',
  'consumer.live.viewer.others': 'Others',
  'consumer.live.viewer.more': 'Show more',
  'consumer.live.langage': 'English',
  'consumer.live.subscribe': 'Subscribe',
  'consumer.live.subscribe.request': 'You need to log in',
  'consumer.live.login': 'Log In',
  'consumer.live.invite': 'Invite',
  'consumer.live.decouvert': 'To be discovered',
  'consumer.live.decouverted': 'None',
  'consumer.live.code': 'Code achat',
  'consumer.live.shopUrl': 'Shopping URL',
  'consumer.live.errorId': 'Your pseudo name session has expired! Please log in again',
  'consumer.live.prsInterested': 'One of your guests is interested in the product',
};
