import { typedAction } from '../utils';

export interface IJoinParams {
  id: string;
  name: string;
  userType: string;
  primaryColor?: string;
}
export interface IerrorLiveJoin {
  error: string;
  status: number | undefined;
}

export interface IResultLiveJoin {
  loading: boolean;
  error: string;
  status: number | undefined;
  participantId: string;
}

const initialStateLiveJoin: IResultLiveJoin = {
  error: '',
  status: undefined,
  loading: false,
  participantId: '',
};
export const LIVE_SESSION_JOIN_REQUEST = 'LIVE_SESSION_JOIN_REQUEST';
export const LIVE_SESSION_JOIN_SUCCESS = 'LIVE_SESSION_JOIN_SUCCESS';
export const LIVE_SESSION_JOIN_FAILED = 'LIVE_SESSION_JOIN_FAILED';
export const LIVE_SESSION_LEAVE_REQUEST = 'LIVE_SESSION_LEAVE_REQUEST';
export const LIVE_SESSION_LEAVE_SUCCESS = 'LIVE_SESSION_LEAVE_SUCCESS';

export const doJoinLiveSession = (joinParam: IJoinParams) => typedAction(LIVE_SESSION_JOIN_REQUEST, joinParam);
export type DoJoinLiveSession = typeof doJoinLiveSession;

export const liveSessionJoined = (participantId: string) => typedAction(LIVE_SESSION_JOIN_SUCCESS, participantId);
export type LiveSessionJoined = typeof liveSessionJoined;

export const liveSessionJoinFailed = (error: IerrorLiveJoin) => typedAction(LIVE_SESSION_JOIN_FAILED, error);
export type LiveSessionJoinFailed = typeof liveSessionJoinFailed;

export const doLeaveLiveSession = () => typedAction(LIVE_SESSION_LEAVE_REQUEST);
export type DoLeaveLiveSession = typeof doLeaveLiveSession;

export const liveSessionLeaved = () => typedAction(LIVE_SESSION_LEAVE_SUCCESS);
export type LiveSessionLeaved = typeof liveSessionLeaved;

export type LiveSessionJoinAction = ReturnType<
  DoJoinLiveSession | LiveSessionJoined | LiveSessionJoinFailed | DoLeaveLiveSession | LiveSessionLeaved
>;

export const DoLiveSessionJoinReducer = (
  state: IResultLiveJoin = initialStateLiveJoin,
  action: LiveSessionJoinAction,
): IResultLiveJoin => {
  switch (action.type) {
    case LIVE_SESSION_JOIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIVE_SESSION_JOIN_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
        error: '',
        participantId: action.payload,
      };
    case LIVE_SESSION_JOIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: action.payload.status,
      };
    case LIVE_SESSION_LEAVE_REQUEST:
      return {
        ...state,
        loading: false,
        status: 1,
        error: '',
        participantId: '',
      };
    case LIVE_SESSION_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
        error: '',
        participantId: '',
      };
    default:
      return {
        ...state,
      };
  }
};
