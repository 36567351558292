import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import CustomBreadcrumbs from '../../common/CustomBreadcrumbs/CustomBreadcrumbs';
import {
  ACCOUNT_RETAILER,
  ADD_PRODUCTS,
  AFFILIATION,
  CREATELIVE,
  CREATE_QUESTIONNAIRE,
  DASHBOARD,
  LIVEDETAILS,
  LIVE_APERCU,
  LIVE_SECTION,
  LIVE_STATISTIQUE,
  MANAGE_INVITATION_VIP,
  PASSWORD,
  PRODUCTS,
  QUESTIONNAIRE,
  UPDATELIVE,
  UPDATE_PRODUCTS,
} from '../../constant/path';
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import AccountComponent from '../AccountComponent/AccountComponent';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import Invitation from '../InvitationComponent/Invitation';
import MobileHomePage from '../homePage/MobileHomePage';
import LiveDetailsComponent from '../LiveDetailsComponent/LiveDetailsComponent';
import ScheduleEventComponent from '../ScheduleEventComponent/ScheduleEventComponent';
import ScheduleLiveComponent from '../ScheduleLiveComponent/ScheduleLiveComponent';
import SideBarComponent from '../SideBarComponent/SideBarComponent';
import StatisticsComponent from '../StatisticsComponent/StatisticsComponent';
import PasswordComponent from '../AccountComponent/password/PasswordComponent';
import { useStyles } from './style';
import { useIsMobile } from '../../common/header/useIsMobile';

const Dashboard: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <Box className={classes.rootDashboard}>
      <SideBarComponent />
      <Box className={classes.sidebarBody}>
        {!isMobile && <CustomBreadcrumbs />}
        <Switch>
          <PrivateRoute path={DASHBOARD} exact component={MobileHomePage} />
          <PrivateRoute path={ACCOUNT_RETAILER} exact component={AccountComponent} />
          <PrivateRoute path={CREATELIVE} exact component={ScheduleEventComponent} />
          <PrivateRoute path={UPDATELIVE} exact component={ScheduleEventComponent} />
          <PrivateRoute path={LIVEDETAILS} exact component={LiveDetailsComponent} />
          <PrivateRoute path={PASSWORD} exact component={PasswordComponent} />
          <PrivateRoute
            path={[
              PRODUCTS,
              ADD_PRODUCTS,
              UPDATE_PRODUCTS,
              LIVE_APERCU,
              QUESTIONNAIRE,
              CREATE_QUESTIONNAIRE,
              AFFILIATION,
              MANAGE_INVITATION_VIP,
            ]}
            exact
            component={LiveDetailsComponent}
          />
          <PrivateRoute path={LIVE_SECTION} exact component={ScheduleLiveComponent} />
          <PrivateRoute path={LIVE_STATISTIQUE} exact component={StatisticsComponent} />
          {/* <PrivateRoute path={MANAGE_INVITATION_VIP} exact component={Invitation} /> */}
        </Switch>
      </Box>
    </Box>
  );
};
export default Dashboard;
