import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography color="textSecondary">LIVOOD</Typography>
        <Typography color="textSecondary" variant="body2">
          {t('footer.plateforme')}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <ul>
          <li>{t('footer.propos')}</li>
          <li>{t('footer.condition')}</li>
          <li>{t('footer.contact')}</li>
        </ul>
        <ul>
          <li>{t('footer.help')}</li>
          <li>{t('footer.preference')}</li>
        </ul>
        <ul>
          <li>{t('footer.info')}</li>
          <li>{t('footer.client')}</li>
        </ul>
        <ul>
          <li>{t('footer.cartes')}</li>
        </ul>
      </Box>
      <Box className={classes.foot}>
        <Typography color="textSecondary" variant="body2">
          {t('footer.reserved')}
        </Typography>
        <Box className="iconfooter">
          <Box>
            <FacebookIcon></FacebookIcon>
          </Box>
          <Box>
            <InstagramIcon></InstagramIcon>
          </Box>
          <Box>
            <LinkedInIcon></LinkedInIcon>
          </Box>
          <Box>
            <TwitterIcon></TwitterIcon>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
