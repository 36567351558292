import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 99999,
      position: 'fixed',
      bottom: 20,
      right: 32,
    },
    triangle: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '11px 12px 0px 12px',
      borderColor: '#ffffff transparent transparent transparent',
    },
    bullMess: {
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      marginLeft: '-22px',
      padding: 6,
      textAlign: 'center',
      wordBreak: 'break-all',
    },
    wrapper: {
      width: 100 + theme.spacing(2),
      backgroundColor: 'transparent',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      margin: theme.spacing(1),
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  }),
);
