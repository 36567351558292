export default {
  'details.live': 'Programmation du live',
  'details.product.sales': 'Produits à la vente',
  'details.name': 'Nom',
  'details.date': 'Date',
  'details.timezone': 'Fuseau Horaire',
  'details.time': 'Heure',
  'details.status': 'Statut',
  'details.miniature': 'Miniature du live',
  'details.invitation.text': ' Personnes invitées',
  'details.showlive.mobile.title': 'Le live',
};
