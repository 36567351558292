import { IBodyConsumerCount } from '../../../type/account';
import { typedAction } from '../../../utils';

export const CREATE_CONSUMER_REQUESTED = 'CREATE/CONSUMER/REQUESTED';
export const CREATE_CONSUMER_SUCCESS = 'CREATE/CONSUMER/SUCCESS';
export const CREATE_CONSUMER_FAILED = 'CREATE/CONSUMER/FAILED';

export interface IinitialstateCreateConsumer {
  id: any;
  loading: boolean;
}

const initialStateCreateConsumer: IinitialstateCreateConsumer = {
  id: '',
  loading: false,
};

export const DO_create_consumer = (data: IBodyConsumerCount) => typedAction(CREATE_CONSUMER_REQUESTED, data);
export type IDO_create_consumer = typeof DO_create_consumer;

export const Create_consumer_succefull = (data?: any) => typedAction(CREATE_CONSUMER_SUCCESS, data);
export type ICreate_consumer_success = typeof Create_consumer_succefull;

export const Create_consumer_failed = () => typedAction(CREATE_CONSUMER_FAILED);
export type ICreate_consumer_failed = typeof Create_consumer_failed;

export type CreateConsumerAction = ReturnType<IDO_create_consumer | ICreate_consumer_failed | ICreate_consumer_success>;

export const Create_consumerReducer = (
  state: IinitialstateCreateConsumer = initialStateCreateConsumer,
  action: CreateConsumerAction,
): IinitialstateCreateConsumer => {
  switch (action.type) {
    case CREATE_CONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CONSUMER_SUCCESS:
      return {
        ...state,
        // id: action.payload,
        loading: false,
      };
    case CREATE_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
