import { IBodyUpdateConsumerCount } from '../../../type/account';
import { typedAction } from '../../../utils';

export const UPADATE_CONSUMER_REQUESTED = 'UPDATE/CONSUMER/REQUESTED';
export const UPADATE_CONSUMER_SUCCESS = 'UPDATE/CONSUMER/SUCCESS';
export const UPADATE_CONSUMER_FAILED = 'UPDATE/CONSUMER/FAILED';

export interface IparamsUpadateConsumer {
  params: string;
  data: IBodyUpdateConsumerCount;
}

export interface InitialstateUpdateConsumer {
  loading: boolean;
}

const initialState: InitialstateUpdateConsumer = {
  loading: false,
};

export const DO_updateConsumer = (params: IparamsUpadateConsumer) => typedAction(UPADATE_CONSUMER_REQUESTED, params);
export type IDO_updateConsumer = typeof DO_updateConsumer;

export const updateConsumerSuccefull = () => typedAction(UPADATE_CONSUMER_SUCCESS);
export type IupdateConsumerSuccefull = typeof updateConsumerSuccefull;

export const updateConsumerFailed = () => typedAction(UPADATE_CONSUMER_FAILED);
export type IupdateConsumerFailed = typeof updateConsumerFailed;

export type updateConsumerAction = ReturnType<IDO_updateConsumer | IupdateConsumerSuccefull | IupdateConsumerFailed>;

export const updateConsumerReducer = (
  state: InitialstateUpdateConsumer = initialState,
  action: updateConsumerAction,
): InitialstateUpdateConsumer => {
  switch (action.type) {
    case UPADATE_CONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case UPADATE_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPADATE_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
