import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  getliveSessionByIdFailed,
  getliveSessionByIdSuccess,
  GET_LIVE_SESSION_BY_ID_REQUEST,
} from '../modules/liveSessionRetailerget';
import { logout } from '../modules/logout';
import { Set_state_snackbar } from '../modules/state/stateMessage';

export function* watchliveSessionget() {
  yield takeEvery(GET_LIVE_SESSION_BY_ID_REQUEST, liveSessionget);
}

export function* liveSessionget({ payload }: any) {
  try {
    const { data, status } = yield call(api.liveSessionRetailer.liveSessionRetaillerGet, payload);
    yield put(getliveSessionByIdSuccess(data));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(getliveSessionByIdFailed(error.toString()));
          yield put(Set_state_snackbar({ status: error.status, message: 'Bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: error.status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(getliveSessionByIdFailed(error.toString()));
          yield put(Set_state_snackbar({ status: error.status, message: 'Error Server', open: true, type: 'error' }));
          break;
        default:
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: error.status,
                message: 'Please! check yours Network',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
