import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    roomContainer: {
      height: '60vh',
      width: '43vw',
      '@media(max-width:1180px)': { width: (prop: any) => (prop.isRetailer ? '100vw' : '43vw'), height: '35vh' },
      backgroundColor: '#000000',
      display: 'flex',
      flexDirection: 'column',
    },
    initiatorContainer: {
      transition: 'fade 0.5s ease-out',
      position: 'relative',
      width: '43vw',
      '@media(max-width:1180px)': { width: (prop: any) => (prop.isRetailer ? '100vw' : '43vw') },
      minHeight: '75%',
      height: '100%',
      flex: 1,
    },
    pinnedInitiatorContainer: {
      maxWidth: '8vw',
      // '@media(max-width:1180px)': { width: (prop: any) => (prop.isRetailer ? '15vw !important' : '8vw') },
      '& .containerAvatar': {
        width: '3vw',
        height: '3vw',
        fontSize: 20,
      },
    },
    userMedia: {
      width: '100%',
      height: '100%',
    },
    vipRoomContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '1%',
      marginBottom: '1%',
      height: '18%',
      '@media(max-width:1180px)': {
        height: (prop: any) => (prop.isRetailer ? '25%' : '18%'),
      },

      gap: 5,
      '&.hasPinnedVip': {
        '& .vipParticipantsContainer': {
          width: 103,
          '& .wihoutVideoContainer': {
            '& .containerAvatarVip': {
              width: '3vw',
              height: '3vw',
              fontSize: 20,
            },
          },
        },
      },
    },
    vipParticipantsContainer: {
      position: 'relative',
      width: (props: any) => (props.isPinnedVip ? 'auto' : 110),
      height: '100%',
      borderRadius: '8px',
      background: '#433f3f',
      '& > button:nth-of-type(1)': {
        display: 'none',
        '&.show': {
          display: 'block',
        },
      },
      '&:hover': {
        '& > button:nth-of-type(1)': {
          display: 'block',
        },
      },
    },
    vipParticipantsBorder: {
      border: '4px solid #518971',
    },
    containerUserStream: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    videoRenderer: {
      borderRadius: 'inherit',
    },
    labelNameParticipant: {
      background: 'rgba(11,0,0,0.73)',
      color: '#ffffff',
      position: 'relative',
      bottom: '20px',
      width: 'fit-content',
      marginLeft: '5px',
      fontSize: '10px',
      padding: '3px',
      borderRadius: '5px',
    },
    bottomManif: {
      width: '100%',
      position: 'relative',
      bottom: '100px',
      padding: '25px',
      display: 'flex',
      justifyContent: 'end',
    },
    btnManif: {
      width: '40px',
      height: '40px',
      borderRadius: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#ffffff',
      cursor: 'pointer',
      background: 'rgb(106 106 106 / 85%)',
    },
    btnManifActive: {
      background: 'rgb(227,202,65)',
      border: '1px solid white',
    },
    pictpict: {
      cursor: 'pointer',
      background: 'rgb(106 106 106 / 85%)',
      height: '40px',
      width: '40px',
      borderRadius: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    alertContener: {
      height: '10%',
      width: '80%',
      position: 'relative',
      margin: '0 auto',
      bottom: '55%',
      padding: '15px',
    },

    pinnedVideoContainer: {
      height: '100%',
    },

    bottomBtnVideoRender: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      bottom: 60,
      gap: (props: any) => (props.isPinnedVip ? 10 : 20),
      height: '0',
      '@media(max-width: 960px)': {
        gap: '10px!important',
        left: '-10vw',
      },
    },
    btnConfCall: {
      width: '40px',
      height: '40px',
      background: 'rgb(106 106 106 / 85%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    btnConfCallDisabled: {
      width: '40px',
      height: '40px',
      background: 'rgba(231,3,3,0.82)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    btnBackToLive: {
      width: '40px',
      height: '40px',
      background: 'rgba(231,3,3,0.82)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50px',
      cursor: 'pointer',
      color: '#ffffff',
    },
    iconBtnConfCall: {
      color: '#fffcfc',
      fontSize: '30px',
    },
    contenerIconMic: {
      position: 'relative',
      top: '6px',
      height: 0,
      display: 'flex',
      zIndex: 11,
      justifyContent: 'right',
      '&.isPinnedVip': {
        display: 'none',
      },
    },
    contentMic: {
      width: '20px',
      height: '20px',
      borderRadius: '50px',
      background: '#ff00009e',
      paddingLeft: '2px',
      paddingTop: '2px',
      marginRight: '4px',
    },
    iconTypeMic: {
      fontSize: '15px',
      color: '#efefef',
      marginRight: '5px',
    },
    wihoutVideoContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2a2121',
      '@media(max-width: 1280px)': {
        // width: '61vw',
        // height: '35vw',
      },
      '@media(max-width:1180px)': {
        width: (prop: any) => (prop.isRetailer ? '100vw' : '100%'),
      },
      '@media(max-width: 958px)': {
        width: '76vw',
        height: '100%',
      },
    },

    containerAvatar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24%',
      height: '41%',
      borderRadius: '100%',
      marginBottom: '4%',
      color: '#FFF',
      fontSize: '6vw',
    },
    btnPinVip: {
      position: 'absolute',
      zIndex: 2,
      top: (props: any) => (props.isPinnedVip ? '85%' : '50%'),
      right: (props: any) => (props.isPinnedVip ? -75 : -50),
      transform: 'translateY(-50%)',
      borderRadius: 1.5,
      textTransform: 'capitalize',
      fontSize: 10,
      fontWeight: 'bold',
    },
    btnPinVipMobile: {
      right: '15px!important',
    },
    btnParticipantOptions: {
      position: 'absolute',
      right: 2,
      bottom: 5,
      backgroundColor: '#000',
      color: '#fff',
      '& svg': {
        width: 20,
        height: 20,
      },
    },
    screenProperty: {
      position: 'absolute',
      right: 20,
      display: 'flex',
      gap: 20,
      padding: '4px 0px 4px 0px',
      '@media(max-width: 960px)': {
        gap: 5,
        top: '-4px',
        right: 0,
      },
    },
    roomContainerFullscreen: {
      height: '100vh!important',
      width: '64vw!important',
      left: 0,
      transition: 'fade 0.5s ease-out',
      position: 'absolute',
      '@media(max-width:1180px)': {
        width: '100vh!important',
        height: '100vw!important',
        transform: 'rotate(90deg) translateY(0) translateX(20%)',
        left: 'inherit',
        '@media screen (max-width:720px), screen and (max-height:480px)': {
          transform: 'rotate(0deg)',
          top: 0,
          left: 0,
          width: '100vw!important',
          height: '100vh!important',
        },
      },
    },
    initiatorContainerFullscreen: {
      width: '64vw!important',
      transition: 'fade 0.5s ease-out',
      '@media(max-width:1180px)': {
        width: '100vh!important',
        '@media screen (max-width:720px), screen and (max-height:480px)': {
          width: '100vw!important',
          height: '100vh!important',
        },
      },
    },
  }),
);
