import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

interface ICustomBackdrop {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
  }),
);

const CustomBackdrop: FC<ICustomBackdrop> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomBackdrop;
