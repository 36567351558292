import { getTimezoneFailed, getTimezoneSuccess, GET_TIMEZONE_REQUEST } from '../modules/timezone';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';

export function* watchtimezone() {
  yield takeEvery(GET_TIMEZONE_REQUEST, timezone);
}

function* timezone() {
  try {
    const { data } = yield call(api.time.timezone);
    yield put(getTimezoneSuccess(data));
  } catch (error: any) {
    yield put(getTimezoneFailed(error.toString()));
  }
}
