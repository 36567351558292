import { all, fork } from 'redux-saga/effects';
import { watchliveSession } from './liveSession';
import { watchliveSessionPage } from './liveSessionPage';
import { watchLogin } from './login';
import { watchliveSessionRetailerPage } from './liveSessionRetailerPage';
import { watchliveSessionCreate } from './liveSessionCreate';
import { watchliveSessionDuplicate } from './liveSessionDuplicate';
import { watchliveSessionget } from './liveSessionget';
import { watchtimezone } from './timezone';
import { watchLogout } from './logout';
import { watchcategorie } from './categorie';
import { watchUpload } from './upload';
import { watchGallerie } from './gallerieImage';
import { watchliveSessionConsumerget } from './liveSessionConsumerget';
import { watchliveSessionUpdate } from './liveSessionUpdate';
import { watchliveSessionSectionRetailer } from './liveSessionRetailler';
import { watchduration } from './duration';
import { watchliveSessionBySection } from './liveSessionListBySection';
import { watchliveSessionDetails } from './liveSessionRetailerDetails';
import { watchliveSessionEnd } from './liveSessionEnd';
import { watchliveSessionStart } from './liveSessionStart';
import { watchliveSessionJoin } from './liveSessionJoin';
import { watchlistener } from './liveListener';
import { watchListenerHistory } from './listenerHistory';
import { watchListProduct } from './productList';
import { watchcreateProduct } from './productCreate';
import { watchUpdateProduct } from './productUpdate';
import { watchgetProductById } from './productget';
import { watchliveSessionConsumerProduct } from './liveSessionConsumerProduct';
import { watchDeleteProduct } from './productDelete';
import { watchGetInfoRetailer } from './account/getInfoRetailer';
import { watchSetLogoRetailer } from './account/setLogoRetailer';
import { watchcreateGuest } from './guest/guestCreate';
import { watchDeleteGuest } from './guest/guestDelete';
import { watchgetGuestById } from './guest/guestGet';
import { watchListGuest } from './guest/guestList';
import { watchMailDataGuest } from './guest/guestGetInvitationMailData';
import { watchUpdateGuest } from './guest/guestUpdate';
import { watchGuestConsumer } from './guest/guestConsumer';
import { watchSendGuest } from './guest/guestSend';
import { watchAffiliation } from './participant/affiliation';
import { watchBindConsummer } from './participant/bindConsumer';
import { watchliveSessionEndedSection } from './liveSessionListEndedSection';
import { watchListGuestSummary } from './guest/guestListsParticipantsSummary';
import { watchCreateConsumer } from './account/consumer/createConsumer';
import { watchlistconsumer } from './account/consumer/listConsumer';
import { watchUpdateConsumer } from './account/consumer/updateConsumer';
import { watchsetAdvantageCodesGuest } from './guest/guestAdvantageCode';
import { watchGuestCounterPartSet } from './guest/guestCounterPartSet';
import { watchGuestCounterPartGet } from './guest/guestCounterPartGet';
import { watchAdvantageCode } from './participant/advantageCode';
import { watchListClickRecordProductByLiveId } from './participantProductClickRecord';
import { watchConfirmationAccountConsumer } from './account/consumer/confirmationAccountConsumer';
import { watchCreateAccountConsumer } from './account/consumer/createAccountConsumer';
import { watchUpdatePassword } from './account/updatePassword';
import { watchSendEmailResetPassword } from './account/sendEmailResetPassword';
import { watchResetPassword } from './account/resetPassword';
import { watchUploadProfileImage } from './account/consumer/uploadProlifeImage';

export const rootSaga = function* root() {
  yield all([
    fork(watchLogin), //add other
    fork(watchliveSession),
    fork(watchliveSessionPage),
    fork(watchliveSessionRetailerPage),
    fork(watchliveSessionDuplicate),
    fork(watchliveSessionCreate),
    fork(watchliveSessionget),
    fork(watchtimezone),
    fork(watchLogout),
    fork(watchcategorie),
    fork(watchUpload),
    fork(watchGallerie),
    fork(watchliveSessionConsumerget),
    fork(watchliveSessionUpdate),
    fork(watchliveSessionSectionRetailer),
    fork(watchduration),
    fork(watchliveSessionBySection),
    fork(watchliveSessionDetails),
    fork(watchliveSessionEnd),
    fork(watchliveSessionStart),
    fork(watchliveSessionJoin),
    fork(watchlistener),
    fork(watchListenerHistory),
    fork(watchcreateProduct),
    fork(watchUpdateProduct),
    fork(watchListProduct),
    fork(watchgetProductById),
    fork(watchliveSessionConsumerProduct),
    fork(watchDeleteProduct),
    fork(watchGetInfoRetailer),
    fork(watchSetLogoRetailer),
    fork(watchcreateGuest),
    fork(watchDeleteGuest),
    fork(watchgetGuestById),
    fork(watchListGuest),
    fork(watchUpdateGuest),
    fork(watchSendGuest),
    fork(watchAffiliation),
    fork(watchBindConsummer),
    fork(watchGuestConsumer),
    fork(watchMailDataGuest),
    fork(watchliveSessionEndedSection),
    fork(watchListGuestSummary),
    fork(watchCreateConsumer),
    fork(watchlistconsumer),
    fork(watchUpdateConsumer),
    fork(watchsetAdvantageCodesGuest),
    fork(watchAdvantageCode),
    fork(watchGuestCounterPartSet),
    fork(watchGuestCounterPartGet),
    fork(watchListClickRecordProductByLiveId),
    fork(watchCreateAccountConsumer),
    fork(watchConfirmationAccountConsumer),
    fork(watchUpdatePassword),
    fork(watchSendEmailResetPassword),
    fork(watchResetPassword),
    fork(watchUploadProfileImage),
  ]);
};
