import React, { FC } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { AdvanatageCodeType } from '../../interface/commonInterface';

interface optionProps {
  value: string;
  label: string;
}

interface CustomSelectInputProps {
  labelName: string;
  options: optionProps[];
  selectedCodeValue: AdvanatageCodeType;
  handleSelect:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
}

const CustomSelectInput: FC<CustomSelectInputProps> = (props) => {
  const { labelName, options, selectedCodeValue, handleSelect } = props;
  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>{labelName}</InputLabel>
        <Select value={selectedCodeValue} onChange={handleSelect}>
          {options.map(({ label, value }) => (
            <MenuItem value={value} key={label + value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(CustomSelectInput);
