import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  getliveSessionConsumerByIdFailed,
  getliveSessionConsumerByIdSuccess,
  GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST,
} from '../modules/liveSessionConsumerget';

export function* watchliveSessionConsumerget() {
  yield takeEvery(GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST, liveSessionConsumerget);
}

export function* liveSessionConsumerget({ payload }: any) {
  try {
    const { data, status } = yield call(api.liveSession.liveSessionConsumerGet, payload);
    yield put(getliveSessionConsumerByIdSuccess(data));
  } catch (error: any) {
    yield put(getliveSessionConsumerByIdFailed(error.toString()));
  }
}
