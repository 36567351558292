import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      background: '#518971',
    },
    drawerContent: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'block',
      },
      '& .MuiListItemText-primary': {
        color: '#FFFFFF',
        fontWeight: 700,
      },
      '& .MuiListItemIcon-root': {
        color: '#FFFFFF',
      },
      '& .MuiListItem-root': {
        cursor: 'pointer',
      },
      '& a': {
        textDecoration: 'none',
      },
    },
  }),
);
