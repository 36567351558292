import { RoomServiceClient } from 'livekit-server-sdk';
import { Participant, Room } from 'livekit-client';
const roomService = new RoomServiceClient(
  process.env.REACT_APP_LIVEKIT_SERVER as string,
  process.env.REACT_APP_LIVEKIT_API_KEY as string,
  process.env.REACT_APP_LIVEKIT_API_SECRET as string,
);

export const updateMetadataOfRoom = async (room: Room, propsMetadata: string, value: any) => {
  if (room) {
    const initialMetadata = room.metadata;
    const metadataParsed = initialMetadata ? JSON.parse(initialMetadata as string) : {};
    metadataParsed[propsMetadata] = value;
    const metadata = JSON.stringify(metadataParsed);
    await roomService.updateRoomMetadata(room.name, metadata);
  }
};

export const updateMetadataOfParticipant = async (
  room: Room,
  participantIdentity: string,
  participant: Participant,
  propsMetadata: string,
  value: any,
) => {
  const initialMetadata = participant?.metadata;
  const metadataParsed = JSON.parse(initialMetadata as string);
  metadataParsed[propsMetadata] = value;
  const metadata = JSON.stringify(metadataParsed);
  await roomService.updateParticipant(room.name, participantIdentity, metadata);
};

export const addOnMetadataParticipantShowToLive = async (room: Room, identity: string) => {
  let listShowToLive = [];

  if (room.metadata && JSON.parse(room.metadata).listShowToLive) {
    listShowToLive = JSON.parse(room.metadata).listShowToLive;
    if (listShowToLive.indexOf(identity) === -1) {
      listShowToLive.push(identity);
      await updateMetadataOfRoom(room, 'listShowToLive', listShowToLive);
    }
  } else {
    listShowToLive.push(identity);
    await updateMetadataOfRoom(room, 'listShowToLive', listShowToLive);
  }
};

export const deleteOnMetadataParticipantShowToLive = async (room: Room, identity: string) => {
  if (room.metadata && JSON.parse(room.metadata).listShowToLive) {
    const listShowToLive = JSON.parse(room.metadata).listShowToLive;
    const index = listShowToLive.indexOf(identity);
    delete listShowToLive[index];
    await updateMetadataOfRoom(room, 'listShowToLive', listShowToLive);
  }
};

export const getListOfParticipantsInLive = (room: Room) => {
  if (room.metadata && JSON.parse(room.metadata).listShowToLive) {
    return JSON.parse(room.metadata).listShowToLive;
  } else {
    return [];
  }
};

export const getPinnedOfRoom = (room: Room): Participant | undefined => {
  if (room.metadata && JSON.parse(room.metadata).pinnedInLive) {
    return JSON.parse(room.metadata).pinnedInLive;
  }
};
