import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxComponentbtnProduct: {
      // display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      justifyContent: 'space-arround',
      height: 400,
      padding: '10px 10px 22px 10px',
    },
  }),
);
