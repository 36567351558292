import { typedAction } from '../utils';

export interface IgetLive {
  id: string;
  idInvitation?: string;
  idAffiliation?: string;
}

export interface IliveConsumerById {
  id: string;
  title: string;
  retailerId: string;
  retailerName: string;
  description: string;
  categoryId: string;
  categoryName: string;
  scheduledStart: string;
  scheduledDuration: number;
  privacy: number;
  thumbnailImageId: string;
  thumbnailImageUrl: string;
  actualStart: string;
  actualEnd: string;
  streamingUrl: string;
  viewingMode: boolean;
  retailerLogoUrl: string;
  primaryColor: string;
  previewImageUrl: string;
  pausedLiveImageUrl: string;
  endedLiveImageUrl: string;
  timeZone: {
    name: string;
    utcOffset: number;
  };
  replayVideoFileUrl: string;
}

export interface IinitialStateConsumerGet {
  loading: boolean;
  error: string;
  status: number | undefined;
  live: IliveConsumerById;
}
const initialState: IinitialStateConsumerGet = {
  loading: false,
  error: '',
  status: undefined,
  live: {
    id: '',
    title: '',
    description: '',
    categoryId: '',
    scheduledDuration: 0,
    privacy: 0,
    primaryColor: '',
    thumbnailImageId: '',
    thumbnailImageUrl: '',
    actualEnd: '',
    actualStart: '',
    categoryName: '',
    retailerId: '',
    retailerName: '',
    scheduledStart: '',
    streamingUrl: '',
    previewImageUrl: '',
    retailerLogoUrl: '',
    viewingMode: false,
    endedLiveImageUrl: '',
    pausedLiveImageUrl: '',
    timeZone: {
      name: '',
      utcOffset: 0,
    },
    replayVideoFileUrl: '',
  },
};

export const GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST = 'GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST';
export const GET_LIVE_SESSION_CONSUMER_BY_ID_SUCCESS = 'GET_LIVE_SESSION_CONSUMER_BY_ID_SUCCESS';
export const GET_LIVE_SESSION_CONSUMER_BY_ID_FAILED = 'GET_LIVE_SESSION_CONSUMER_BY_ID_FAILED';

export const getliveSessionConsumerById = (id: string) => typedAction(GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST, id);
export type GetliveSessionConsumerById = typeof getliveSessionConsumerById;

export const getliveSessionConsumerByIdSuccess = (result: IliveConsumerById) =>
  typedAction(GET_LIVE_SESSION_CONSUMER_BY_ID_SUCCESS, result);
export type GetliveSessionConsumerByIdSuccess = typeof getliveSessionConsumerByIdSuccess;

export const getliveSessionConsumerByIdFailed = (error: string) =>
  typedAction(GET_LIVE_SESSION_CONSUMER_BY_ID_FAILED, error);
export type GetliveSessionConsumerByIdFailed = typeof getliveSessionConsumerByIdFailed;

export type liveSessionById = ReturnType<
  GetliveSessionConsumerById | GetliveSessionConsumerByIdSuccess | GetliveSessionConsumerByIdFailed
>;

export const getliveSessionConsumerByIdReducer = (
  state: IinitialStateConsumerGet = initialState,
  action: liveSessionById,
): IinitialStateConsumerGet => {
  switch (action.type) {
    case GET_LIVE_SESSION_CONSUMER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIVE_SESSION_CONSUMER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        live: action.payload,
      };
    case GET_LIVE_SESSION_CONSUMER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
