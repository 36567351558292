import { typedAction } from '../utils';
import { IerrorCreate } from './liveSessionRetailerCreate';

export interface IDetails {
  id: string;
  title: string;
  description: string;
  categoryName: string;
  scheduledStart: string;
  timeZoneName: string;
  timeZoneUtcOffset: number;
  scheduledDuration: number;
  privacy: string;
  isPublished: true;
  previewImageUrl: string;
  pausedLiveImageUrl: string;
  endedLiveImageUrl: string;
  primaryColor: string;
  actualStart: string | null;
  actualEnd: string | null;
}
export interface IinitialStateDetails {
  loading: boolean;
  error: IerrorCreate | undefined;
  liveDetails: IDetails;
}

const initialStateDetails: IinitialStateDetails = {
  loading: true,
  error: undefined,
  liveDetails: {
    id: '',
    title: '',
    description: '',
    categoryName: '',
    scheduledStart: '',
    timeZoneName: '',
    timeZoneUtcOffset: 0,
    scheduledDuration: 0,
    privacy: '',
    isPublished: true,
    previewImageUrl: '',
    pausedLiveImageUrl: '',
    endedLiveImageUrl: '',
    actualStart: null,
    actualEnd: null,
    primaryColor: '',
  },
};

export const GET_SESSION_DETAILS_REQUEST = 'GET_SESSION_DETAILS_REQUEST';
export const GET_SESSION_DETAILS_SUCCESS = 'GET_SESSION_DETAILS_SUCCESS';
export const GET_SESSION_DETAILS_FAILED = 'GET_SESSION_DETAILS_FAILED';

export const getLiveSessionDetails = (id: string) => {
  return typedAction(GET_SESSION_DETAILS_REQUEST, id);
};
export type GetLiveSessionDetails = typeof getLiveSessionDetails;

export const liveSessionDetailsSuccess = (result: IDetails) => typedAction(GET_SESSION_DETAILS_SUCCESS, result);
export type LiveSessionDetailsSuccess = typeof liveSessionDetailsSuccess;

export const liveSessionDetailsFailed = (error: IerrorCreate) => typedAction(GET_SESSION_DETAILS_FAILED, error);
export type LiveSessionDetailsFailed = typeof liveSessionDetailsFailed;

export type LiveSessionDetailsAction = ReturnType<
  GetLiveSessionDetails | LiveSessionDetailsSuccess | LiveSessionDetailsFailed
>;

export const liveSessionDetailsReducer = (
  state: IinitialStateDetails = initialStateDetails,
  action: LiveSessionDetailsAction,
): IinitialStateDetails => {
  switch (action.type) {
    case GET_SESSION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        liveDetails: action.payload,
        loading: false,
      };
    case GET_SESSION_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
