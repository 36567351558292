import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import TableStickyHead from '../../common/Table/TableStickyHead';
import TriButton from '../../common/TriButton/TriButton';
import { get_AllGuest, IdataGuest, IinitialStateListGuest } from '../../redux-services/modules/guest/guestList';
import { RootState } from '../../redux-services';
import { do_create_guest, IinitialStateGuest } from '../../redux-services/modules/guest/guestCreate';
import { do_send_guest, IinitialStateSendGuest } from '../../redux-services/modules/guest/guestSend';
import { DoDeleteGuest, IinitialStateDeleteGuest } from '../../redux-services/modules/guest/guestDelete';
import CustomModal from '../../common/modal/Modal';
import Email from '../../common/Card/Email';
import EmailMobile from '../../common/Card/EmailMobile';
import { isEmailValid } from '../../lib/auth/authvalidate';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import {
  clear_invitationMailData,
  get_invitationMailData,
  IinitialStateMailDataGuest,
} from '../../redux-services/modules/guest/guestGetInvitationMailData';
import { IinitialStateGuestAdvantageCode } from '../../redux-services/modules/guest/guestAdvantageCodes';
import { useIsMobile } from '../../common/header/useIsMobile';
import { ArrowBackIos } from '@material-ui/icons';

const Invitation = () => {
  const { t } = useTranslation();
  const style = useStyles();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isShowdetail, setIsShowDetail] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [emailsId, setEmailsId] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [dataListGuest, setDataListGuest] = useState<IdataGuest[]>([]);

  const data = useSelector<RootState, IinitialStateListGuest>((state) => state.guestList);
  const dataCreated = useSelector<RootState, IinitialStateGuest>((state) => state.guestCreated);
  const dataDeleted = useSelector<RootState, IinitialStateDeleteGuest>((state) => state.guestDeleted);
  const dataSended = useSelector<RootState, IinitialStateSendGuest>((state) => state.guestSend);
  const dataMail = useSelector<RootState, IinitialStateMailDataGuest>((state) => state.guestInvitationMailData);
  const dataAdvantageCodeAdded = useSelector<RootState, IinitialStateGuestAdvantageCode>(
    (state) => state.guestSetAdvantageCodes,
  );

  const isMobile = useIsMobile();
  const history = useHistory();

  const AddGuest = () => {
    dispatch(
      do_create_guest({
        liveSessionId: id,
        emailAddress: newEmail.toLowerCase(),
      }),
    );
    setNewEmail('');
  };

  const SendToGuest = () => {
    dispatch(
      do_send_guest({
        liveSessionId: id,
        guestIds: emailsId,
      }),
    );
    setOpenModal(false);
  };

  const DeletEmailGuest = () => {
    let dataToDelete = '?';
    for (const [i, d] of emailsId.entries()) {
      dataToDelete = i === emailsId.length - 1 ? dataToDelete + `ids=${d}` : dataToDelete + `ids=${d}&`;
    }
    setEmailsId([]);
    dispatch(DoDeleteGuest(dataToDelete));
  };

  const GoBack = () => {
    history.push(`/dashboard/livesessions/${id}`);
  };

  const SelectAll = useCallback(() => {
    setSelectAll(!selectAll);
    setIsSelectedAll(!isSelectedAll);
  }, [selectAll, isSelectedAll]);

  useEffect(() => {
    if (!dataDeleted.loading && !dataCreated.loading && !dataSended.loading && !dataAdvantageCodeAdded.loading) {
      dispatch(get_AllGuest(id));
    }
  }, [dataDeleted.loading, dataCreated.loading, dataSended.loading, dataAdvantageCodeAdded.loading]);

  useEffect(() => {
    setDataListGuest(data.ListGuest);
  }, [data]);

  useEffect(() => {
    if (emailsId && emailsId.length === 1) {
      dispatch(get_invitationMailData(emailsId[0]));
    } else {
      dispatch(clear_invitationMailData());
    }
  }, [emailsId]);

  console.log('dataMail', dataListGuest, dataMail, emailsId);

  const handleChangeEmailAddress = useCallback((text) => {
    setNewEmail(text.target.value);
  }, []);

  const TextInputWithMemo = useMemo(() => {
    console.log('rendering textInput');
    return (
      <TextField
        className={style.textFiels}
        label={t('invitation.asking.adress')}
        id="fullWidth"
        variant="outlined"
        size={isMobile ? 'small' : 'medium'}
        color="primary"
        style={{ fontSize: '16px !important' }}
        value={newEmail}
        onChange={handleChangeEmailAddress}
      />
    );
  }, [newEmail]);

  const MemoizedEmail = useCallback(
    () => <Email subject={dataMail.mailDataGuest.subject} body={dataMail.mailDataGuest.body} />,
    [dataMail],
  );

  const MemoizedEmailMobile = useCallback(
    () => (
      <EmailMobile
        subject={dataMail.mailDataGuest.subject}
        body={dataMail.mailDataGuest.body}
        addressSender={dataListGuest.find((d) => d.id === emailsId[0])?.emailAddress}
      />
    ),
    [dataMail],
  );

  return (
    <>
      <CustomBackdrop {...{ open: dataCreated.loading || dataDeleted.loading || dataSended.loading }} />
      <CustomModal
        {...{
          open: openModal,
          onConfirm: SendToGuest,
          title: 'Confirmation envoi',
          textConfirm: 'Confirmer',
          TextCancel: 'Annuler',
          onClose: () => {
            setOpenModal(false);
          },
        }}
      >
        <Typography>{t('invitation.message.send')}</Typography>
      </CustomModal>
      {!isShowdetail && (
        <>
          {!isMobile && (
            <Box className={style.address}>
              <Typography
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  marginTop: '5px',
                }}
              >
                {t('invitation.title')}
              </Typography>
            </Box>
          )}
          <Grid container className={style.root} style={isMobile ? { marginTop: '00px' } : {}}>
            <div style={{ display: 'flex', paddingBottom: 8 }}>
              {isMobile && (
                <>
                  <Button style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 4, minWidth: 'auto' }} onClick={GoBack}>
                    <ArrowBackIos className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" />
                  </Button>
                  <Typography
                    style={{
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      marginTop: '5px',
                    }}
                  >
                    Invitation des VIP
                  </Typography>
                </>
              )}
            </div>
            <Grid
              item
              xs={12}
              md={7}
              style={isMobile ? { paddingTop: 16, paddingBottom: 10 } : {}}
              className={isMobile ? '' : style.address}
            >
              {isMobile ? (
                <Grid container className={style.addAdress}>
                  <Grid item sm={6} style={{ margin: 0, padding: 0 }}>
                    {TextInputWithMemo}
                  </Grid>
                  <Grid item sm={6} style={{ paddingRight: 12, alignItems: 'end', textAlign: 'end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={AddGuest}
                      disabled={!isEmailValid(newEmail)}
                      style={{ fontSize: 14, padding: '8px 8px' }}
                    >
                      {t('invitation.button.add')}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Box className={style.addAdress}>
                  {TextInputWithMemo}
                  <Button variant="contained" color="primary" onClick={AddGuest} disabled={!isEmailValid(newEmail)}>
                    {t('invitation.button.add')}
                  </Button>
                </Box>
              )}
              <TableStickyHead
                data={dataListGuest}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                emailsId={emailsId}
                setEmailsId={setEmailsId}
                isSelectedAll={isSelectedAll}
              />
              {!isMobile && (
                <TriButton
                  styleContainer={style.triButtonRoot}
                  button1={{
                    isDisabled: emailsId.length === 0,
                    label: t('invitation.send'),
                    onClick: () => {
                      setOpenModal(true);
                    },
                  }}
                  button2={{
                    isDisabled: emailsId.length === 0,
                    label: t('invitation.delete'),
                    onClick: DeletEmailGuest,
                  }}
                  button3={{
                    label: selectAll ? t('invitation.deselectAll') : t('invitation.selectAll'),
                    onClick: SelectAll,
                  }}
                />
              )}
            </Grid>
            {!isMobile && (
              <Grid item xs={12} md={5} className={style.email}>
                <Typography
                  style={{
                    fontSize: isMobile ? 16 : '1.375rem',
                    lineHeight: '2.313rem',
                    fontWeight: 'bold',
                    marginBottom: '18px',
                  }}
                >
                  {t('invitation.title.send')}
                </Typography>
                <MemoizedEmail />
                {/* <Email subject={dataMail.mailDataGuest.subject} body={dataMail.mailDataGuest.body} /> */}
              </Grid>
            )}
          </Grid>
          {!isMobile && (
            <Grid container>
              <Typography className={style.info} variant="body1">
                {t('invitation.info')}
              </Typography>
            </Grid>
          )}
          {isMobile && (
            <Box
              style={{
                position: 'fixed',
                width: 'calc(100vw + 20px)',
                padding: 20,
                left: '-20px',
                borderTop: '1px solid #acacac',
                bottom: 0,
                backgroundColor: '#f1f1f1',
              }}
            >
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  disabled={emailsId.length === 0}
                  style={{
                    backgroundColor: emailsId.length === 0 ? '#ffffff' : 'rgb(81, 137, 113)',
                    // width: '100%',
                    margin: 5,
                    borderRadius: 4,
                    padding: '4px 10px',
                    textTransform: 'unset',
                  }}
                >
                  {t('invitation.send')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={DeletEmailGuest}
                  disabled={emailsId.length === 0}
                  style={{
                    backgroundColor: emailsId.length === 0 ? '#ffffff' : 'rgb(81, 137, 113)',
                    // width: '100%',
                    margin: 5,
                    borderRadius: 4,
                    padding: '4px 10px',
                    textTransform: 'unset',
                  }}
                >
                  {t('invitation.delete')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsShowDetail(true);
                  }}
                  disabled={emailsId.length !== 1}
                  style={{
                    backgroundColor: emailsId.length !== 1 ? '#ffffff' : 'rgb(81, 137, 113)',
                    // width: '100%',
                    margin: 5,
                    borderRadius: 4,
                    padding: '4px 10px',
                    textTransform: 'unset',
                  }}
                >
                  Invitation
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
      {isShowdetail && (
        <Grid container style={{ marginTop: '0px', paddingRight: 12, paddingLeft: 12 }}>
          <Grid>
            <div style={{ display: 'flex', paddingBottom: 8 }}>
              <>
                <Button
                  style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 4, minWidth: 'auto' }}
                  onClick={() => {
                    setIsShowDetail(false);
                  }}
                >
                  <ArrowBackIos className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" />
                </Button>
                <Typography
                  style={{
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    marginTop: '5px',
                  }}
                >
                  {dataListGuest.find((d) => d.id === emailsId[0])?.emailAddress}
                </Typography>
              </>
            </div>
          </Grid>
          <Grid item xs={12} md={12} className={style.email}>
            <Typography
              style={{
                fontSize: isMobile ? 16 : '1.375rem',
                lineHeight: '2.313rem',
                fontWeight: 'bold',
                marginBottom: '18px',
              }}
            >
              {t('invitation.title.send')}
            </Typography>
            <MemoizedEmailMobile />
            {/* <Email subject={dataMail.mailDataGuest.subject} body={dataMail.mailDataGuest.body} /> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Invitation;
