import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { url } from 'inspector';
import bg from '../../Assets/images/fond.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      fontFamily: 'Montserrat',
      backgroundImage: `url(${bg})`,
    },
    sectionOne: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&>div:first-of-type': {
        width: '60%',
      },
      '&>.photo': {
        '&>img': {
          width: '100%',
          objectFit: 'cover',
        },
      },
      '&>.description': {
        '&>p': {
          color: '#7a7a7a',
          fontSize: 16,
        },
        maxWidth: 460,
        '&>div': {
          textAlign: 'end',
          marginTop: 60,
          '&>button': {
            fontFamily: 'Montserrat',
            textTransform: 'uppercase',
            padding: '8px 16px',
            backgroundColor: '#518971',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
          },
          '@media(max-width:750px)': {
            textAlign: 'center!important',
            marginTop: '30px!important',
          },
        },
        '@media(max-width:1024px)': {
          maxWidth: '35%',
        },
        '@media(max-width:750px)': {
          maxWidth: 'unset',
        },
      },
      '@media(max-width:750px)': {
        flexDirection: 'column',
        '&>div:first-of-type': {
          width: '100%',
        },
        '&>.photo': {
          '&>img': {
            height: '300px!important',
          },
        },
      },
    },
    sectionTwo: {
      '&>h1': {
        textAlign: 'center',
        marginTop: 28,
        marginBottom: 48,
        '@media(max-width:700px)': {
          marginBottom: 28,
        },
      },
    },
    threeCard: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 24,
      '&>.card': {
        flex: '1 1 0px',
        maxWidth: 380,
        color: '#7a7a7a',
        '&>h2': {
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bolder',
          color: '#518971',
          fontStyle: 'italic',
        },
      },
      '@media(max-width:700px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    vipInit: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      '&>h1': {
        color: '#518971',
      },
      '&>p': {
        color: '#7a7a7a',
        marginTop: 0,
      },
    },
    sectionVip: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 48,
      '&>.photo-vip': {
        '&>img': {
          width: 400,
          height: '100%',
          objectFit: 'cover',
        },
      },
      '&>.details-vip': {
        marginLeft: 150,
        '&>h1': {
          marginTop: 0,
        },
        '&>.paraph': {
          color: '#7a7a7a',
          marginBottom: 36,
          '&>ul': {
            padding: 0,
          },
          '&>ul:first-child': {
            color: '#518971',
            fontWeight: 500,
          },
          '&>ul:last-child': {
            listStyle: 'none',
            '&>li': {
              position: 'relative',
            },
            '&>li:before': {
              content: "'-'",
              position: 'absolute',
              left: '-17px',
            },
          },
        },
      },
      '@media(max-width:850px)': {
        '&>.photo-vip': {
          width: '35%',
          minWidth: 250,
          '&>img': {
            width: '100%!important',
          },
        },
        '&>.details-vip': {
          marginLeft: 40,
        },
      },
      '@media(max-width:640px)': {
        flexDirection: 'column',
        '&>.photo-vip': {
          width: '100%',
          minWidth: 'unset',
          marginBottom: 20,
          '&>img': {
            width: '100%!important',
          },
        },
        '&>.details-vip': {
          marginLeft: 0,
          '&>.paraph': {
            marginLeft: '18px!important',
          },
        },
      },
    },
    vipDetails: {
      backgroundColor: '#f2f6f4',
      marginTop: 28,
    },
    sectionVipTwo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '&>.six-grid': {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 1080,
        margin: '0 auto',
        justifyContent: 'center',
        '&>.item': {
          width: '50%',
          maxWidth: 480,
          marginBottom: 40,
        },
        '@media(max-width:690px)': {
          flexDirection: 'column',
          '&>.item': {
            width: '100%',
            marginBottom: 16,
          },
        },
      },
    },
    footer: {
      backgroundColor: '#000000',
      color: 'white',
      padding: '60px 0',
      '& a': {
        textDecoration: 'none',
        color: 'white',
      },
      '& h2': {
        marginTop: 0,
        '&>span': {
          color: '#ffd700',
        },
      },
      '& p': {
        margin: 0,
      },
    },
  }),
);
