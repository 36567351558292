import { Box, Button, DialogProps, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import {
  ADD_PRODUCTS,
  AFFILIATION,
  CREATE_QUESTIONNAIRE,
  LIVE_APERCU,
  LIVEDETAILS,
  MANAGE_INVITATION_VIP,
  PRODUCTS,
  QUESTIONNAIRE,
  UPDATE_PRODUCTS,
} from '../../constant/path';
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import { RootState } from '../../redux-services';
import { doEndLiveSession, IinitialStateLiveEnd } from '../../redux-services/modules/liveSessionEnd';
import { doJoinLiveSession } from '../../redux-services/modules/liveSessionJoin';
import { getLiveSessionDetails, IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { initialStateUpdate } from '../../redux-services/modules/liveSessionRetailerUpdate';
import { doStartLiveSession, IinitialStateLiveStart } from '../../redux-services/modules/liveSessionStart';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import ApercuComponent, { IActusWithActivated } from '../ApercuLiveComponent/ApercuComponent';
import NewProductToSale from '../NewProductToSale/NewProductToSale';
import ProductsToSale from '../ProductsToSale/ProductsToSale';
import DetailsComponent from './DetailsComponent';
import ListQuestionnaireComponent from './QuestionnaireComponent/ListQuestionnaireComponent';
import QuestionnaireComponent from './QuestionnaireComponent/QuestionnaireComponent';
import Invitation from './../InvitationComponent/Invitation';
import AffiliationComponent from '../Affiliation/AffiliationComponent';
import PopupSummaryGuest from '../../common/Table/PopupSummaryGuest';
import { useStyles } from './styles';
import CustomModal from '../../common/modal/Modal';
import { IDom } from '../../redux-services/modules/dom';
import LiveDetailsViewers from './LiveDetailsViewers';
import { useIsMobile } from '../../common/header/useIsMobile';
import clsx from 'clsx';
import {
  InfoOutlined,
  ModeCommentOutlined,
  PeopleOutlineOutlined,
  StorefrontOutlined,
  AcUnit,
} from '@material-ui/icons';
import Tabs, { TabsData } from '../Tabs/Tabs';
import { IMessage } from '../../redux-services/type/listenerApi';
import { HubConnection } from '@microsoft/signalr';
import Chat from '../../common/chat/Chat';
import VipComponent from './VipComponent/VipComponent';
import ProductRetailerMobile from './ProductRetailerMobile/ProductRetailerMobile';
import ActualityRetailerMobile from './ActualityRetailerMobile/ActualityRetailerMobile';
import { get_AllGuestSummaryByLive } from '../../redux-services/modules/guest/guestListsParticipantsSummary';
import Affiliate from '../../Assets/images/affiliate.svg';
import RoomComponent from '../RoomComponent/RoomComponent';
import { Participant, ParticipantEvent } from 'livekit-client';
import PrejoinComponent from '../RoomComponent/PrejoinComponent';
import { getTokenLiveKit } from '../../services/tokenLivekitService';
import { useRoom } from '@livekit/react-core';
import { roomOptions } from '../RoomComponent/utilsRoom/configRooms';
// @ts-ignore
import randomColor from 'randomcolor';
import { getliveSessionById, IinitialStateGet } from '../../redux-services/modules/liveSessionRetailerget';

const LiveDetailsComponent = () => {
  const style = useStyles();
  const { id }: any = useParams();
  const { connect, room, participants, isConnecting, error: errorLiveKit } = useRoom(roomOptions);
  const { pathname } = useLocation();
  const [openAffiliation, setOpenAffiliation] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { liveDetails: live, loading } = useSelector<RootState, IinitialStateDetails>(
    (state) => state.liveSessionDetails,
  );
  const getLiveSession = useSelector<RootState, IinitialStateGet>((state) => state.getLiveSessionById);
  const { error: erroGetLive, loading: loadingGetLive, live: liveGetted } = getLiveSession;

  const updateLiveSession = useSelector<RootState, initialStateUpdate>((state) => state.updateLiveSession);
  const { loading: loadingUpdate } = updateLiveSession;

  const { liveStoped } = useSelector<RootState, IinitialStateLiveEnd>((state) => state.liveSessionEnd);
  const { adaptorAntMedia } = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);
  const { liveStarted } = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);

  const displayGestionProducts = [
    `/dashboard/livesessions/${id}/products`,
    `/dashboard/livesessions/${id}/products/addproduct`,
  ].includes(pathname);

  const displayButtonAddProduct = [`/dashboard/livesessions/${id}/products`].includes(pathname);
  const displayGestionQuestionnaire = [`/dashboard/livesessions/${id}/questionnaire`].includes(pathname);
  const displayCreateQuestionnaire = [`/dashboard/livesessions/${id}/questionnaire/create`].includes(pathname);
  const displayliveApercu = [`/dashboard/livesessions/${live.id}/showlive`].includes(pathname);
  const displayAccessRapide = [`/dashboard/livesessions/${live.id}`].includes(pathname);
  const displayActionRapide = [`/dashboard/livesessions/${live.id}`].includes(pathname);
  const displayManageInvitationVIP = [`/dashboard/livesessions/${live.id}/invitations`].includes(pathname);

  const [openDialog, setopenDialog] = useState<boolean>(false);
  const [openDialogStartLive, setOpenDialogStartLive] = useState<boolean>(false);
  const [videoState, setVideoState] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [participantsLive, setParticipantLive] = useState<Participant[]>();
  const [webRTCAdaptor, setwebRTCAdaptor] = useState<any>();
  const [actuality, setActuality] = useState<IActusWithActivated>();

  const [userId, setUserId] = useState('');
  const [liveIsStarted, setLiveIsStarted] = useState<boolean | null>(null);

  const [loadingMessViewers, setLoadingMessViewers] = useState({ mess: true, viewers: true });
  const [VIP, setVIP] = useState<Participant[]>();

  const [liveIsEnded, setLiveIsEnded] = useState<boolean>(false);
  const location = useLocation();
  const checkIfShowLive = location.pathname.search('showlive');
  const [productsToShare, setProductsToShare] = useState<string[]>([]);
  const [messages, setMessages] = useState<IMessage[]>([
    {
      dateTime: '',
      participantId: userId,
      participantName: 'me',
      text: 'Hi',
      backgroundAvatar: randomColor(),
    },
  ]);
  const [paramsMediaRoom, setParamsMediaRoom] = useState<any>();
  const isMobile = useIsMobile();

  const { isPinnedVip, pinnedVip } = useSelector<RootState, IDom>((state) => state.dom);
  const [isConnectedLiveKit, setConnectedLiveKit] = useState<boolean>(false);

  const productsShare = async (products: string[]) => {
    if (connection) {
      try {
        await connection.invoke('ShowProducts', products);
      } catch (e: any) {
        console.log('Error share products :>> ', e);
      }
    }
    setProductsToShare(products);
  };

  const renderChat = () => (
    <Chat
      messages={messages}
      userType="Retailer"
      id={id}
      primaryColor={live.primaryColor}
      loading={loadingMessViewers.mess}
      isMobile={isMobile}
      backgroundAvatar={randomColor()}
      connectionHub={connection ? connection : null}
      userId={userId}
    />
  );
  const renderViewers = () =>
    VIP ? room && <VipComponent participantsVIP={VIP} primaryColor={live.primaryColor} room={room} /> : <Box />;
  const renderSharedProduct = () => <ProductRetailerMobile productsShare={productsShare} id={id} />;
  const renderActuality = () => <ActualityRetailerMobile actuality={actuality} />;
  const tabsData: TabsData[] = [
    {
      label: 'Chat',
      icon: <ModeCommentOutlined />,
      children: (
        <Box
          className={clsx({
            [style.tabContentContainer]: false,
            [style.chatContainerMobile]: false,
          })}
        >
          {renderChat()}
        </Box>
      ),
    },
    {
      label: 'Amis',
      icon: <PeopleOutlineOutlined />,
      children: <Box className={clsx(style.tabContentContainer, style.viewersContainerMobile)}>{renderViewers()}</Box>,
    },
    {
      label: 'Produits',
      icon: <StorefrontOutlined />,
      children: (
        <Box
          className={clsx({
            [style.tabContentContainer]: true,
            [style.productsContainerMobile]: true,
          })}
        >
          {renderSharedProduct()}
        </Box>
      ),
    },
    {
      label: 'Infos',
      icon: <InfoOutlined />,
      children: <Box className={clsx(style.tabContentContainer, style.infosContainerMobile)}>{renderActuality()}</Box>,
    },
  ];

  // *** Start Live ***//
  const createPublisher = async () => {
    setShowLoading(true);
    const username = await authSevices.getNameRetailer();
    const userID = await authSevices.getParticipantId();
    const data = JSON.stringify({
      type: 'initiator',
      backgroundColor: randomColor(),
      showToLive: true,
      grade: 'retailer',
    });
    //get token to serverLivekit API
    getTokenLiveKit({
      participantName: username as string,
      participantID: userID as string,
      roomName: id,
      canPublish: true,
      canSubscribe: true,
      metadata: data,
    })
      .then((jwtToken) => {
        connect(process.env.REACT_APP_LIVEKIT_SERVER as string, jwtToken)
          .then((_roomDetails) => {
            setConnectedLiveKit(true);
            if (!live.actualStart) {
              dispatch(doStartLiveSession({ idLive: id, webRTCAdaptor }));
            }
            setShowLoading(false);
          })
          .catch((error) => {
            alert(error);
            throw new Error('Error during Retailer connect on livekit', error);
          });
      })
      .catch((error) => {
        throw new Error('Error during Retailer connect on livekit', error);
      });
  };

  const showLive = () => {
    history.push(`/dashboard/livesessions/${live.id}/showlive`);
  };

  //*** Stop Live ***//
  const stopLive = async () => {
    dispatch(doEndLiveSession({ id, adaptorRTC: adaptorAntMedia }));
    room?.disconnect(true);
    setopenDialog(false);
    setLiveIsEnded(true);
  };

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpenAffiliation(true);
    setScroll(scrollType);
    dispatch(get_AllGuestSummaryByLive(id));
  };

  const getParamsPrejoin = (params: any) => {
    setParamsMediaRoom(params);
  };

  useEffect(() => {
    if (id) {
      dispatch(getLiveSessionDetails(id));
      dispatch(getliveSessionById(id));
    }
  }, [id]);

  useEffect(() => {
    if (live.actualStart && !live.actualEnd) {
      createPublisher();
    }
    if (live.actualEnd) {
      setLiveIsEnded(true);
    }
  }, [live.actualStart, live.actualEnd]);

  useEffect(() => {
    const filter = participants.filter((participant) => {
      if (participant.metadata && JSON.parse(participant.metadata as string).grade === 'vip') {
        return participant;
      }
    });
    setVIP(filter);
  }, [participants]);

  return (
    <>
      <Box className={style.container}>
        {showLoading && <CustomBackdrop {...{ open: showLoading }} />}
        <CustomModal
          {...{
            open: openDialog,
            title: 'Confirmation',
            TextCancel: 'Annuler',
            onConfirm: () => {
              stopLive();
            },
            textConfirm: 'Confirmer',
            onClose: () => {
              setopenDialog(false);
            },
          }}
        >
          <Typography>Confirmez-vous vouloir arrêter le live ?Si oui, il sera définitivement arrêté.</Typography>
        </CustomModal>

        <CustomModal
          {...{
            open: openDialogStartLive,
            title: 'Démarrer le live',
            TextCancel: 'Non',
            onConfirm: () => {
              createPublisher().then(() => {
                setOpenDialogStartLive(false);
              });
            },
            textConfirm: 'Oui',
            onClose: () => {
              setOpenDialogStartLive(false);
            },
          }}
        >
          <Typography>Voulez-vous démarrer le live maintenant ?</Typography>
        </CustomModal>

        <Grid container>
          <Grid
            item
            xl={pathname.includes('/invitations') ? 12 : 8}
            lg={pathname.includes('/invitations') ? 12 : 8}
            className={style.contentPL}
          >
            <div style={{ display: displayliveApercu ? 'block' : 'none' }}>
              <ApercuComponent
                participantConnected={participants}
                messages={messages}
                setMessages={setMessages}
                connection={connection}
                setConnection={setConnection}
                setLoadingMessViewers={setLoadingMessViewers}
                loadingMessViewers={loadingMessViewers}
                setUserId={setUserId}
                userId={userId}
                VIP={VIP}
                productsShare={productsShare}
                setActuality={setActuality}
                actuality={actuality}
                room={room}
                backgroundAvatar={randomColor()}
              >
                {!loading && !isConnectedLiveKit && !live.actualEnd ? (
                  <PrejoinComponent
                    getParams={getParamsPrejoin}
                    liveisEnd={live.actualEnd}
                    liveIsStarted={live.actualStart}
                  />
                ) : (
                  room &&
                  live.actualStart &&
                  !live.actualEnd && (
                    <RoomComponent
                      paramatersRoom={paramsMediaRoom}
                      room={room}
                      participantRoom={participants}
                      typeUser="retailer"
                      liveIsEnded={liveIsEnded}
                    />
                  )
                )}
                {live.actualEnd && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {liveGetted.replayVideoFileUrl !== null ? (
                      <div style={{ position: 'relative', width: '100%', height: '0px', paddingBottom: '57.500%' }}>
                        <iframe
                          allow="fullscreen"
                          allowFullScreen
                          height="100%"
                          src={
                            liveGetted.replayVideoFileUrl &&
                            liveGetted.replayVideoFileUrl.replace(
                              'https://streamable.com/',
                              'https://streamable.com/e/',
                            )
                          }
                          width="100%"
                          style={{
                            border: 'none',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                            overflow: 'hidden',
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        alt="preview"
                        src={live.endedLiveImageUrl}
                        style={{ width: '42vw', height: '43vh', objectFit: 'contain' }}
                      />
                    )}
                  </div>
                )}
              </ApercuComponent>
            </div>
            <Switch>
              <PrivateRoute path={LIVEDETAILS} exact component={DetailsComponent} />
              <PrivateRoute path={LIVE_APERCU} exact>
                <div />
              </PrivateRoute>
              <PrivateRoute path={ADD_PRODUCTS} exact component={NewProductToSale} />
              <PrivateRoute path={UPDATE_PRODUCTS} exact component={NewProductToSale} />
              <PrivateRoute path={AFFILIATION} exact component={AffiliationComponent} />
              <PrivateRoute path={PRODUCTS} exact component={ProductsToSale} />
              <PrivateRoute path={QUESTIONNAIRE} exact component={ListQuestionnaireComponent} />
              <PrivateRoute path={CREATE_QUESTIONNAIRE} exact component={QuestionnaireComponent} />
              <PrivateRoute path={MANAGE_INVITATION_VIP} exact component={Invitation} />
            </Switch>
          </Grid>
          {!displayManageInvitationVIP && (!displayButtonAddProduct || !isMobile) && (
            <Grid item lg={4} md={4}>
              {!isMobile && (
                <Typography
                  component="p"
                  variant="h5"
                  style={{
                    width: '20vw',
                    float: 'right',
                  }}
                >
                  {t('action.rapide')}
                </Typography>
              )}

              {displayliveApercu && openAffiliation && isMobile && (
                <PopupSummaryGuest
                  handleClickOpen={handleClickOpen}
                  setOpenAffiliation={setOpenAffiliation}
                  openAffiliation={openAffiliation}
                  scroll={scroll}
                  setScroll={setScroll}
                  id={id}
                  primaryColor={live.primaryColor}
                />
              )}

              {!displayCreateQuestionnaire && !isMobile && (
                <Box className={style.vipContent}>
                  <Box>
                    {displayButtonAddProduct && !live.actualStart && !live.actualEnd && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(`/dashboard/livesessions/${id}/products/addproduct`);
                        }}
                        style={{
                          backgroundColor: ' #222222',
                          width: '100%',
                          margin: 5,
                          borderRadius: 10,
                          padding: 10,
                          textTransform: 'unset',
                        }}
                      >
                        {t('action.addProduct')}
                      </Button>
                    )}

                    {displayliveApercu && (
                      <PopupSummaryGuest
                        handleClickOpen={handleClickOpen}
                        setOpenAffiliation={setOpenAffiliation}
                        openAffiliation={openAffiliation}
                        scroll={scroll}
                        setScroll={setScroll}
                        id={id}
                        primaryColor={live.primaryColor}
                      />
                    )}

                    {!live.actualStart && !live.actualEnd && displayActionRapide && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(`/dashboard/livesessions/${live.id}/update`);
                        }}
                        style={{
                          backgroundColor: ' #222222',
                          width: '100%',
                          margin: 5,
                          borderRadius: 10,
                          padding: 10,
                          textTransform: 'unset',
                        }}
                      >
                        {t('action.update.live')}
                      </Button>
                    )}
                    {displayActionRapide && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            history.push(`/dashboard/livesessions/${live.id}/products`); //here
                          }}
                          style={{
                            backgroundColor: ' #222222',
                            width: '100%',
                            margin: 5,
                            borderRadius: 10,
                            padding: 10,
                            textTransform: 'unset',
                          }}
                        >
                          {t('action.manage.product')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            history.push(`/dashboard/livesessions/${live.id}/parametre`); //here
                          }}
                          style={{
                            backgroundColor: ' #222222',
                            width: '100%',
                            margin: 5,
                            borderRadius: 10,
                            padding: 10,
                            textTransform: 'unset',
                          }}
                        >
                          {t('action.manage.affiliation')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            history.push(`/dashboard/livesessions/${live.id}/invitations`); //here
                          }}
                          style={{
                            backgroundColor: ' #222222',
                            width: '100%',
                            margin: 5,
                            borderRadius: 10,
                            padding: 10,
                            textTransform: 'unset',
                          }}
                        >
                          {t('action.manage.invitations')}
                        </Button>
                      </>
                    )}
                  </Box>
                  {window.location.pathname.includes('showlive') && (
                    <Box className={style.viewersContainer}>
                      <LiveDetailsViewers participants={participants} />
                    </Box>
                  )}

                  <Box>
                    {displayActionRapide && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const username = await authSevices.getNameRetailer();
                          const participantByLive = await liveSessionServices.getParticipantByLive(
                            live.id,
                            'Retailer',
                            username!,
                          );
                          if (participantByLive && participantByLive !== undefined) {
                            history.push(`/dashboard/livesessions/${live.id}/showlive`);
                          } else {
                            dispatch(doJoinLiveSession({ id: live.id, name: username!, userType: 'Retailer' }));
                            history.push(`/dashboard/livesessions/${live.id}/showlive`);
                          }
                        }}
                        style={{
                          backgroundColor: ' #222222',
                          width: '100%',
                          margin: 5,
                          borderRadius: 10,
                          padding: 10,
                          textTransform: 'unset',
                        }}
                      >
                        {t('action.apercu.live')}
                      </Button>
                    )}

                    {displayliveApercu && !live.actualStart && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenDialogStartLive(true)}
                        style={{
                          backgroundColor: live.primaryColor ?? '#518971',
                          width: '100%',
                          margin: 5,
                          borderRadius: 10,
                          padding: 10,
                          textTransform: 'unset',
                        }}
                      >
                        {t('action.start.live')}
                      </Button>
                    )}
                    {displayliveApercu && live.actualStart && !live.actualEnd && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setopenDialog(true);
                        }}
                        style={{
                          backgroundColor: live.primaryColor ? live.primaryColor : '#518971',
                          width: '100%',
                          margin: 5,
                          borderRadius: 10,
                          padding: 10,
                          textTransform: 'unset',
                        }}
                      >
                        {t('action.end.live')}
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
          )}
          {isMobile && displayliveApercu && (
            <Box
              className={clsx({
                [style.mobileLiveStartLiveBlocButtons]: displayliveApercu && !live.actualStart,
                [style.mobileLiveStartLiveBlocButtonsStart]: displayliveApercu && live.actualStart && !live.actualEnd,
              })}
            >
              <div>
                {displayliveApercu && !live.actualStart && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenDialogStartLive(true)}
                    style={{
                      backgroundColor: live.primaryColor ?? '#518971',
                      margin: 5,
                      borderRadius: 10,
                      padding: 10,
                      textTransform: 'unset',
                    }}
                  >
                    {t('action.start.live')}
                  </Button>
                )}
                {displayliveApercu && live.actualStart && !live.actualEnd && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setopenDialog(true);
                    }}
                    style={{
                      backgroundColor: live.primaryColor ? live.primaryColor : '#518971',
                      width: '100%',
                      margin: 5,
                      borderRadius: 10,
                      padding: 10,
                      textTransform: 'unset',
                    }}
                  >
                    {t('action.end.live')}
                  </Button>
                )}
              </div>
              {displayliveApercu && (
                <div
                  className={clsx({
                    [style.goToAffiliation]: !liveIsEnded,
                    [style.goToAffiliationEnded]: liveIsEnded,
                  })}
                  onClick={handleClickOpen('paper')}
                >
                  <img src={Affiliate} style={{ width: 50 }} />
                </div>
              )}
            </Box>
          )}
        </Grid>
        {isMobile && displayliveApercu && (
          <Box className={clsx({ [style.tabsContainer]: true })}>
            <Tabs defaultValue={0} data={tabsData} indicatorPosition="top" indicatorColor={live.primaryColor} />
          </Box>
        )}
      </Box>
      {isMobile &&
        // eslint-disable-next-line
        pathname.match(/^\/dashboard\/livesessions\/[^\/]*[a-zA-Z0-9][^\/]?$/gi) && (
          <Box
            style={{
              position: 'fixed',
              width: '100vw',
              padding: 20,
              borderTop: '1px solid #acacac',
              bottom: 0,
              backgroundColor: '#f1f1f1',
            }}
          >
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const username = await authSevices.getNameRetailer();
                  const participantByLive = await liveSessionServices.getParticipantByLive(
                    live.id,
                    'Retailer',
                    username!,
                  );
                  if (participantByLive && participantByLive !== undefined) {
                    history.push(`/dashboard/livesessions/${live.id}/showlive`);
                  } else {
                    dispatch(doJoinLiveSession({ id: live.id, name: username!, userType: 'Retailer' }));
                    history.push(`/dashboard/livesessions/${live.id}/showlive`);
                  }
                }}
                style={{
                  backgroundColor: 'rgb(81, 137, 113)',
                  // width: '100%',
                  margin: 5,
                  borderRadius: 8,
                  padding: '4px 10px',
                  textTransform: 'unset',
                }}
              >
                {t('action.apercu.live')}
              </Button>
            </Box>
          </Box>
        )}
    </>
  );
};

export default LiveDetailsComponent;
