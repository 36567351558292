import { typedAction } from '../../utils';

export interface IResultGetInfoRetailerSAGA {
  name: string;
  logoUrl: string;
  loading: boolean;
  error: string;
  status: number | undefined;
}
const initialState: IResultGetInfoRetailerSAGA = {
  name: '',
  logoUrl: '',
  loading: false,
  error: '',
  status: undefined,
};
interface IdataError {
  message: string;
  status: number;
}
export const AUTH_GETINFO_RETAILER_REQUEST = 'AUTH/GETINFO_RETAILER/REQUEST';
export const AUTH_GETINFO_RETAILER_FAILED = 'AUTH/GETINFO_RETAILER/FAILED';
export const AUTH_GETINFO_RETAILER_SUCCESS = 'AUTH/GETINFO_RETAILER/SUCCESS';

export const getInfoRetailer = () => typedAction(AUTH_GETINFO_RETAILER_REQUEST);
export type GetInfoRetailerStart = typeof getInfoRetailer;

export const getInfoRetailerSuccess = (data: IResultGetInfoRetailerSAGA) =>
  typedAction(AUTH_GETINFO_RETAILER_SUCCESS, data);
export type GetInfoRetailerSuccess = typeof getInfoRetailerSuccess;

export const getInfoRetailerFailed = (error: IdataError) => typedAction(AUTH_GETINFO_RETAILER_FAILED, error);
export type GetInfoRetailerFailed = typeof getInfoRetailerFailed;

export type AuthAction = ReturnType<GetInfoRetailerStart | GetInfoRetailerSuccess | GetInfoRetailerFailed>;

export const GetInfoRetailer_Reducers = (
  state: IResultGetInfoRetailerSAGA = initialState,
  action: AuthAction,
): IResultGetInfoRetailerSAGA => {
  switch (action.type) {
    case AUTH_GETINFO_RETAILER_SUCCESS:
      return {
        ...state,
        loading: false,
        logoUrl: action.payload.logoUrl,
        error: '',
        name: action.payload.name,
        status: action.payload.status,
      };
    case AUTH_GETINFO_RETAILER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        status: action.payload.status,
      };
    case AUTH_GETINFO_RETAILER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
