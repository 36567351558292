import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  update_live_session_failed,
  UPDATE_LIVE_SESSION_REQUEST,
  update_live_session_success,
} from '../modules/liveSessionRetailerUpdate';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction } from './utils/function';

export function* watchliveSessionUpdate() {
  yield takeEvery(UPDATE_LIVE_SESSION_REQUEST, liveSessionUpdate);
}
export function* liveSessionUpdate({ payload }: any) {
  try {
    const { data, status } = yield call(api.liveSessionRetailer.liveSessionRetaillerUpdate, payload);
    // const result = { id: data, status };
    yield put(update_live_session_success(data));
    yield put(
      Set_state_snackbar({
        message: 'Operation effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { status } = error?.response;
    const errorText = error.response.data.errors;
    yield put(
      update_live_session_failed({
        error: (Object.values(errorText)[0] as any)[0],
        status: status,
      }),
    );
    yield call(ErrorFunction, error);
  }
}
