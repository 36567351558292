export default {
  'consumer.live.viewer.title': 'Participants',
  'consumer.live.viewer.friends': 'Ami(e)s',
  'consumer.live.viewer.others': 'Autres',
  'consumer.live.viewer.more': 'Afficher plus',
  'consumer.live.langage': 'Français',
  'consumer.live.subscribe': "S'abonner",
  'consumer.live.subscribe.request': 'Vous devez vous connecter',
  'consumer.live.login': 'Se connecter',
  'consumer.live.invite': 'Inviter',
  'consumer.live.decouvert': 'A decouvrir',
  'consumer.live.decouverted': 'Néant',
  'consumer.live.code': 'Code achat',
  'consumer.live.shopUrl': 'Lien Boutique',
  'consumer.live.errorId': 'La session de votre pseudo est expirée! Merci de vous réidentifier',
  'consumer.live.prsInterested': 'Un de vos invités s’interesse au produit',
};
