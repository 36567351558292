import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../../api';
import { Set_state_snackbar } from '../../../modules/state/stateMessage';
import {
  CreateAccount_consumer_failed,
  CreateAccount_consumer_succefull,
  CREATE_ACCOUNT_CONSUMER_REQUESTED,
} from '../../../modules/account/consumer/createAccountConsumer';

export function* watchCreateAccountConsumer() {
  yield takeEvery(CREATE_ACCOUNT_CONSUMER_REQUESTED, createConsumer);
}

export function* createConsumer({ payload }: any) {
  try {
    const { status, data } = yield call(api.account.createAccountConsumer, payload);
    console.log('data :>> ', data);
    yield put(CreateAccount_consumer_succefull(status));
    yield put(
      Set_state_snackbar({
        status: status,
        message: 'Creation du compte consumer reussie, merci de verifier votre email pour la confirmation',
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    console.log('error :>> ', error);
    const { response } = error;
    yield put(CreateAccount_consumer_failed());
    if (response) {
      const { status } = response;
      yield put(
        Set_state_snackbar({
          status: status,
          message: 'Votre email, votre identifiant ou votre mot de passe est incorrect ou déjà utilisé.',
          open: true,
          type: 'error',
        }),
      );
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 504,
                message: 'Network error, please check your internet connection',
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
