import { typedAction } from '../../utils';

export const CREATE_PRODUCT_REQUEST = 'CREATE/PRODUCT/REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE/PRODUCT/SUCCESS';
export const CREATE_PRODUCT_FAILED = 'CREATE/PRODUCT/FAILED';

export interface IdataProduct {
  liveSessionId: string;
  label: string;
  description: string;
  furtherInformation: string;
  url: string;
  priceBeforeDiscount: number | 0;
  priceAfterDiscount: number | 0;
  mainImageId: string;
}

export interface IproductSuccess {
  id: string;
  message: string | null;
  status: number | undefined;
}

export interface IproductFailed {
  message: string;
  status: number | undefined;
}
export interface IinitialStateProduct {
  id: string;
  status: number | undefined;
  message: string | null;
  loading: boolean;
}
const initialStateProduct: IinitialStateProduct = {
  loading: false,
  status: undefined,
  message: '',
  id: '',
};

export const do_create_product = (data: IdataProduct) => typedAction(CREATE_PRODUCT_REQUEST, data);
export type Ido_create_product = typeof do_create_product;

export const product_created = (result: IproductSuccess) => typedAction(CREATE_PRODUCT_SUCCESS, result);
export type IproduitCreated = typeof product_created;

export const product_create_failed = (error: IproductFailed) => typedAction(CREATE_PRODUCT_FAILED, error);
export type Iproduct_create_failed = typeof product_create_failed;

export type CreateProductAction = ReturnType<Ido_create_product | IproduitCreated | Iproduct_create_failed>;

export const CreateProductReducers = (
  state: IinitialStateProduct = initialStateProduct,
  action: CreateProductAction,
): IinitialStateProduct => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return {
        ...initialStateProduct,
        loading: true,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
        message: action.payload.message,
      };
    case CREATE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
