import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../api';
import { logout } from '../../modules/logout';
import {
  guest_SetAdvantageCode_failed,
  guest_SetedAdvantageCode,
  SET_ADVANTAGECODES_GUEST_REQUEST,
} from '../../modules/guest/guestAdvantageCodes';
import { Set_state_snackbar } from '../../modules/state/stateMessage';

// const { t } = useTranslation();
export function* watchsetAdvantageCodesGuest() {
  yield takeEvery(SET_ADVANTAGECODES_GUEST_REQUEST, setAdvantageCodesGuest);
}

function* setAdvantageCodesGuest({ payload }: any) {
  try {
    const { data } = yield call(api.guest.SetAdvantageCodes, payload);
    const message = payload[0].shoppingUrl
      ? 'Votre lien boutique a été enregistré avec succès'
      : 'Votre code achat a été enregistré avec succès';
    yield put(guest_SetedAdvantageCode({ status: 200, message }));
    yield put(
      Set_state_snackbar({
        message,
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { status, message } = error.response;
    // yield put(Set_state_snackbar({ status: status, message: message, open: true, type: 'error' }));
    yield put(guest_SetAdvantageCode_failed({ status: status, message: message }));
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: 'Erreur de serveur! Merci de vérifier votre connexion.',
              open: true,
              type: 'error',
            }),
          );
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );

            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
