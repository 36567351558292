import { typedAction } from '../../utils';

interface IparamsDelete {
  id: string;
}

export interface IinitialStateDeleteGuest {
  loading: boolean;
  data: string;
}

export const initialStateDeleteGuest: IinitialStateDeleteGuest = {
  data: '',
  loading: false,
};

export const DELETE_GUEST_REQUEST = 'DELETE/GUEST/REQUEST';
export const DELETE_GUEST_SUCCESS = 'DELETE_GUEST_SUCCESS';
export const DELETE_GUEST_FAILED = 'DELETE_GUEST_FAILED';

export const DoDeleteGuest = (ids: string) => typedAction(DELETE_GUEST_REQUEST, ids);
type IDoDeleteGuest = typeof DoDeleteGuest;

export const DeleteGuestSuccess = (data: string) => typedAction(DELETE_GUEST_SUCCESS, data);
type IDeleteGuestSuccess = typeof DeleteGuestSuccess;

export const DeleteGuestFailed = () => typedAction(DELETE_GUEST_FAILED);
type IDeleteGuestFailed = typeof DeleteGuestFailed;

export type IDeleteGuestAction = ReturnType<IDeleteGuestFailed | IDeleteGuestSuccess | IDoDeleteGuest>;

export const DeleteGuestReducer = (
  state: IinitialStateDeleteGuest = initialStateDeleteGuest,
  action: IDeleteGuestAction,
): IinitialStateDeleteGuest => {
  switch (action.type) {
    case DELETE_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case DELETE_GUEST_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
