import { InputBase, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './style';

interface PropsInputCustomized {
  label: string;
  textDefault?: string;
  disabled?: boolean;
  overflow?: 'auto' | 'hidden';
}

const CustomInputWithLabel: FC<PropsInputCustomized> = ({
  label,
  textDefault = '',
  disabled = false,
  overflow = 'auto',
}) => {
  return (
    <Typography style={{ display: 'flex', flex: 1, height: '100%', width: '100%' }}>
      {label}
      <InputBase
        style={{
          flex: 1,
          marginLeft: '8px',
          color: '#222222',
          padding: '3px 0 0',
          height: '100%',
          width: '100%',
          fontSize: '14px',
        }}
        type="text"
        name="name"
        multiline
        // defaultValue={textDefault}
        inputProps={{ style: { height: '100%', overflow: overflow, width: '100%' } }}
        disabled={disabled}
        value={textDefault}
      />
    </Typography>
  );
};

export default CustomInputWithLabel;
