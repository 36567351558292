import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MyCustomInput from '../../MyCustomInput/MyCustomInput';
import { useStylesRecognition as useStyles } from './styles';
import { event } from 'react-ga';

export interface PersonalInfoState {
  email: string;
  emailValidation: string;
  lastname: string;
  firstname: string;
  phoneNumber: string;
  country: string;
  password: string;
  confirmpassword: string;
}

export interface CompanyInformationState {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  webSite: string;
  instagram: string;
  tiktok: string;
  facebook: string;
  snapchat: string;
  activity: string;
  cgu?: string;
}

interface IPropsRecognitionComponent {
  isConsumer: boolean;
  personalInfo: PersonalInfoState;

  companyInformation: CompanyInformationState;
  handlePersonalInfoChange: (prop: keyof PersonalInfoState) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyInfoInfoChange: (
    prop: keyof CompanyInformationState,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RecognitionComponent: React.FC<IPropsRecognitionComponent> = (props: IPropsRecognitionComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isConsumer, companyInformation, personalInfo, handleCompanyInfoInfoChange, handlePersonalInfoChange } = props;

  return (
    <div className={classes.container}>
      <div className={classes.subcontainer}>
        <Typography className={`${classes.typography} _desktopView`}>{t('recognition.personalInfo')}</Typography>
        <div className={classes.divPersonnel}>
          <div className={classes.boxLeft}>
            <MyCustomInput
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.email')}
              label={t('recognition.email') + '*'}
              value={personalInfo.email}
              onChange={handlePersonalInfoChange('email')}
            />
            <MyCustomInput
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.lastname')}
              label={t('recognition.lastname') + '*'}
              value={personalInfo.lastname}
              onChange={handlePersonalInfoChange('lastname')}
            />
            <MyCustomInput
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.firstname')}
              label={t('recognition.firstname') + '*'}
              value={personalInfo.firstname}
              onChange={handlePersonalInfoChange('firstname')}
            />
            <MyCustomInput
              type="password"
              autoComplete="new-password"
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.password')}
              label={t('recognition.password') + '*'}
              value={personalInfo.password}
              onChange={handlePersonalInfoChange('password')}
            />
            <MyCustomInput
              {...{}}
              type="password"
              autoComplete="new-password"
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.confirmpassword')}
              label={t('recognition.confirmpassword') + '*'}
              value={personalInfo.confirmpassword}
              onChange={handlePersonalInfoChange('confirmpassword')}
            />
          </div>
          <div className={classes.boxLeft}>
            <MyCustomInput
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.phoneNumber')}
              label={t('recognition.phoneNumber')}
              value={personalInfo.phoneNumber}
              onChange={handlePersonalInfoChange('phoneNumber')}
            />
            <MyCustomInput
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t('recognition.country')}
              label={t('recognition.country')}
              value={personalInfo.country}
              onChange={handlePersonalInfoChange('country')}
            />
          </div>
        </div>
      </div>
      {!isConsumer && (
        <div className={classes.divConsumer}>
          <Typography className={classes.typography}>{t('recognition.companyInfo')}</Typography>
          <div className={classes.divCompany}>
            <div className={classes.boxLeft}>
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.companyName')}
                label={t('recognition.companyName') + '*'}
                value={companyInformation.name}
                onChange={handleCompanyInfoInfoChange('name')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.activityType')}
                label={t('recognition.activityType')}
                value={companyInformation.activity}
                onChange={handleCompanyInfoInfoChange('activity')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.address')}
                label={t('recognition.address')}
                value={companyInformation.address}
                onChange={handleCompanyInfoInfoChange('address')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.postalCode')}
                label={t('recognition.postalCode')}
                value={companyInformation.postalCode}
                onChange={handleCompanyInfoInfoChange('postalCode')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.city')}
                label={t('recognition.city')}
                value={companyInformation.city}
                onChange={handleCompanyInfoInfoChange('city')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.companyCountry')}
                label={t('recognition.companyCountry')}
                value={companyInformation.country}
                onChange={handleCompanyInfoInfoChange('country')}
              />
            </div>

            <div className={classes.boxLeft}>
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.website')}
                label={t('recognition.website')}
                value={companyInformation.webSite}
                onChange={handleCompanyInfoInfoChange('webSite')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.instagram')}
                label={t('recognition.instagram')}
                value={companyInformation.instagram}
                onChange={handleCompanyInfoInfoChange('instagram')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.tiktok')}
                label={t('recognition.tiktok')}
                value={companyInformation.tiktok}
                onChange={handleCompanyInfoInfoChange('tiktok')}
              />

              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.fb')}
                label={t('recognition.fb')}
                value={companyInformation.facebook}
                onChange={handleCompanyInfoInfoChange('facebook')}
              />
              <MyCustomInput
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={t('recognition.snapchat')}
                label={t('recognition.snapchat')}
                value={companyInformation.snapchat}
                onChange={handleCompanyInfoInfoChange('snapchat')}
              />
            </div>
          </div>
        </div>
      )}
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: 'rgb(81, 137, 113)',
              }}
              checked={(companyInformation.cgu as string) === 'true'}
              onChange={(event) => {
                event.target.value = event.target.checked ? 'true' : 'false';
                handleCompanyInfoInfoChange('cgu')(event);
              }}
            />
          }
          label={
            <Typography variant="body2" style={{ fontStyle: 'italic' }}>
              En cochant cette case, j'accepte les{' '}
              <a
                style={{ color: '#01010199', fontWeight: 'bold' }}
                href="http://livood.com/conditions-dutilisation-de-livood"
                target="_blank"
              >
                CGU{' '}
              </a>
              et la{' '}
              <a
                style={{ color: '#01010199', fontWeight: 'bold' }}
                href="http://livood.com/conditions-dutilisation-de-livood/"
                target="_blank"
              >
                politique de confidentialité
              </a>
            </Typography>
          }
        />
      </Box>
    </div>
  );
};

export default RecognitionComponent;
