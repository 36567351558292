import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBoxChamp: {
      display: 'flex',
      flexDirection: 'row',
      padding: 5,
    },
    dateStyle: {
      padding: '10px',
      fontSize: 14,
      fontFamily: 'Open Sans',
      color: 'black',
    },
    selectOption: {
      padding: 10,
      '& .MuiOutlinedInput-input': {
        padding: 5,
      },
    },
    selectType: {
      width: '100%',
      '& >div': {
        fontSize: 14,
        fontFamily: 'Open Sans',
      },
    },
  }),
);
