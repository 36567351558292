import { Box, Button, Checkbox, Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { CustomSelect } from '../../common/customSelect/CustomSelect';
import TextInputWithIcon from '../../common/TextInput/TextInputWithIcon';
import { useStyles as useStyle } from './style';
import CloseIcon from '@material-ui/icons/Close';
import { DO_setCounterPart, IinitialStateCounterPart } from '../../redux-services/modules/guest/guestCounterPartSet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import {
  DO_getCounterPart,
  IinitialStateCounterPartInfo,
  DO_clearGetCounterPart,
} from '../../redux-services/modules/guest/guestCounterPartGet';
import { setAffiliationSaved } from '../../redux-services/modules/state/stateAffiliation';
import { useIsMobile } from '../../common/header/useIsMobile';
import { ArrowBackIos } from '@material-ui/icons';
import ModalGoBackConfirmation from './ModalGoBackConfirmation/ModalGoBackConfirmation';
interface ITypecontrePartie {
  id: number;
  name: string;
}
interface IStateContrePartie {
  montantMin: number;
  montantMax: number;
  ValuetypecontrePartie: number;
  contrePartie: string | number;
  typeIcon?: string;
}

const AffiliationComponent: React.FC = () => {
  const style = useStyle();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { id: idSession }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    id: idCreateAffiliation,
    error: errorCreationParAffiliation,
  } = useSelector<RootState, IinitialStateCounterPart>((state) => state.guestCountPartSet);
  const { dataCounterPart, loading: loadingDataCounterPart } = useSelector<RootState, IinitialStateCounterPartInfo>(
    (state) => state.guestCountPartGet,
  );
  const [disableContrePartie, setdisableContrePartie] = useState<boolean>(false);
  const [createdAffiliation, setcreatedAffiliation] = useState<boolean>(false);
  const [selectValue, setselectValue] = useState();

  const isMobile = useIsMobile();

  const typeCOntraPartie: ITypecontrePartie[] = [
    { id: 0, name: 'Nature' },
    { id: 1, name: "Bon d'achat" },
    // { id: 2, name: 'Remise' },
  ];
  const [contrePartie, setContrePartie] = useState<IStateContrePartie[] | undefined>([
    {
      ValuetypecontrePartie: 0,
      montantMin: 10,
      montantMax: 30,
      contrePartie: 'Echantillon',
    },
  ]);

  const initialState: IStateContrePartie = {
    ValuetypecontrePartie: 0,
    montantMin: 10,
    montantMax: 30,
    contrePartie: '',
  };

  const GoBackDetails = () => {
    history.push(`/dashboard/livesessions/${idSession}`);
  };

  const OnChecked = () => {
    dispatch(setAffiliationSaved(false));
    setdisableContrePartie(!disableContrePartie);
    if (!disableContrePartie) {
      setContrePartie([{ montantMin: 0, montantMax: 0, contrePartie: '', ValuetypecontrePartie: 0 }]);
      dispatch(DO_clearGetCounterPart());
    } else {
      setContrePartie([{ ...initialState }]);
    }
  };

  const OnchageSelected = (event: any, index: number) => {
    dispatch(setAffiliationSaved(false));
    const { value, name } = event.target;
    setselectValue(value);
    setContrePartie((cp) => {
      const value = +event.target.value;
      return (cp || []).map((curr, i) => {
        if (i === index) {
          return {
            ...curr,
            ValuetypecontrePartie: value,
          };
        }
        return { ...curr };
      });
    });
  };

  const OnchageValueNature = (event: any, index: number) => {
    dispatch(setAffiliationSaved(false));
    setContrePartie((cp) => {
      const value = event.target.value;
      return (cp || []).map((curr, i) => {
        if (i === index) {
          return {
            ...curr,
            contrePartie: value,
          };
        }
        return { ...curr };
      });
    });
  };

  const increaseMaxValue = (index: number, evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setAffiliationSaved(false));
    setContrePartie((cp) => {
      const value = +evt.target.value;
      return (cp || []).map((curr, i) => {
        if (index === i) {
          return {
            ...curr,
            montantMax: value,
          };
        }
        if (index + 1 === i) {
          return {
            ...curr,
            montantMin: value,
          };
        }
        return curr;
      });
    });
  };

  const onValidate = () => {
    const data =
      contrePartie?.length === 1 &&
      JSON.stringify(contrePartie[0]) ===
        JSON.stringify({ montantMin: 0, montantMax: 0, contrePartie: '', ValuetypecontrePartie: 0 })
        ? []
        : contrePartie?.map((item) => {
            return {
              description: item.contrePartie as string,
              revenuesLowerBound: item.montantMin,
              revenuesUpperBound: item.montantMax,
              type: item.ValuetypecontrePartie,
            };
          });
    if (data) {
      dispatch(
        DO_setCounterPart({
          data: data,
          id: idSession,
        }),
      );
      setcreatedAffiliation(true);
    }
  };

  const errorContrePart: boolean = useMemo(
    () =>
      contrePartie && contrePartie.length > 0
        ? contrePartie
            .map((_cp, i) => {
              const min = _cp.montantMin;
              const max = _cp.montantMax;
              return (i !== 0 && contrePartie[i - 1].montantMax > min) || min > 99999999 || min > max || max > 99999999;
            })
            .includes(true)
        : false,
    [contrePartie],
  );

  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (!loading && idCreateAffiliation && createdAffiliation) {
      dispatch(setAffiliationSaved(true));
      history.push(`/dashboard/livesessions/${idSession}`);
      setcreatedAffiliation(false);
      setContrePartie([{ ...initialState }]);
    }
  }, [loading, idCreateAffiliation, errorCreationParAffiliation]);

  useEffect(() => {
    if (idSession) {
      dispatch(DO_getCounterPart(idSession));
    }
    return (() => {
      dispatch(DO_clearGetCounterPart());
    })();
  }, []);
  useEffect(() => {
    setContrePartie(
      dataCounterPart.length
        ? dataCounterPart.map((_d) => ({
            ValuetypecontrePartie: _d.type,
            montantMin: _d.revenuesLowerBound,
            montantMax: _d.revenuesUpperBound,
            contrePartie: _d.description,
          }))
        : [{ montantMin: 0, montantMax: 0, contrePartie: '', ValuetypecontrePartie: 0 }],
    );
    setdisableContrePartie(!dataCounterPart.length);
  }, [dataCounterPart]);

  const handleCancel = () => setOpenConfirmModal(false);
  const handleConfirm = () => GoBackDetails();

  return (
    <>
      <CustomBackdrop {...{ open: loading || loadingDataCounterPart }} />
      {isMobile && (
        <ModalGoBackConfirmation handleConfirm={handleConfirm} handleCancel={handleCancel} open={openConfirmModal} />
      )}
      <Box className={style.content}>
        {!isMobile && (
          <>
            <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
              <Checkbox
                checked={disableContrePartie}
                color="primary"
                style={{ color: 'black', alignSelf: 'flex-end' }}
                onClick={OnChecked}
              />
              <Typography>Aucune contrepartie pour les VIP</Typography>
            </Box>
            <Box style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ fontSize: 22 }}>Contreparties VIP</Typography>
            </Box>

            <Box className={`${style.ButtonBox} _mobileView`}>
              <Button
                style={{ backgroundColor: errorContrePart ? 'gray' : '#518971' }}
                onClick={onValidate}
                disabled={errorContrePart}
              >
                Valider
              </Button>
              <Button
                onClick={() => {
                  dispatch(setAffiliationSaved(true));
                  history.goBack();
                }}
              >
                Annuler
              </Button>
            </Box>
          </>
        )}
        {!isMobile && (
          <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Box className={style.spaceTable}>
              <Box className={style.MontantContrePartie}>Montant CA invités(€)</Box>
              <Box className={style.TypeContrePartie}>Type de contre partie</Box>
              <Box className={style.TextContrePartie}>Contre partie</Box>
            </Box>

            {contrePartie?.map((item: IStateContrePartie, index) => {
              const min = item.montantMin;
              const max = item.montantMax;

              return (
                <Box key={index} className={style.spaceTable1}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ margin: '0px 10px', color: disableContrePartie ? 'gray' : '#000' }}>
                      De
                    </Typography>
                    <Box className={style.montant}>
                      <TextInputWithIcon
                        {...{
                          // label: undefined,
                          labelWidth: undefined,
                          type: 'number',
                          error: (index !== 0 && contrePartie[index - 1].montantMax > min) || min > 99999999,
                          value: min,
                          onChange: (evt) => {
                            dispatch(setAffiliationSaved(false));
                            const cp = contrePartie;
                            cp[index].montantMin = +evt.target.value;
                            setContrePartie([...cp]);
                          },
                          style: {
                            color: disableContrePartie ? 'gray' : '#000',
                          },
                          name: 'minValue',
                          readOnly: disableContrePartie,
                          endAdornment: (
                            <InputAdornment position="end" className={style.Adornment}>
                              <Typography style={{ color: disableContrePartie ? 'gray' : '#000' }}>€</Typography>
                            </InputAdornment>
                          ),
                          disabled: disableContrePartie,
                          helperText: min > 99999999 ? 'value > 99999999' : '< montantPrécédent',
                        }}
                      />
                    </Box>
                    <Typography style={{ margin: '0px 10px', color: disableContrePartie ? 'gray' : '#000' }}>
                      à
                    </Typography>
                    <Box className={style.montant}>
                      <TextInputWithIcon
                        {...{
                          labelWidth: undefined,
                          type: 'number',
                          error: min > max || max > 99999999,
                          value: max,
                          onChange: (evt) => increaseMaxValue(index, evt),
                          name: 'maxValue',
                          style: {
                            color: disableContrePartie ? 'gray' : '#000',
                          },
                          readOnly: disableContrePartie,
                          endAdornment: (
                            <InputAdornment position="end" className={style.Adornment}>
                              <Typography style={{ color: disableContrePartie ? 'gray' : '#000' }}>€</Typography>
                            </InputAdornment>
                          ),
                          disabled: disableContrePartie,
                          helperText: max > 99999999 ? 'value > 99999999' : 'montantMin > value',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box style={{ width: 350 }}>
                    <CustomSelect
                      {...{
                        defaultValue: 0,
                        Optionvalue: typeCOntraPartie,
                        className: style.selectNature,
                        value: item.ValuetypecontrePartie,
                        inputProps: { className: style.inputPropsSelect },
                        variant: 'outlined',
                        disabled: disableContrePartie,
                        onChange: (event: any) => OnchageSelected(event, index),
                      }}
                    />
                  </Box>
                  <Box className={style.contrePartie}>
                    <TextInputWithIcon
                      {...{
                        labelWidth: undefined,
                        type: item.ValuetypecontrePartie === 0 ? 'text' : 'number',
                        name: 'contrepartie',
                        readOnly: disableContrePartie,
                        value: item.contrePartie,
                        onChange: (event: any) => OnchageValueNature(event, index),
                        endAdornment: (
                          <InputAdornment position="end" className={style.Adornment}>
                            {item.ValuetypecontrePartie === 0 ? '' : item.ValuetypecontrePartie === 1 ? '€' : '%'}
                          </InputAdornment>
                        ),
                        disabled: disableContrePartie,
                      }}
                    />
                    {index > 0 ? (
                      <Button
                        onClick={() => {
                          dispatch(setAffiliationSaved(false));
                          setContrePartie((c) => {
                            if (!c) {
                              return c;
                            }
                            return c.filter((_, index1) => index != index1);
                          });
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    ) : (
                      <Box style={{ width: 70 }} />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        {!isMobile && (
          <Button
            disabled={disableContrePartie}
            // color="secondary"
            className={!disableContrePartie ? style.addButtonConterParty : ''}
            style={{ backgroundColor: '#00000005' }}
            onClick={() => {
              // setinitialState({ ...initialState, tarif: '20€ à 40€' });
              if (contrePartie) {
                setContrePartie((prev) => {
                  if (prev) {
                    const previous = prev[prev.length - 1];
                    return [
                      ...prev,
                      {
                        ...previous,
                        montantMin: previous.montantMax,
                      },
                    ];
                  }
                });
              }
            }}
          >
            Ajouter une ligne
          </Button>
        )}

        <Box className={`${style.ButtonBox} _desktopView`}>
          <Button
            style={{ backgroundColor: errorContrePart ? 'gray' : '#518971' }}
            onClick={onValidate}
            disabled={errorContrePart}
          >
            Valider
          </Button>
          <Button
            onClick={() => {
              dispatch(setAffiliationSaved(true));
              history.goBack();
            }}
          >
            Annuler
          </Button>
        </Box>
        <>
          {isMobile && (
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                width: 'calc(100%)',
                marginLeft: '-12px',
                paddingRight: '15px',
                paddingLeft: '15px',
                paddingBottom: '15px',
                zIndex: 999,
              }}
            >
              <Box
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '20px',
                }}
              >
                <Button style={{ textTransform: 'unset', paddingTop: 0, paddingLeft: 0, minWidth: 'auto' }}>
                  <ArrowBackIos
                    onClick={() => setOpenConfirmModal(true)}
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                  />
                </Button>
                <span
                  style={{
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                    fontWeight: 'bold',
                  }}
                >
                  Contreparties VIP
                </span>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{ backgroundColor: errorContrePart ? 'gray' : '#518971', color: 'white' }}
                  onClick={onValidate}
                  disabled={errorContrePart}
                >
                  Valider
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setAffiliationSaved(true));
                    history.goBack();
                  }}
                  style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#000000', marginInlineStart: 10 }}
                >
                  Annuler
                </Button>
              </Box>
            </div>
          )}

          {isMobile && (
            <>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingTop: '100px',
                }}
              >
                <Box
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Checkbox
                    checked={disableContrePartie}
                    color="primary"
                    style={{ color: 'black', alignSelf: 'flex-end' }}
                    onClick={OnChecked}
                  />
                  <Typography>Aucune contrepartie pour les VIP</Typography>
                </Box>
                {contrePartie?.map((item: IStateContrePartie, index) => {
                  const min = item.montantMin;
                  const max = item.montantMax;
                  return (
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      style={{ boxShadow: '-1px -1px 2px inset #acacac', marginBottom: 20 }}
                    >
                      <Grid item xs={3}>
                        <Box>Montant CA invités(€)</Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography style={{ margin: '0px 10px', color: disableContrePartie ? 'gray' : '#000' }}>
                            De
                          </Typography>
                          <Box className={style.montant}>
                            <TextInputWithIcon
                              {...{
                                // label: undefined,
                                labelWidth: undefined,
                                type: 'number',
                                error: (index !== 0 && contrePartie[index - 1].montantMax > min) || min > 99999999,
                                value: min,
                                onChange: (evt) => {
                                  dispatch(setAffiliationSaved(false));
                                  const cp = contrePartie;
                                  cp[index].montantMin = +evt.target.value;
                                  setContrePartie([...cp]);
                                },
                                style: {
                                  color: disableContrePartie ? 'gray' : '#000',
                                },
                                name: 'minValue',
                                readOnly: disableContrePartie,
                                endAdornment: (
                                  <InputAdornment position="end" className={style.Adornment}>
                                    <Typography style={{ color: disableContrePartie ? 'gray' : '#000' }}>€</Typography>
                                  </InputAdornment>
                                ),
                                disabled: disableContrePartie,
                                helperText: min > 99999999 ? 'value > 99999999' : '< montantPrécédent',
                              }}
                            />
                          </Box>
                          <Typography style={{ margin: '0px 10px', color: disableContrePartie ? 'gray' : '#000' }}>
                            à
                          </Typography>
                          <Box className={style.montant}>
                            <TextInputWithIcon
                              {...{
                                labelWidth: undefined,
                                type: 'number',
                                error: min > max || max > 99999999,
                                value: max,
                                onChange: (evt) => increaseMaxValue(index, evt),
                                name: 'maxValue',
                                style: {
                                  color: disableContrePartie ? 'gray' : '#000',
                                },
                                readOnly: disableContrePartie,
                                endAdornment: (
                                  <InputAdornment position="end" className={style.Adornment}>
                                    <Typography style={{ color: disableContrePartie ? 'gray' : '#000' }}>€</Typography>
                                  </InputAdornment>
                                ),
                                disabled: disableContrePartie,
                                helperText: max > 99999999 ? 'value > 99999999' : 'montantMin > value',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>Type de contre partie</Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box style={{ paddingLeft: 8 }}>
                          <CustomSelect
                            {...{
                              defaultValue: 0,
                              Optionvalue: typeCOntraPartie,
                              className: style.selectNature,
                              value: item.ValuetypecontrePartie,
                              inputProps: { className: style.inputPropsSelect },
                              variant: 'outlined',
                              disabled: disableContrePartie,
                              onChange: (event: any) => OnchageSelected(event, index),
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>Type de contre partie</Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box style={{ flexGrow: 'initial', paddingLeft: 0 }}>
                            <TextInputWithIcon
                              {...{
                                labelWidth: undefined,
                                type: item.ValuetypecontrePartie === 0 ? 'text' : 'number',
                                name: 'contrepartie',
                                readOnly: disableContrePartie,
                                style: { paddingLeft: 0 },
                                value: item.contrePartie,
                                onChange: (event: any) => OnchageValueNature(event, index),
                                endAdornment: (
                                  <InputAdornment position="end" className={style.Adornment}>
                                    {item.ValuetypecontrePartie === 0
                                      ? ''
                                      : item.ValuetypecontrePartie === 1
                                      ? '€'
                                      : '%'}
                                  </InputAdornment>
                                ),
                                disabled: disableContrePartie,
                              }}
                            />
                          </Box>
                          {index > 0 ? (
                            <Button
                              onClick={() => {
                                dispatch(setAffiliationSaved(false));
                                setContrePartie((c) => {
                                  if (!c) {
                                    return c;
                                  }
                                  return c.filter((_, index1) => index != index1);
                                });
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          ) : (
                            <Box style={{ width: 70 }} />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
              <Button
                disabled={disableContrePartie}
                // color="secondary"
                className={!disableContrePartie ? style.addButtonConterParty : ''}
                style={{
                  backgroundColor: '#000000',
                  textTransform: 'inherit',
                  color: 'white',
                  maxWidth: 140,
                  borderRadius: 8,
                }}
                onClick={() => {
                  // setinitialState({ ...initialState, tarif: '20€ à 40€' });
                  if (contrePartie) {
                    setContrePartie((prev) => {
                      if (prev) {
                        const previous = prev[prev.length - 1];
                        return [
                          ...prev,
                          {
                            ...previous,
                            montantMin: previous.montantMax,
                          },
                        ];
                      }
                    });
                  }
                }}
              >
                Ajouter contrepartie
              </Button>
            </>
          )}
        </>
      </Box>
    </>
  );
};

export default AffiliationComponent;
