import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

const ModalGoBackConfirmation = ({
  open,
  handleCancel,
  handleConfirm,
}: {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>Êtes-vous sûr de quitter sans valider les changements ?</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Annuler</Button>
        <Button onClick={handleConfirm}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalGoBackConfirmation;
