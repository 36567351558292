import { typedAction } from '../../utils';

export const DO_URL_FRAME_SET = 'URL/FRAME/SET';
export const DO_URL_FRAME_RESET = 'URL/FRAME/RESET';

export interface IinitialStateurlToFrame {
  url: string | null;
}
const initialState: IinitialStateurlToFrame = {
  url: '',
};

export const setUrlToFrame = (url: string) => typedAction('URL/FRAME/SET', url);
export type IsetUrlToFrame = typeof setUrlToFrame;

export const ResetUrlToFrame = () => typedAction('URL/FRAME/RESET');
export type IresetUrlToFrame = typeof ResetUrlToFrame;

export type urlToFrameAction = ReturnType<IsetUrlToFrame | IresetUrlToFrame>;

export const urlToFrameReducer = (
  state: IinitialStateurlToFrame = initialState,
  action: urlToFrameAction,
): IinitialStateurlToFrame => {
  switch (action.type) {
    case DO_URL_FRAME_SET:
      return {
        ...state,
        url: action.payload,
      };
    case DO_URL_FRAME_RESET:
      return {
        ...state,
        url: '',
      };
    default:
      return {
        ...state,
      };
  }
};
