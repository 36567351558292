import { typedAction } from '../utils';

export interface ITimezone {
  id: number;
  countryCode: string;
  name: string;
  utcOffset: number;
  daylightSavingTime: boolean;
}

export interface IinitialTimezone {
  error: string;
  loading: boolean;
  timezone: ITimezone[];
}
export const initialState: IinitialTimezone = {
  error: '',
  loading: false,
  timezone: [
    {
      id: 0,
      countryCode: '',
      name: '',
      utcOffset: 0,
      daylightSavingTime: true,
    },
  ],
};

export const GET_TIMEZONE_REQUEST = 'GET_TIMEZONE_REQUEST';
export const GET_TIMEZONE_SUCCESS = 'GET_TIMEZONE_SUCCESS';
export const GET_TIMEZONE_FAILED = 'GET_TIMEZONE_FAILED';

export const getTimezone = () => typedAction(GET_TIMEZONE_REQUEST);
export type GetTimezone = typeof getTimezone;

export const getTimezoneSuccess = (Result: ITimezone[]) => typedAction(GET_TIMEZONE_SUCCESS, Result);
export type GetTimezoneSuccess = typeof getTimezoneSuccess;

export const getTimezoneFailed = (error: string) => typedAction(GET_TIMEZONE_FAILED, error);
export type GetTimezoneFailed = typeof getTimezoneFailed;

export type TimeZoneAction = ReturnType<GetTimezone | GetTimezoneSuccess | GetTimezoneFailed>;

export const TimeZoneReducer = (state: IinitialTimezone = initialState, action: TimeZoneAction): IinitialTimezone => {
  switch (action.type) {
    case GET_TIMEZONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TIMEZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        timezone: action.payload,
      };
    case GET_TIMEZONE_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
