import { Breadcrumbs, Button, Link, LinkProps, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Link as RouterLink, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { CapitalizeFirstLetter } from '../../constant/function';
import { RootState } from '../../redux-services';
import { doEndLiveSession } from '../../redux-services/modules/liveSessionEnd';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { IinitialStateLiveStart } from '../../redux-services/modules/liveSessionStart';
import { IinitialStateProductById } from '../../redux-services/modules/product/productget';
import { IinitialStateAffiliation, setAffiliationSaved } from '../../redux-services/modules/state/stateAffiliation';
import { IStateProducts } from '../../redux-services/modules/state/stateProducts';
import { IStateQuestion } from '../../redux-services/modules/state/stateSurvey';
import theme from '../../Themes';
import CustomModal from '../modal/Modal';
import { useStyles } from './style';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

type ParamsType = {
  id: string;
  idProduct: string;
};

const CustomBreadcrumbs = ({ match: { params }, location }: RouteComponentProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { liveDetails: live } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const {
    autreInfo,
    checked,
    description,
    devise,
    livraisons,
    paysDeLivraisons,
    prixApres,
    prixAvant,
    productName,
    productUrl,
    stocks,
  } = useSelector<RootState, IStateProducts>((state) => state.stateProduct.stateProduct);
  const { name, question } = useSelector<RootState, IStateQuestion>((state) => state.stateQuestion.stateQuestion);
  const { ListProduct } = useSelector<RootState, IinitialStateProductById>((state) => state.productGetById);
  const [activPreced, setactivPreced] = useState<boolean>(true);
  const [link, setLink] = useState<string>('');
  const [openDialog, setopenDialog] = useState<boolean>(false);
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { id, idProduct } = params as ParamsType;
  const [message, setMessage] = useState('');
  const StateContrePart = useSelector<RootState, IinitialStateAffiliation>((state) => state.stateAffiliation);
  const { adaptorAntMedia } = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);

  const onConfirmDialog = async () => {
    dispatch(setAffiliationSaved(true));
    if (live.actualStart && StateContrePart.isSaved) {
      await dispatch(doEndLiveSession({ id, adaptorRTC: adaptorAntMedia }));
    }
    await setopenDialog(false);
    await history.push(link);
  };

  const onCloseDialog = () => {
    setopenDialog(false);
  };

  useEffect(() => {
    if (
      autreInfo ||
      checked ||
      description ||
      devise ||
      livraisons ||
      paysDeLivraisons ||
      prixApres ||
      prixAvant ||
      productName ||
      productUrl ||
      stocks ||
      name ||
      question
    ) {
      setactivPreced(false);
      window.onbeforeunload = () => {
        return 'show warning';
      };
    } else {
      setactivPreced(true);
    }
  }, [
    autreInfo,
    checked,
    description,
    devise,
    livraisons,
    paysDeLivraisons,
    prixApres,
    prixAvant,
    productName,
    productUrl,
    stocks,
    name,
    question,
  ]);

  const GoToLink = (event: any, link: string) => {
    if (
      ((location.pathname.includes(`dashboard/livesessions/${id}/showlive`) &&
        !link.includes(`dashboard/livesessions/${id}`)) ||
        (location.pathname === `/dashboard/livesessions/${id}` && link === `/dashboard/livesessions`)) &&
      live.actualStart &&
      !live.actualEnd
    ) {
      setMessage('Etes-vous sûr de stopper le live avant de quitter la page ?');
      setopenDialog(true);
      setLink(link);
      return event.preventDefault();
    } else if (location.pathname === `/dashboard/livesessions/${id}/parametre` && !StateContrePart.isSaved) {
      setMessage('Êtes-vous sûr de quitter sans valider les changements ?');
      setopenDialog(true);
      setLink(link);
    } else {
      history.push(link);
    }
  };
  return (
    <div className={classes.root}>
      <CustomModal
        {...{
          open: openDialog,
          title: 'Confirmation',
          TextCancel: 'Annuler',
          onConfirm: onConfirmDialog,
          textConfirm: 'Confirmer',
          onClose: onCloseDialog,
        }}
      >
        <Typography>{message}</Typography>
      </CustomModal>
      <Route>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ color: 'black', width: '100%' }}
          className={classes.customBreadCrumbs}
        >
          {pathnames.map((value, index) => {
            const capitalized = CapitalizeFirstLetter(value);
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            return last ? (
              <Typography variant="subtitle1" key={`${to} ${index}`} style={{ color: theme.palette.primary.light }}>
                {value === id ? CapitalizeFirstLetter(live.title) : idProduct ? ListProduct.label : capitalized}
              </Typography>
            ) : (
              <Button
                key={`${to} ${index}`}
                disabled={!activPreced}
                style={{ textTransform: 'unset' }}
                onClick={(event) => GoToLink(event, to)}
              >
                {/* <LinkRouter type="Typography" variant="subtitle1" color="textPrimary" to={to} key={to}> */}
                {value === id ? CapitalizeFirstLetter(live.title) : capitalized}
                {/* </LinkRouter> */}
              </Button>
            );
          })}
        </Breadcrumbs>
      </Route>
    </div>
  );
};

export default withRouter(CustomBreadcrumbs);
