import { call, put, takeEvery } from 'redux-saga/effects';
import { DeleteGuestFailed, DeleteGuestSuccess, DELETE_GUEST_REQUEST } from '../../modules/guest/guestDelete';
import api from '../../api/index';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { logout } from '../../modules/logout';

export function* watchDeleteGuest() {
  yield takeEvery(DELETE_GUEST_REQUEST, DeleteGuestionfunction);
}

export function* DeleteGuestionfunction({ payload }: any) {
  try {
    const { data, status } = yield call(api.guest.DeleteGuest, payload);

    yield put(DeleteGuestSuccess(payload));
    const dataError = data.find((_d: any) => !_d.isSuccess);
    yield put(
      Set_state_snackbar({
        status: 200,
        message: dataError ? dataError.errorMessage : 'Suppression effectuée avec succès',
        open: true,
        type: dataError ? 'error' : 'success',
      }),
    );
  } catch (error: any) {
    // const { status, message } = error.response;
    // yield put(Set_state_snackbar({ status: status, message: message, open: true, type: 'error' }));
    // yield put(guest_create_failed({ status: status, message: message }));
    yield put(DeleteGuestFailed());
    if (error.response) {
      const { status } = error.response;
      console.log(`error`, error);

      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: 'Erreur de serveur! Merci de vérifier votre connexion.',
              open: true,
              type: 'error',
            }),
          );
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );

            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
