import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../api';
import { getCategorie } from '../modules/categorie';
import { getduration } from '../modules/duration';
import { AUTH_LOGIN_REQUEST, loginFailed, loginSuccess } from '../modules/login';
import { setProfileImg } from '../modules/state/stateProfileImg';
import authSevices from '../services/authSevices';
import { ErrorFunction } from './utils/function';

export function* watchLogin() {
  yield takeEvery(AUTH_LOGIN_REQUEST, login);
}

function* login({ payload }: any) {
  try {
    const { data, status } = yield call(APi.auth.authlogin, payload);
    const { jwt: Token, displayName } = data;
    const result = { ...data, status };
    if (Token) {
      yield authSevices.setAccessToken(Token);
      yield authSevices.setNameRetailer(displayName);
      yield authSevices.setTypeRetailer(payload.userType);
      yield put(loginSuccess(result));
      if (payload.userType === 'Retailer') {
        yield put(getCategorie());
        yield put(getduration());
      }
      if (payload.userType === 'Consumer') {
        yield put(setProfileImg({ url: data.profilePictureUrl ?? '', name: displayName }));
        yield authSevices.setProfilConsumer(data.profilePictureUrl);
      }
    }
  } catch (error: any) {
    const { status } = error.response;
    const errorText = error.response.data.errors
      ? (Object.values(error.response.data.errors)[0] as any)[0]
      : error.response.data.title;
    yield put(loginFailed({ status, message: errorText }));
    // yield call(ErrorFunction, error);
  }
}
