import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'space-around',
      display: 'flex',
      marginTop: '20px',
      '@media(max-width:700px)': {
        flexWrap: 'no-wrap',
        maxWidth: '94vw',
        oveerflowX: 'hidden',
        justifyContent: 'space-between',
        '&>button': {
          marginTop: 8,
          marginInlineEnd: 4,
        },
      },
    },
    button1: { textTransform: 'none', backgroundColor: theme.palette.primary.light, color: 'white' },
    button2: { textTransform: 'none' },
    button3: { textTransform: 'none' },
  }),
);
