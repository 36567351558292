import APi from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getdurationFailed, getdurationSuccess, GET_DURATION_REQUEST } from '../modules/duration';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { logout } from '../modules/logout';

export function* watchduration() {
  yield takeEvery(GET_DURATION_REQUEST, duration);
}

export function* duration() {
  try {
    const { data } = yield call(APi.duration.durationApi);
    yield put(getdurationSuccess(data));
  } catch (e: any) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case 400:
          yield put(getdurationFailed({ status, error: 'bad request' }));
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(getdurationFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        default:
          yield put(getdurationFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }));
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = e;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(getdurationFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );

            break;
          default:
            yield put(getdurationFailed({ status: 503, error: 'Please! check yours Network' }));
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
