import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../../api';
import { Set_state_snackbar } from '../../../modules/state/stateMessage';
import {
  ConfirmationAccount_consumer_failed,
  ConfirmationAccount_consumer_succefull,
  CONFIRMATION_ACCOUNT_CONSUMER_REQUESTED,
} from '../../../modules/account/consumer/confirmationAccountConsumer';
import { ErrorFunction } from '../../utils/function';

export function* watchConfirmationAccountConsumer() {
  yield takeEvery(CONFIRMATION_ACCOUNT_CONSUMER_REQUESTED, confirmationConsumer);
}

export function* confirmationConsumer({ payload }: any) {
  try {
    const { status, data } = yield call(api.account.confirmationAccountConsumer, payload);

    yield put(ConfirmationAccount_consumer_succefull(data));
    yield put(
      Set_state_snackbar({
        status: status,
        message: 'Confirmation compte consumer reussie.',
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    yield put(ConfirmationAccount_consumer_failed());
    const { response } = error;

    if (
      response &&
      response.data &&
      typeof response.data === 'string' &&
      response.data.includes('ConsumerEmailAlreadyConfirmed')
    ) {
      yield put(
        Set_state_snackbar({
          status: 500,
          message: 'Consumer compte déjà confirmé',
          open: true,
          type: 'error',
        }),
      );
    } else {
      yield call(ErrorFunction, error);
    }
  }
}
