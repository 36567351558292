import { createStyles, FormHelperText, makeStyles, Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import React from 'react';
import { forceScrollToTop } from '../../utils/common';

interface ITextInputIcon {
  width?: string | number;
  helperText?: string;
}
const TextInputWithIcon: React.FC<OutlinedInputProps & ITextInputIcon> = (props, { width }) => {
  const classes = useStyles();
  return (
    <FormControl className={clsx(classes.margin, { width: width })} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-weight"
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        labelWidth={10}
        {...{ ...props }}
        onBlur={forceScrollToTop}
      />
      {!!props.error && (
        <FormHelperText error id="accountId-error" style={{ fontSize: 8, position: 'absolute', top: 42, margin: 0 }}>
          {props?.helperText ?? ''}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

export default TextInputWithIcon;
