import { IcardShooping } from '../../../common/MyCardShopping/MyCardShopping';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux-services';
import { IinitialStateListProduct } from '../../../redux-services/modules/product/productList';
import { IActus } from '../../../redux-services/type/actus';
import { IinitialStateClickRecordProductByLiveId } from '../../../redux-services/modules/product/participantProductClickRecord';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { IActusWithActivated } from '../../ApercuLiveComponent/ApercuComponent';
import { useStyles } from './styles';

interface IListProductsProps {
  actuality?: IActusWithActivated;
}

const ActualityRetailerMobile: React.FC<IListProductsProps> = (props: IListProductsProps) => {
  const classes = useStyles();
  const { actuality } = props;
  const [actualitys, setActualitys] = useState<IActus[]>([]);
  const { ListProduct } = useSelector<RootState, IinitialStateListProduct>((state) => state.productList);
  const { ListDataClickRecordProducts, loading: loadingListDataClickRecordProducts } = useSelector<
    RootState,
    IinitialStateClickRecordProductByLiveId
  >((state) => state.listClickRecordProductByLiveId);

  const DataProducts: IcardShooping[] = ListProduct.map((item) => {
    return {
      id: item.id,
      description: item.label,
      pathProduct: item.mainImageUrl,
      price: item.priceAfterDiscount,
      activePartage: true,
    };
  });

  useEffect(() => {
    if (!loadingListDataClickRecordProducts && ListDataClickRecordProducts.length > 0) {
      setActualitys(
        ListDataClickRecordProducts.map((_historyActu) => ({
          productId: _historyActu.productLabel,
          participantId: _historyActu.participantId,
          participantName: _historyActu.participantName,
        })),
      );
    }
  }, [loadingListDataClickRecordProducts]);

  useEffect(() => {
    if (actuality && actuality?.productId !== '' && actuality?.participantName !== '') {
      const _actuality: IActus = {
        ...actuality,
        productId: DataProducts.find((p) => p.id == actuality.productId)?.description || '',
      };
      setActualitys((_actus) => (_actus.length ? [..._actus, _actuality] : [_actuality]));

      const elementToScroll = document.getElementById('actuality-box');
      elementToScroll?.scrollTo(0, elementToScroll?.scrollHeight);
    }
  }, [actuality]);

  return (
    <Box className={classes.boxComponentbtnProduct} id="actuality-box">
      {actualitys.length ? (
        actualitys.map((actu, indexKey: number) => (
          <Typography key={indexKey}>{`${actu.participantName} s’intéresse à ${actu.productId}`}</Typography>
        ))
      ) : (
        <Typography>I am a actualiter</Typography>
      )}
    </Box>
  );
};

export default ActualityRetailerMobile;
