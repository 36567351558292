import { typedAction } from '../../utils';

export interface IStateProducts {
  productId: string;
  productUrl: string;
  productName: string;
  description: string;
  prixAvant: string;
  prixApres: string;
  devise: string;
  stocks: string;
  livraisons: string;
  paysDeLivraisons: string;
  autreInfo: string;
  checked: boolean;
}

export const STATE_PRODUCT = 'State/Product';
export const STATE_INITIAL_PRODUCT = 'State/ProductInitial';

interface IinitialStateProduct {
  stateProduct: IStateProducts;
}

export const initialStateProduct: IinitialStateProduct = {
  stateProduct: {
    productId: '',
    productUrl: '',
    productName: '',
    description: '',
    prixAvant: '',
    prixApres: '',
    devise: '',
    stocks: '',
    livraisons: '',
    paysDeLivraisons: '',
    autreInfo: '',
    checked: false,
  },
};

export const setStateProduct = (args: { [key: string]: any }) => typedAction(STATE_PRODUCT, args);
export type SetStateProduct = typeof setStateProduct;

export const ResetStateProduct = () => typedAction(STATE_INITIAL_PRODUCT);
export type IResetStateProduct = typeof ResetStateProduct;

type PRODUCTACTION = ReturnType<SetStateProduct | IResetStateProduct>;

export const ProductStateReducers = (state = initialStateProduct, action: PRODUCTACTION): IinitialStateProduct => {
  switch (action.type) {
    case STATE_PRODUCT:
      return {
        ...state,
        stateProduct: { ...state.stateProduct, ...action.payload },
      };
    case STATE_INITIAL_PRODUCT:
      return {
        ...state,
        stateProduct: { ...initialStateProduct.stateProduct },
      };
    default:
      return {
        ...state,
      };
  }
};
