export default {
  'question.title': 'Manage survey',
  'question.created': 'Questions created',
  'question.updated': 'Update',
  'question.delete': 'Delete',
  'question.type': 'Type :',
  'question.questionnaire': 'Name of question : ',
  'question.questionnaireform': 'Name of question',
  'question.name': 'Question',
  'question.add.response': 'Add response',
  'question.type.question': 'Type of question',
  'question.btn.validate': 'Validate',
  'question.max.response': 'Answers (2 minimum, 4 maximum)',
  'question.btn.cancel': 'Cancel',
  'question.choice.only': 'Only choice',
  'question.choice.multiple': 'Multiple choice',
};
