import { typedAction } from '../../utils';

export const RESET_PASSWORD_REQUESTED = 'RESET/PASSWORD/REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'RESET/PASSWORD/SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET/PASSWORD/FAILED';

export interface IparamsResetPassword {
  userType: string;
  data: {
    consumerId: string;
    securityStamp: string;
    password: string;
  };
}

export interface InitialstateResetPassword {
  loading: boolean;
  status: number;
}

const initialState: InitialstateResetPassword = {
  loading: false,
  status: 0,
};

export const DO_resetPassword = (params: IparamsResetPassword) => typedAction(RESET_PASSWORD_REQUESTED, params);
export type IDO_resetPassword = typeof DO_resetPassword;

export const resetPasswordSuccefull = () => typedAction(RESET_PASSWORD_SUCCESS);
export type IresetPasswordSuccefull = typeof resetPasswordSuccefull;

export const resetPasswordFailed = () => typedAction(RESET_PASSWORD_FAILED);
export type IresetPasswordFailed = typeof resetPasswordFailed;

export type resetPasswordAction = ReturnType<IDO_resetPassword | IresetPasswordSuccefull | IresetPasswordFailed>;

export const resetPasswordReducer = (
  state: InitialstateResetPassword = initialState,
  action: resetPasswordAction,
): InitialstateResetPassword => {
  switch (action.type) {
    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        status: 400,
      };
    default:
      return {
        ...state,
      };
  }
};
