import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    BoxRoute: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 50,
      marginBottom: 40,
    },
    BoxDisplay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
    },
    Boxresponse: { marginTop: 10, display: 'flex', flexDirection: 'column' },
    boxType: { width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', flex: 2 },
    boxBtn: {
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'space-between',
      width: '100%',
      marginTop: 10,
    },
    btnUpdate: {
      color: '#222222',
    },
    btnDelete: {
      color: 'red',
    },
  }),
);
