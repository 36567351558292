import { authlogin } from './loginApi';
import {
  liveSessionConsumerProduct,
  liveSessionPage,
  liveSessionList,
  liveSessionConsumerGet,
  liveSessionJoin,
} from './liveSessionAPi';
import { durationApi } from './duration';
import {
  liveSessionRetaillerCreate,
  liveSessionRetaillerGet,
  liveSessionRetaillerPage,
  liveSessionRetaillerUpdate,
  liveSessionRetaillerSection,
  liveSessionRetailerListBySection,
  liveSessionStart,
  liveSessionEnd,
  liveSessionDetails,
  liveSessionRetailerListEndedSection,
  liveSessionDuplicate,
} from './liveSessionRetailler';
import { timezone } from './timezone';
import { liveSessionCategory } from './categorie';
import { uploadImage } from './uploadImage';
import { GalleryImage, GetImageById } from './gellerieImage';
import { checkHistoryMessagesListener, connexionToListener, checkHistoryViewersListener } from './listenerApi';
import {
  CreateProduct,
  UpdateProduct,
  ProductList,
  ProductById,
  DeleteProduct,
  ParticipantProductClickRecord,
} from './product';
import {
  CreateGuest,
  UpdateGuest,
  GuestList,
  GuestById,
  DeleteGuest,
  SendInvitationsGuest,
  GuestConsumer,
  GuestGetInvitationMailData,
  GuestParticipantsSummary,
  GuestParticipantsSummaryExcelFile,
  SetAdvantageCodes,
} from './guest';
import { getProfilRetailer, resetPassword, sendEmailResetPassword, setLogoRetailer, updatePassword } from './account';
import {
  createConsumer,
  listConsumerAPi,
  updateConsumer,
  uploadProfilImage as uploadConsumerProfileImage,
} from './acount/consumer/consumerAccount';
import {
  Affiliation,
  BindConsumer,
  CountAffiliationCreation,
  GetAffiliationInfo,
  CountAffiliationGetInfo,
} from './participant';
import { createAccountConsumer, confirmationAccountConsumer } from './acount/consumer/singUp';

export default {
  auth: {
    authlogin,
    createConsumer,
    listConsumerAPi,
    updateConsumer,
    sendEmailResetPassword,
    resetPassword,
    updatePassword,
  },
  account: {
    getProfilRetailer,
    setLogoRetailer,
    createAccountConsumer,
    confirmationAccountConsumer,
    uploadConsumerProfileImage,
  },
  liveSession: {
    liveSessionList,
    liveSessionPage,
    liveSessionCategory,
    liveSessionConsumerGet,
    liveSessionJoin,
    liveSessionConsumerProduct,
  },
  liveSessionRetailer: {
    liveSessionRetaillerPage,
    liveSessionRetaillerCreate,
    liveSessionRetaillerGet,
    liveSessionRetaillerUpdate,
    liveSessionRetaillerSection,
    liveSessionRetailerListBySection,
    liveSessionDetails,
    liveSessionStart,
    liveSessionEnd,
    liveSessionRetailerListEndedSection,
    liveSessionDuplicate,
  },
  time: { timezone },
  gallerie: { uploadImage, GalleryImage, GetImageById },
  duration: { durationApi },
  listener: { connexionToListener, checkHistoryMessagesListener, checkHistoryViewersListener },
  product: { CreateProduct, UpdateProduct, ProductList, ProductById, DeleteProduct, ParticipantProductClickRecord },
  guest: {
    CreateGuest,
    UpdateGuest,
    GuestList,
    GuestById,
    DeleteGuest,
    SendInvitationsGuest,
    GuestConsumer,
    GuestGetInvitationMailData,
    GuestParticipantsSummary,
    GuestParticipantsSummaryExcelFile,
    SetAdvantageCodes,
  },
  participant: { Affiliation, BindConsumer, GetAffiliationInfo, CountAffiliationCreation, CountAffiliationGetInfo },
};
