import { Box, Button, CircularProgress, Menu, Typography } from '@material-ui/core';
import { ArrowBackIos, Close } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { HubConnection } from '@microsoft/signalr';
import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import { RootState } from '../../redux-services';
import { IParticipant } from '../../redux-services/modules/liveListener';
import { IinitialStateLiveEnd } from '../../redux-services/modules/liveSessionEnd';
import { getLiveSessionDetails, IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { IinitialStateLiveStart } from '../../redux-services/modules/liveSessionStart';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import { IMessage, IViewer } from '../../redux-services/type/listenerApi';
import ItemChatComponent from './ItemOneComponent/ItemOneChat';
import ListProductComponent from './listProductComponent/ListProductComponent';
import { useStyles } from './styles';
import Call from '../../redux-services/api';
import { IActus } from '../../redux-services/type/actus';
import {
  clearCheckHistoryListener,
  IHistoryResult,
  startCheckHistoryListener,
} from '../../redux-services/modules/listenerHistory';
import { doJoinLiveSession, doLeaveLiveSession } from '../../redux-services/modules/liveSessionJoin';
import TimerUp from '../../common/timer/TimerUp';
import { Participant, Room } from 'livekit-client';
import { useIsMobile } from '../../common/header/useIsMobile';
import Chat from '../../common/chat/Chat';
import rings from '../../Assets/sounds/notif.mp3';

export interface IActusWithActivated extends IActus {
  activate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IApercuComponent {
  children?: ReactNode;
  participantConnected: Participant[];
  room?: Room;
  messages: IMessage[];
  setMessages: Dispatch<SetStateAction<IMessage[]>>;
  connection: HubConnection | null;
  setConnection: Dispatch<SetStateAction<HubConnection | null>>;
  setLoadingMessViewers: Dispatch<
    SetStateAction<{
      mess: boolean;
      viewers: boolean;
    }>
  >;
  loadingMessViewers: {
    mess: boolean;
    viewers: boolean;
  };
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
  VIP: Participant[] | undefined;
  productsShare: (products: string[]) => Promise<void>;
  setActuality: Dispatch<SetStateAction<IActusWithActivated | undefined>>;
  actuality: IActusWithActivated | undefined;
  backgroundAvatar: string;
}

const ApercuComponent: FC<IApercuComponent> = (props: IApercuComponent) => {
  const isMobile = useIsMobile();
  const {
    children,
    participantConnected,
    room,
    messages,
    loadingMessViewers,
    setLoadingMessViewers,
    setMessages,
    connection,
    setConnection,
    setUserId,
    userId,
    VIP,
    productsShare,
    actuality,
    setActuality,
    backgroundAvatar,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();
  const [token, settoken] = React.useState<string>();
  // const [connection, setConnection] = useState<HubConnection | null>(null);
  // const [userId, setUserId] = useState('');
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const sound = new Audio(rings);
  const [isStartedLiveYet, setIsStartedLiveYet] = useState(false);
  const [doStopLive, setDoStopLive] = useState(false);

  const [internet, setInternet] = useState<boolean>(false);
  // const [loadingMessViewers, setLoadingMessViewers] = useState({ mess: true, viewers: true });
  // const [productsToShare, setProductsToShare] = useState<string[]>([]);
  const { loading: loadingStopLive } = useSelector<RootState, IinitialStateLiveEnd>((state) => state.liveSessionEnd);

  const { loading: loadingStartLive, liveStarted } = useSelector<RootState, IinitialStateLiveStart>(
    (state) => state.liveSessionStart,
  );

  const { liveDetails, loading } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dataHistory = useSelector<RootState, IHistoryResult>((state) => state.listenerHistory);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const startConnection = async (liveId: string, connectionCheck: HubConnection | null) => {
    try {
      const _token = await authSevices.getAccessToken();
      if (_token) {
        settoken(_token);
      } else {
        settoken('');
      }

      const username = await authSevices.getNameRetailer();
      const _participantByLive = await liveSessionServices.getParticipantByLive(liveId, 'Retailer', username!);

      const _userId = _participantByLive?.participantId;
      if (_userId) {
        await authSevices.setParticipantId(_userId);
        if (connectionCheck) {
          connectionCheck.start();
          return connectionCheck;
        } else {
          const connex = await Call.listener.connexionToListener();

          connex.on('ParticipantHasJoined', (dataUserJoinned) => {
            console.log('dataUserJoinned :>> ', dataUserJoinned);
            if (internet && liveId && _userId) {
              dispatch(startCheckHistoryListener({ userType: 'Retailer', id: `${liveId}?participantId=${_userId}` }));
            }
          });

          connex.on('ParticipantHasLeft', (dataUserHasLeft) => {
            console.log('dataUserHasLeft :>> ', dataUserHasLeft);
            setParticipants((prs) => prs && prs.filter((p) => p.id !== dataUserHasLeft.id));
            if (internet && liveId && _userId) {
              dispatch(startCheckHistoryListener({ userType: 'Retailer', id: `${liveId}?participantId=${_userId}` }));
            }
          });

          connex.on('ParticipantHasClickedOnProduct', (participant, productId) => {
            console.log('dataproductIds :>> ', productId, participant);
            setActuality((actu) =>
              actu
                ? {
                    productId: productId,
                    participantId: participant.id,
                    participantName: participant.name,
                    activate: !actu.activate,
                  }
                : {
                    productId: productId,
                    participantId: participant.id,
                    participantName: participant.name,
                    activate: true,
                  },
            );
          });

          connex.on('LiveSessionHasStarted', () => {
            setTimeout(() => {
              setIsStartedLiveYet(true);
            }, 5000);
          });

          connex.on('LiveSessionHasEnded', () => {
            setDoStopLive(true);
          });

          connex.onclose(() => {
            console.log('connection closed');
            setConnection(null);
            setInternet(false);
            setParticipants((prs) => prs && prs.filter((p) => p.id !== _userId));
          });

          await connex.start().then(async () => await connex.invoke('Join'));

          console.log('userId _userId :>> ', _userId);
          setConnection(connex);
          setUserId(_userId);
          return connex;
        }
      } else {
        console.log('error:: no parcticipant id ');
        dispatch(doJoinLiveSession({ id: id, name: username!, userType: 'Retailer' }));
        setInternet(false);
        // history.push(`/auth/Consumer/${id}`);
      }
    } catch (e: any) {
      console.log('Error start connection:', e);
      const _participantByLive = await liveSessionServices.getParticipantByLive(liveId, 'Consumer');

      const _userId = _participantByLive?.participantId;
      const username = await authSevices.getNameRetailer();
      if (navigator.onLine && _userId && _userId !== '' && _userId !== 'undefined' && username) {
        await liveSessionServices.removeParticipantByLive(_userId);
        dispatch(doJoinLiveSession({ id: id, name: username!, userType: 'Retailer' }));
      }
      setInternet(false);
    }
  };

  const checkExistingParticipant = async () => {
    if (
      userId &&
      dataHistory.data.viewers.length &&
      dataHistory.data.viewers.some((_viewer) => _viewer.id === userId) &&
      connection
    ) {
      await connection.invoke('Leave');
      await connection.invoke('Join');
    }
  };

  const leaveSession = async (_connection: Promise<HubConnection | undefined>) => {
    const _userId = await authSevices.getParticipantId();
    const _connex = await _connection;
    if (_connex?.state === 'Connected') {
      await _connex?.invoke('Leave', _userId);
      _connex?.stop();
    }
  };
  const leavePage = async () => {
    dispatch(clearCheckHistoryListener());
    dispatch(doLeaveLiveSession());
  };

  useEffect(() => {
    if (id) {
      dispatch(getLiveSessionDetails(id));
    }
  }, [id, loadingStartLive, loadingStopLive]);

  useEffect(() => {
    if (!dataHistory.loading && userId !== '') {
      setMessages((mess_) =>
        dataHistory.data.messages.length && dataHistory.data.messages[0].text != ''
          ? dataHistory.data.messages.map((m: IMessage) => {
              return m;
            })
          : mess_,
      );
      setLoadingMessViewers({ mess: false, viewers: true });
      // checkExistingParticipant();
      setParticipants((prs_) =>
        dataHistory.data.viewers.length && dataHistory.data.viewers[0].name != ''
          ? dataHistory.data.viewers.map((v: IViewer) => {
              const viewer_ = {
                ...v,
                identifiant: v.name.slice(0, 2),
                image: '',
              };
              return viewer_;
            })
          : prs_,
      );
      setLoadingMessViewers({ mess: false, viewers: false });
    }
  }, [dataHistory, userId]);

  useEffect(() => {
    if (!internet) {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setInternet(true);
            clearInterval(webPing);
          })
          .catch(() => {
            setInternet(false);
          });
      }, 2000);
    } else {
      const _idLive = id;

      const _connexStarted = startConnection(_idLive, connection);

      /*if ((liveStarted || liveDetails.actualStart) && !liveDetails.actualEnd) {
        const onCancel = () => {
          console.log('I am the Cancel.');
        };
        const onLeave = (ev: Event, _dispatch: Dispatch) => {
          console.log('I am the leave.', _idLive);
          leaveSession(_connexStarted);
          _dispatch(doEndLiveSession({ id: _idLive, adaptorRTC: adaptorAntMedia }));
        };

        const onExit = (e: any) => {
          if (!e) e = window.event;
          e.returnValue = 'You sure you want to leave?';
          if (e.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
          }
          console.log('I am the beforeunload.');
          window.requestAnimationFrame(onCancel);
        };

        window.addEventListener('beforeunload', onExit, { capture: true });
        window.addEventListener('unload', (e) => {
          onLeave(e, dispatch);
        });

        return () => {
          window.removeEventListener('beforeunload', onExit);
          window.removeEventListener('unload', (e) => {
            onLeave(e, dispatch);
          });
        };
      } else {
        return async () => {
          await leaveSession(_connexStarted);
        };
      }*/
    }
  }, [internet, liveStarted]);

  useEffect(() => {
    return () => {
      leavePage();
    };
  }, []);

  const goBack = () => {
    history.push(`/dashboard/livesessions/${id}`);
  };

  return (
    <Box className={classes.content}>
      <CustomBackdrop {...{ open: loading }} />
      {isMobile && (
        <div className={classes.liveStatisticBloc}>
          <Button style={{ textTransform: 'unset', paddingTop: 0, paddingLeft: 0, minWidth: 'auto' }}>
            <ArrowBackIos onClick={goBack} className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" />
          </Button>
          <div>
            <Typography variant="subtitle1">Viewers</Typography>
            {loadingMessViewers.viewers ? (
              <CircularProgress size={20} style={{ marginTop: '10px' }} />
            ) : (
              <Typography variant="overline">{participantConnected.length}</Typography>
            )}
          </div>
          <div>
            <Typography variant="subtitle1">VIP</Typography>
            {loadingMessViewers.viewers ? (
              <CircularProgress size={20} style={{ marginTop: '10px' }} />
            ) : (
              <Typography variant="overline">{VIP?.length}</Typography>
            )}
          </div>
          {/* <div>
                <Typography variant="subtitle1">Achats</Typography>
                <Typography variant="overline">20</Typography>
              </div> */}
          <div>
            <Typography variant="subtitle1">Durée du live</Typography>
            <Typography variant="overline">
              <TimerUp />
            </Typography>
          </div>
        </div>
      )}
      {/* <CustomBackdrop {...{ open: loadingStartLive }} /> */}
      <Box className={classes.root}>
        {!isMobile && (
          <Typography variant="h2" className="title" style={{ alignSelf: 'start' }}>
            Live show
          </Typography>
        )}
        <Box className={classes.rootColumn}>
          <ItemChatComponent
            messages={messages}
            userType="Retailer"
            id={id}
            loading={loadingMessViewers.mess}
            participantsVIP={VIP}
            room={room}
            primaryColor={liveDetails.primaryColor}
            backgroundAvatar={backgroundAvatar}
            connectionHub={connection ? connection : null}
            userId={userId}
          />
          <Box className={classes.itemTwo}>
            {!isMobile && (
              <Typography variant="h5" className="title">
                {t('apercu.apercu.text')}
              </Typography>
            )}
            {/* {!liveStarted && (isStartedLiveYet || !!liveDetails.actualStart) && (
              <CardVideo
                id={id}
                isEndedLive={!!liveDetails.actualEnd}
                isStartLive={!!liveDetails.actualStart}
                {...(!!liveDetails.actualEnd && { urlImage: liveDetails.endedLiveImageUrl })}
              />
            )} */}
            {children}
            {!isMobile && (
              <>
                <Box className={classes.details}>
                  <Box className={classes.footerMobile}>
                    <Box
                      className={classes.conversation}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <ChatIcon />
                    </Box>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      className={classes.chatmobileplace}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div>
                        <Chat
                          messages={messages}
                          userType="Retailer"
                          id={id}
                          primaryColor={liveDetails.primaryColor}
                          loading={loadingMessViewers.mess}
                          backgroundAvatar={backgroundAvatar}
                          connectionHub={connection ? connection : null}
                          userId={userId}
                        />
                        <Box className={classes.closeChat} onClick={handleClose}>
                          <Close />
                        </Box>
                      </div>
                    </Menu>
                  </Box>
                  {!isMobile && (
                    <>
                      <Box>
                        <Typography variant="subtitle1">Viewers</Typography>
                        {loadingMessViewers.viewers ? (
                          <CircularProgress size={20} style={{ marginTop: '10px' }} />
                        ) : (
                          <Typography variant="overline">{participantConnected.length}</Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">VIP</Typography>
                        {loadingMessViewers.viewers ? (
                          <CircularProgress size={20} style={{ marginTop: '10px' }} />
                        ) : (
                          <Typography variant="overline">{VIP?.length}</Typography>
                        )}
                      </Box>
                      {/* <Box>
                <Typography variant="subtitle1">Achats</Typography>
                <Typography variant="overline">20</Typography>
              </Box> */}
                      <Box>
                        <Typography variant="subtitle1">Durée du live</Typography>
                        <Typography variant="overline">
                          <TimerUp />
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <Box className={classes.RootProductList}>
            <ListProductComponent productsShare={productsShare} actuality={actuality} />
            <Box className={classes.contentInfo}>
              <Typography variant="h5">{t('apercu.visible.info')}</Typography>
              <Typography variant="h6">{liveDetails.title}</Typography>
              <Typography>{liveDetails.description}</Typography>
              <Box className={classes.lang}>Français</Box>
              <Box className={classes.iconfooter}>
                <Box>
                  <FacebookIcon />
                </Box>
                <Box>
                  <InstagramIcon />
                </Box>
                <Box>
                  <TwitterIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ApercuComponent;
