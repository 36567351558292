import { useStyles } from './style';
import classNames from 'classnames';
import homepagePhoto from '../../Assets/images/main_image.png';
import participantsPhoto from '../../Assets/images/participants.png';
import { useHistory } from 'react-router-dom';
import { AUTH_CONSUMER_LOG } from '../../constant/path';

const NewHomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <div className={classes.wrapper}>
        <div>
          <div className={classNames('container', classes.sectionOne)}>
            <div className="photo">
              <img src={homepagePhoto} alt="photo home" />
            </div>
            <div className="description">
              <h1>Transforme tes envies de shopping en moment de Plaisir, Partage, Proximité avec tes amis</h1>
              <p>Participe à des Live Shopping reprenant et modernisant les codes de la vente à domicile</p>
              <div>
                <button onClick={() => history.push(AUTH_CONSUMER_LOG)}>creer un compte livood</button>
              </div>
            </div>
          </div>
          <div className={classNames('container', classes.sectionTwo)}>
            <h1>Avec LIVOOD, fais du shooping autrement...</h1>
            <div className={classes.threeCard}>
              <div className="card">
                <h2>Plaisir</h2>
                <div>
                  <p>- Retrouve tes amis dans des live shopping conviviaux de tes marques préférées</p>
                  <p>- Decouvre des produits dans un cadre, un environnement et une mise en scène valorisants</p>
                  <p>- Retrouve les avantages du shopping en magasin et du shopping online dans un même lieu</p>
                </div>
              </div>
              <div className="card">
                <h2>Partage</h2>
                <div>
                  <p>- Partage avec tes amis un bon moment et de bons plans</p>
                  <p>
                    - Découvre des marques qui te correspondent et/ou fais leur découvrir des marques qu'ils vont aimér
                  </p>
                  <p>- Exprime tes goûts et comprends ceux de tes amis</p>
                </div>
              </div>
              <div className="card">
                <h2>Proximité</h2>
                <div>
                  <p>- Gomme les frontières entre réel et virtuel</p>
                  <p>- Echange et interagis avec les autres participants du live</p>
                  <p>- Écoute les, apprends à mieux les connaitre</p>
                  <p>- Reste en contact avec tes amis et tes marques preférées en toute circonstance grâce à Livood</p>
                </div>
              </div>
            </div>
            <div className={classes.vipInit}>
              <h1>
                ET
                <br />
                optimise ces moments shopping en devenant VIP
              </h1>
              <p>
                Ton affinité avec la Marque fait de toi un(e) ambassadeur(drice) privilégié(e) et légitime de la Marque.
              </p>
            </div>
          </div>
          <div className={classNames('container', classes.sectionVip)}>
            <div className="photo-vip">
              <img src={participantsPhoto} alt="vip-photo" />
            </div>
            <div className="details-vip">
              <h1>Etre VIP permet de ...</h1>
              <div className="paraph">
                <ul>
                  <li>Avoir une relation privilégiée avec ses marques préferées</li>
                </ul>
                <ul>
                  <li>Être acteur d’un shopping joyeux et ludique</li>
                  <li>
                    Être un(e) interlocuteur(trice) privilégié(e) de la marque quant aux questions de cette dernière sur
                    son évolution
                  </li>
                </ul>
              </div>
              <div className="paraph">
                <ul>
                  <li>Inviter ses amis à participer au live</li>
                </ul>
                <ul>
                  <li>Définir parmi ses amis celles et ceux les plus en phase avec la marque</li>
                  <li>
                    Donner encore plus de sens au live shopping en orientant efficacement la Marque dans le choix des
                    produits les plus à même de plaire aux invité(e)s
                  </li>
                  <li>Avoir avec ses invité(e) un rôle sincère de "personnal shopper"</li>
                </ul>
              </div>
              <div className="paraph">
                <ul>
                  <li>Etre récompensé(e) en fonction du succès du live</li>
                </ul>
                <ul>
                  <li>
                    Bénéficier d’avantages définis par la marque et attribués aux VIP en fonction du CA réalisé avec ses
                    invités
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.vipDetails}>
            <div className={classNames('container', classes.sectionVipTwo)}>
              <h1 style={{ fontSize: 'xxx-large' }}>C'est simple d'être VIP...</h1>
              <div className="six-grid">
                <div className="item">
                  <svg
                    id="Groupe_59904"
                    data-name="Groupe 59904"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="26.975"
                    viewBox="0 0 36 26.975"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25417" data-name="Rectangle 25417" width="36" height="26.974" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59903" data-name="Groupe 59903" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27851"
                        data-name="Tracé 27851"
                        d="M17.975,26.974q-7.236,0-14.471,0A3.317,3.317,0,0,1,0,23.511Q0,13.505,0,3.5A3.287,3.287,0,0,1,3.526,0Q18,0,32.469,0a3.332,3.332,0,0,1,3.526,3.519q.011,10.005,0,20.011a3.364,3.364,0,0,1-3.476,3.44q-7.272,0-14.543,0m.073-23.6H5.1c-1.747,0-1.688,0-1.771,1.714A2.071,2.071,0,0,0,4.275,7c3.233,2.554,6.554,5,9.687,7.681a12.852,12.852,0,0,0,2.952,1.9,2.273,2.273,0,0,0,2.006.053,10.163,10.163,0,0,0,2.289-1.338q4.8-4.009,9.817-7.746a3.389,3.389,0,0,0,1.613-3.4c-.071-.644-.247-.784-.851-.781-4.58.023-9.161.012-13.741.012M17.9,23.627H31.792c.409,0,.828.071.826-.58-.014-3.957-.007-7.914-.01-11.871,0-.137.046-.311-.1-.394-.188-.109-.3.079-.435.165a3.527,3.527,0,0,0-.284.22c-2.939,2.349-5.857,4.725-8.826,7.036a8.424,8.424,0,0,1-4.614,2.006,6.835,6.835,0,0,1-4.72-1.627c-3.164-2.451-6.3-4.937-9.452-7.4-.217-.17-.426-.527-.7-.386s-.118.523-.119.8q-.012,5.54-.005,11.08c0,.956,0,.959.951.959H17.9"
                        transform="translate(0 0)"
                        fill="#528972"
                      />
                    </g>
                  </svg>

                  <h2>
                    01
                    <br />
                    Reçois un invitation pour un live
                  </h2>
                  <p>
                    Les marques ou conseillers qui t'ont sélectionné en tant que VIP t'envoient une invitation pour te
                    connecter au live et inviter tes amis
                  </p>
                </div>
                <div className="item">
                  <svg
                    id="Groupe_59906"
                    data-name="Groupe 59906"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="31.956"
                    viewBox="0 0 36 31.956"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25418" data-name="Rectangle 25418" width="36" height="31.956" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59905" data-name="Groupe 59905" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27852"
                        data-name="Tracé 27852"
                        d="M60.173,3.583c0-.618-.009-1.236,0-1.854A1.559,1.559,0,0,1,61.1.127a1.467,1.467,0,0,1,1.724.442q4.4,4.18,8.8,8.353a1.293,1.293,0,0,1,.012,2.146c-2.963,2.815-5.948,5.608-8.908,8.428a1.471,1.471,0,0,1-1.637.364,1.443,1.443,0,0,1-.921-1.474c-.015-1.236-.031-2.473,0-3.707.016-.538-.162-.694-.688-.682a20.08,20.08,0,0,0-7.081,1.121,4.9,4.9,0,0,0-3.272,4.908,14.758,14.758,0,0,0,.684,4.461,1.131,1.131,0,0,1-.063,1.095,1.031,1.031,0,0,1-1.573.1,11.552,11.552,0,0,1-2.876-3.392,10.631,10.631,0,0,1,.044-11.449A8.572,8.572,0,0,1,50.4,7.262a28.357,28.357,0,0,1,9.072-1.221c.576.007.751-.179.708-.733-.044-.572-.01-1.15-.009-1.725"
                        transform="translate(-36.21 0)"
                        fill="#528971"
                      />
                      <path
                        id="Tracé_27853"
                        data-name="Tracé 27853"
                        d="M14.014,51.452q-5.468,0-10.936,0A2.9,2.9,0,0,1,0,48.358Q0,37.487,0,26.616a2.926,2.926,0,0,1,3.079-3.1c2.387-.008,4.775,0,7.163,0,.442,0,.879.006,1.008.555s-.2.809-.639,1.028A10.35,10.35,0,0,0,7.89,27.086a1.372,1.372,0,0,1-1.086.428c-.7-.022-1.41.039-2.109-.019-.624-.051-.738.2-.734.763.022,3.5.011,6.991.011,10.487,0,2.686.012,5.372-.011,8.057,0,.52.131.692.674.69q9.337-.027,18.674,0c.553,0,.681-.178.663-.692-.036-1.065-.019-2.131-.008-3.2.011-1.024.264-1.238,1.262-1.12a4.634,4.634,0,0,0,1.637-.209c.72-.174,1.127.1,1.133.848.016,1.812.041,3.625-.006,5.435A2.944,2.944,0,0,1,24.95,51.45q-5.468.007-10.936,0"
                        transform="translate(0 -19.497)"
                        fill="#538972"
                      />
                    </g>
                  </svg>
                  <h2>
                    02
                    <br />
                    Partage l'invitation avec tes amis
                  </h2>
                  <p>
                    {
                      "Transfère le lien « invité » à tes amis les plus susceptibles d'être intéressés par les produits du live"
                    }
                  </p>
                </div>
                <div className="item">
                  <svg
                    id="Groupe_59908"
                    data-name="Groupe 59908"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="28.77"
                    viewBox="0 0 36 28.77"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25419" data-name="Rectangle 25419" width="36" height="28.77" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59907" data-name="Groupe 59907" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27854"
                        data-name="Tracé 27854"
                        d="M18.017,0H32.863C34.95,0,36,1.059,36,3.167q0,8.486,0,16.972c0,2.269-1.021,3.287-3.312,3.289q-6.071.006-12.141,0c-.734,0-.734,0-.734.758,0,1.01,0,1.01,1,1.01h8.574c.921,0,1.2.274,1.206,1.2,0,.441.008.882,0,1.323-.017.786-.294,1.053-1.094,1.053q-6.617,0-13.235,0-4.862,0-9.724,0c-.848,0-1.135-.277-1.157-1.127-.012-.46-.019-.921,0-1.381.036-.773.344-1.061,1.117-1.062,3.011,0,6.023-.015,9.034.012.539,0,.681-.154.665-.674-.034-1.106-.008-1.107-1.14-1.107q-5.869,0-11.738,0a3.611,3.611,0,0,1-2-.412A2.551,2.551,0,0,1,.013,20.785q-.025-9.09,0-18.18A2.7,2.7,0,0,1,2.827,0Q10.422,0,18.017,0m-.025,19.77c4.584,0,9.168-.009,13.753.012.519,0,.632-.149.63-.647q-.028-7.451,0-14.9c0-.512-.148-.647-.649-.647q-13.724.02-27.448,0c-.562,0-.714.179-.712.724q.027,7.393,0,14.786c0,.517.126.689.672.686,4.584-.022,9.168-.012,13.753-.013"
                        transform="translate(0 0)"
                        fill="#538972"
                      />
                    </g>
                  </svg>
                  <h2>
                    03
                    <br />
                    Assiste au live avec tes amis
                  </h2>
                  <p>Assiste au live et vois quels sont tes invités présents</p>
                </div>
                <div className="item">
                  <svg
                    id="Groupe_59910"
                    data-name="Groupe 59910"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="32.3"
                    viewBox="0 0 36 32.3"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25420" data-name="Rectangle 25420" width="36" height="32.3" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59909" data-name="Groupe 59909" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27855"
                        data-name="Tracé 27855"
                        d="M17.876,28.99a25.43,25.43,0,0,1-3.92-.341.861.861,0,0,0-.812.234,12.969,12.969,0,0,1-6.6,3.256,11.2,11.2,0,0,1-5.818-.554.933.933,0,0,1-.364-1.633,16.275,16.275,0,0,0,2.125-2.82,6.352,6.352,0,0,0,.723-1.917,2.543,2.543,0,0,0-.592-2.361A9.9,9.9,0,0,1,2.624,9.428a2.589,2.589,0,0,0,.541-2.55A9.946,9.946,0,0,0,.7,2.713C.6,2.6.5,2.488.4,2.374-.235,1.628-.063.969.859.651A11.668,11.668,0,0,1,9.631,1.1a12.266,12.266,0,0,1,3.335,2.157,1.238,1.238,0,0,0,1.159.347,22.91,22.91,0,0,1,5.711-.216c4.82.3,9.242,1.74,12.77,5.18,4.739,4.621,4.475,11.342-.542,15.678a19.243,19.243,0,0,1-9.789,4.381,29.356,29.356,0,0,1-4.4.362m-.22-3.163a21.728,21.728,0,0,0,4.652-.429,15.625,15.625,0,0,0,8.154-4.22,6.658,6.658,0,0,0,.593-8.91,13.115,13.115,0,0,0-6.3-4.261,21.838,21.838,0,0,0-9.9-.917,16.559,16.559,0,0,0-8.342,3.249A7.329,7.329,0,0,0,3.163,16.4a6.9,6.9,0,0,0,2.65,5.361A2.96,2.96,0,0,1,6.761,24.4a9.028,9.028,0,0,1-1.2,4.1c-.069.128-.2.263-.086.407.094.12.237.018.36,0a7.992,7.992,0,0,0,1.8-.455,11.355,11.355,0,0,0,4.146-2.969.875.875,0,0,1,.963-.271,18.918,18.918,0,0,0,4.911.615"
                        transform="translate(0 0)"
                        fill="#538972"
                      />
                      <path
                        id="Tracé_27856"
                        data-name="Tracé 27856"
                        d="M133.536,86.841a2.133,2.133,0,0,1-.026-4.265,2.216,2.216,0,0,1,2.195,2.159,2.179,2.179,0,0,1-2.168,2.106"
                        transform="translate(-108.814 -68.384)"
                        fill="#538972"
                      />
                      <path
                        id="Tracé_27857"
                        data-name="Tracé 27857"
                        d="M52.591,86.842a2.173,2.173,0,0,1-2.136-2.133,2.218,2.218,0,0,1,2.15-2.133,2.2,2.2,0,0,1,2.161,2.112,2.172,2.172,0,0,1-2.175,2.154"
                        transform="translate(-41.785 -68.385)"
                        fill="#538972"
                      />
                      <path
                        id="Tracé_27858"
                        data-name="Tracé 27858"
                        d="M93.007,86.841A2.131,2.131,0,0,1,90.841,84.7a2.164,2.164,0,0,1,2.14-2.12,2.133,2.133,0,0,1,.027,4.266"
                        transform="translate(-75.23 -68.385)"
                        fill="#538972"
                      />
                    </g>
                  </svg>
                  <h2>
                    04
                    <br />
                    Chat avec la marque et tes amis
                  </h2>
                  <p>Pose tes questions à la marque et échange avec tes amis grâce au chat</p>
                </div>
                <div className="item">
                  <svg
                    id="Groupe_59912"
                    data-name="Groupe 59912"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="32.058"
                    viewBox="0 0 36 32.058"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25421" data-name="Rectangle 25421" width="36" height="32.057" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59911" data-name="Groupe 59911" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27859"
                        data-name="Tracé 27859"
                        d="M14.567,26.136A3.516,3.516,0,1,1,8.8,29.981a3.42,3.42,0,0,1,.924-4.07,1.017,1.017,0,0,0,.4-1.184C9.352,21.035,8.6,17.335,7.847,13.638c-.61-2.987-1.231-5.973-1.813-8.966-.105-.541-.348-.681-.858-.669-1.174.029-2.35.02-3.524.005A1.472,1.472,0,0,1,0,2.336c0-.277-.01-.556,0-.833A1.481,1.481,0,0,1,1.537.018q3.172-.037,6.344,0c.875.01,1.423.614,1.6,1.619.116.65.278,1.293.378,1.945.054.35.219.482.55.469.192-.007.385,0,.577,0H34.125c1.624,0,2.129.669,1.762,2.271q-1.414,6.173-2.8,12.351c-.249,1.1-.581,1.36-1.735,1.361q-8.588,0-17.175,0c-.886,0-.886,0-.738.849.2,1.14.2,1.14,1.315,1.14q7.466,0,14.932,0A5.682,5.682,0,0,1,30.7,22.1a1.488,1.488,0,0,1,1.225,1.679,1.007,1.007,0,0,1-.051.25,1.487,1.487,0,0,0,.641,2.09,3.214,3.214,0,0,1,.835,3.632,3.621,3.621,0,0,1-2.922,2.286,3.371,3.371,0,0,1-3.457-1.833,3.29,3.29,0,0,1,.313-3.784c.051-.067.087-.146.17-.288Z"
                        transform="translate(0 0)"
                        fill="#528971"
                      />
                    </g>
                  </svg>
                  <h2>
                    05
                    <br />
                    Achète en direct
                  </h2>
                  <p>
                    Tes amis et toi utilisent le code achat affiché pendant le live pour acheter en direct les produits
                    qui t'intéressent
                  </p>
                </div>
                <div className="item">
                  <svg
                    id="Groupe_59914"
                    data-name="Groupe 59914"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="31.529"
                    viewBox="0 0 36 31.529"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect id="Rectangle_25422" data-name="Rectangle 25422" width="36" height="31.529" fill="none" />
                      </clipPath>
                    </defs>
                    <g id="Groupe_59913" data-name="Groupe 59913" clipPath="url(#clipPath)">
                      <path
                        id="Tracé_27860"
                        data-name="Tracé 27860"
                        d="M17.986,18.037q-7.987,0-15.973,0a8.132,8.132,0,0,1-1-.058A1.039,1.039,0,0,1,0,16.812C.008,14.941-.011,13.07.009,11.2A2.178,2.178,0,0,1,1.942,9.052,16.068,16.068,0,0,1,4.6,9c.591.013.61-.2.447-.669A6.094,6.094,0,0,1,8.336.533a6.59,6.59,0,0,1,7.077,1.284,16.791,16.791,0,0,1,2.22,2.445c.327.439.528.4.847-.014A13.084,13.084,0,0,1,21.98.876a6.371,6.371,0,0,1,7.259.577,6,6,0,0,1,1.837,6.82c-.234.625-.107.731.492.722a15.28,15.28,0,0,1,2.37.051,2.226,2.226,0,0,1,2.04,2.215c.034,1.822.023,3.646,0,5.469-.009.84-.321,1.156-1.16,1.269a6.461,6.461,0,0,1-.861.037q-7.987,0-15.973,0M13.3,8.984c.935,0,1.87-.01,2.8,0,.491.007.7-.083.355-.59a33.428,33.428,0,0,0-2.486-3.342A4.484,4.484,0,0,0,11.13,3.395,2.822,2.822,0,0,0,7.982,5.94a2.779,2.779,0,0,0,2.587,3.037c.91.03,1.821.005,2.732.006m9.433,0v0c.935,0,1.872.022,2.806-.006A2.648,2.648,0,0,0,27.991,6.99a2.814,2.814,0,0,0-1.255-3.239,3.234,3.234,0,0,0-3.624.375A24.568,24.568,0,0,0,19.6,8.483c-.317.457-.063.507.329.5.935-.008,1.871,0,2.806,0"
                        transform="translate(0.001 0)"
                        fill="#528972"
                      />
                      <path
                        id="Tracé_27861"
                        data-name="Tracé 27861"
                        d="M111.879,105.468c2.016,0,4.032.015,6.047-.01.536-.007.756.105.748.707-.033,2.64-.007,5.28-.017,7.919A2.325,2.325,0,0,1,116.1,116.7c-3.479.033-6.959.006-10.438.019-.395,0-.55-.1-.548-.521q.02-5.076,0-10.151c0-.511.219-.589.65-.586,2.039.014,4.079.006,6.119.006"
                        transform="translate(-84.915 -85.196)"
                        fill="#528972"
                      />
                      <path
                        id="Tracé_27862"
                        data-name="Tracé 27862"
                        d="M18.271,105.459c1.991,0,3.983.016,5.974-.01.537-.007.753.107.747.707q-.044,4.931,0,9.862c.006.6-.2.7-.741.7-3.311-.023-6.622-.008-9.933-.013-2.042,0-2.879-.85-2.881-2.892,0-2.543.016-5.087-.013-7.63-.007-.569.154-.743.727-.734,2.039.032,4.079.012,6.118.012"
                        transform="translate(-9.231 -85.187)"
                        fill="#528972"
                      />
                    </g>
                  </svg>
                  <h2>
                    06
                    <br />
                    Sois récompensé
                  </h2>
                  <p>
                    En fonction de ce qui a été défini avant le live,la marque peut te récompenser par des
                    contreparties.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className={classes.footer}>
        <div className="container">
          <h2>
            <span>[</span> CONTACT <span>]</span>
          </h2>
          <a href="mailto:Hellolivood@livood.com">Hellolivood@livood.com</a>
          <p>Uncle</p>
          <p>
            5 Avenue Claude Vellefaux <br /> Paris
          </p>
        </div>
      </footer>
    </>
  );
};

export default NewHomePage;
