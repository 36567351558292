import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import { logout } from '../modules/logout';
import { GET_ONLY_PRODUCT_REQUEST, get_ProductByIdFailed, get_ProductByIdSuccess } from '../modules/product/productget';
import { Set_state_snackbar } from '../modules/state/stateMessage';

export function* watchgetProductById() {
  yield takeEvery(GET_ONLY_PRODUCT_REQUEST, getProductById);
}

function* getProductById({ payload }: any) {
  try {
    const { data } = yield call(api.product.ProductById, payload);
    yield put(get_ProductByIdSuccess(data));
  } catch (error: any) {
    const { status, message } = error.response;
    yield put(get_ProductByIdFailed({ message: message, status: status }));
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: 'Erreur de serveur! Merci de vérifier votre connexion.',
              open: true,
              type: 'error',
            }),
          );
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );

            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
