import { Box, Button, createMuiTheme, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import { AUTH_INSCRIPTION, RESETPASSWORD } from '../../constant/path';
import { isEmailValid } from '../../lib/auth/authvalidate';
import { RootState } from '../../redux-services';
import { IgetLive } from '../../redux-services/modules/liveSessionConsumerget';
import { doJoinLiveSession, IResultLiveJoin } from '../../redux-services/modules/liveSessionJoin';
import { IResultLoginSAGA, loginStart } from '../../redux-services/modules/login';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import { useStyles } from './style';

const MsgError400 = 'Votre email, votre identifiant ou votre mot de passe est incorrect';
const MsgInvalidEmail = 'Mail format invalide ';
const MsgError401 = "Votre email n'a pas encore été confirmer, merci de vérifier";
interface IFieldState {
  value: string;
  error: boolean;
  errorText?: string;
}

interface loginProps {
  email: IFieldState;
  password: IFieldState;
}
const initialLoginState: loginProps = {
  email: { value: '', error: false, errorText: '' },
  password: { value: '', error: false, errorText: '' },
};

const LoginConsumerScreen = () => {
  const history = useHistory();
  const { id, idInvitation, idAffiliation } = useParams<IgetLive>();
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const liveSessionJoinned = useSelector<RootState, IResultLiveJoin>((state) => state.liveSessionJoinned);
  const { loading, token, status } = login;

  const style = useStyles();
  const dispatch = useDispatch();
  const [input, setInput] = useState<loginProps>(initialLoginState);
  const [disabled, setdisabled] = useState<boolean>(true);
  const [errorInput, setErrorInput] = useState(false);
  const [msgError, setMsgError] = useState(MsgError400);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#4B7FD5',
      },
    },
  });

  const handleBlur = (key: keyof loginProps) => {
    if (input[key].value === '') {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: true,
          errorText: 'champ obligatoire',
        },
      });
    } else {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: false,
          errorText: '',
        },
      });
    }
  };

  const handleClick = () => {
    let isValidate = true;
    if (input.email.value === '') {
      setInput({ ...input, email: { ...input.email, errorText: 'Champ obligatoire', error: true } });
    } else if (input.password.value === '') {
      setInput({ ...input, password: { ...input.password, errorText: 'Champ obligatoire', error: true } });
    }
    if (input.email.value === '' || input.password.value === '') isValidate = false;

    if (isValidate) {
      dispatch(loginStart({ password: input.password.value, userType: 'Consumer', username: input.email.value }));
    } else {
      handleBlur('password');
      handleBlur('email');
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter' && !disabled) {
      handleClick();
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    setErrorInput(false);
    switch (name) {
      case 'email': {
        if (!isEmailValid(value)) {
          setErrorInput(true);
          setMsgError(MsgInvalidEmail);
          setInput({
            ...input,
            email: { value, error: true, errorText: 'Mail format invalide ' },
          });
        } else {
          setInput({
            ...input,
            email: { value, error: false, errorText: '' },
          });
        }
        break;
      }
      case 'password': {
        setInput((prevstate) => ({
          ...prevstate,
          [name]: { ...(prevstate as any)[name], value, error: false, errorText: '' },
        }));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (id) {
      authSevices.getAccessToken().then((_token) => {
        if (_token) {
          authSevices
            .getNameRetailer()
            .then((name) => {
              if (name) {
                dispatch(doJoinLiveSession({ id, name, userType: 'Consumer' }));
              }
            })
            .then(() => {
              // history.push((`/LiveCurrent/${id}` + urlBase).trim());
            });
        }
      });
    } else {
      if (token) {
        history.push('/');
      }
    }
  }, [token, id]);

  useEffect(() => {
    if (id) {
      const urlBase = idAffiliation ? `/af/${idAffiliation}` : idInvitation ? `/vp/${idInvitation}` : '';
      liveSessionServices.getParticipantByLive(id, 'Consumer').then((info) => {
        console.log('info :>> ', info);
        if (info && info?.participantId) {
          history.push((`/LiveCurrent/${id}` + urlBase).trim());
        }
      });
    }
  }, [liveSessionJoinned, idInvitation, idAffiliation, id]);

  useEffect(() => {
    if (input.email.value && input.password.value) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  }, [input.email.value, input.password.value]);
  useEffect(() => {
    if (status && status !== 200) {
      if (status === 401) {
        setMsgError(MsgError401);
      } else {
        setMsgError(MsgError400);
      }
      setErrorInput(true);
    }
  }, [status]);

  return (
    <Box className={style.consumerBg}>
      <Typography className={`${style.consumerLoginTitle} _mobileView`}>LIVOOD</Typography>
      {loading && <CustomBackdrop {...{ open: loading }} />}
      <Box className={style.root}>
        <Box className={style.consummerInstruction}>
          <Typography>
            Vous êtes <u>participant au live</u>
            <br className="_mobileView" />
            <b> (VIP, invité...)</b>
          </Typography>
        </Box>
        <Box className={style.cadre}>
          <div className={style.logo}>
            <Typography variant="h6">
              <span> Authentification </span>
            </Typography>
          </div>

          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'email',
                label: 'Email',
                type: 'email',
                autoComplete: 'new-email',
                variant: 'outlined',
                value: input.email.value,
                error: input.email.error,
                onBlur: () => handleBlur('email'),
                onChange: (e) => handleChange(e),
                onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            {input.email.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.email.errorText}</Typography>
            )}
          </Box>
          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'password',
                label: 'Mot de passe',
                type: 'password',
                autoComplete: 'new-password',
                onBlur: () => handleBlur('password'),
                value: input.password.value,
                error: input.password.error,
                variant: 'outlined',
                onKeyDown: (e) => onKeyPressed(e),
                onChange: (e) => handleChange(e),
              }}
            />
            {input.password.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.password.errorText}</Typography>
            )}
          </Box>

          <ThemeProvider theme={theme}>
            <Link to={RESETPASSWORD} style={{ textDecoration: 'none', color: theme.palette.primary.light }}>
              Mot de passe oublié
            </Link>

            {errorInput && <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{msgError}</Typography>}
            <Button
              {...{ disabled, style: { marginTop: 20, color: theme.palette.primary.light } }}
              onClick={() => {
                handleClick();
              }}
            >
              <Typography>Se connecter</Typography>
            </Button>
            <Typography>Vous n'êtes pas encore membre?</Typography>
            <Link
              to={{
                pathname: AUTH_INSCRIPTION,
                state: {
                  isConsumer: true,
                },
              }}
              style={{ textDecoration: 'none', color: theme.palette.primary.light }}
              target="_blank"
            >
              Inscrivez-vous maintenant
            </Link>
          </ThemeProvider>
        </Box>
        <Box className={style.consummerInstructionFooter}>
          <Box>
            <Typography>
              Si vous êtes l'animateur du live <a href="/auth/retailer"> cliquez ici</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginConsumerScreen;
