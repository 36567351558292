import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import { product_updated, product_update_failed, UPDATE_PRODUCT_REQUEST } from '../modules/product/productUpdate';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction, ErrorFunctionUtils } from './utils/function';

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT_REQUEST, UpdateProduct);
}

function* UpdateProduct({ payload }: any) {
  try {
    const { data } = yield call(api.product.UpdateProduct, payload);
    yield put(product_updated({ status: 200, id: data, message: 'Opération effectuée avec succès' }));
    yield put(
      Set_state_snackbar({
        message: 'Opération effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { status } = error.response;
    const message = error.message;
    // const errorMessage = { status, message };
    yield put(product_update_failed({ status: status, message: 'Failed' }));
    yield call(ErrorFunction, error);
  }
}
