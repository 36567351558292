import { typedAction } from '../../utils';

export const SEND_MAIL_RESET_PASSWORD_REQUESTED = 'SEND/MAIL/RESET/PASSWORD/REQUESTED';
export const SEND_MAIL_RESET_PASSWORD_SUCCESS = 'SEND/MAIL/RESET/PASSWORD/SUCCESS';
export const SEND_MAIL_RESET_PASSWORD_FAILED = 'SEND/MAIL/RESET/PASSWORD/FAILED';

export interface IparamsSendEmailResetPass {
  userType: string;
  email: string;
}

export interface InitialstateSendEmailResetPassword {
  loading: boolean;
  status: number;
}

const initialState: InitialstateSendEmailResetPassword = {
  loading: false,
  status: 0,
};

export const DO_sendEmailResetPassword = (params: IparamsSendEmailResetPass) =>
  typedAction(SEND_MAIL_RESET_PASSWORD_REQUESTED, params);
export type IDO_sendEmailResetPassword = typeof DO_sendEmailResetPassword;

export const sendEmailResetPasswordSuccefull = () => typedAction(SEND_MAIL_RESET_PASSWORD_SUCCESS);
export type IsendEmailResetPasswordSuccefull = typeof sendEmailResetPasswordSuccefull;

export const sendEmailResetPasswordFailed = () => typedAction(SEND_MAIL_RESET_PASSWORD_FAILED);
export type IsendEmailResetPasswordFailed = typeof sendEmailResetPasswordFailed;

export type sendEmailResetPasswordAction = ReturnType<
  IDO_sendEmailResetPassword | IsendEmailResetPasswordSuccefull | IsendEmailResetPasswordFailed
>;

export const sendEmailResetPasswordReducer = (
  state: InitialstateSendEmailResetPassword = initialState,
  action: sendEmailResetPasswordAction,
): InitialstateSendEmailResetPassword => {
  switch (action.type) {
    case SEND_MAIL_RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        loading: true,
        status: 0,
      };
    case SEND_MAIL_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 200,
      };
    case SEND_MAIL_RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        status: 400,
      };
    default:
      return {
        ...state,
      };
  }
};
