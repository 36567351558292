import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../api';
import {
  RESET_PASSWORD_REQUESTED,
  resetPasswordSuccefull,
  resetPasswordFailed,
} from '../../modules/account/resetPassword';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { ErrorFunction } from '../utils/function';

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD_REQUESTED, resetPassword);
}

export function* resetPassword({ payload }: any) {
  try {
    const { status } = yield call(api.auth.resetPassword, payload.userType, payload.data);
    if (status === 200) {
      yield put(resetPasswordSuccefull());
    }
  } catch (error: any) {
    if (error.response) {
      yield put(resetPasswordFailed());
      const { status } = error.response;
      switch (status) {
        case 401:
          yield put(Set_state_snackbar({ status: status, message: 'Email not confirmed', open: true, type: 'error' }));
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Unkwown email', open: true, type: 'error' }));
          break;
        default:
          yield call(ErrorFunction, error);
          break;
      }
    } else {
      yield call(ErrorFunction, error);
    }
  }
}
