import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../api';
import {
  SEND_MAIL_RESET_PASSWORD_REQUESTED,
  sendEmailResetPasswordSuccefull,
  sendEmailResetPasswordFailed,
} from '../../modules/account/sendEmailResetPassword';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { ErrorFunction } from '../utils/function';

export function* watchSendEmailResetPassword() {
  yield takeEvery(SEND_MAIL_RESET_PASSWORD_REQUESTED, sendEmailResetPassword);
}

export function* sendEmailResetPassword({ payload }: any) {
  try {
    const { status } = yield call(api.auth.sendEmailResetPassword, payload.userType, payload.email);
    if (status) {
      yield put(sendEmailResetPasswordSuccefull());
    }
  } catch (error: any) {
    if (error.response) {
      yield put(sendEmailResetPasswordFailed());
      const { status } = error.response;
      switch (status) {
        case 401:
          yield put(Set_state_snackbar({ status: status, message: 'Email not confirmed', open: true, type: 'error' }));
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Unkwown email', open: true, type: 'error' }));
          break;
        default:
          yield call(ErrorFunction, error);
          break;
      }
    } else {
      yield call(ErrorFunction, error);
    }
  }
}
