import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useStyles } from './styleAccountConsumer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-services';
import authSevices from '../../redux-services/services/authSevices';
import { useHistory } from 'react-router-dom';
import PasswordComponent from './password/PasswordComponent';
import { DO_updatePassword, InitialstateUpdatePassword } from '../../redux-services/modules/account/updatePassword';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import {
  IstateInitialUploadProfileImage,
  uploadProfileImage,
} from '../../redux-services/modules/account/consumer/uploadProfileImage';
import { setProfileImg } from '../../redux-services/modules/state/stateProfileImg';

interface IInfo {
  name: string;
  logoUrl: string;
  idImage?: string;
}

const AccountConsumerComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [isUpdate, setIsUpdate] = useState(false);
  // const [isUpdatePass, setIsUpdatePass] = useState(false);
  const [info, setInfo] = useState<IInfo>({ name: '', logoUrl: '' });
  const [isLocalSource, setIsLocalSource] = useState<boolean>(false);
  const [localImgFile, setLocalImgFile] = useState<File>();

  const { loading: loadingUpdatePassword, status } = useSelector<RootState, InitialstateUpdatePassword>(
    (state) => state.updatePassword,
  );
  const { loading: loadingUploadProfileImage, id: idImg } = useSelector<RootState, IstateInitialUploadProfileImage>(
    (state) => state.uploadConsumerProfileImage,
  );

  const OnChangeLogo = async (e: any) => {
    if (e.target.files[0]) {
      setIsLocalSource(true);
      setInfo({
        name: info.name,
        logoUrl: URL.createObjectURL(e.target.files[0]),
      });
      setLocalImgFile(e.target.files[0]);
    }
  };

  const OnValidateLogo = () => {
    console.log('Info', info.logoUrl, setLocalImgFile);
    if (isLocalSource && localImgFile) {
      dispatch(uploadProfileImage(localImgFile));
    }
  };

  const OnValidePassword = (pass: string) => {
    if (pass && pass !== '') {
      dispatch(DO_updatePassword({ userType: 'Consumer', password: pass }));
    }
  };

  useEffect(() => {
    (async () => {
      const _name = await authSevices.getNameRetailer();
      const _logo = await authSevices.getProfilConsumer();
      setInfo({ name: _name ?? info.name, logoUrl: _logo ?? info.logoUrl });
    })();
  }, []);

  useEffect(() => {
    if (!loadingUpdatePassword && status === 200) {
      // setIsUpdatePass(false);
    }
    if (!loadingUploadProfileImage && idImg) {
      // setIsUpdate(false);
      authSevices.setProfilConsumer(info.logoUrl);
      dispatch(setProfileImg({ url: info.logoUrl, name: info.name }));
      setIsLocalSource(false);
    }
  }, [loadingUpdatePassword, status, loadingUploadProfileImage]);

  return info.name === '' ? (
    <Box className={classes.container}>
      <Typography>I'm the account component.</Typography>
    </Box>
  ) : (
    <>
      <CustomBackdrop {...{ open: loadingUpdatePassword || loadingUploadProfileImage }} />
      <Grid container spacing={1} className={classes.containerWithData}>
        <Grid item md={9} className={classes.containerData}>
          <Grid item md={4}>
            <Typography variant="h5">Consumer information</Typography>
          </Grid>
          <Grid item md={6}>
            <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
              <Box style={{ display: 'flex', flex: 1, margin: 40, padding: 20, flexDirection: 'column' }}>
                <Box display="flex">
                  <input
                    // disabled={!isUpdate}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={OnChangeLogo}
                  />
                  <label htmlFor="icon-button-file" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{
                        border: '2px solid red',
                        width: '150px',
                        aspectRatio: '1',
                        overflow: 'hidden',
                        backgroundImage: `url(${info.logoUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      // disabled={!isUpdate}
                    >
                      <KeyboardArrowUpIcon />
                      <Typography variant="subtitle1" style={{ textTransform: 'lowercase' }}>
                        Photo
                      </Typography>
                    </Button>
                  </label>
                  <Box
                    flex={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent={'center'}
                    style={{ marginLeft: 20 }}
                  >
                    <TextField
                      style={{ display: 'flex', flexDirection: 'row' }}
                      inputProps={{ style: { width: 560 } }}
                      id="outlined-read-only-input"
                      label={
                        <Typography variant="button" style={{ color: 'red' }}>
                          {t('details.name')}
                        </Typography>
                      }
                      defaultValue={info.name}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Button className={classes.buttonSave} onClick={OnValidateLogo} disabled={!isLocalSource}>
                  Valider le changement du photo de profile
                </Button>
              </Box>

              <PasswordComponent isUpdate={!loadingUpdatePassword} OnValidePassword={OnValidePassword} />
            </Box>
          </Grid>
        </Grid>
        <Grid item md={3}>
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.buttonUpdate}
            onClick={() => {
              setIsUpdate(!isUpdate);
            }}
          >
            {t('productToSale.modify')} LOGO
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonUpdate}
            onClick={() => {
              setIsUpdatePass(!isUpdatePass);
            }}
          >
            {t('account.change.password')}
          </Button> */}
        </Grid>
      </Grid>
    </>
  );
};

export default AccountConsumerComponent;
