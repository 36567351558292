import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './messages/en-US';
import fr from './messages/fr-FR';

export const i18nextOptions = {
  lng: 'fr',
  resources: {
    fr: {
      translation: fr,
    },
    en: {
      translation: en,
    },
    // add languages here
  },

  nsSeparator: false as any,
  keySeparator: false as any,
  fallbackLng: false as any,

  // debug: true,
};

i18n.use(initReactI18next).init(i18nextOptions);
export default i18n;
