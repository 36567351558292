import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../../api';
import { Set_state_snackbar } from '../../../modules/state/stateMessage';
import { logout } from '../../../modules/logout';
import {
  UPLOAD_PROFILE_IMAGE_REQUEST,
  uploadProfileImageSuccess,
  uploadProfileImageFailed,
} from '../../../modules/account/consumer/uploadProfileImage';

export function* watchUploadProfileImage() {
  yield takeEvery(UPLOAD_PROFILE_IMAGE_REQUEST, uploadProfileImage);
}

export function* uploadProfileImage({ payload }: any) {
  try {
    const { status } = yield call(api.account.uploadConsumerProfileImage, payload);

    yield put(uploadProfileImageSuccess(status));
    yield put(
      Set_state_snackbar({
        status: status,
        message: "La modification de la photo de profile s'est déroulée avec succès.",
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { message } = error.response;
    yield put(uploadProfileImageFailed(message));
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: 'Erreur de serveur! Merci de vérifier votre connexion.',
              open: true,
              type: 'error',
            }),
          );
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
