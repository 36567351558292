import { typedAction } from '../../utils';
import { IdataCounterPart } from './guestCounterPartSet';

export const GET_GUEST_COUNTER_PART_REQUEST = 'GET/GUEST/COUNTER/PART/REQUEST';
export const GET_GUEST_COUNTER_PART_SUCCESS = 'GET/GUEST/COUNTER/PART/SUCCESS';
export const GET_GUEST_COUNTER_PART_FAILED = 'GET/GUEST/COUNTER/PART/FAILED';
export const CLEAR_GUEST_COUNTER_PART_REQUEST = 'CLEAR/GUEST/COUNTER/PART/FAILED';

export interface IinitialStateCounterPartInfo {
  loading: boolean;
  dataCounterPart: IdataCounterPart[];
  error: string | undefined;
}

const initialState: IinitialStateCounterPartInfo = {
  loading: false,
  dataCounterPart: [],
  error: undefined,
};

export const DO_getCounterPart = (id: string) => typedAction(GET_GUEST_COUNTER_PART_REQUEST, id);
export type IDO_getCounterPart = typeof DO_getCounterPart;

export const DO_clearGetCounterPart = () => typedAction(CLEAR_GUEST_COUNTER_PART_REQUEST);
export type IDO_clearGetCounterPart = typeof DO_clearGetCounterPart;

export const getCounterPartSuccess = (result: any) => typedAction(GET_GUEST_COUNTER_PART_SUCCESS, result);
export type IgetCounterPartSuccess = typeof getCounterPartSuccess;

export const getCounterPartFailed = () => typedAction(GET_GUEST_COUNTER_PART_FAILED);
export type IgetCounterPartFailed = typeof getCounterPartFailed;

export type counterPartActionInfo = ReturnType<
  IDO_getCounterPart | IgetCounterPartFailed | IgetCounterPartSuccess | IDO_clearGetCounterPart
>;

export const CounterPartInfoReducer = (
  state: IinitialStateCounterPartInfo = initialState,
  action: counterPartActionInfo,
): IinitialStateCounterPartInfo => {
  switch (action.type) {
    case GET_GUEST_COUNTER_PART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_GUEST_COUNTER_PART_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCounterPart: action.payload,
      };
    case GET_GUEST_COUNTER_PART_FAILED:
      return {
        ...state,
        error: 'error',
        loading: false,
      };
    case CLEAR_GUEST_COUNTER_PART_REQUEST:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};
