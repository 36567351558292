import { typedAction } from '../../utils';

export const GUEST_CONSUMER_REQUESTED = 'GUEST/CONSUMER/REQUESTED';
export const GUEST_CONSUMER_SUCCESS = 'GUEST/CONSUMER/SUCCESS';
export const GUEST_CONSUMER_FAILED = 'GUEST/CONSUMER/FAILED';

interface IdoGuesttoConsumer {
  idInvitation: string;
  liveId: string;
  participantId: string;
}
export interface IinitialStateGuestConsumer {
  loading: boolean;
}

const initialState: IinitialStateGuestConsumer = {
  loading: false,
};
export const Do_GuestConsumer = (params: IdoGuesttoConsumer) => typedAction(GUEST_CONSUMER_REQUESTED, params);
export type IDo_GuestConsumer = typeof Do_GuestConsumer;

export const GuestConsumerSuccess = () => typedAction(GUEST_CONSUMER_SUCCESS);
export type IGuestConsumerSuccess = typeof GuestConsumerSuccess;

export const GuestConsumerFailed = () => typedAction(GUEST_CONSUMER_FAILED);
export type IGuestConsumerFailed = typeof GuestConsumerFailed;

export type GuestConsumerAction = ReturnType<IDo_GuestConsumer | IGuestConsumerFailed | IGuestConsumerSuccess>;

export const GuestConsumerReducer = (
  state: IinitialStateGuestConsumer = initialState,
  action: GuestConsumerAction,
): IinitialStateGuestConsumer => {
  switch (action.type) {
    case GUEST_CONSUMER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GUEST_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GUEST_CONSUMER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
