import { typedAction } from '../../../utils';

export interface IstateInitialUploadProfileImage {
  id: string;
  loading: boolean;
  error: any;
}

export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAILED = 'UPLOAD_PROFILE_IMAGE_FAILED';

export const uploadProfileImage = (file: File) => typedAction(UPLOAD_PROFILE_IMAGE_REQUEST, file);
export type UploadProfileImage = typeof uploadProfileImage;

export const uploadProfileImageSuccess = (id: string) => typedAction(UPLOAD_PROFILE_IMAGE_SUCCESS, id);
export type UploadProfileImageSuccess = typeof uploadProfileImageSuccess;

export const uploadProfileImageFailed = (error: string) => typedAction(UPLOAD_PROFILE_IMAGE_FAILED, error);
export type UploadProfileImageFailded = typeof uploadProfileImageFailed;

export type UploadAction = ReturnType<UploadProfileImage | UploadProfileImageSuccess | UploadProfileImageFailded>;

const stateInitial: IstateInitialUploadProfileImage = {
  error: null,
  loading: false,
  id: '',
};

export const UploadProfileImageReducer = (
  state: IstateInitialUploadProfileImage = stateInitial,
  action: UploadAction,
): IstateInitialUploadProfileImage => {
  switch (action.type) {
    case UPLOAD_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        id: action.payload,
        loading: false,
      };
    case UPLOAD_PROFILE_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
