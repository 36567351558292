import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import CustomModal from '../../common/modal/Modal';
import { CustomSnackBar } from '../../common/snackBar/CustomSnackBar';
import { ACCOUNT_RETAILER, CREATELIVE, LIVE_SECTION, LIVE_STATISTIQUE } from '../../constant/path';
import { RootState } from '../../redux-services';
import { getInfoRetailer, IResultGetInfoRetailerSAGA } from '../../redux-services/modules/account/getInfoRetailer';
import { IResultSetLogoRetailerSAGA } from '../../redux-services/modules/account/setLogoRetailer';
import { doEndLiveSession } from '../../redux-services/modules/liveSessionEnd';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { IinitialStateLiveStart } from '../../redux-services/modules/liveSessionStart';
import { IinitialStateAffiliation, setAffiliationSaved } from '../../redux-services/modules/state/stateAffiliation';
import { IStateProducts } from '../../redux-services/modules/state/stateProducts';
import { ResetProfileImg } from '../../redux-services/modules/state/stateProfileImg';
import { IStateQuestion } from '../../redux-services/modules/state/stateSurvey';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import theme from '../../Themes';
import AccountIcon from '../AccountIcon/AccountIcon';
import LogoutIcon from '../LogoutIcon/LogoutIcon';
import ScheduleEventIcon from '../ScheduleEventIcon/ScheduleEventIcon';
import ScheduleLiveIcon from '../ScheduleLiveIcon/ScheduleLiveIcon';
import StatisticsIcon from '../StatisticsIcon/StatisticsIcon';
import { useStyles } from './style';

interface resultStatusBackDrop {
  message: string | '';
  type: 'error' | 'warning' | 'info' | 'success';
}

const SideBarComponent = () => {
  const dispatch = useDispatch();
  const { liveDetails: live } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const { adaptorAntMedia } = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);
  const [open, setOpen] = useState<boolean>(false);
  const [result, setresult] = useState<resultStatusBackDrop>({
    message: '',
    type: 'info',
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { id }: any = useParams();

  const [idParams, setIdParams] = useState<boolean>();
  const [openDialog, setopenDialog] = useState<boolean>(false);
  const [goToLink, setgoToLink] = useState<string>('');
  const [message, setMessage] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const isLivePreview = pathname.includes(`dashboard/livesessions/${id}/showlive`);

  const showLiveSessionColor = isLivePreview && live.primaryColor;
  const {
    autreInfo,
    checked,
    description,
    devise,
    livraisons,
    paysDeLivraisons,
    prixApres,
    prixAvant,
    productName,
    productUrl,
    stocks,
  } = useSelector<RootState, IStateProducts>((state) => state.stateProduct.stateProduct);
  const { name, question } = useSelector<RootState, IStateQuestion>((state) => state.stateQuestion.stateQuestion);
  const RetailerInfo = useSelector<RootState, IResultGetInfoRetailerSAGA>((state) => state.accountInfoRetailer);
  const SetedLogoRetailer = useSelector<RootState, IResultSetLogoRetailerSAGA>((state) => state.accountSetLogoRetailer);
  const StateContrePart = useSelector<RootState, IinitialStateAffiliation>((state) => state.stateAffiliation);

  const handleDisableClick = async (e: any, link?: string) => {
    if (link) {
      setgoToLink(link);
    }
    if (pathname === `/dashboard/livesessions/${id}/update`) {
      return await e.preventDefault();
    }

    if (
      autreInfo ||
      checked ||
      description ||
      devise ||
      livraisons ||
      paysDeLivraisons ||
      prixApres ||
      prixAvant ||
      productName ||
      productUrl ||
      stocks ||
      name ||
      question
    ) {
      setOpen(true);
      setresult({ message: t('common.warning.formulaire'), type: 'warning' });
      return await e.preventDefault();
    }

    if (pathname.includes(`dashboard/livesessions/${idParams}`) && live.actualStart && !live.actualEnd) {
      setMessage('Etes-vous sûr de stopper le live avant de quitter la page ?');
      setopenDialog(true);
      return await e.preventDefault();
    }
    if (pathname === `/dashboard/livesessions/${idParams}/parametre` && !StateContrePart.isSaved) {
      setMessage('Êtes-vous sûr de quitter sans valider les changements ?');
      setopenDialog(true);
      return await e.preventDefault();
    }
  };
  const onConfirmDialog = () => {
    dispatch(setAffiliationSaved(true));
    if (live.actualStart && StateContrePart.isSaved) {
      dispatch(doEndLiveSession({ id, adaptorRTC: adaptorAntMedia }));
    }
    history.push(goToLink);
    setopenDialog(false);
  };

  const onCloseDialog = () => {
    setopenDialog(false);
  };

  useEffect(() => {
    dispatch(getInfoRetailer());
  }, []);

  useEffect(() => {
    setIdParams(id);
  }, [id]);

  return (
    <>
      <CustomSnackBar {...{ open, setOpen, ...result }} />
      <CustomModal
        {...{
          open: openDialog,
          title: 'Confirmation',
          TextCancel: 'Annuler',
          onConfirm: onConfirmDialog,
          textConfirm: 'Confirmer',
          onClose: onCloseDialog,
        }}
      >
        <Typography>{message}</Typography>
      </CustomModal>
      <Box
        className={classes.sidebar}
        style={{ background: showLiveSessionColor ? live.primaryColor : theme.palette.primary.light }}
      >
        <Box className={classes.logo}>
          {(RetailerInfo.loading || SetedLogoRetailer.loading) && RetailerInfo.logoUrl === '' ? (
            <CircularProgress /> // <SideBarLogo />
          ) : (
            <img
              src={`${
                SetedLogoRetailer.logoUrl !== ''
                  ? SetedLogoRetailer.logoUrl
                  : RetailerInfo.logoUrl && RetailerInfo.logoUrl !== ''
                  ? RetailerInfo.logoUrl
                  : authSevices.getLogoRetailer()
              }`}
              className={classes.imageSrc}
            />
          )}
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: ' hidden auto',
          }}
        >
          <NavLink to={ACCOUNT_RETAILER} onClick={(event) => handleDisableClick(event, ACCOUNT_RETAILER)}>
            <Box className={classes.RootSideBar}>
              <AccountIcon />
              <Typography component="p" variant="subtitle1" className={classes.labelStyle}>
                {t('dashboard.account')}
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to={CREATELIVE}
            isActive={() => [CREATELIVE].includes(pathname)}
            onClick={(event) => handleDisableClick(event, CREATELIVE)}
          >
            <Box className={classes.RootSideBar}>
              <ScheduleEventIcon />
              <Typography component="p" variant="subtitle1" className={classes.labelStyle}>
                {t('dashboard.live.create')}
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to={LIVE_SECTION}
            onClick={(event) => handleDisableClick(event, LIVE_SECTION)}
            isActive={() =>
              [
                LIVE_SECTION,
                `/dashboard/livesessions/${id}`,
                `/dashboard/livesessions/${id}/showlive`,
                `/dashboard/livesessions/${id}/update`,
                `/dashboard/livesessions/${id}/products/addproduct`,
                `/dashboard/livesessions/${id}/showlive/questionnaire`,
                `/dashboard/livesessions/${id}/showlive/questionnaire/create`,
                `/dashboard/livesessions/${id}/products`,
              ].includes(pathname)
            }
          >
            <Box className={classes.RootSideBar}>
              <ScheduleLiveIcon />
              <Typography component="p" variant="subtitle1" className={classes.labelStyle}>
                {t('dashboard.live.details')}
              </Typography>
            </Box>
          </NavLink>
          <NavLink to={LIVE_STATISTIQUE} onClick={(event) => handleDisableClick(event, LIVE_STATISTIQUE)}>
            <Box className={classes.RootSideBar}>
              <StatisticsIcon />
              <Typography component="p" variant="subtitle1" className={classes.labelStyle}>
                {t('dashboard.live.statistique')}
              </Typography>
            </Box>
          </NavLink>
        </Box>
        <Box
          className={classes.logout}
          id="logout"
          onClick={() => {
            // history.push('/');
            // dispatch(logout());
            window.location.replace('https://livood.com/');
            dispatch(ResetProfileImg());
            authSevices.removeRetailerInfo();
            liveSessionServices.cleanParticipantByLive();
          }}
        >
          <LogoutIcon />
          <Typography component="p" variant="subtitle1" className={classes.labelStyle}>
            {t('dashboard.live.logout')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SideBarComponent;
