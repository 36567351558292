import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopView: {
      zIndex: 90,
      display: 'block',
      '@media(max-width:700px)': {
        display: 'none',
      },
    },
    mobileView: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'block',
      },
    },
    mobileValidationButton: {
      display: 'none',
      '@media(max-width:700px)': {
        display: 'flex',
        justifyContent: 'end',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        minHeight: '52px',
        alignItems: 'center',
        background: '#FAFAFA',
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      '& .MuiOutlinedInput-input': {
        padding: ' 8.5px 14px',
      },
    },
    rootDashboard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dateStyle: {
      padding: '10px',
      fontSize: 14,
      fontFamily: 'Open Sans',
      color: 'black',
    },
    selectOption: {
      padding: 0,
      '& .MuiInputBase-root': {
        fontSize: 14,
        fontFamily: 'Open Sans',
      },
    },
    inputPropsSelect: {
      padding: 10,
      '& .MuiOutlinedInput-input': {
        padding: 5,
      },
    },
    customSelect: {
      width: '125px',
    },
    selectCategorie: {
      width: '100%',
    },
    SelectVideo1: {
      width: '100%',
    },
    descriptions: {
      width: '100%',
      borderRadius: 4,
      padding: '10px',
      fontFamily: 'Open Sans',
      fontSize: 14,
      border: '1px solid #bdbdbd',
      boxSizing: 'border-box',
    },
    customSelectStatus: {
      width: '100%',
      '& >div': {
        fontSize: 14,
        fontFamily: 'Open Sans',
      },
    },
    ViewBoxColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rootBox: {
      display: 'flex',
      width: '100%',
      '& >div>h5': {
        marginLeft: 10,
      },
      '@media(max-width:980px)': {
        flexDirection: 'column',
        '& >div>h5': {
          marginLeft: 0,
        },
      },
    },
    boxduration: {
      display: 'flex',
      alignItems: 'center',
    },
    categorieError: {
      ' & .MuiFormControl-root': {
        width: '100%',
        border: '1px solid red',
        borderRadius: 5,
      },
    },
    categorieSuccess: {
      ' & .MuiFormControl-root': {
        width: '100%',
        borderRadius: 5,
      },
      '@media(max-width:980px)': {
        width: '100%!important',
      },
    },
    TimezoneError: {
      ' & .MuiFormControl-root': {
        width: '100%',
        border: '1px solid red',
        borderRadius: 5,
      },
    },
    TimezoneSuccess: {
      ' & .MuiFormControl-root': {
        width: '100%',
        borderRadius: 5,
      },
    },
    boxDate: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& >div': {
        width: '33.3333%',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& >div>div>div>div': {
        margin: 0,
      },
      '& >div>div>div>div>button': {
        padding: '0px 3px',
      },
      '@media(max-width:700px)': {
        flexDirection: 'column',
        alignItems: 'unset',
        '& >div': {
          width: '100%',
          marginLeft: '0px!important',
        },
        '& >div>div': {
          width: '100%',
          marginLeft: '0px!important',
        },
      },
    },
    textRequired: { marginTop: 0, color: 'red', fontSize: 12 },
    sectionOne: {
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
      '@media(max-width:600px)': {
        width: '100%',
      },
      '@media(min-width:1180px)': {
        // marginBottom: '150px !important',
      },
    },
    sectionTwo: {
      width: '25%',
      '@media(max-width:600px)': {
        width: '0%',
      },
    },
    col1: {
      width: '50%',
      paddingRight: 20,
      '@media(max-width:1250px)': {
        width: '40%',
      },
      '@media(max-width:980px)': {
        width: '100%',
      },
      '@media(max-width:700px)': {
        paddingRight: 0,
      },
    },
    col2: {
      width: '50%',
      '& >div': {
        marginBottom: 8,
      },
      '@media(max-width:1250px)': {
        width: '60%',
      },
      '@media(max-width:980px)': {
        width: '100%',
      },
    },
    btnValid: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      fontFamily: 'Open Sans',
      textTransform: 'capitalize',
      width: 90,
      '&:hover': {
        backgroundColor: '#345f4c',
      },
    },
    btnCancel: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: 'Open Sans',
      textTransform: 'capitalize',
      border: '1px solid #222222',
      marginLeft: 10,
      width: 90,
    },
    checkb: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      '& >span': {
        padding: 0,
        color: '#222222',
      },
    },
    btnImage: {},
    boxContent: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      '@media(max-width:700px)': {
        justifyContent: 'space-between',
      },
    },
    boxImageButton: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      '@media(max-width:700px)': {
        width: '47%',
        margin: 5,
      },
    },
    customPicker: {
      '& >div:nth-child(2)>div:nth-child(2) > div:nth-child(2)': {
        display: 'none',
      },
      '& div:nth-child(2) > div:nth-child(2) > div.flexbox-fix > div > div > label': {
        display: 'none !important',
      },
      width: '100% !important',
    },
    paper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      top: '25px',
      left: '-230px',
      '@media(max-width:700px)': {
        top: '-250px',
        left: '-230px',
      },
      '@media(max-height:858px)': {
        top: '-250px',
        left: '-230px',
      },
      position: 'fixed',
      borderRadius: 6,
      width: 270,
    },
  }),
);
