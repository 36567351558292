import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import './App.css';
import { API_URL, ID_TRACKING_CLIENT } from './lib/config';
import Main from './Main';
import authSevices from './redux-services/services/authSevices';
import { store } from './redux-services/store';
import i18n from './i18n/index';
import { BrowserRouter } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import liveSessionServices from './redux-services/services/liveSessionServices';
import CustomBackdrop from './common/backdrop/CustomBackdrop';

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = await authSevices.getAccessToken();
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  config.baseURL = API_URL;
  return config;
});

const App = () => {
  // store.dispatch(loginStart({ password: 'consumer01#210702', userType: 'Consumer', username: 'consumer01@gmail.com' })); // teste login consumer
  // store.dispatch(loginStart({ password: 'retailer1.user1#', userType: 'Retailer', username: 'user1@retailer1.com' })); // teste login Retailer
  const [loadCheckToken, setLoadCheckToken] = useState(true);

  const checkToken = () =>
    authSevices.getAccessToken().then((token) => {
      if (token) {
        const decoded = jwtDecode<JwtPayload>(token || '');
        const dateNow = new Date();
        if (decoded.exp && decoded.exp * 1000 < dateNow.getTime()) {
          console.log('Token expired.');
          authSevices.removeRetailerInfo();
          liveSessionServices.cleanParticipantByLive();
          setLoadCheckToken(false);
        } else {
          console.log('Valid token');
          setLoadCheckToken(false);
        }
      } else {
        setLoadCheckToken(false);
      }
    });

  useEffect(() => {
    checkToken();
    // ReactGA.initialize(ID_TRACKING_INTERNE);
    ReactGA.initialize(ID_TRACKING_CLIENT);
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  if (loadCheckToken) {
    return <CustomBackdrop open={loadCheckToken} />;
  }
  return (
    <I18nextProvider i18n={i18n}>
      <Provider {...{ store }}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
