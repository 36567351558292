import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomTextField from '../../../common/TextInput/CustomTextField';
import { useStyles } from '../styleAccountConsumer';

interface IPassword {
  oldpassword: string;
  newpassword: string;
}

interface IPropsPassword {
  isUpdate: boolean;
  OnValidePassword: (pass: string) => void;
}

const PasswordComponent: React.FC<IPropsPassword> = (props: IPropsPassword) => {
  const classes = useStyles();
  const { isUpdate, OnValidePassword } = props;
  const [password, setPassword] = useState<IPassword>({ oldpassword: '', newpassword: '' });

  useEffect(() => {
    if (!isUpdate) {
      setPassword({ oldpassword: '', newpassword: '' });
    }
  }, [isUpdate]);

  return (
    <Box
      style={{
        margin: 50,
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '80%',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <Box>
        <Typography
          {...{
            className: classes.titleChangPassword,
            style: {
              fontSize: 25,
            },
          }}
        >
          Changer le mot de passe
        </Typography>
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', height: 200, flexDirection: 'column' }}>
        <Box style={{ width: '750px' }}>
          <CustomTextField
            {...{
              disabled: !isUpdate,
              type: 'password',
              name: 'Mot de passe',
              placeholder: 'Mot de passe',
              textError: 'Obligatoire',
              label: 'Mot de passe',
              require: false,
              value: password.oldpassword,
              onChange: (e) => {
                setPassword((prev) => {
                  return { ...prev, oldpassword: e.target.value };
                });
              },
            }}
          />
        </Box>
        <Box style={{ width: '750px' }}>
          <CustomTextField
            {...{
              disabled: !isUpdate,
              type: 'Password',
              name: 'Confirmer Mot de passe',
              placeholder: 'Confirmer Mot de passe',
              textError: 'Obligatoire',
              require: false,
              value: password.newpassword,
              onChange: (e) => {
                setPassword((prev) => {
                  return { ...prev, newpassword: e.target.value };
                });
              },
            }}
          />
        </Box>
        {password.newpassword !== password.oldpassword && (
          <Typography style={{ margin: 17, color: 'red', fontSize: 12 }}>
            La confirmation du mot de passe est invalide
          </Typography>
        )}
      </Box>
      <Box>
        {/* <Button color="secondary" style={{ borderWidth: 1, backgroundColor: 'whitesmoke' }}>
          <Typography style={{ color: '#000', padding: '0px 10px 0px 10px' }}>Annuler</Typography>
        </Button> */}
        <Button
          className={classes.buttonSave}
          disabled={
            !isUpdate ||
            password.newpassword !== password.oldpassword ||
            (password.newpassword === password.oldpassword && password.newpassword === '')
          }
          onClick={(e: any) => {
            OnValidePassword(password.newpassword);
          }}
        >
          Valider le changement du mot de passe
        </Button>
      </Box>
    </Box>
  );
};
export default PasswordComponent;
