import authSevices from '../redux-services/services/authSevices';

export const GetTimeFromMins = (mins: number) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.');
  }
  const h = (mins / 60) | 0,
    m = mins % 60 | 0;
  const date = new Date(new Date().setHours(h)).setMinutes(m);

  return date;
};

export const FormatedTime = (mins: number): string => {
  const min =
    Math.trunc(mins / 60).toString().length === 1
      ? '0' + Math.trunc(mins / 60).toString()
      : Math.trunc(mins / 60).toString();
  const sec = (mins % 60).toString().length === 1 ? '0' + (mins % 60).toString() : (mins % 60).toString();
  return `${min}:${sec}`;
};

export function CapitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
