import * as React from 'react';

function StatisticsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5596 1V14.4401H31.9997C31.9997 12.6751 31.6521 10.9274 30.9766 9.29681C30.3012 7.66618 29.3112 6.18455 28.0632 4.93652C26.8151 3.68849 25.3335 2.6985 23.7029 2.02307C22.0723 1.34764 20.3246 1 18.5596 1V1Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4244 5.1355C12.2153 5.13809 10.0409 5.68517 8.09357 6.72833C6.14628 7.77149 4.48614 9.27856 3.26006 11.1162C2.03398 12.9538 1.27977 15.0653 1.06417 17.2639C0.848564 19.4624 1.17822 21.6803 2.02395 23.7211C2.86969 25.7619 4.20543 27.5627 5.91298 28.9643C7.62053 30.3659 9.64724 31.3249 11.8138 31.7566C13.9803 32.1883 16.2198 32.0793 18.3341 31.4393C20.4485 30.7992 22.3725 29.6479 23.9358 28.0871L14.4244 18.5756V5.1355Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6274 18.5756L29.2084 26.7947C31.0294 24.4429 32.0119 21.5499 31.9999 18.5756H20.6274Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StatisticsIcon;
