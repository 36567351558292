import { typedAction } from '../../utils';

export const DO_PROFILE_IMG_SET = 'PROFILE/IMG/SET';
export const DO_PROFILE_IMG_RESET = 'PROFILE/IMG/RESET';

export interface IinitialProfileImg {
  url: string | null;
  name: string | null;
}
const initialState: IinitialProfileImg = {
  url: '',
  name: '',
};

export const setProfileImg = (payload: { url: string; name: string }) => typedAction('PROFILE/IMG/SET', payload);
export type IsetProfileImg = typeof setProfileImg;

export const ResetProfileImg = () => typedAction('PROFILE/IMG/RESET');
export type IresetProfileImg = typeof ResetProfileImg;

export type profileImgAction = ReturnType<IsetProfileImg | IresetProfileImg>;

export const profileImgReducer = (
  state: IinitialProfileImg = initialState,
  action: profileImgAction,
): IinitialProfileImg => {
  switch (action.type) {
    case DO_PROFILE_IMG_SET:
      return {
        ...state,
        url: action.payload.url,
        name: action.payload.name,
      };
    case DO_PROFILE_IMG_RESET:
      return {
        ...state,
        url: '',
        name: '',
      };
    default:
      return {
        ...state,
      };
  }
};
