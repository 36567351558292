import React, { FC, ReactNode } from 'react';
import { useStyles } from './TabPannel.style';
import { Tabs as MUITabs, Tab } from '@material-ui/core';

export interface TabPanelProps {
  index: number;
  value: number;
  children?: ReactNode;
}

export interface TabsData {
  label: string;
  children: ReactNode;
}

export interface TabsProps {
  data: TabsData[];
  indicatorPosition?: 'top' | 'bottom';
  indicatorColor?: string;
}

const TabPanel: FC<TabPanelProps> = ({ index, value, children, ...restProps }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...restProps}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs: FC<TabsProps> = ({ data, indicatorPosition = 'bottom', indicatorColor }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <MUITabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{
          className: `MuiTabs-indicator ${indicatorPosition}`,
          style: { background: indicatorColor, height: 4 },
        }}
      >
        {data.map((d, index) => {
          return <Tab key={`${index}-tab`} label={d.label} {...a11yProps(index)} />;
        })}
      </MUITabs>
      {data.map((d, index) => {
        return (
          <TabPanel key={`${index}-tabpanel`} value={value} index={index}>
            {d.children}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default Tabs;
