import { takeEvery, put, call } from 'redux-saga/effects';
import { AdvantageSuccess, ADVANTAGE_REQUESTED, AdvantageFailed } from '../../modules/participant/advantageCode';
import Api from '../../api';
import { Set_state_snackbar } from '../../modules/state/stateMessage';
import { logout } from '../../modules/logout';

export function* watchAdvantageCode() {
  yield takeEvery(ADVANTAGE_REQUESTED, AdvantageFunction);
}

export function* AdvantageFunction({ payload }: any) {
  try {
    const { data } = yield call(Api.participant.GetAffiliationInfo, payload);
    yield put(AdvantageSuccess(data));
  } catch (e: any) {
    if (e.response) {
      yield put(AdvantageFailed());
      const { status, title } = e.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'BadDDDDDD Request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Bad request', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message, status } = e;
      console.log(`message`, e);
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({
                status: 503,
                message: 'please! check your Connexion&&&&&&',
                open: true,
                type: 'error',
              }),
            );
            break;
          default:
            yield put(
              Set_state_snackbar({
                status: 503,
                message: `please! check your Connexionrggregegregerg `,
                open: true,
                type: 'error',
              }),
            );
            break;
        }
      }
    }
  }
}
