import { typedAction } from '../../utils';

export interface IStateQuestion {
  name: string;
  time: Date;
  question: string;
  type: number;
}

export const STATE_QUESTION = 'State/questionnaire';
export const STATE_INITIAL_QUESTION = 'State/ResetStateQuestionnaire';

interface IinitialStateQuestion {
  stateQuestion: IStateQuestion;
}

export const initialStateQuestion: IinitialStateQuestion = {
  stateQuestion: {
    name: '',
    question: '',
    time: new Date(),
    type: 0,
  },
};

export const setStateQuestion = (args: { [key: string]: string }) => typedAction(STATE_QUESTION, args);
export type SetStateQuestion = typeof setStateQuestion;

export const ResetStateQuestion = () => typedAction(STATE_INITIAL_QUESTION);
export type IResetStateQuestion = typeof ResetStateQuestion;

type QUESTIONACTION = ReturnType<SetStateQuestion | IResetStateQuestion>;

export const QuestionStateReducers = (state = initialStateQuestion, action: QUESTIONACTION): IinitialStateQuestion => {
  switch (action.type) {
    case STATE_QUESTION:
      return {
        ...state,
        stateQuestion: { ...state.stateQuestion, ...action.payload },
      };
    case STATE_INITIAL_QUESTION:
      return {
        ...state,
        stateQuestion: { ...initialStateQuestion.stateQuestion },
      };
    default:
      return {
        ...state,
      };
  }
};
