export default {
  'details.live': 'Live programming',
  'details.product.sales': 'Produits for sales',
  'details.name': 'Name',
  'details.timezone': 'TimeZone',
  'details.date': 'Date',
  'details.time': 'Time',
  'details.status': 'Status',
  'details.miniature': 'Miniature of the live',
  'details.invitation.text': 'Invited people',
  'details.showlive.mobile.title': 'The live',
};
