import {
  GET_SESSION_DETAILS_REQUEST,
  liveSessionDetailsFailed,
  liveSessionDetailsSuccess,
} from '../modules/liveSessionRetailerDetails';
import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../api';
import { logout } from '../modules/logout';

export function* watchliveSessionDetails() {
  yield takeEvery(GET_SESSION_DETAILS_REQUEST, liveSessionDetails);
}

export function* liveSessionDetails({ payload }: any) {
  try {
    const { data } = yield call(Api.liveSessionRetailer.liveSessionDetails, payload);
    yield put(liveSessionDetailsSuccess(data));
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(liveSessionDetailsFailed({ status, error: 'bad request' }));
          break;
        case 401:
          yield put(
            liveSessionDetailsFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          yield put(logout());
          break;
        default:
          yield put(
            liveSessionDetailsFailed({ status, error: 'Votre session est expirée! Merci de vous reconnecter' }),
          );
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(liveSessionDetailsFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
          default:
            yield put(liveSessionDetailsFailed({ status: 503, error: 'Please! check yours Network' }));
            break;
        }
      }
    }
  }
}
