export enum AuthKeys {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const isEmailValid = (email: string): boolean => {
  const regexEmail =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  const valid = email !== undefined && regexEmail.test(email.trim());
  return valid;
};

export const isPasswordValid = (password: string) => {
  if (password) {
    return /^(?=.*\d)(?=.*[!@#$%^&*:=])(?=.*[a-z]).{12,}$/.test(password);
  }
};

// export const authValidator = (obj: { [key: string]: string }) => {
//   if (Object.entries(obj).length === 0) return;
//   const key = Object.keys(obj)[0];
//   switch (key) {
//     case AuthKeys.EMAIL:
//       if (obj[key] === '') return true;
//       // else if (!isEmailValid(obj[key])) return true;
//       else return false;

//     case AuthKeys.PASSWORD:
//       if (obj[key] === '') return true;
//       // if (!isPasswordValid(obj[key])) return true;
//       return false;
//     default:
//       return false;
//   }
// };
