import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../api';
import { logout } from '../../modules/logout';
import { guest_updated, guest_update_failed, UPDATE_GUEST_REQUEST } from '../../modules/guest/guestUpdate';
import { Set_state_snackbar } from '../../modules/state/stateMessage';

export function* watchUpdateGuest() {
  yield takeEvery(UPDATE_GUEST_REQUEST, UpdateGuest);
}

function* UpdateGuest({ payload }: any) {
  try {
    const { data } = yield call(api.guest.UpdateGuest, payload);
    yield put(guest_updated({ status: 200, id: data, message: 'Opération effectuée avec succès' }));
    yield put(
      Set_state_snackbar({
        message: 'Opération effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { status, message } = error.response;
    yield put(guest_update_failed({ status: status, message: message }));
    // yield put(Set_state_snackbar({ status: status, message: 'Erreur du Server', open: true, type: 'error' }));
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          yield put(Set_state_snackbar({ status: status, message: 'bad request', open: true, type: 'error' }));
          break;
        case 401:
          yield put(
            Set_state_snackbar({
              status: status,
              message: 'Votre session est expirée! Merci de vous reconnecter',
              open: true,
              type: 'error',
            }),
          );
          yield put(logout());
          break;
        case 500:
          yield put(
            Set_state_snackbar({
              status: 503,
              message: 'Erreur de serveur! Merci de vérifier votre connexion.',
              open: true,
              type: 'error',
            }),
          );
          break;
        case 404:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
        default:
          yield put(Set_state_snackbar({ status: status, message: 'Server Introuvable', open: true, type: 'error' }));
          break;
      }
    } else {
      const { message } = error;
      if (message) {
        switch (message) {
          case 'Network Error':
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );

            break;
          default:
            yield put(
              Set_state_snackbar({ status: 503, message: 'Please! check yours Network', open: true, type: 'error' }),
            );
            break;
        }
      }
    }
  }
}
