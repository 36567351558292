import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100%',
      // marginTop: 70,
      paddingTop: 0,
      '& >p': {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStyle: 'italic',
      },
    },
  }),
);
