import { typedAction } from '../utils';

export interface IstateInitialUpload {
  id: string;
  loading: boolean;
  error: any;
}

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';

export const uploadImage = (file: File) => typedAction(UPLOAD_IMAGE_REQUEST, file);
export type UploadImage = typeof uploadImage;

export const uploadImageSuccess = (id: string) => typedAction(UPLOAD_IMAGE_SUCCESS, id);
export type UploadImageSuccess = typeof uploadImageSuccess;

export const uploadImageFailed = (error: string) => typedAction(UPLOAD_IMAGE_FAILED, error);
export type UploadImageFailded = typeof uploadImageFailed;

export type UploadAction = ReturnType<UploadImage | UploadImageSuccess | UploadImageFailded>;

const stateInitial: IstateInitialUpload = {
  error: null,
  loading: false,
  id: '',
};

export const UploadImageReducer = (
  state: IstateInitialUpload = stateInitial,
  action: UploadAction,
): IstateInitialUpload => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        id: action.payload,
        loading: false,
      };
    case UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
