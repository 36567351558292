import React from 'react';
import { Box, Paper } from '@material-ui/core';
import Slider from 'react-slick';
import { useStyles } from './styles';
import { API_URL } from '../../lib/config';

interface ImageSliderComponentProps {
  images: string[];
  slidesToShow?: number;
}

const ImageSliderComponent: React.FC<ImageSliderComponentProps> = (props) => {
  const classes = useStyles();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: props.slidesToShow ?? 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className={classes.slider}>
      {props.images.map((image, indexKey) => (
        <ImageBox key={indexKey} src={image} />
      ))}
    </Slider>
  );
};

const ImageBox = (props: any) => {
  const classes = useStyles();
  return <Box className={classes.card}>{props.src ? <img alt={'hey'} src={props.src} /> : <div>j</div>}</Box>;
};

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
}

export default ImageSliderComponent;
