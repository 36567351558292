import { typedAction } from '../utils';
import { section, detailSection } from './liveSessionRetailerListBySection';

export interface IListEndedSectionRetailer {
  section: section;
  sessions: detailSection[];
}
export interface ListEndedLiveError {
  status: number;
  error: string;
}
export interface IinitialStateEndedSection {
  ListEndedLive: IListEndedSectionRetailer;
  error: ListEndedLiveError | undefined;
  loading: boolean;
}
export const initialStateEndedSection: IinitialStateEndedSection = {
  ListEndedLive: {
    section: {
      id: 0,
      name: 'Lives terminés',
    },
    sessions: [],
  },
  error: undefined,
  loading: false,
};

export const LIST_ENDED_SECTION_REQUEST = 'LIST_ENDED_SECTION_REQUEST';
export const LIST_ENDED_SECTION_SUCCESS = 'LIST_ENDED_SECTION_SUCCESS';
export const LIST_ENDED_SECTION_FAILED = 'LIST_ENDED_SECTION_FAILED';

export const getListEndedLive = () => typedAction(LIST_ENDED_SECTION_REQUEST);
export type GetListEndedLive = typeof getListEndedLive;

export const listEndedLiveSuccess = (Result: detailSection[]) => typedAction(LIST_ENDED_SECTION_SUCCESS, Result);
export type ListEndedLiveSuccess = typeof listEndedLiveSuccess;

export const listEndedLiveFailed = (error: ListEndedLiveError) => typedAction(LIST_ENDED_SECTION_FAILED, error);
export type ListEndedLiveFailed = typeof listEndedLiveFailed;

export type ActionListEndedLive = ReturnType<GetListEndedLive | ListEndedLiveSuccess | ListEndedLiveFailed>;

export const listEndedLiveReducer = (
  state: IinitialStateEndedSection = initialStateEndedSection,
  action: ActionListEndedLive,
): IinitialStateEndedSection => {
  switch (action.type) {
    case LIST_ENDED_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_ENDED_SECTION_SUCCESS:
      return {
        ...state,
        ListEndedLive: {
          section: {
            id: 0,
            name: 'Lives terminés',
          },
          sessions: action.payload,
        },
        error: undefined,
        loading: false,
      };
    case LIST_ENDED_SECTION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
