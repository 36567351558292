import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 184px)',
      // width: calc(100% - 104px);
      paddingLeft: '20px',
      paddingTop: 8,
      paddingBottom: 8,
      position: 'fixed',
      top: 0,
      zIndex: 99,
      backgroundColor: '#fafafa',
      right: 14,
      '@media(max-width:900px)': {
        width: 'calc(100% - 104px)',
      },
      '@media(max-width:425px)': {
        width: 'calc(100% - 64px)',
      },
      '@media(max-width:460px)': {
        paddingLeft: 12,
        paddingRight: 8,
      },
    },
    lists: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    customBreadCrumbs: {
      '&>ol>li': {
        margin: 0,
        '&>button': {
          padding: 0,
          fontSize: 14,
        },
      },
    },
  }),
);
