import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TFunction } from 'react-i18next';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { IconButton } from '@material-ui/core';
import { IdataGuest } from '../../redux-services/modules/guest/guestList';
import { useDispatch } from 'react-redux';
import { do_SetAdvantageCodes_guest } from '../../redux-services/modules/guest/guestAdvantageCodes';
import CustomSelect from '../SelectInput';
import { AdvanatageCodeType } from '../../interface/commonInterface';
import { validateUrl } from '../../utils/common';
interface IData extends IdataGuest {
  check: boolean;
}
interface IPropsModalSetCode {
  data: IData;
  t: TFunction<'translation'>;
}

const advantageOptions = [
  {
    label: 'Code Achat',
    value: 'advantageCode',
  },
  {
    label: 'Lien Boutique',
    value: 'shoppingUrl',
  },
];

const ModalSetadvantageCode: FC<IPropsModalSetCode> = (props: IPropsModalSetCode) => {
  const {
    data: { id, emailAddress, advantageCode, shoppingUrl },
    t,
  } = props;
  const [open, setOpen] = useState(false);
  const [showUrlInvalidError, setShowUrlInvalidError] = useState(false);
  const [value, setValue] = useState<string>('');
  const [selectedCodeValue, setSelectedCodeValue] = useState<AdvanatageCodeType>('advantageCode');
  const dispatch = useDispatch();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [open]);

  const handleSubmit = () => {
    if (selectedCodeValue === 'shoppingUrl' && !validateUrl(value)) {
      setShowUrlInvalidError(true);
      return;
    }
    dispatch(
      do_SetAdvantageCodes_guest([
        {
          guestId: id,
          ...(selectedCodeValue === 'advantageCode'
            ? {
                advantageCode: value,
              }
            : {
                shoppingUrl: value,
              }),
        },
      ]),
    );
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onChangeValue = useCallback((e) => {
    setShowUrlInvalidError(false);
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    advantageCode ? setSelectedCodeValue('advantageCode') : setSelectedCodeValue('shoppingUrl');
    setValue(advantageCode ? advantageCode : shoppingUrl ? shoppingUrl : '');
  }, [advantageCode, shoppingUrl]);

  const handleSelect = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      setSelectedCodeValue(event.target.value as any);
      setShowUrlInvalidError(false);
    },
    [],
  );

  const forminputTitle = useMemo(
    () => (selectedCodeValue === 'advantageCode' ? t('invitation.advantageCode.title') : t('invitation.shoppingUrl')),
    [selectedCodeValue],
  );

  const palceHolderTitle = useMemo(
    () =>
      selectedCodeValue === 'advantageCode' ? t('invitation.advantageCode.title') : t('invitation.shopingUrlExample'),
    [selectedCodeValue],
  );

  const dialogContent = useMemo(
    () =>
      selectedCodeValue === 'advantageCode'
        ? `${t('invitation.advantageCode.text')} ${emailAddress} :`
        : `${t('invitation.shoppingUrl')} pour le VIP :  ${emailAddress}`,
    [selectedCodeValue],
  );

  const helperText = useMemo(() => {
    if (!showUrlInvalidError) {
      return '';
    }
    return <p style={{ color: 'red' }}>{t('invitation.shopingUrlInvalid')}</p>;
  }, [showUrlInvalidError]);

  return (
    <div>
      <IconButton style={{ marginBottom: '-5px' }} onClick={handleClickOpen}>
        <BorderColorIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{forminputTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }}>{dialogContent}</DialogContentText>
          <CustomSelect
            labelName="Avantage"
            options={advantageOptions}
            handleSelect={handleSelect}
            selectedCodeValue={selectedCodeValue}
          />
          <TextField
            autoFocus
            margin="dense"
            id="advantageCode"
            label={forminputTitle}
            type="text"
            fullWidth
            value={value}
            onChange={onChangeValue}
            placeholder={palceHolderTitle}
            helperText={helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('action.close')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('formulaire.btn.validate')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ModalSetadvantageCode);
