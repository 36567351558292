import { Box, Button, createTheme, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBackdrop from '../../common/backdrop/CustomBackdrop';
import Header from '../../common/header/Header';
import { isEmailValid } from '../../lib/auth/authvalidate';
import { RootState } from '../../redux-services';
import { IgetLive, IinitialStateConsumerGet } from '../../redux-services/modules/liveSessionConsumerget';
import { doJoinLiveSession, IResultLiveJoin } from '../../redux-services/modules/liveSessionJoin';
import { IResultLoginSAGA, loginStart } from '../../redux-services/modules/login';
import { logout } from '../../redux-services/modules/logout';
import authSevices from '../../redux-services/services/authSevices';
import { useStyles } from './style';
import { ILiveStatus } from '../../redux-services/type/live';
import { liveSessionConsumerGet } from '../../redux-services/api/liveSessionAPi';
interface IFieldState {
  value: string;
  error: boolean;
  errorText?: string;
}

export interface LoginProps {
  email: IFieldState;
  password: IFieldState;
}
const initialLoginState: LoginProps = {
  email: { value: '', error: false, errorText: '' },
  password: { value: '', error: false, errorText: '' },
};

interface LoginConsumerProps {
  actualEnd: any;
}

const LoginConsumer = (props: LoginConsumerProps) => {
  const { actualEnd } = props;
  const history = useHistory();
  const { id, idInvitation, idAffiliation } = useParams<IgetLive>();
  const { t } = useTranslation();
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const { loading, token, username } = login;
  const style = useStyles();
  const dispatch = useDispatch();
  const [input, setInput] = useState<LoginProps>(initialLoginState);
  const [disabled, setdisabled] = useState<boolean>(true);
  const [inputIdentifiant, setInputIdentifiant] = useState<string>('');
  const [emptyIdentifiant, setEmptyIdentifiant] = useState<boolean>(false);
  const { participantId, loading: loadingJoinLive } = useSelector<RootState, IResultLiveJoin>(
    (state) => state.liveSessionJoinned,
  );
  const [userType, setUserType] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string | null>();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4B7FD5',
      },
    },
  });

  const handleBlur = (key: keyof LoginProps) => {
    if (input[key].value === '') {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: true,
          errorText: 'champ obligatoire',
        },
      });
    } else {
      setInput({
        ...input,
        [key]: {
          ...input[key],
          error: false,
          errorText: '',
        },
      });
    }
  };

  const handleClick = () => {
    let isValidate = true;
    if (input.email.value === '') {
      setInput({ ...input, email: { ...input.email, errorText: 'Champ obligatoire', error: true } });
    } else if (input.password.value === '') {
      setInput({ ...input, password: { ...input.password, errorText: 'Champ obligatoire', error: true } });
    }
    if (input.email.value === '' || input.password.value === '') isValidate = false;

    if (isValidate) {
      dispatch(loginStart({ password: input.password.value, userType: 'Consumer', username: input.email.value }));
      setUserType('Consumer');
    } else {
      handleBlur('password');
      handleBlur('email');
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === 'Enter' && !disabled) {
      handleClick();
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    switch (name) {
      case 'email': {
        if (!isEmailValid(value)) {
          setInput({
            ...input,
            email: { value, error: true, errorText: 'Mail format invalide ' },
          });
        } else {
          setInput({
            ...input,
            email: { value, error: false, errorText: '' },
          });
        }
        break;
      }
      case 'password': {
        setInput((prevstate) => ({ ...prevstate, [name]: { ...(prevstate as any)[name], value } }));
        break;
      }
      default:
        break;
    }
  };

  const JoinWithoutAuth = () => {
    if (inputIdentifiant) {
      dispatch(doJoinLiveSession({ id, name: inputIdentifiant, userType: 'Consumer' }));
    } else {
      setEmptyIdentifiant(true);
    }
  };

  const uuidv4 = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
  };

  useEffect(() => {
    liveSessionConsumerGet(id).then((data) => {
      setDateEnd(data.data.actualEnd);
      if (data.data.actualEnd !== null) {
        dispatch(doJoinLiveSession({ id, name: uuidv4(), userType: 'Consumer' }));
      }
    });
  }, []);

  useEffect(() => {
    if (token && username && userType === 'Consumer') {
      dispatch(doJoinLiveSession({ id, name: username, userType: 'Consumer' }));
    }
  }, [token, username]);

  useEffect(() => {
    if (input.email.value && input.password.value) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  }, [input.email.value, input.password.value]);

  useEffect(() => {
    const urlBase = idAffiliation ? `/af/${idAffiliation}` : idInvitation ? `/vp/${idInvitation}` : '';
    if (participantId !== '') {
      history.push((`/LiveCurrent/${id}` + urlBase).trim());
    }
  }, [participantId]);

  useEffect(() => {
    authSevices.getTypeRetailer().then((type) => {
      if (type) {
        if (type !== 'Consumer') {
          dispatch(logout());
        } else {
          setUserType(type);
          authSevices.getAccessToken().then((token) => {
            if (token) {
              authSevices.getNameRetailer().then((name) => {
                if (name) {
                  dispatch(doJoinLiveSession({ id, name, userType: 'Consumer' }));
                }
              });
            }
          });
        }
      }
    });
  }, []);

  return (
    <Box>
      {loading && <CustomBackdrop {...{ open: loading }} />}
      {loadingJoinLive && <CustomBackdrop {...{ open: loadingJoinLive }} />}
      {/* <Header /> */}
      <Box className={style.root}>
        {/* <Box className={style.cadreConsumer}>
          <div className={style.logo}>
            <Typography variant="h6">
              <span> Authentification </span>
            </Typography>
          </div>

          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'email',
                label: 'Email',
                type: 'email',
                variant: 'outlined',
                value: input.email.value,
                error: input.email.error,
                onBlur: () => handleBlur('email'),
                onChange: (e) => handleChange(e),
                onKeyDown: (e) => onKeyPressed(e),
              }}
            />
            {input.email.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.email.errorText}</Typography>
            )}
          </Box>
          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'password',
                label: 'Mot de passe',
                type: 'password',
                onBlur: () => handleBlur('password'),
                value: input.password.value,
                error: input.password.error,
                variant: 'outlined',
                onKeyDown: (e) => onKeyPressed(e),
                onChange: (e) => handleChange(e),
              }}
            />
            {input.password.error && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>{input.password.errorText}</Typography>
            )}
          </Box>

          <ThemeProvider theme={theme}>
            <Button
              {...{ color: 'primary', disabled, style: { marginTop: 20 } }}
              onClick={() => {
                handleClick();
              }}
            >
              <Typography>Se connecter et {t('homepage.join.live')}</Typography>
            </Button>
          </ThemeProvider>
        </Box> */}

        <Box className={style.cadreConsumer}>
          <div className={style.logo}>
            <Typography variant="h6">
              <span>
                {/* Vous n'avez pas encore de compte ? <br />  */}
                {t('auth.identifiant.title')}
              </span>
            </Typography>
          </div>

          <Box style={{ width: 316, padding: '10px', margin: 5 }}>
            <TextField
              {...{
                style: { width: '100%', background: '#FFFFFF', opacity: 1, height: 42 },
                name: 'identifiant',
                label: t('auth.identifiant.pseudo'),
                variant: 'outlined',
                value: inputIdentifiant,
                onKeyDown: (e) => e.key === 'Enter' && JoinWithoutAuth(),
                onChange: (e) => {
                  setInputIdentifiant(e.target.value);
                  setEmptyIdentifiant(false);
                },
              }}
            />
            {emptyIdentifiant && (
              <Typography style={{ marginTop: 17, color: 'red', fontSize: 12 }}>Merci de remplir ce champ</Typography>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <Button
              {...{ color: 'primary', disabled: !inputIdentifiant, style: { marginTop: 20 } }}
              onClick={JoinWithoutAuth}
            >
              <Typography>{t('homepage.join.live')}</Typography>
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginConsumer;
