import React from 'react';
import { Box } from '@material-ui/core';
import InfoLive from '../dashboard/CreateLive/Formulaire';
import { useStyles } from './style';

const ScheduleEventComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <InfoLive />
    </Box>
  );
};

export default ScheduleEventComponent;
