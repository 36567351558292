import React from 'react';
import CardQuestionnaire from './common/CardQuestionaire';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ICardQuestionnaire {
  nameQuestion: string;
  question: string;
  response: string[];
  typeQuestion: string;
}
const ListQuestionnaireComponent: React.FC = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const { t } = useTranslation();

  const fakeData = [
    {
      nameQuestion: 'Questionnaire 1',
      question: 'Quel est votre avis sur cette model',
      response: ['tres satisfaisant', 'satisfaisant', 'insatisfaisant'],
      typeQuestion: 'Choix Choix Unique',
    },
    {
      nameQuestion: 'Questionnaire 2',
      question: 'Quel est votre couleur preferé',
      response: ['Rouge', 'Vert', 'Bleu'],
      typeQuestion: 'Choix Multiple',
    },
    {
      nameQuestion: 'Questionnaire 3',
      question: "qu'est ce que vous propose qu'on vous annonce la prochaine choix",
      response: ['materiel du bureau', 'materiel informatique'],
      typeQuestion: 'Choix Multiple',
    },
  ];
  return (
    <Box>
      <Typography
        style={{
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          fontWeight: 'bold',
          marginBottom: '47px',
        }}
      >
        {t('question.title')}
      </Typography>
      <Typography variant="caption">{t('question.created')}</Typography>
      {fakeData.map((item: ICardQuestionnaire, indexKey: number) => {
        return (
          <CardQuestionnaire
            key={indexKey}
            {...{
              ...item,
            }}
          />
        );
      })}
    </Box>
  );
};

export default ListQuestionnaireComponent;
