import { Box } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import React from 'react';
import { API_URL } from '../../lib/config';
import { useStyles } from './style';

interface IButtonImage {
  urlImage?: string | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties | undefined;
  styleRoot?: React.CSSProperties | undefined;
  disabled?: boolean;
  isLocalSource?: boolean;
}
export const ButtonImage: React.FC<IButtonImage> = ({
  urlImage,
  children,
  onClick,
  style,
  styleRoot,
  disabled = false,
  isLocalSource = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={styleRoot}>
      <ButtonBase
        focusRipple
        key={'urlImage'}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{ ...style, border: !disabled ? '2px solid red' : '0px solid' }}
        onClick={onClick}
        disabled={disabled}
      >
        {isLocalSource && urlImage ? (
          <img src={urlImage} alt="sary" style={{ height: '100%', objectFit: 'contain' }} />
        ) : (
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${urlImage})`,
            }}
          />
        )}
        {!disabled && (
          <>
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>{children}</span>
          </>
        )}
      </ButtonBase>
    </Box>
  );
};
