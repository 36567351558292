import { IMessage, IViewer } from '../type/listenerApi';
import { typedAction } from '../utils';

export interface IListenerHistoryParams {
  userType: string;
  id: string;
}
export interface IHistorySuccess {
  viewers: IViewer[];
  messages: IMessage[];
}
export interface IHistoryFailed {
  status: number | undefined;
  error: any;
}
export interface IHistoryResult {
  data: IHistorySuccess;
  error: any;
  loading: boolean;
  status: number | undefined;
}
const initialState: IHistoryResult = {
  data: {
    viewers: [{ id: '', name: '', isGuest: false, type: 0 }],
    messages: [
      {
        dateTime: '',
        participantId: '',
        participantName: '',
        text: '',
        backgroundAvatar: '',
      },
    ],
  },
  error: '',
  loading: false,
  status: undefined,
};

export const CHECK_HISTORY_REQUEST = 'CHECK_HISTORY_REQUEST';
export const CHECK_HISTORY_SUCCESS = 'CHECK_HISTORY_SUCCESS';
export const CHECK_HISTORY_FAILED = 'CHECK_HISTORY_FAILED';
export const CLEAR_HISTORY_REQUEST = 'CLEAR_HISTORY_REQUEST';

export const startCheckHistoryListener = (historyParams: IListenerHistoryParams) =>
  typedAction(CHECK_HISTORY_REQUEST, historyParams);
export type StartCheckHistoryListener = typeof startCheckHistoryListener;

export const clearCheckHistoryListener = () => typedAction(CLEAR_HISTORY_REQUEST);
export type ClearCheckHistoryListener = typeof clearCheckHistoryListener;

export const checkHistoryListenerSuccess = (historyResult: IHistorySuccess) =>
  typedAction(CHECK_HISTORY_SUCCESS, historyResult);
export type CheckHistoryListenerSuccess = typeof checkHistoryListenerSuccess;

export const checkHistoryListenerFailed = (historyfailed: IHistoryFailed) =>
  typedAction(CHECK_HISTORY_FAILED, historyfailed);
export type CheckHistoryListenerFailed = typeof checkHistoryListenerFailed;

export type ListenerHistoryAction = ReturnType<
  StartCheckHistoryListener | CheckHistoryListenerSuccess | CheckHistoryListenerFailed
>;

export const ListenerHistory_Reduceur = (
  state: IHistoryResult = initialState,
  action: ListenerHistoryAction,
): IHistoryResult => {
  switch (action.type) {
    case CHECK_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHECK_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
      };
    case CHECK_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        data: {
          viewers: [{ id: '', name: '', isGuest: false, type: 0 }],
          messages: [
            {
              dateTime: '',
              participantId: '',
              participantName: '',
              text: '',
              backgroundAvatar: '',
            },
          ],
        },
        error: action.payload.error,
        status: action.payload.status,
      };
    case CLEAR_HISTORY_REQUEST:
      return initialState;

    default:
      return state;
  }
};
