import { useHistory } from 'react-router';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../api';
import {
  create_live_session_failed,
  CREATE_LIVE_SESSION_REQUEST,
  create_live_session_success,
} from '../modules/liveSessionRetailerCreate';
import { Set_state_snackbar } from '../modules/state/stateMessage';
import { ErrorFunction } from './utils/function';

export function* watchliveSessionCreate() {
  yield takeEvery(CREATE_LIVE_SESSION_REQUEST, liveSessionCreate);
}
export function* liveSessionCreate({ payload }: any) {
  // const history = useHistory();
  try {
    const { data, status } = yield call(api.liveSessionRetailer.liveSessionRetaillerCreate, payload);
    const result = { id: data, status };

    yield put(create_live_session_success(result));
    yield put(
      Set_state_snackbar({
        message: 'Operation effectuée avec succès',
        status: 200,
        open: true,
        type: 'success',
      }),
    );
  } catch (error: any) {
    const { status, title } = error.response;
    yield put(create_live_session_failed({ error: 'Failed', status: status }));
    yield call(ErrorFunction, error);
  }
}
