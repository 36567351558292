export default {
  'invitation.title': 'Email addresses of VIP guests',
  'invitation.asking.adress': 'Enter VIP email address',
  'invitation.button.add': 'Add VIP',
  'invitation.date': 'Invitation sent on',
  'invitation.send': 'Send',
  'invitation.delete': 'Delete',
  'invitation.selectAll': 'Select All',
  'invitation.info':
    'VIPs will automatically receive an email after entering their email address, checking the action box and clicking on "send".',
  'invitation.title.send': 'Mail sent to VIPs',
  'invitation.mail.sended': 'Sending mail : ',
  'invitation.mail.objet': 'Subject : ',
  'invitation.mail.sujet': 'Subject :',
  'invitation.mail.time': 'Hours :',
  'invitation.mail.https': 'https :',
  'invitation.message.send':
    'Make sure that the VIP benefits have been filled in under "set VIP benefits" before confirming the shipment',
  'invitation.email.noSend': 'mail not sent',
  'invitation.purchase.code': 'VIP guest benefit code',
  'invitation.purchase.help1':
    'Enter a different advantage code per VIP issued by your ecommerce site in order to track the turnover of VIP guests more easily.',
  'invitation.purchase.help2':
    'These benefit codes will be visible to the guests during the live event and must be entered on your site during the purchase.',
  'invitation.advantageCode.title': 'Purchase code',
  'invitation.advantageCode.text': 'Purchase code for the VIP ',
  'invitation.shoppingUrl': 'Shopping Url',
  'invitation.shopingUrlExample': 'https://www.livood.com',
  'invitation.shopingUrlInvalid': 'Url is invalid',
};
