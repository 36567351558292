import { typedAction } from '../../utils';

export const LIST_GUEST_SUMMARY_REQUEST = 'LIST/GUEST_SUMMARY/REQUEST';
export const CLEAR_GUEST_SUMMARY_REQUEST = 'CLEAR/GUEST_SUMMARY/REQUEST';
export const LIST_GUEST_SUMMARY_SUCCESS = 'LIST/GUEST_SUMMARY/SUCCESS';
export const LIST_GUEST_SUMMARY_FAILED = 'LIST/GUEST_SUMMARY/FAILED';
export const EXCELFILE_GUEST_SUMMARY_REQUEST = 'EXCELFILE_GUEST_SUMMARY_REQUEST';
export const EXCELFILE_GUEST_SUMMARY_SUCCESS = 'EXCELFILE_GUEST_SUMMARY_SUCCESS';

export interface IParticitant {
  id: string;
  name: string;
}
export interface IParticitantUser {
  consumerId: string;
  consumerLastName: string;
  consumerFirstName: string;
  consumerEmail: string;
  participants: IParticitant[];
}

export interface IdataGuestSummary {
  guestEmail: string;
  guestConsumer: IParticitantUser;
  affiliatedConsumers: IParticitantUser[];
  affiliatedParticipants: IParticitant[];
  advantageCode?: string;
}

// export interface Ierror {
//   message: string;
//   status: number | undefined;
// }

export interface IinitialStateListGuestSummary {
  ListGuestSummary: IdataGuestSummary[];
  excelFile: string;
  loading: boolean;
  error: string | undefined;
  status: number | undefined;
}

const initialState: IinitialStateListGuestSummary = {
  ListGuestSummary: [],
  excelFile: '',
  status: undefined,
  error: undefined,
  loading: false,
};

export const get_AllGuestSummaryByLive = (idSession: string) => typedAction(LIST_GUEST_SUMMARY_REQUEST, idSession);
export type Get_AllGuestSummary = typeof get_AllGuestSummaryByLive;

export const clear_AllGuestSummaryByLive = () => typedAction(CLEAR_GUEST_SUMMARY_REQUEST);
export type Clear_AllGuestSummary = typeof clear_AllGuestSummaryByLive;

export const get_GuestSummaryExcelFile = (idSession: string) => typedAction(EXCELFILE_GUEST_SUMMARY_REQUEST, idSession);
export type Get_GuestSummary = typeof get_GuestSummaryExcelFile;

export const get_GuestSummaryExcelFile_Success = (data: string) => typedAction(EXCELFILE_GUEST_SUMMARY_SUCCESS, data);
export type Get_GuestSummaryExcelFile_Success = typeof get_GuestSummaryExcelFile_Success;

export const get_allGuestSummary_Success = (data: IdataGuestSummary[]) => typedAction(LIST_GUEST_SUMMARY_SUCCESS, data);
export type Get_allGuestSummary_Success = typeof get_allGuestSummary_Success;

export const get_allGuestSummary_failed = (args: { status: number; error: string }) =>
  typedAction(LIST_GUEST_SUMMARY_FAILED, args);
export type Get_allGuestSummary_failed = typeof get_allGuestSummary_failed;

export type UpdateGuestSummaryAction = ReturnType<
  Get_AllGuestSummary | Get_allGuestSummary_Success | Get_allGuestSummary_failed | Clear_AllGuestSummary
>;

export const ListGuestSummaryReducers = (
  state: IinitialStateListGuestSummary = initialState,
  action: UpdateGuestSummaryAction,
): IinitialStateListGuestSummary => {
  switch (action.type) {
    case LIST_GUEST_SUMMARY_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case CLEAR_GUEST_SUMMARY_REQUEST:
      return initialState;
    case LIST_GUEST_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        ListGuestSummary: action.payload,
      };
    case EXCELFILE_GUEST_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EXCELFILE_GUEST_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        excelFile: action.payload,
      };
    case LIST_GUEST_SUMMARY_FAILED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        loading: false,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
