import { call, put, takeEvery } from 'redux-saga/effects';
import APi from '../api';
import { liveSessionPageFailed, liveSessionPageSuccess, LIVE_SESSION_PAGE_REQUEST } from '../modules/liveSessionPage';

export function* watchliveSessionPage() {
  yield takeEvery(LIVE_SESSION_PAGE_REQUEST, liveSessionPage);
}

export function* liveSessionPage({ payload }: any) {
  try {
    const { data, status } = yield call(APi.liveSession.liveSessionPage, payload);
    const Result = { ...data, type: payload.type };
    yield put(liveSessionPageSuccess(Result));
  } catch (error: any) {
    yield put(liveSessionPageFailed());
  }
}
