import axios, { AxiosPromise } from 'axios';
import { IBodyConsumerCount } from '../type/account';
export const getProfilRetailer = (): AxiosPromise<any> => axios.get(`/Retailer/Profile/Get`);

export const setLogoRetailer = (galleryImageId: string): AxiosPromise<any> =>
  axios.post(`/Retailer/Profile/SetLogo`, galleryImageId);

export const sendEmailResetPassword = (usertype: string, email: string): AxiosPromise<any> =>
  axios.post(`/${usertype}/Account/SendPasswordResetMail`, { email });

export const resetPassword = (
  userType: string,
  data: {
    consumerId: string;
    securityStamp: string;
    password: string;
  },
): AxiosPromise<any> => axios.post(`/${userType}/Account/ResetPassword`, data);

export const updatePassword = (userType: string, password: string): AxiosPromise<any> =>
  axios.post(`${userType}/Account/UpdatePassword`, { password });
