import axios from 'axios';
import { AccessToken } from 'livekit-server-sdk';

interface bodyJoinTokenInterface {
  participantID: string;
  participantName: string;
  roomName: string;
  canPublish: boolean;
  canSubscribe: boolean;
  metadata?: string;
}

export const getTokenLiveKit = async (data: bodyJoinTokenInterface) => {
  const at = new AccessToken(
    process.env.REACT_APP_LIVEKIT_API_KEY as string,
    process.env.REACT_APP_LIVEKIT_API_SECRET as string,
    {
      identity: data.participantID,
      name: data.participantName,
      metadata: data.metadata,
    },
  );
  at.addGrant({
    roomJoin: true,
    room: data.roomName,
    canPublish: true,
    canSubscribe: true,
  });
  return at.toJwt();
};
