import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      '& .MuiTabs-root': {
        borderBottom: '2px solid #acacac22',
        backgroundColor: '#acacac33',
        boxShadow: '1px 1px 4px 2px  #acacac44',
        '& .MuiTabs-fixed': {
          '& .MuiTabs-flexContainer': {
            width: '100%',
            height: 50,
            alignItems: 'center',
          },
        },
        '& .MuiTabs-indicator.top': {
          bottom: 'unset',
          top: 0,
        },
      },
      '& .MuiTab-root': {
        textTransform: 'capitalize',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'black',
        paddingTop: 0,
        paddingBottom: 0,
        opacity: 1,
        '& .MuiTab-wrapper': {
          lineHeight: 1,
          '& .MuiSvgIcon-root': {
            marginBottom: 0,
          },
        },
      },
    },
  });
});
