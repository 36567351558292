import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      '& .MuiTabs-root': {
        borderTop: '1px solid gray',
        backgroundColor: 'lightgray',
        '& .MuiTabs-fixed': {
          '& .MuiTabs-flexContainer': {
            width: '100%',
            height: 50,
            alignItems: 'center',
          },
        },
        '& .MuiTabs-indicator.top': {
          bottom: 'unset',
          top: 0,
        },
      },
      '& .MuiTab-root': {
        minWidth: '5vw',
        textTransform: 'capitalize',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'black',
        paddingTop: 0,
        paddingBottom: 0,
        opacity: 1,
        '& .MuiTab-wrapper': {
          lineHeight: 1,
          '& .MuiSvgIcon-root': {
            marginBottom: 0,
          },
        },
      },
    },
  });
});
