import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, SvgIconTypeMap, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ListItemButton from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import LogoutIcon from '../../../component/LogoutIcon/LogoutIcon';
import HomeIcon from '@material-ui/icons/Home';
import AccountIcon from '../../../component/AccountIcon/AccountIcon';
import ScheduleEventIcon from '../../../component/ScheduleEventIcon/ScheduleEventIcon';
import ScheduleLiveIcon from '../../../component/ScheduleLiveIcon/ScheduleLiveIcon';
import StatisticsIcon from '../../../component/StatisticsIcon/StatisticsIcon';

import { useStyles } from './style';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { ResetProfileImg } from '../../../redux-services/modules/state/stateProfileImg';
import authSevices from '../../../redux-services/services/authSevices';
import liveSessionServices from '../../../redux-services/services/liveSessionServices';
import CustomModal from '../../modal/Modal';
import React, { useState } from 'react';
import { IinitialStateAffiliation, setAffiliationSaved } from '../../../redux-services/modules/state/stateAffiliation';
import { doEndLiveSession } from '../../../redux-services/modules/liveSessionEnd';
import { RootState } from '../../../redux-services';
import { IinitialStateDetails } from '../../../redux-services/modules/liveSessionRetailerDetails';
import { IinitialStateLiveStart } from '../../../redux-services/modules/liveSessionStart';

interface DrawerProps {
  toggleDrawer: () => void;
  open: boolean;
  userType: string | null;
}

interface IconsInterface {
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | JSX.Element;
  name: string;
  path: string;
  id: number;
}

const Icons: IconsInterface[] = [
  {
    id: 2,
    name: 'Accueil',
    icon: <HomeIcon />,
    path: '/dashboard',
  },
  {
    id: 3,
    name: 'Mon Compte',
    icon: <AccountIcon />,
    path: '/dashboard/account',
  },
  {
    id: 4,
    name: 'Programmer un live',
    icon: <ScheduleEventIcon />,
    path: '/dashboard/create',
  },
  {
    id: 5,
    name: 'Lives programmés',
    icon: <ScheduleLiveIcon />,
    path: '/dashboard/livesessions',
  },
  {
    id: 6,
    name: 'Statistiques',
    icon: <StatisticsIcon />,
    path: '/dashboard/stats',
  },
];

const CustomDrawer = ({ open, toggleDrawer, userType }: DrawerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { liveDetails: live } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const { adaptorAntMedia } = useSelector<RootState, IinitialStateLiveStart>((state) => state.liveSessionStart);
  const StateContrePart = useSelector<RootState, IinitialStateAffiliation>((state) => state.stateAffiliation);
  const [goToLink, setgoToLink] = useState<string>('');
  const { id }: any = useParams();
  const history = useHistory();
  const [openDialog, setopenDialog] = useState<boolean>(false);
  const { pathname } = useLocation();

  const handleLogout = () => {
    toggleDrawer();
    window.location.replace('https://livood.com/');
    dispatch(ResetProfileImg());
    authSevices.removeRetailerInfo();
    liveSessionServices.cleanParticipantByLive();
  };

  const onConfirmDialog = () => {
    dispatch(setAffiliationSaved(true));
    if (live.actualStart && StateContrePart.isSaved) {
      dispatch(doEndLiveSession({ id, adaptorRTC: adaptorAntMedia }));
    }
    history.push(goToLink);
    setopenDialog(false);
  };

  const onCloseDialog = () => {
    setopenDialog(false);
  };

  const handleChange = async (e: any, path: string) => {
    if (!path.includes(`dashboard/livesessions/`) && live.actualStart && !live.actualEnd) {
      setopenDialog(true);
      return await e.preventDefault();
    } else {
      toggleDrawer();
    }
  };

  const list = () => (
    <Box sx={{ width: 300 }} role="presentation">
      <CustomModal
        {...{
          open: openDialog,
          title: 'Confirmation',
          TextCancel: 'Annuler',
          onConfirm: onConfirmDialog,
          textConfirm: 'Confirmer',
          onClose: onCloseDialog,
        }}
      >
        <Typography>Etes-vous sûr de stopper le live avant de quitter la page ?</Typography>
      </CustomModal>

      <Box style={{ height: '15%' }} onClick={toggleDrawer}>
        <List>
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText primary="Fermer" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box style={{ height: '70%' }}>
        {userType !== 'Consumer' && (
          <List>
            {Icons.map(({ icon, id, name, path }) => (
              <NavLink to={path} onClick={(e) => handleChange(e, path)}>
                <ListItem key={id}>
                  <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ))}
          </List>
        )}
      </Box>
      <Box style={{ height: '15%', position: 'absolute', bottom: 0 }}>
        <List>
          <ListItem onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Se déconnecter</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      className={classes.drawerContent}
      classes={{ paper: classes.paper }}
    >
      {list()}
    </Drawer>
  );
};

export default CustomDrawer;
