import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, CircularProgress, IconButton, InputBase, Paper, Popover, Typography } from '@material-ui/core';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { useStyles } from './style';
import { IMessage } from '../../redux-services/type/listenerApi';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IHistoryResult, startCheckHistoryListener } from '../../redux-services/modules/listenerHistory';
import authSevices from '../../redux-services/services/authSevices';
import { RootState } from '../../redux-services';
import { IResultLoginSAGA } from '../../redux-services/modules/login';
import { DisplayMessage } from './DIsplayMessage';
import liveSessionServices from '../../redux-services/services/liveSessionServices';
import theme from '../../Themes';
import { IinitialStateDetails } from '../../redux-services/modules/liveSessionRetailerDetails';
import { HubConnection } from '@microsoft/signalr';
import Call from '../../redux-services/api';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

interface IChatProps {
  id: string;
  userType?: string;
  messages: IMessage[];
  loading: boolean;
  handleOpen?: () => void;
  isLiveStarted?: boolean;
  isLiveEnded?: boolean;
  primaryColor?: string;
  backgroundAvatar: string;
  isMobile?: boolean;
  userId: string;
  connectionHub: HubConnection | null;
}

const Chat: React.FC<IChatProps> = (props: IChatProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    id,
    messages,
    primaryColor,
    userType,
    loading,
    handleOpen,
    isLiveStarted,
    isLiveEnded,
    isMobile,
    connectionHub,
    userId,
  } = props;
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [listMessage, setListMessage] = useState<IMessage[]>(messages);
  const [popOver, setPopOver] = useState({ open: false, anchorEl: null });
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const inputRef = useRef(null);
  const login = useSelector<RootState, IResultLoginSAGA>((state) => state.login);
  const dispatch = useDispatch();
  const { liveDetails: live } = useSelector<RootState, IinitialStateDetails>((state) => state.liveSessionDetails);
  const isRetailer = userType === 'Retailer';
  const isBoxShown = !isRetailer && !isLiveStarted;
  const { search } = useLocation();

  const scrollToBottom = () => {
    const divChat = document.getElementById('box-message-content');
    divChat?.scrollTo(0, divChat?.scrollHeight);

    const element = document.getElementsByClassName('discussion');
    element[1]?.scrollTo(0, element[1]?.scrollHeight);
    const tabActive = search.split('tabsActive=')[1];
    if (tabActive === '0') {
      divChat?.scrollTo(0, 1000000);
      setTimeout(() => {
        divChat?.scrollTo(0, 1000000);
      }, 100);
    }
  };

  const handleOpenPopOver = (e: any) => {
    e.preventDefault();
    setPopOver({ open: true, anchorEl: e.currentTarget });
  };

  const handleClose = () => {
    setPopOver({ open: false, anchorEl: null });
  };

  const onSubmitChat = async (e: any) => {
    setLoadingSend(true);
    if (!token.length) {
      handleOpenPopOver(e);
    } else {
      if (message && connectionHub) {
        try {
          await connectionHub.invoke('SendMessage', message);
          setMessage('');
          const typeUser = await authSevices.getTypeRetailer();
          const idUser = localStorage.getItem('participantId');
          if (typeUser === 'Retailer') {
            const urlProfil = await authSevices.getLogoRetailer();
            const name = await authSevices.getNameRetailer();
            setListMessage((messages) => [
              ...messages,
              {
                dateTime: '',
                participantId: idUser as string,
                participantName: name as string,
                text: message,
                profilePictureUrl: urlProfil as string,
                backgroundAvatar: `#`,
              },
            ]);
          } else {
            const _logo = await authSevices.getProfilConsumer();
            const name = await localStorage.getItem('user');
            setListMessage((messages) => [
              ...messages,
              {
                dateTime: '',
                participantId: idUser as string,
                participantName: name as string,
                text: message,
                profilePictureUrl: _logo as string,
                backgroundAvatar: ``,
              },
            ]);
          }
        } catch (e: any) {
          setLoadingSend(false);
          console.log('Error send message :>> ' + e);
          throw new Error('Error send message :>> ', e);
        }
      }
    }
    setLoadingSend(false);
  };

  const onSubmitChatByKey = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmitChat(e);
    }
  };

  useEffect(() => {
    setListMessage(messages);
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [listMessage]);

  useEffect(() => {
    (async () => {
      const token_ = await authSevices.getAccessToken();
      if (token_?.length) {
        setToken(token_);
      }
    })();
    if (id && id !== undefined) {
      (async () => {
        const _userId = await localStorage.getItem('participantId');
        if ((userType && userType === 'Retailer' && id) || (id && _userId)) {
          dispatch(
            startCheckHistoryListener({
              userType: userType ?? 'Consumer',
              id: userType === 'Retailer' ? id : `${id}?participantId=${_userId}`,
            }),
          );
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (login.token) {
      setToken(login.token);
    }
  }, [login.token]);

  useEffect(() => {
    if (connectionHub) {
      (async () => {
        connectionHub.on('MessageSent', (dataMessageSent) => {
          console.log('Data message reçu :>> ', dataMessageSent);
          const username = localStorage.getItem('user');
          if (dataMessageSent.participantName !== username) {
            setListMessage((messages) => [
              ...messages,
              {
                dateTime: '',
                participantId: dataMessageSent.participantId,
                participantName: dataMessageSent.participantName,
                text: dataMessageSent.text,
                profilePictureUrl: dataMessageSent.profilePictureUrl,
                backgroundAvatar: '',
              },
            ]);
          }
        });

        //await connectionHub.start().then(async () => await connectionHub.invoke('Join'));
      })();
    }
  }, [connectionHub]);

  return (
    <>
      {isMobile && isRetailer ? (
        <div>
          <Box className={classes.chatRetailerInput}>
            <InputBase
              ref={inputRef}
              className="inputBase"
              fullWidth={true}
              placeholder={t('consumer.chat.placeholderInput')}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              rows={2}
              multiline={true}
              onKeyDown={
                isBoxShown
                  ? (e: any) => {
                      handleOpenPopOver(e);
                    }
                  : onSubmitChatByKey
              }
            />
            <IconButton
              className="iconsend"
              style={{ backgroundColor: primaryColor ?? theme.palette.primary.light }}
              onClick={
                isBoxShown
                  ? (e: any) => {
                      handleOpenPopOver(e);
                    }
                  : onSubmitChat
              }
            >
              {!loadingSend ? <SendOutlinedIcon /> : <CircularProgress color="primary" size={14} />}
            </IconButton>
          </Box>
          <Paper className={classes.content}>
            <Box
              className={classes.discussion}
              id="box-message-content"
              style={{ display: loading ? 'flex' : 'block' }}
            >
              <Box
                style={{ paddingLeft: 30, display: loading ? 'flex' : 'block' }}
                className={loading ? classes.discussionLoadingContainer : undefined}
              >
                <Typography className="titlechat" variant="body2">
                  {t('consumer.chat.welcome')}
                </Typography>
                {isBoxShown ? (
                  <Box />
                ) : (
                  <DisplayMessage
                    {...{ idLive: id, userType: userType, loading, messages: listMessage, primaryColor }}
                  />
                )}
              </Box>
            </Box>
          </Paper>
        </div>
      ) : (
        <Box className={classes.chat}>
          <Typography variant="h5">{t('consumer.chat.title')}</Typography>
          <Paper className={classes.content}>
            <Box
              className={clsx({ [classes.discussion]: true, ['discussion']: true })}
              id="box-message-content"
              style={{ display: loading ? 'flex' : 'block' }}
            >
              <Box
                style={{ paddingLeft: 30, display: loading ? 'flex' : 'block' }}
                className={loading ? classes.discussionLoadingContainer : undefined}
              >
                <Typography className="titlechat" variant="body2">
                  {t('consumer.chat.welcome')}
                </Typography>
                {isBoxShown ? (
                  <Box />
                ) : (
                  <DisplayMessage
                    {...{ idLive: id, userType: userType, loading, messages: listMessage, primaryColor }}
                  />
                )}

                {/* {isBoxShown && <DisplayMessage {...{ loading, messages }} />}
                {!isRetailer && !isLiveStarted && <Box style={{ height: 250, backgroundColor: '#00000075' }} />}
                {!isRetailer && isLiveStarted && <DisplayMessage {...{ loading, messages }} />} */}
              </Box>
            </Box>
            {(live.actualStart !== null || isLiveStarted) && (!live.actualEnd || isLiveEnded) && (
              <Box className="footer">
                <Box className="dark">
                  <Typography color="textSecondary" variant="body2">
                    {t('consumer.chat.forSubscriber')}
                  </Typography>
                </Box>
                <Box className="light">
                  <InputBase
                    ref={inputRef}
                    className="inputBase"
                    fullWidth={true}
                    placeholder={t('consumer.chat.placeholderInput')}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    rows={2}
                    multiline={true}
                    onKeyDown={
                      isBoxShown
                        ? (e: any) => {
                            handleOpenPopOver(e);
                          }
                        : onSubmitChatByKey
                    }
                  />
                  <IconButton
                    className="iconsend"
                    style={{ backgroundColor: primaryColor ?? theme.palette.primary.light }}
                    onClick={
                      isBoxShown
                        ? (e: any) => {
                            handleOpenPopOver(e);
                          }
                        : onSubmitChat
                    }
                  >
                    {!loadingSend ? <SendOutlinedIcon /> : <CircularProgress color="primary" size={14} />}
                  </IconButton>
                </Box>
                {!token?.length && (
                  <Popover
                    id="my-pop-over"
                    open={!!popOver.anchorEl}
                    anchorEl={popOver.anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Box className={classes.popContent}>
                      {t('consumer.live.subscribe.request')}
                      <Button variant="contained" onClick={handleOpen}>
                        {t('consumer.live.login')}
                      </Button>
                    </Box>
                  </Popover>
                )}
              </Box>
            )}
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Chat;
