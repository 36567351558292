import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Poppins, sans-serif',
      width: '100vw',
      height: '90vh',
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '@media (max-height: 604px)': {
        /* height: "100%", */
      },
      '@media (max-width: 991px)': {
        backgroundImage: 'initial',
        //height: "100%",
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4B7FD5',
            borderWidth: 1,
          },
        },
      },
    },
    retailerInstruction: {
      display: 'block',
      position: 'relative',
      width: '100%',
      bottom: 46,
      '& p': {
        color: '#0c0c0c',
        textAlign: 'center',
        '& u': {
          stroke: '#000',
          fontWeight: 600,
        },
        '& b': {
          fontSize: 13,
          fontStyle: 'italic',
        },
      },
    },
    retailerInstructionFooter: {
      '& div': {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
          '@media (max-width: 700px)': {
            width: 300,
          },
          color: '#0c0c0c',
          marginTop: 20,
          textAlign: 'left',
          '& a': {
            fontWeight: 600,
            fontStyle: 'italic',
          },
        },
      },
      display: 'block',
      position: 'relative',
      top: 20,
      width: '100%',
    },
    cadre: {
      width: 396,
      maxHeight: 457,
      background: '#FFFFFF',
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      opacity: 1,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 40,
      '@media (max-width: 700px)': {
        padding: 20,
        width: 320,
        borderRadius: 'unset !important',
      },
    },
    cadreConsumer: {
      width: '396px',
      height: '350px',
      background: '#FFFFFF',
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      opacity: 1,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 40,
      '@media (max-width: 639px)': {
        padding: 20,
        width: 300,
      },
    },
    textField: {
      width: 316,
      background: '#FFFFFF',
      opacity: 1,
      height: 42,
      margin: '0 0 20px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
      '&:last-of-type': {
        marginBottom: 30,
      },
      '& label': {
        color: '#A8AAB3',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          borderColor: '#4B7FD5',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4B7FD5',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -5px) scale(0.75)',
        },
      },
      '& .MuiInputLabel-formControl': {
        fontFamily: 'Poppins, sans-serif',
      },
      '& input': {
        borderRadius: 6,
        padding: '12px 14px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    logintext: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.875em',
      textAlign: 'center',
      lineHeight: '20px',
      letterSpacing: '.25px',
      color: '#A8AAB3',
      justifyContent: 'center',
      margin: '0 0 36px',
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    btnconnect: {
      boxShadow: 'none',
      fontSize: 15,
      width: 316,
      height: 42,
      borderRadius: 4,
      margin: 0,
      '@media (max-width: 639px)': {
        width: '100%',
      },
    },
    logo: {
      // height: 64,
      marginBottom: 18,
      // width: 64,
    },
    textTypography: {
      fontFamily: 'Poppins, sans-serif',
      width: 60,
      height: 60,
      backgroundColor: ' #4B7FD5',
      borderRadius: 10,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      transform: 'rotate(45deg)',
      color: theme.palette.common.white,
      margin: 8,
      '& > span': {
        lineHeight: '100%',
        transform: 'rotate(-45deg)',
        '& strong': {
          fontWeight: 700,
        },
      },
    },
    textBienvenue: {
      color: '#212226',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1.5em',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.18,
      marginBottom: 16,
      textAlign: 'center',
    },
    desktopView: {
      '@media (min-width:700px)': {
        background: '#518971',
        height: '100%',
      },
    },
    consumerLoginTitle: {
      fontWeight: 900,
      color: '#FFFFFF',
      textAlign: 'center',
      paddingTop: 10,
    },
    consumerBg: {
      background: '#518971',
      height: '100%',
    },
    consummerInstruction: {
      display: 'block',
      position: 'relative',
      width: '100%',
      bottom: 20,
      '& p': {
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 600,
        '& u': {
          stroke: '#000',
        },
        '& b': {
          fontSize: 13,
          fontStyle: 'italic',
        },
      },
    },
    consummerInstructionFooter: {
      '& div': {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
          '@media (max-width: 700px)': {
            width: 300,
          },
          color: '#FFFFFF',
          marginTop: 20,
          fontWeight: 600,
          textAlign: 'left',
          '& a': {
            fontStyle: 'italic',
            color: '#FFFFFF',
          },
          '@media (max-width:700px)': {
            textAlign: 'center',
          },
        },
      },
      display: 'block',
      position: 'relative',
      top: 20,
      width: '100%',
    },
  }),
);
