import { Avatar, CircularProgress, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { IMessage } from '../../redux-services/type/listenerApi';
import theme from '../../Themes';
import { useStyles } from './style';
import authSevices from '../../redux-services/services/authSevices';
import liveSessionServices from '../../redux-services/services/liveSessionServices';

interface IDisplayMessage {
  idLive: string;
  userType?: string;
  loading: boolean;
  primaryColor?: string;
  messages: IMessage[] | null | undefined;
}
export const DisplayMessage: React.FC<IDisplayMessage> = (props: IDisplayMessage) => {
  const { idLive, userType, loading, messages, primaryColor } = props;
  const styles = useStyles();
  const [meId, setMeId] = useState<string | null>();

  const getMe = useCallback(async () => {
    const _userId = await localStorage.getItem('user');
    setMeId(_userId);
  }, [messages]);

  useEffect(() => {
    getMe();
  }, []);

  return loading ? (
    <CircularProgress className="loading" style={{ display: 'flex', alignSelf: 'center', justifySelf: 'center' }} />
  ) : messages ? (
    <>
      {messages.map((mess: IMessage, indexKey: number) => {
        if ((meId && mess.participantName !== meId) || meId === null) {
          return (
            <React.Fragment key={indexKey}>
              <div className={styles.nameUser}>{mess.participantName}</div>
              <div className={styles.contemess}>
                <div className={styles.messageOther}>{mess.text}</div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <Typography
              key={indexKey}
              variant="body2"
              className="messageByMe"
              style={{ background: primaryColor ?? theme.palette.primary.light }}
            >
              {mess.text}
            </Typography>
          );
        }
      })}
    </>
  ) : (
    <>
      <Typography variant="body2" className="message">
        <span>brice:</span> sympa
      </Typography>
    </>
  );
};
