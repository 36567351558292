import { createStyles, makeStyles, Theme } from '@material-ui/core';
import bg from '../../Assets/images/fond.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    seeProduct: {
      position: 'fixed',
      zIndex: 1000,
      top: '90vh',
      left: '30px',
      padding: 10,
      borderRadius: '10px',
      color: '#FFFFFF',
      fontWeight: 'bold',
      cursor: 'pointer',
    },

    mainContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    subHeaderBlockContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media(max-width:1180px)': {
        height: 'fit-content',
        minHeight: 'calc(100dvh - 40px)',
        display: 'grid',
        gridTemplateRows: 'calc(45dvh + 65px) 1fr',
        overflow: 'hidden',
        position: 'relative',
      },
      '@media(max-width:700px)': {
        height: '100vh !important',
      },
      '@media screen (max-width:720px), screen and (max-height:480px)': {
        position: 'initial',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: 7,
      paddingLeft: '3%',
      paddingRight: '3%',
      backgroundImage: `url(${bg})`,
      '@media(max-width:1180px)': {
        padding: 0,
        '& > div:nth-of-type(2)': {
          '& > div:nth-of-type(2)': {
            display: 'none',
          },
        },
      },
    },
    rootFullscreen: {
      paddingLeft: '0px!important',
      paddingRight: '0px!important',
      paddingTop: '0px!important',
      '@media(max-width:1180px)': {
        width: '100vw!important',
        height: '100vh!important',
      },
    },
    videFullscreen: {
      padding: '0px!important',
      width: '68vw!important',
      display: 'flex',
      '@media(max-width:1180px)': {
        width: '100vw!important',
        height: '100vh!important',
        flexDirection: 'column!important',
      },
    },
    viewers: {
      transition: 'fade 0.5s ease-in-out',
      width: '18%',
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    viewerDialog: {
      '& > div': {
        width: '100%',
        display: 'block',
      },
    },
    video: {
      width: '60vw',
      //display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 3%',
      '& .footer': {
        display: 'flex',
        padding: '9px 0px',
        '@media(max-width:1180px)': {
          padding: 0,
        },
        '@media(max-width:400px)': {
          flexDirection: 'column',
        },
        width: '100%',
        '& .left': {
          width: '12%',
          '@media(max-width:1180px)': {
            display: 'none',
          },
        },
        '& .center': {
          width: '75%',
          display: 'flex',
          flexDirection: 'column',
          '&>div': {
            marginBottom: 10,
          },
          '@media(max-width:1180px)': {
            width: '50%',
            '& >h5': {
              fontSize: 16,
            },
            '&>div': {
              marginBottom: 5,
            },
          },
          '@media(max-width:480px)': {
            display: 'none',
          },
        },
        '& .right': {
          width: '15%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          '&>button': {
            marginBottom: 10,
          },
          '@media(max-width:1180px)': {
            display: 'none',
          },
        },
        '& .rightMobile': {
          display: 'none',
          '@media(max-width:400px)': {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
      '& .roomContainer': {
        height: '61vh',
        '& .vipRoomContainer': {
          maxHeight: 98,
        },
        '& .vipRoomContainer + div.initiatorContainer': {
          maxHeight: 'calc(61vh - 78px)',
        },
        '& .initiatorContainer': {
          width: '100%',
          //minHeight: 'unset',
        },
        '& .initiatorContainer.pinnedInitiatorContainer': {
          minHeight: 'unset',
          maxWidth: '8vw',
        },
        '& .pinnedVip': {
          '& .wihoutVideoContainer': {
            '& .containerAvatar': {
              width: '10vw',
              height: '10vw',
              fontSize: '6vw',
            },
          },
          '& .bottomBtnVideoRender': {
            bottom: 55,
            '& > div': {
              width: 40,
              height: 40,
              '& > svg': {
                fontSize: '1.5rem',
              },
            },
          },
        },
      },
      '@media(max-width:1180px)': {
        width: '100%',
        flexDirection: 'column-reverse',
        padding: 0,
        '& .roomContainer': {
          width: '100vw',
          height: '45dvh',
          marginBottom: 0,
          '& .initiatorContainer': {
            width: '100vw',
            height: '100%',
            minHeight: 'unset !important',
            maxHeight: '100%',
            '& .wihoutVideoContainer': {
              width: '100%',
              height: '100%',
              '& .containerAvatar': {
                margin: 0,
                width: 45,
                height: 45,
                fontSize: 20,
              },
            },
          },
          '& .initiatorContainer.pinnedInitiatorContainer': {
            minWidth: 103,
          },
          '& .vipRoomContainer + div.initiatorContainer': {
            maxHeight: 'calc(45dvh - 70px) !important',
          },
          '& .vipRoomContainer': {
            margin: '5px 0',
            maxHeight: 'unset !important',
            '& .vipParticipantsContainer': {
              '& .wihoutVideoContainer': {
                width: '100%',
                height: '100%',
                '& .containerAvatarVip': {
                  width: 45,
                  height: 45,
                },
              },
            },
          },
        },
      },
    },
    boxAvatar: {
      display: 'flex',
      padding: '0px',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      width: '33%',
      marginBottom: '5px',
    },
    accordionDetails: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      maxHeight: '346px',
      overflow: 'auto',
      justifyContent: 'center',
    },
    content: {
      backgroundColor: '#F9FAF9',
      height: 500,
      overflow: 'hidden',
      position: 'relative',
      marginTop: 20,
      boxShadow: 'inset 2px 2px 10px 2px rgba(34, 34, 34, 0.05)',
      borderRadius: 5,
      padding: '10px 18px',

      '& .MuiAccordionSummary-root': {
        backgroundColor: '#F9FAF9',
        padding: 0,
        '& .MuiAccordionSummary-content': {
          '& .title': {
            fontSize: 15,
            fontWeight: 700,
          },
        },
      },
      '& .titlechat': {
        fontStyle: 'italic',
      },
      '& .message': {
        marginTop: 10,
        '& span': {
          fontWeight: 700,
        },
      },
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        marginTop: 10,
        '& .title': {
          fontSize: 15,
          fontWeight: 700,
        },
        '& .more': {
          fontSize: 14,
          fontWeight: 600,
          fontFamily: 'Open Sans',
        },
      },
      '&>ul>li': {
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
        marginTop: 5,
      },
      '& .footer': {
        height: 100,
        background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 56%, transparent 82%)',
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .dark': {
          height: 30,
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 30,
          boxSizing: 'border-box',
        },
        '& .light': {
          color: '#A0A0A0',
          height: 60,
          width: '100%',
          alignItems: 'flex-start',
          display: 'flex',
          paddingLeft: 30,
          backgroundColor: '#f9f9f9',
          boxSizing: 'border-box',
          paddingTop: 4,
          borderRadius: 5,
          '& input': {
            fontSize: 14,
            fontFamily: 'Open Sans',
          },
          '& .iconsend': {
            height: 30,
            width: 30,
            borderRadius: 100,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 15,
            '& svg': {
              color: 'white',
              height: 22,
              width: 22,
            },
          },
        },
      },
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      backgroundColor: '#0000004d',
      fontSize: 14,
      borderRadius: 5,
      fontFamily: 'Open Sans',
      fontWeight: 400,
      paddingLeft: 10,
      paddingRight: 10,
      height: 30,
      width: 60,
      '@media(max-width:1180px)': {
        height: 20,
      },
    },
    iconfooter: {
      display: 'flex',
      '& div': {
        backgroundColor: '#000000',
        borderRadius: 50,
        color: 'white',
        marginLeft: 20,
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:first-child': {
          marginLeft: 0,
        },
        '@media(max-width:1180px)': {
          marginLeft: 10,
          width: 30,
          height: 30,
          '& svg': {
            width: 20,
            height: 20,
          },
        },
      },
    },
    btnAbonner: {
      display: 'flex',
      backdropFilter: 'blur(20px)',
      textTransform: 'unset',
      color: 'white',
      borderRadius: 10,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      '&:hover': {
        background: '#386551',
      },
      cursor: 'default',
    },
    btnInviter: {
      display: 'flex',
      padding: '4px 8px 4px 8px',
      maxWidth: '100%',
      fontSize: 16,
      width: 200,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      textTransform: 'unset',
      marginLeft: 10,
      color: '#222222',
      borderRadius: 10,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      border: '1px solid #222222',
      cursor: 'default',
      // width: 90,
      '&:hover': {
        background: '#efefef',
      },
    },
    avatar: {
      width: '36px',
      height: '36px',
      // marginRight: 8,
    },
    btnText: {
      cursor: 'text',
    },
    btnCursor: {
      cursor: 'pointer',
    },
    footerMobile: {
      display: 'none',
      width: '100%',
      order: -1,
      marginTop: 5,
      padding: 10,
      '@media(max-width:1180px)': {
        display: 'flex',
        padding: '0 10px',
        '& > div:nth-of-type(2)': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: 370,
          margin: '0 auto',
          gap: 30,
          backgroundColor: '#FFFFFF',
          zIndex: 1111,
          position: 'relative',
          bottom: '4vw',
          '& > button': {
            width: 'fit-content !important',
            minWidth: 'fit-content',
          },
          '& > div': {
            width: 'fit-content !important',
            minWidth: 120,
          },
        },
      },
      '@media(max-width:480px)': {
        '& > div:nth-of-type(2)': {
          bottom: '0px',
        },
      },
    },
    conversation: {
      cursor: 'pointer',
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    chatplace: {
      width: '22%',
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    chatmobileplace: {
      '& .MuiPaper-root.MuiMenu-paper': {
        width: 300,
        position: 'relative',
        '& > ul.MuiList-padding': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        '& h5': {
          display: 'none',
        },
        '& h5 + div': {
          minHeight: 400,
          marginTop: 0,
          '& .footer': {
            height: 'auto',
          },
        },
      },
      '@media(min-width:1180px)': {
        display: 'none',
      },
    },
    popContent: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginTop: 10,
      },
    },
    closeChat: {
      position: 'absolute',
      top: 10,
      right: 15,
      cursor: 'pointer',
      '& svg': {
        height: 20,
        width: 20,
      },
    },
    sharedProductContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      '& > div': {
        width: '100%',
      },
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    tabsContainer: {
      width: '100%',
      display: 'none',
      '@media(max-width:1180px)': {
        height: ' 35vh',
        display: 'block',
        position: 'fixed',
        bottom: '0',

        // Tabs root
        '& > div': {
          height: '100%',
          position: 'relative',
          // Tabpanels
          '& > div:not(:nth-last-of-type(1))': {
            width: '100%',
            height: 'calc(100% - 51px)',
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
          },
        },
      },

      '@media(max-height:860px)': {
        height: '30vh',
      },

      '@media(max-height:738px)': {
        height: '25vh',
      },

      '& .MuiTabs-root': {
        width: '100%',
        minHeight: '51px',
        position: 'absolute',
        bottom: 0,
      },
    },
    imgFingerContainerMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media(max-width:1180px)': {
        display: 'none',
      },
    },
    tabContentContainer: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    chatContainerMobile: {
      height: '100%',
      overflow: 'hidden',
      '& > div': {
        height: '100%',
        '& > h5': {
          display: 'none',
        },
        '& > div': {
          marginTop: 0,
          borderRadius: 0,
          minHeight: '100%',
          maxHeight: '100%',
          '& > div:nth-of-type(1)': {
            height: '30vh',
            paddingBottom: 25,
            overflowY: 'auto',
            display: 'flex!important',
            flexDirection: 'column-reverse',
          },
          '& > div:nth-of-type(2)': {
            '& > div:nth-of-type(1)': {
              display: 'none',
            },
            '& > div:nth-of-type(2)': {
              borderRadius: 0,
              position: 'fixed',
              width: '100%',
              bottom: '53px',
            },
          },
        },
      },
    },
    viewersContainerMobile: {
      '& > div': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > h5': {
          display: 'none',
        },
        '& > div': {
          marginTop: 0,
        },
      },
    },
    productsContainerMobile: {
      '& > div': {
        height: 'auto',
        minHeight: '80vh',
        '@media(max-width:480px)': {
          minHeight: 'auto',
        },
      },
    },
    infosContainerMobile: {
      padding: '5px 10px 10px',
      '& > div': {
        width: '100%',
        '& > div:nth-of-type(1)': {
          '& > div:nth-of-type(2)': {
            '& *': {
              fontWeight: 'normal',
            },
          },
          '& > div:nth-of-type(3)': {
            display: 'none',
          },
        },
        '& > div:not(:nth-of-type(1))': {
          display: 'none',
        },
      },
    },
    viewersFullscreen: {
      display: 'none',
      transition: 'fade 0.5s ease-out',
    },
    footerFullscreen: {
      display: 'none!important',
    },
    footerLabelFullscreen: {
      display: 'none!important',
      transition: 'fade 0.5s ease-out',
    },
    chatplaceFullscreen: {
      display: 'none',
      transition: 'fade 0.5s ease-out',
    },
    tabsContainerFullscreen: {
      display: 'block!important',
      position: 'fixed',
      right: 0,
      bottom: '0px',
      width: '30vw',
      height: '82vh',
      '@media(max-width:1180px)': {
        display: 'none!important',
      },
    },
    sharedProductContainerFullscreen: {
      display: 'none!important',
    },
    chatContainerMobileFullscreen: {
      '& > div': {
        '& > div': {
          display: 'flex',
          flexDirection: 'column-reverse',
          '& > div:nth-of-type(1)': {
            height: '72vh',
            flexDirection: 'column-reverse',
          },
          '& > div:nth-of-type(2)': {
            '& > div:nth-of-type(2)': {
              position: 'initial',
            },
          },
        },
      },
    },

    footerMobileFullscreen: {
      display: 'flex',
      width: '30vw',
      position: 'absolute',
      right: '12px',

      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '0 auto',
        gap: 20,
        '& > button': {
          width: 'fit-content !important',
          minWidth: 'fit-content',
        },
        '& > div': {
          width: 'fit-content !important',
          minWidth: 120,
        },
      },

      '@media(max-width:1180px)': {
        display: 'none!important',
      },
    },

    conversationFullscreen: {
      display: 'none',
    },
    tabContentContainerFullscreen: {
      height: '80vh',
      '& > div > div > div > div > div > div ': {
        width: 'auto!important',
      },
    },
  }),
);
